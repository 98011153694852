import { Button, Space, Table, message, Popconfirm, Input, Badge } from "antd";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import LoaderComp from "../../Utility/LoaderComp";
import {
  SettingTwoTone,
  PlusCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import CreateClient from "./CreateClient";
import { getClient, patchClient, getclientinfo } from "../../../API/Clients";
import ArchiveClients from "./ArchiveClients";

const ManageClient = (props) => {
  const { Search } = Input;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [popOpen, setPopOpen] = useState({});
  const [edit, setEdit] = useState(false);
  const [clientData, setClientData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [filteredClient, setFilteredClient] = useState([]);
  const [clientList, setClientList] = useState(null);
  const [clientChange, setClientChange] = useState(false);
  const userRole = useSelector((state) => state.user.role);

  const fail = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };
  const successful = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };
  const dispatch = useDispatch();

  const handleConfirm = async (key) => {
    setConfirmLoading(true);
    let popconf = {};
    popconf[key.name] = false;
    let archiveClient = { ...key, isArchived: "true" };
    if (userRole === "admin") {
      archiveClient["userrole"] = "admin";
    }
    let res = await patchClient(archiveClient);
    setTimeout(() => {
      setPopOpen(popconf);
      setConfirmLoading(false);
      if (res.err === false) {
        successful("client Archived");
        getData();
      } else {
        fail("error occured");
      }
    }, 2000);
  };
  const handlePopConfirm = async (key) => {
    let popconf = {};
    popconf[key] = true;
    setPopOpen(popconf);
  };
  const handleCancel = (key) => {
    let popconf = {};
    popconf[key] = false;
    setPopOpen(popconf);
  };
  const handleEdit = async (clientdata) => {

    let res
    if(clientdata.name){
      res  = await getclientinfo(clientdata.name)
    }
      setClientData(res.msg?.client);
    setEdit(true);
    setOpen(true);
    setModalOpen(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      style: { fontSize: 20 },
      render: (text) => <span>{text}</span>,
      // width: 150,
    },
    {
      title: "CDN",
      dataIndex: "cdnEnabled",
      key: "cdnEnabled",
      render: (_, record) => {
        return (
          <Badge
            color={record?.cdnEnabled ?? true ? "green" : "red"}
            text={record?.cdnEnabled ?? true ? "Enabled" : "Disabled"}
          />
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="text"
            onClick={() => {
              handleEdit(record);
            }}
            icon={<SettingTwoTone twoToneColor="#6425FE" />}
          />
          <Popconfirm
            placement="bottomRight"
            title="Confirm"
            description="Are you sure you want to delete this item?"
            open={popOpen[record.name] ?? false}
            onConfirm={() => {
              handleConfirm(record);
            }}
            onCancel={() => {
              handleCancel(record.name);
            }}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            style={{ width: "100px" }}
            okButtonProps={{ loading: confirmLoading }}
          >
            {contextHolder}
            <Button
              type="text"
              onClick={() => {
                handlePopConfirm(record.name);
              }}
              className="flex items-center justify-center"
              danger
              icon={
                <Icon
                  icon="material-symbols:archive-rounded"
                  width="18"
                  height="18"
                />
              }
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [spin, setSpin] = useState(true);
  const [searchPub, setSearchPub] = useState("");
  const [archiveModal, setArchiveModal] = useState(false);
  const [archOpen, setArchOpen] = useState(false);
  const handleArchived = () => {
    setArchiveModal(true);
    setArchOpen(true);
  };

  const getData = async () => {
    const res = await getClient();
    if (!res?.err) {
      let archivedClients = res.data.msg.filter(
        (client) => client.isArchived === "false"
      );
      setClientList(archivedClients ?? []);
    } else {
      setClientList([]);
    }
  };
  useEffect(() => {
    getData();
  }, [clientChange, confirmLoading, archOpen]);

  useEffect(() => {
    if (clientList) {
      setSpin(false);
    }
    setFilteredClient(clientList);
  }, [clientList]);

  const afterModify = async () => {
    try {
      const response = {};
      setFilteredClient(response.data.msg);
    } catch (error) {
      fail("refresh page");
    }
  };

  const handleCreatePub = () => {
    setEdit(false);
    setOpen(true);
    setModalOpen(true);
  };
  const handleSearch = (text) => {
    setSearchPub(text);
    let searchedPubs = clientList.filter((pub) =>
      pub.name.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredClient(searchedPubs);
  };
  return (
    <>
      {spin ? (
        <LoaderComp />
      ) : (
        <div className="pl-5 pr-5 w-full">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="font-medium text-[20px]">Manage Clients</h1>
            </div>
            <div className="flex gap-4">
              {/* <input
                className="p-2 w-[300px] border-0 outline-none text-sm text-gray-600 placeholder-gray-400"
                placeholder="Search for Publishers"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <SearchOutlined className="px-2 text-[#A49F9F] cursor-pointer" /> */}
              <Button
                type="primary"
                danger
                onClick={handleArchived}
                className="flex justify-center gap-2 items-center"
              >
                {/* <UndoOutlined /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect x="2" y="4" width="20" height="5" rx="2"></rect>
                  <path d="M12 13v7"></path>
                  <path d="m9 16 3-3 3 3"></path>
                  <path d="M4 9v9a2 2 0 0 0 2 2h2"></path>
                  <path d="M20 9v9a2 2 0 0 1-2 2h-2"></path>
                </svg>
                <p className="hidden md:block">Archived Clients</p>
              </Button>
              <Search
                placeholder="Search for Client"
                allowClear
                // style={{
                //   width: 320,
                // }}

                className="sm:w-[320px] w-[100%]"
                value={searchPub}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>

          <Button
            size="large"
            shape="round"
            onClick={handleCreatePub}
            type="primary"
            style={{
              width: "200px",
              height: "60px",
              fontSize: "18px",
              fontWeight: "500",
              position: "fixed",
              right: "30px",
              bottom: "50px",
              zIndex: 10,
            }}
          >
            <PlusCircleFilled style={{ fontSize: "18px" }} /> Add Client
          </Button>
          <Table
            columns={columns}
            dataSource={filteredClient}
            pagination={false}
            // pagination={{ pageSize: 6 }}
            scroll={{ y: 450 }}
          />
        </div>
      )}
      {modalOpen && (
        <CreateClient
          edit={edit}
          open={open}
          setOpen={setOpen}
          setModalOpen={setModalOpen}
          data={clientData}
          successful={successful}
          setSuccess={setSuccess}
          fail={fail}
          handleSearch={handleSearch}
          afterModify={afterModify}
          setClientChange={setClientChange}
        />
      )}
      {archiveModal && (
        <ArchiveClients archOpen={archOpen} setArchOpen={setArchOpen} />
      )}
    </>
  );
};

export default ManageClient;
