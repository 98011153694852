import {
  Button,
  Space,
  Table,
  message,
  Popconfirm,
  Input,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import SuccessPage from "../Utility/SuccessPage";
import { Icon } from "@iconify/react";
import LoaderComp from "../Utility/LoaderComp";
import { deletePublishers } from "../../API/Publishers";
import { getPublishers, getPublishersByName } from "../../API/Publishers";
import {
  SettingTwoTone,
  PlusCircleFilled,
  QuestionCircleOutlined,
  UndoOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import Create from "./Create";
import { updatePub } from "../../redux/reducers/PubSlice";
import ArchivePub from "./ArchivePub";
import { getClient, patchClient, getClientChanges } from "../../API/Clients";
import { getDateTime } from "../Utility/utils";

const Modify = (props) => {
  const { Search } = Input;
  const publisherList = useSelector((state) => state.publisher.publisherList);
  const publisherChange = useSelector(
    (state) => state.publisher.publisherChange
  );

  const userName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const userData = useSelector((state) => state.user);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [popOpen, setPopOpen] = useState({});
  const [edit, setEdit] = useState(false);
  const [pubData, setPubData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [filteredPub, setFilteredPub] = useState([]);

  const fail = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };
  const successful = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };
  const dispatch = useDispatch();

  const handleConfirm = (key) => {
    setConfirmLoading(true);
    let popconf = {};
    popconf[key] = false;
    setTimeout(() => {
      setPopOpen(popconf);
      setConfirmLoading(false);

      messageApi.open({
        type: "success",
        content: "Archived!",
        className: "custom-class",
        style: {
          marginTop: "80vh",
        },
      });
      deletePub(key);
    }, 2000);
  };
  const handlePopConfirm = (key) => {
    let popconf = {};
    popconf[key] = true;
    setPopOpen(popconf);
  };
  const handleCancel = (key) => {
    let popconf = {};
    popconf[key] = false;
    setPopOpen(popconf);
  };
  const handleEdit = async (data) => {
    const res = await getPublishersByName(data.name);
    setPubData(res.data.msg);
    setEdit(true);
    setOpen(true);
    setModalOpen(true);
  };
  const handleLogs = (data) => {
    props.setCurrPub(data.name);
    props.setFromPub(true);
    props.setCurrOption("logs");
  };
  const deletePub = async (key) => {
    const res = await deletePublishers(key);

    dispatch(
      updatePub({ publisherList: publisherList, publisherChange: true })
    );
  };

  const getClientData = async (clientname) => {
    if (userName?.length > 0) {
      return getClientChanges(userData.type === "user" ? clientname : userName);
    }
  };

  const columns = [
    {
      title: "Names",
      dataIndex: "name",
      key: "name",
      style: { fontSize: 20 },
      render: (text) => <span>{text}</span>,
      // width: 150,
    },
    userType === "user"
      ? {
          title: "Client",
          dataIndex: "client",
          key: "client",
          style: { fontSize: 20 },
          render: (text, record) => <span>{record.parent}</span>,
        }
      : {},
    {
      title: "Revenue Share",
      dataIndex: "revShare",
      key: "revShare",
      style: { fontSize: 20 },
      render: (text, record) => (
        <>
          {record.isFixed === "false" ? (
            <Tooltip
              placement="top"
              title={
                "This is the percentage that publisher receives from the partner (Publisher : You)"
              }
            >
              <span>
                {(isNaN(parseInt(record.revShare))
                  ? 0
                  : parseInt(record.revShare)) +
                  " : " +
                  (isNaN(parseInt(record.revShare))
                    ? 0
                    : 100 - parseInt(record.revShare))}
              </span>
            </Tooltip>
          ) : (
            <Tooltip placement="top" title={"This is fixed CPM"}>
              <span>Fixed CPM: {record.fixedCPM} $</span>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="text"
            onClick={() => {
              handleEdit(record);
            }}
            icon={<SettingTwoTone twoToneColor="#6425FE" />}
          />
          <Button
            type="text"
            onClick={() => {
              handleLogs(record);
            }}
            icon={<UnorderedListOutlined twoToneColor="#6425FE" />}
          />
          <Popconfirm
            placement="bottomRight"
            title="Confirm"
            description="Are you sure you want to delete this item?"
            open={popOpen[record.name] ?? false}
            onConfirm={() => {
              handleConfirm(record.name);
              setLogs(record, "deleted");
            }}
            onCancel={() => {
              handleCancel(record.name);
            }}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            style={{ width: "100px" }}
            okButtonProps={{ loading: confirmLoading }}
          >
            {contextHolder}
            <Button
              type="text"
              onClick={() => {
                handlePopConfirm(record.name);
              }}
              className="flex items-center justify-center"
              danger
              icon={
                <Icon
                  icon="material-symbols:archive-rounded"
                  width="18"
                  height="18"
                />
              }
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [spin, setSpin] = useState(true);
  const [pubName, setPubName] = useState("");
  const [searchPub, setSearchPub] = useState("");
  const [archiveModal, setArchiveModal] = useState(false);
  const [archOpen, setArchOpen] = useState(false);
  const handleArchived = () => {
    setArchiveModal(true);
    setArchOpen(true);
  };
  useEffect(() => {
    if (publisherList.length > 0 || !publisherChange) {
      setSpin(false);
    }
    const filter = publisherList.filter((pub) => {
      return pub.isArchived === "false";
    });
    setFilteredPub(filter);
  }, [publisherList]);

  const afterModify = async () => {
    try {
      dispatch(updatePub({ publisherList, publisherChange: true }));
    } catch (error) {
      fail("refresh page");
    }
  };

  const handleCreatePub = () => {
    setEdit(false);
    setOpen(true);
    setModalOpen(true);
  };
  const handleSearch = (text) => {
    setSearchPub(text);

    let searchedPubs = publisherList
      .filter((pub) => {
        return pub.isArchived === "false";
      })
      .filter((pub) => pub.name.toLowerCase().includes(text.toLowerCase()));
    setFilteredPub(searchedPubs);
  };

  const setLogs = async (data, kind, lhs, rhs) => {
    const { date, time } = getDateTime();
    const res = await getClientData(
      userData.type === "user" &&
        userData.role !== "admin" &&
        userData.role !== "adops"
        ? userData.access.client
        : data.client
    );
    const clientData = res.msg;
    if (userType === "user") {
      clientData["userrole"] = "user";
    } else {
      clientData["userrole"] = "client";
    }
    if (kind === "added") {
      const result = await patchClient({
        ...clientData,
        changes: [
          ...(clientData?.changes ?? []),
          {
            user: userName,
            name: data.name,
            time,
            date,
            kind,
            rhs: {
              name: data.name,
              revShare: data.revShare,
              isFixed: data.isFixed,
            },
          },
        ],
      });
    } else if (kind === "edited") {
      const result = await patchClient({
        flag: "logs",
        ...clientData,
        changes: [
          ...(clientData?.changes ?? []),
          {
            user: userName,
            name: data.name,
            time,
            date,
            kind,
            lhs,
            rhs,
          },
        ],
      });
    } else if (kind === "deleted") {
      const result = await patchClient({
        ...clientData,
        changes: [
          ...(clientData?.changes ?? []),
          {
            user: userName,
            name: data.name,
            time,
            date,
            kind,
            lhs: data,
          },
        ],
      });
    }
  };
  return (
    <>
      {success ? (
        <SuccessPage
          type="pub"
          setCurrOption={props.setCurrOption}
          setSuccess={setSuccess}
          pubName={pubName}
          getpubname={props.getpubname}
        />
      ) : spin ? (
        <LoaderComp />
      ) : (
        <div className="pl-5 pr-5 w-full">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="font-medium text-[20px]">Manage Publishers</h1>
            </div>
            <div className="flex gap-4">
              {/* <input
                className="p-2 w-[300px] border-0 outline-none text-sm text-gray-600 placeholder-gray-400"
                placeholder="Search for Publishers"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <SearchOutlined className="px-2 text-[#A49F9F] cursor-pointer" /> */}
              <Button
                type="primary"
                danger
                onClick={handleArchived}
                className="flex justify-center gap-2 items-center"
              >
                {/* <UndoOutlined /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect x="2" y="4" width="20" height="5" rx="2"></rect>
                  <path d="M12 13v7"></path>
                  <path d="m9 16 3-3 3 3"></path>
                  <path d="M4 9v9a2 2 0 0 0 2 2h2"></path>
                  <path d="M20 9v9a2 2 0 0 1-2 2h-2"></path>
                </svg>
                <p className="hidden md:block">Archived Publishers</p>
              </Button>
              <Search
                placeholder="Search for Publishers"
                allowClear
                // style={{
                //   width: 320,
                // }}

                className="sm:w-[320px] w-[100%]"
                value={searchPub}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>

          <Button
            size="large"
            shape="round"
            onClick={handleCreatePub}
            type="primary"
            style={{
              width: "200px",
              height: "60px",
              fontSize: "18px",
              fontWeight: "500",
              position: "fixed",
              right: "30px",
              bottom: "50px",
              zIndex: 10,
            }}
          >
            <PlusCircleFilled style={{ fontSize: "18px" }} /> Add Publisher
          </Button>
          <Table
            columns={columns}
            dataSource={filteredPub}
            pagination={false}
            // pagination={{ pageSize: 6 }}
            scroll={{ y: 450 }}
          />
        </div>
      )}
      {modalOpen && (
        <Create
          edit={edit}
          open={open}
          setOpen={setOpen}
          setModalOpen={setModalOpen}
          data={pubData}
          successful={successful}
          setSuccess={setSuccess}
          setPubName={setPubName}
          fail={fail}
          handleSearch={handleSearch}
          afterModify={afterModify}
          setLogs={setLogs}
        />
      )}
      {archiveModal && (
        <ArchivePub
          archOpen={archOpen}
          setArchOpen={setArchOpen}
          setLogs={setLogs}
        />
      )}
    </>
  );
};

export default Modify;
