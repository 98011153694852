// DUMMY DATA FOR GRAPHS
const graphData = [
    {
      "date": "2010-01",
      "scales": 1998
    },
    {
      "date": "2010-02",
      "scales": 1850
    },
    {
      "date": "2010-03",
      "scales": 1720
    },
    {
      "date": "2010-04",
      "scales": 1818
    },
    {
      "date": "2010-05",
      "scales": 1920
    },
    {
      "date": "2010-06",
      "scales": 1802
    },
    {
      "date": "2010-07",
      "scales": 1945
    },
    {
      "date": "2010-08",
      "scales": 1856
    },
    {
      "date": "2010-09",
      "scales": 2107
    },
    {
      "date": "2010-10",
      "scales": 2140
    },
    {
      "date": "2010-11",
      "scales": 2311
    },
    {
      "date": "2010-12",
      "scales": 1972
    },
    {
      "date": "2011-01",
      "scales": 1760
    },
    {
      "date": "2011-02",
      "scales": 1824
    },
    {
      "date": "2011-03",
      "scales": 1801
    },
    {
      "date": "2011-04",
      "scales": 2001
    },
    {
      "date": "2011-05",
      "scales": 1640
    },
    {
      "date": "2011-06",
      "scales": 1502
    },
    {
      "date": "2011-07",
      "scales": 1621
    },
    {
      "date": "2011-08",
      "scales": 1480
    },
    {
      "date": "2011-09",
      "scales": 1549
    },
    {
      "date": "2011-10",
      "scales": 1390
    },
    {
      "date": "2011-11",
      "scales": 1325
    },
    {
      "date": "2011-12",
      "scales": 1250
    },
    {
      "date": "2012-01",
      "scales": 1394
    },
    {
      "date": "2012-02",
      "scales": 1406
    },
    {
      "date": "2012-03",
      "scales": 1578
    },
    {
      "date": "2012-04",
      "scales": 1465
    },
    {
      "date": "2012-05",
      "scales": 1689
    },
    {
      "date": "2012-06",
      "scales": 1755
    },
    {
      "date": "2012-07",
      "scales": 1495
    },
    {
      "date": "2012-08",
      "scales": 1508
    },
    {
      "date": "2012-09",
      "scales": 1433
    },
    {
      "date": "2012-10",
      "scales": 1344
    },
    {
      "date": "2012-11",
      "scales": 1201
    },
    {
      "date": "2012-12",
      "scales": 1065
    },
    {
      "date": "2013-01",
      "scales": 1255
    },
    {
      "date": "2013-02",
      "scales": 1429
    },
    {
      "date": "2013-03",
      "scales": 1398
    },
    {
      "date": "2013-04",
      "scales": 1678
    },
    {
      "date": "2013-05",
      "scales": 1524
    },
    {
      "date": "2013-06",
      "scales": 1688
    },
    {
      "date": "2013-07",
      "scales": 1500
    },
    {
      "date": "2013-08",
      "scales": 1670
    },
    {
      "date": "2013-09",
      "scales": 1734
    },
    {
      "date": "2013-10",
      "scales": 1699
    },
    {
      "date": "2013-11",
      "scales": 1508
    },
    {
      "date": "2013-12",
      "scales": 1680
    },
    {
      "date": "2014-01",
      "scales": 1750
    },
    {
      "date": "2014-02",
      "scales": 1602
    },
    {
      "date": "2014-03",
      "scales": 1834
    },
    {
      "date": "2014-04",
      "scales": 1722
    },
    {
      "date": "2014-05",
      "scales": 1430
    },
    {
      "date": "2014-06",
      "scales": 1280
    },
    {
      "date": "2014-07",
      "scales": 1367
    },
    {
      "date": "2014-08",
      "scales": 1155
    },
    {
      "date": "2014-09",
      "scales": 1289
    },
    {
      "date": "2014-10",
      "scales": 1104
    },
    {
      "date": "2014-11",
      "scales": 1246
    },
    {
      "date": "2014-12",
      "scales": 1098
    },
    {
      "date": "2015-01",
      "scales": 1189
    },
    {
      "date": "2015-02",
      "scales": 1276
    },
    {
      "date": "2015-03",
      "scales": 1033
    },
    {
      "date": "2015-04",
      "scales": 956
    },
    {
      "date": "2015-05",
      "scales": 845
    },
    {
      "date": "2015-06",
      "scales": 1089
    },
    {
      "date": "2015-07",
      "scales": 944
    },
    {
      "date": "2015-08",
      "scales": 1043
    },
    {
      "date": "2015-09",
      "scales": 893
    },
    {
      "date": "2015-10",
      "scales": 840
    },
    {
      "date": "2015-11",
      "scales": 934
    },
    {
      "date": "2015-12",
      "scales": 810
    },
    {
      "date": "2016-01",
      "scales": 782
    },
    {
      "date": "2016-02",
      "scales": 1089
    },
    {
      "date": "2016-03",
      "scales": 745
    },
    {
      "date": "2016-04",
      "scales": 680
    },
    {
      "date": "2016-05",
      "scales": 802
    },
    {
      "date": "2016-06",
      "scales": 697
    },
    {
      "date": "2016-07",
      "scales": 583
    },
    {
      "date": "2016-08",
      "scales": 456
    },
    {
      "date": "2016-09",
      "scales": 524
    },
    {
      "date": "2016-10",
      "scales": 398
    },
    {
      "date": "2016-11",
      "scales": 278
    },
    {
      "date": "2016-12",
      "scales": 195
    },
    {
      "date": "2017-01",
      "scales": 145
    },
    {
      "date": "2017-02",
      "scales": 207
    }
  ]

export default graphData;