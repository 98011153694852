const userFields = [
  {
    name: "GA_CODE",
    label: "GA CODE",
    type: "Input",
    placeholder: "Enter code",
    default: "",
    selectOptions: null,
    isRequired: ["unibots"],
    display: true,
    category: "Others",
    description: "Generate GA code from google Analytics",
    style: { width: "30%" },
    disabledclients: ["unibots"],
  },
  {
    name: "CHECK_BLOCKED_PAGES",
    label: "CHECK BLOCKED PAGES",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    isRequired: ["unibots"],
    enabledclients: ["unibots", "user"],
  },
  {
    name: "DIV_ID_CLASS",
    label: "Smart Player Floating ID",
    type: "Textarea",
    placeholder: "Enter div id here",
    default: "",
    style: { width: "90%" },
    rows: 5,
    saveType: "array",
    isRequired: false,
    display: true,
    category: "Others",
    info: "Enter one div id per line",
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "BLOCK_CHECK_NAME",
    label: "BLOCK CHECK NAME",
    type: "Input",
    placeholder: "Enter code",
    default: "",
    selectOptions: null,
    isRequired: ["unibots"],
    enabledclients: ["unibots", "user"],
    display: true,
    category: "Others",
    description: "",
    style: { width: "90%" },
    lower: true,
    info: (
      <>
        Required for Block Check, Use the website name for which the player will
        go live without https:// and .com <br /> e.g. for{" "}
        <b>https://www.example.com</b> use <b>example</b> only.
      </>
    ),
  },
  {
    name: "BLOCK_GEO_LOCATION",
    label: "BLOCK GEO LOCATION",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "GEO_LOCATIONS_TO_BLOCK",
    label: "GEO LOCATIONS TO BLOCK",
    type: "Textarea",
    placeholder: "Enter Country code here",
    default: "",
    style: { width: "90%" },
    rows: 5,
    saveType: "array",
    isRequired: false,
    display: true,
    category: "Others",
    info: "Enter one Country code per line",
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "SIZE",
    label: "Desktop Size",
    type: "Dropdown",
    default: "640x360",
    split: ["PLAYER_WIDTH", "PLAYER_HEIGHT"],
    selectOptions: [
      "640x360",
      "560x315",
      "544x306",
      "528x297",
      "400x300",
      "400x225",
      "360x203",
      "320x180",
      "300x250",
      "256x144",
      "192x108",
    ],
    isRequired: true,
    display: true,
    dependency: [{ ENABLED_FOR_PC: true }],
    category: "Main",
    description: "Size of player on PC",
  },

  {
    name: "SIZE_MB",
    label: "Mobile size",
    type: "Dropdown",
    default: "400x225",
    split: ["PLAYER_WIDTH_MB", "PLAYER_HEIGHT_MB"],
    selectOptions: [
      "560x315",
      "544x306",
      "528x297",
      "400x300",
      "400x225",
      "360x203",
      "320x180",
      "300x250",
      "256x144",
      "192x108",
    ],
    isRequired: true,
    display: true,
    dependency: [{ ENABLED_FOR_MB: true }],
    category: "MainMobile",
    description: "Size of player on Mobile",
  },
  {
    name: "FLOATING_BLOCKED_URL",
    label: "Floating blocked url",
    type: "Textarea",
    placeholder: "Enter urls here",
    default: "",
    style: { width: "90%" },
    rows: 5,
    saveType: "array",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    dependency: [{ PLAYER_FLOATING: true }, { IS_FLOATING_ON_MOBILE: true }],
    description: "Enter one url per line for blocking on both PC & Mobile",
    info: "Enter one url per line",
  },
  {
    name: "CUSTOM_VIDEO_SOURCE",
    label: "Custom Video Source",
    type: "Textarea",
    placeholder: "Enter urls here",
    default: "",
    style: { width: "80%" },
    rows: 10,
    saveType: "array",
    selectOptions: null,
    enabledclients: ["unibots", "admin"],
    isRequired: false,
    display: true,
    category: "Others",
    dependency: [{ PLAYER_FLOATING: true }, { IS_FLOATING_ON_MOBILE: true }],
    description: "Enter one url per line for adding additional media links",
    info: "Enter one url per line. This option is additional to the actual content",
  },
  {
    name: "CLIENTSITE_TARGETED_DIV_ID_CLASSES",
    label: "Target on specific div id or class",
    type: "Textarea",
    placeholder: "Enter ids here",
    default: "",
    style: { width: "90%" },
    rows: 5,
    saveType: "array",
    selectOptions: null,
    isRequired: false,
    display: false,
    category: "Others",
    description:
      "Enter one id per line without identifier ('.', '#') for targeting ad div id or class",
    info: "Enter one id per line without identifier ('.', '#'). Player will be present after that div id or class.",
  },

  {
    name: "PLAYER_FLOATING",
    label: "Float on desktop",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    category: "FloatingHeader",
    description: "Enable floating on PC",
    style: { width: "40%" },
  },
  {
    name: "IS_FLOATING_ON_MOBILE",
    label: "Float on mobile",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    category: "FloatingMobileHeader",
    description: "Enable floating on Mobile",
    style: { width: "40%" },
  },
  {
    name: "DISABLE_LOGS",
    label: "DISABLE LOGS",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "AD_DELAY_ENABLED",
    label: "Enable Aggressive Mode",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    category: "Others",
    description: "Enable ad delay",
    disabledclients: ["unibots", "adipolo"],
    confirm: true,
  },
  {
    name: "AD_REQUEST_DURATION",
    label: "Ad request delay (seconds)",
    type: "Input",
    default: 0,
    display: true,
    isNum: true,
    dependency: [{ AD_DELAY_ENABLED: false }],
    category: "Others",
    description: "Specify ad request delay in seconds",
    disabledclients: ["unibots"],
  },
  {
    name: "SHORT_TIME_FLOATING",
    label: "Float for only 7 seconds",
    type: "switch",
    default: false,
    isBool: true,
    isRequired: true,
    display: true,
    dependency: [{ PLAYER_FLOATING: true }, { IS_FLOATING_ON_MOBILE: true }],
    category: "Others",
    description: "Player float only for short time",
  },
  {
    name: "ENABLE_CLARITY",
    label: "Enable Clarity",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "CLARITY_CODE",
    label: "Clarity Code",
    type: "Input",
    placeholder: "Enter code",
    default: "",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "MARGIN_BOTTOM"],
    label: "MARGIN BOTTOM",
    type: "Input",
    placeholder: "Enter Margin Bottom",
    default: 12,
    selectOptions: null,
    isRequired: false,
    display: true,
    isNum: true,
    category: "Others",
    dependency: [{ PLAYER_FLOATING: true }],
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "PLAY_AD_UNMUTED",
    label: "PLAY AD UNMUTED",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "RUN_OUTSTREAM_PLAYER",
    label: "RUN OUTSTREAM PLAYER",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "OUTSTREAM_PLAYER_NAME",
    label: "OUTSTREAM PLAYER NAME",
    default: "",
    type: "Input",
    isRequired: false,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "SEND_AD_WHEN_PAUSED",
    label: "SEND AD WHEN PAUSED",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },

  {
    name: "RUN_MULTI_PLAYER",
    label: "RUN MULTI PLAYER",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "MULTI_CLIENT_NAME",
    label: "MULTI CLIENT NAME",
    default: "",
    type: "Input",
    isRequired: false,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },

  {
    name: "PLAY_ON_DOM_CLICK",
    label: "PLAY ON DOM CLICK",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "MUTE_PLAYER",
    label: "MUTE PLAYER",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "COLLECT_DATA",
    label: "COLLECT DATA",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "PLCMT",
    default: -2,
    label: "PLCMT",
    type: "Dropdown",
    isRequired: false,
    category: "Others",
    selectOptions: [-2, -1, 0, 1, 2],
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },

  {
    name: "SEND_AD_WITHOUT_DELAY",
    label: "SEND AD WITHOUT DELAY",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "DESTROY_AD_AFTER_7_SEC",
    label: "DESTROY AD AFTER 7 SEC",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "REMOVE_PLCMT_FOR_VPMUTE1",
    label: "REMOVE PLCMT FOR VPMUTE1",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "SCRIPT_TEST_LEVEL",
    label: "SCRIPT TEST LEVEL",
    type: "Dropdown",
    default: 0,
    selectOptions: [
      { value: 0, label: "Disabled" },
      { value: 1, label: "Level 1" },
      { value: 2, label: "Level 2" },
      { value: 3, label: "Level 3" },
      { value: 4, label: "Level 4" },
    ],
    isRequired: false,
    display: true,
    category: "Others",
    description: "Used for testing purpose",
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "SEND_5_ADS_POLICY_VIOLATION",
    label: "SEND 5 ADS POLICY VIOLATION",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "SEND_FIRST_AD_PAUSED",
    label: "SEND FIRST AD PAUSED",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "SEND_DATA_TO_DB",
    label: "SEND DATA TO DB",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "AD_MODE",
    label: "AD MODE",
    type: "Dropdown",
    selectOptions: ["live", "test"],
    default: "live",
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "COMPANION_AD",
    label: "COMPANION AD",
    type: "objectgroup",
    default: {},
    fields: [
      {
        name: ["COMPANION_AD", "IS_ENABLE"],
        label: "IS ENABLE",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "30px", width: "15%" },
      },
      {
        name: ["COMPANION_AD", "PIXEL_LOAD"],
        label: "PIXEL LOAD",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "28px", width: "15%" },
      },
      {
        name: ["COMPANION_AD", "AD_WIDTH_PC"],
        label: "AD WIDTH PC",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "30px", width: "15%" },
      },
      {
        name: ["COMPANION_AD", "AD_WIDTH_MOB"],
        label: "AD WIDTH MOB",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "28px", width: "15%" },
      },

      {
        name: ["COMPANION_AD", "DIV_ID"],
        label: "DIV ID",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "30px", width: "25%" },
      },
      {
        name: ["COMPANION_AD", "SLOT_ID"],
        label: "SLOT ID",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "25px", width: "98%", marginTop: "-25px" },
      },
      {
        name: ["COMPANION_AD", "SLOT_SIZE"],
        default: ["300x50", "320x100", "300x100", "320x50"],
        label: "SLOT SIZE",
        type: "DropdownMulti",
        isRequired: false,
        category: "Others",
        selectOptions: ["300x50", "320x100", "300x100", "320x50"],
        display: true,
        style: {
          marginLeft: "25px",
          width: "98%",
          marginTop: "-25px",
        },
      },
    ],
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "IGNORE_POLICY_CHNAGE",
    label: "IGNORE POLICY CHANGE",
    type: "objectgroup",
    default: {},
    fields: [
      {
        name: ["IGNORE_POLICY_CHNAGE", "IS_ENABLE"],
        label: "IS ENABLE",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["IGNORE_POLICY_CHNAGE", "PERCENT_USERS_TO_RUN"],
        label: "PERCENT USERS TO RUN",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["IGNORE_POLICY_CHNAGE", "USER_FREQUENCY_CAP"],
        label: "USER FREQUENCY CAP",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["IGNORE_POLICY_CHNAGE", "ENABLE_TIME"],
        label: "ENABLE TIME",
        type: "DropdownMulti",
        isRequired: false,
        category: "Others",
        selectOptions: [""],
        display: true,
        style: {
          width: "98%",
          height: "40px",
        },
        info: "Enter start and end times in the format 'HH:MM' (e.g., '01:00', '04:15', '12:00', '17:40')",
      },
      {
        name: ["IGNORE_POLICY_CHNAGE", "START_TIME"],
        label: "START TIME",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        saveType: "array",
      },
      {
        name: ["IGNORE_POLICY_CHNAGE", "END_TIME"],
        label: "END TIME",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        saveType: "array",
      },
    ],
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "NON_LINEAR_AD",
    label: "NON LINEAR AD",
    type: "objectgroup",
    default: {},
    fields: [
      {
        name: ["NON_LINEAR_AD", "IS_ENABLE"],
        label: "IS ENABLE",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "30px", width: "20%" },
      },
      {
        name: ["NON_LINEAR_AD", "IS_ENABLE_MB"],
        label: "IS ENABLE MB",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "40px", width: "20%" },
      },

      {
        name: ["NON_LINEAR_AD", "AD_REFRESH_TIME"],
        label: "AD REFRESH TIME",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "40px", width: "20%" },
      },
      {
        name: ["NON_LINEAR_AD", "REMOVE_AD_AFTER_DISPLAYED"],
        label: "REMOVE AD AFTER DISPLAYED",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "40px", width: "20%" },
      },
      {
        name: ["NON_LINEAR_AD", "DIV_ID"],
        label: "DIV ID",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "27px", width: "25%" },
      },
      {
        name: ["NON_LINEAR_AD", "SLOT_ID"],
        label: "SLOT ID",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        style: { width: "78%", marginLeft: "-60px" },
      },

      {
        name: ["NON_LINEAR_AD", "SLOT_SIZE_PC"],
        default: ["300x50", "320x100", "300x100", "320x50"],
        label: "SLOT SIZE PC",
        type: "DropdownMulti",
        isRequired: false,
        category: "Others",
        selectOptions: ["300x50", "320x100", "300x100", "320x50"],
        display: true,
        style: {
          marginLeft: "25px",
          width: "98%",
          marginTop: "-20px",
        },
      },
      {
        name: ["NON_LINEAR_AD", "SLOT_SIZE_MB"],
        default: ["300x50", "320x100", "300x100", "320x50"],
        label: "SLOT SIZE MOB",
        type: "DropdownMulti",
        isRequired: false,
        category: "Others",
        selectOptions: ["300x50", "320x100", "300x100", "320x50"],
        display: true,
        style: {
          marginLeft: "25px",
          width: "98%",
          marginTop: "-20px",
        },
      },
    ],
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "STICKY_V_POSITION"],
    label: "Vertical position",
    type: "Dropdown",
    default: "bottom",
    selectOptions: [
      { value: "bottom", label: "Floating bottom" },
      { value: "top", label: "Floating top" },
    ],
    display: true,
    dependency: [{ PLAYER_FLOATING: true }],
    category: "Floating",
    description: "Vertical position on desktop",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "BOTTOM_SPACING"],
    label: "Space from bottom",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [{ PLAYER_FLOATING: true }, { STICKY_V_POSITION: "bottom" }],
    category: "Floating",
    description: "Space from bottom on desktop",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "TOP_SPACING"],
    label: "Space from top",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [{ PLAYER_FLOATING: true }, { STICKY_V_POSITION: "top" }],
    category: "Floating",
    description: "Space from top on desktop",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "FLOATING_SIZE"],
    label: "Desktop floating size",
    type: "Dropdown",
    default: "400x225",
    split: ["STICKY_PLAYER_WIDTH", "STICKY_PLAYER_HEIGHT"],
    selectOptions: ["400x225", "360x203", "320x180", "256x144", "192x108"],
    isRequired: true,
    display: true,
    dependency: [{ PLAYER_FLOATING: true }],
    category: "Floating",
    description: "Size of floating player on PC",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "STICKY_H_POSITION"],
    label: "Horizontal position",
    type: "Dropdown",
    default: "right",
    selectOptions: [
      { value: "right", label: "Floating right" },
      { value: "left", label: "Floating left" },
    ],
    display: true,
    dependency: [{ PLAYER_FLOATING: true }],
    category: "Floating",
    description: "Horizontal position on desktop",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "LEFT_SPACING"],
    label: "Space from left",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [{ PLAYER_FLOATING: true }, { STICKY_H_POSITION: "left" }],
    category: "Floating",
    description: "Space from left on desktop",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "RIGHT_SPACING"],
    label: "Space from right",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [{ PLAYER_FLOATING: true }, { STICKY_H_POSITION: "right" }],
    category: "Floating",
    description: "Space from right on desktop",
  },

  {
    name: ["FLOATING_PLAYER_SETTINGS", "STICKY_V_POSITION_MB"],
    label: "Vertical position on mobile",
    type: "Dropdown",
    default: "bottom",
    selectOptions: [
      { value: "bottom", label: "Floating bottom" },
      { value: "top", label: "Floating top" },
    ],
    display: true,
    dependency: [{ IS_FLOATING_ON_MOBILE: true }],
    category: "FloatingMobile",
    description: "Vertical position on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "BOTTOM_SPACING_MB"],
    label: "Space from bottom on mobile",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [
      { IS_FLOATING_ON_MOBILE: true },
      { STICKY_V_POSITION_MB: "bottom" },
    ],
    category: "FloatingMobile",
    description: "Space from bottom on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "TOP_SPACING_MB"],
    label: "Space from top on mobile",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [
      { IS_FLOATING_ON_MOBILE: true },
      { STICKY_V_POSITION_MB: "top" },
    ],
    category: "FloatingMobile",
    description: "Space from top on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "FLOATING_SIZE_MB"],
    label: "Mobile floating size",
    type: "Dropdown",
    default: "256x144",
    split: ["STICKY_PLAYER_WIDTH_MB", "STICKY_PLAYER_HEIGHT_MB"],
    selectOptions: ["256x144", "192x108"],
    isRequired: true,
    display: true,
    dependency: [{ IS_FLOATING_ON_MOBILE: true }],
    category: "FloatingMobile",
    description: "Size of floating player on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "STICKY_H_POSITION_MB"],
    label: "Horizontal position on mobile",
    type: "Dropdown",
    default: "right",
    selectOptions: [
      { value: "right", label: "Floating right" },
      { value: "left", label: "Floating left" },
    ],
    display: true,
    dependency: [{ IS_FLOATING_ON_MOBILE: true }],
    category: "FloatingMobile",
    description: "Horizontal position on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "LEFT_SPACING_MB"],
    label: "Space from left on mobile",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [
      { IS_FLOATING_ON_MOBILE: true },
      { STICKY_H_POSITION_MB: "left" },
    ],
    category: "FloatingMobile",
    description: "Space from left on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "RIGHT_SPACING_MB"],
    label: "Space from right on mobile",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [
      { IS_FLOATING_ON_MOBILE: true },
      { STICKY_H_POSITION_MB: "right" },
    ],
    category: "FloatingMobile",
    description: "Space from right on mobile",
  },
  {
    name: "ENABLED_FOR_PC",
    label: "ENABLED FOR PC",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    category: "MainHeader",
    description: "Enabled for PC",
    style: { width: "40%" },
  },
  {
    name: "ENABLED_FOR_MB",
    label: "ENABLED FOR MOBILE",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    category: "MainMobileHeader",
    description: "Enabled for MB",
    style: { width: "40%" },
  },
  {
    name: "CLOSE_BUTTON_ENABLE",
    label: "Close button enable",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    dependency: [{ ENABLED_FOR_PC: true }],
    category: "Main",
    description: "Toggle close button visibility",
  },
  {
    name: "CLOSE_BUTTON_ENABLE_MB",
    label: "Close button enable on mobile",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    dependency: [{ ENABLED_FOR_MB: true }],
    category: "MainMobile",
    description: "Toggle close button visibility on mobile",
  },
];

const adminFields = [
  {
    name: "GA_CODE",
    label: "GA CODE",
    type: "Input",
    placeholder: "Enter code",
    default: "",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    description: "Generate GA code from google Analytics",
    style: { width: "30%" },
  },
  {
    name: "DIV_ID_CLASS",
    label: "Smart Player Floating ID",
    type: "Textarea",
    placeholder: "Enter div id here",
    default: "",
    style: { width: "90%" },
    rows: 5,
    saveType: "array",
    isRequired: false,
    display: true,
    category: "Others",
    info: "Enter one div id per line",
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "BLOCK_CHECK_NAME",
    label: "BLOCK CHECK NAME",
    type: "Input",
    placeholder: "Enter code",
    default: "",
    selectOptions: null,
    isRequired: ["unibots"],
    enabledclients: ["unibots", "user"],
    display: true,
    category: "Others",
    description: "",
    style: { width: "90%" },
    lower: true,
    info: (
      <>
        Required for Block Check, Use the website name for which the player will
        go live without https:// and .com <br /> e.g. for{" "}
        <b>https://www.deepika.com</b> use <b>deepika</b> only.
      </>
    ),
  },
  {
    name: "BLOCK_GEO_LOCATION",
    label: "BLOCK GEO LOCATION",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "GEO_LOCATIONS_TO_BLOCK",
    label: "GEO LOCATIONS TO BLOCK",
    type: "Textarea",
    placeholder: "Enter Country code here",
    default: "",
    style: { width: "90%" },
    rows: 5,
    saveType: "array",
    isRequired: false,
    display: true,
    category: "Others",
    info: "Enter one Country code per line",
    enabledclients: ["unibots", "test90", "user"],
  },

  {
    name: "LOAD_VD_TIMEOUT",
    label: "LOAD VD TIMEOUT",
    type: "Input",
    placeholder: "Enter timeout",
    default: 8000,
    selectOptions: null,
    isNum: true,
    isRequired: false,
    display: true,
    category: "Others",
    description: "Player load time before throwing error",
  },
  {
    name: "SIZE",
    label: "Desktop Size",
    type: "Dropdown",
    default: "640x360",
    split: ["PLAYER_WIDTH", "PLAYER_HEIGHT"],
    selectOptions: [
      "640x360",
      "560x315",
      "544x306",
      "528x297",
      "400x300",
      "400x225",
      "360x203",
      "320x180",
      "300x250",
      "256x144",
      "192x108",
    ],
    isRequired: true,
    display: true,
    dependency: [{ ENABLED_FOR_PC: true }],
    category: "Main",
    description: "Size of player on PC",
  },
  {
    name: "SIZE_MB",
    label: "Mobile size",
    type: "Dropdown",
    default: "400x225",
    split: ["PLAYER_WIDTH_MB", "PLAYER_HEIGHT_MB"],
    selectOptions: [
      "560x315",
      "544x306",
      "528x297",
      "400x300",
      "400x225",
      "360x203",
      "320x180",
      "300x250",
      "256x144",
      "192x108",
    ],
    isRequired: true,
    display: true,
    dependency: [{ ENABLED_FOR_MB: true }],
    category: "MainMobile",
    description: "Size of player on Mobile",
  },
  {
    name: "SCRIPT_TEST_LEVEL",
    label: "SCRIPT TEST LEVEL",
    type: "Dropdown",
    default: 0,
    selectOptions: [
      { value: 0, label: "Disabled" },
      { value: 1, label: "Level 1" },
      { value: 2, label: "Level 2" },
      { value: 3, label: "Level 3" },
      { value: 4, label: "Level 4" },
    ],
    isRequired: false,
    display: true,
    category: "Others",
    description: "Used for testing purpose",
  },
  {
    name: "CUSTOM_AD_DELAY",
    label: "CUSTOM AD DELAY",
    type: "Input",
    placeholder: "Enter number",
    default: "",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    description: "Ad delay amount",
  },
  {
    name: "CLIENTSITE_TARGETED_DIV_ID_CLASSES",
    label: "CLIENTSITE TARGETED DIV ID CLASSES",
    type: "Input",
    placeholder: "Enter id or class",
    default: "",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    description: "Player is Placed after this div id/class",
  },
  {
    name: "PL_BUTTON_LINK",
    label: "PL BUTTON LINK",
    type: "Input",
    placeholder: "Enter url",
    default: "",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    description: "Link for learn more button",
  },
  {
    name: "HLS_CLIENT_NAME",
    label: "HLS CLIENT NAME",
    type: "Input",
    placeholder: "Enter name",
    default: "",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    description: "Enter hls client's name",
  },
  {
    name: "VIDEO_DYNAMIC_API",
    label: "VIDEO DYNAMIC API",
    type: "Input",
    placeholder: "Enter api",
    default: "",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    description: "Api link for client videos",
    style: { width: "90%" },
  },
  {
    name: "FLOATING_BLOCKED_URL",
    label: "Floating blocked url",
    type: "Textarea",
    placeholder: "Enter urls here",
    default: "",
    style: { width: "90%" },
    rows: 5,
    saveType: "array",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    dependency: [{ PLAYER_FLOATING: true }, { IS_FLOATING_ON_MOBILE: true }],
    description: "Enter one url per line for blocking on both PC & Mobile",
    info: "Enter one url per line",
  },
  {
    name: "CUSTOM_VIDEO_SOURCE",
    label: "Custom Video Source",
    type: "Textarea",
    placeholder: "Enter urls here",
    default: "",
    style: { width: "100%" },
    rows: 10,
    saveType: "array",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    dependency: [{ PLAYER_FLOATING: true }, { IS_FLOATING_ON_MOBILE: true }],
    description: "Enter one url per line for adding additional media links",
    info: "Enter one url per line. This option is additional to the actual content",
  },
  {
    name: "REQUIRED_SCROLL_FOR_PL_RUN",
    label: "REQUIRED SCROLL FOR PL RUN",
    type: "Input",
    placeholder: "Enter in pixels",
    default: 100,
    selectOptions: null,
    isRequired: false,
    isNum: true,
    display: true,
    category: "Others",
    description: "",
  },
  {
    name: "NEW_AD_REQUEST_VERSION",
    label: "NEW AD REQUEST VERSION",
    type: "Dropdown",
    placeholder: "Select version",
    default: 2,
    selectOptions: [1, 2, 3, 4],
    isRequired: false,
    display: true,
    category: "Others",
    description: "Apply delay on ad request, after certain ad request.",
  },
  {
    name: "AD_DELAY_DURATIONS",
    label: "AD DELAY DURATIONS",
    type: "Textarea",
    placeholder: "Enter duration",
    default: [900, 30, 900],
    selectOptions: null,
    saveType: "array(int)",
    style: { width: "90%" },
    rows: 5,
    isRequired: false,
    display: true,
    category: "Others",
    description: "4c structure",
  },
  {
    name: "AD_PLAY_VERSION",
    label: "AD PLAY VERSION",
    type: "Input",
    placeholder: "Enter number",
    default: 4,
    selectOptions: null,
    isNum: true,
    isRequired: false,
    display: true,
    category: "Others",
    description: "",
  },
  {
    name: "AD_REQUEST_DURATION",
    label: "AD REQUEST DURATION",
    type: "Input",
    placeholder: "Enter number",
    default: 0,
    selectOptions: null,
    isNum: true,
    isRequired: false,
    display: true,
    category: "Others",
    description: "1 sec, delay between each request.",
  },
  {
    name: "PREROLL_TIMEOUT",
    label: "PREROLL TIMEOUT",
    type: "Input",
    placeholder: "Enter number",
    default: 10000,
    selectOptions: null,
    isNum: true,
    isRequired: false,
    display: true,
    category: "Others",
    description: "Numeric values, Spinner Timeout",
  },
  {
    name: "REQUIRED_PX_FOR_NEARBY_VIEWPORT",
    label: "REQUIRED PX FOR NEARBY VIEWPORT",
    type: "Input",
    placeholder: "Enter number",
    default: 0,
    selectOptions: null,
    isNum: true,
    isRequired: false,
    display: true,
    category: "Others",
    description: "Player load before pixel",
  },
  {
    name: "AD_PLAY_DURATION_VERSION",
    label: "AD PLAY DURATION VERSION",
    type: "Input",
    placeholder: "Enter number",
    default: 2,
    selectOptions: null,
    isNum: true,
    isRequired: false,
    display: true,
    category: "Others",
    description: "Pattern for ad delay request",
  },
  {
    name: "MANAGE_DEALY_FOR_UNFILL_REQUESTS",
    label: "MANAGE DEALY FOR UNFILL REQUESTS",
    type: "objectgroup",
    default: {},
    fields: [
      {
        name: ["MANAGE_DEALY_FOR_UNFILL_REQUESTS", "IS_ENABLE"],
        label: "IS ENABLE",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: true,
        category: "Others",
        display: true,
      },
      {
        name: ["MANAGE_DEALY_FOR_UNFILL_REQUESTS", "AFTER"],
        label: "AFTER",
        type: "Input",
        default: 5,
        selectOptions: null,
        isNum: true,
        category: "Others",
        display: true,
      },
      {
        name: ["MANAGE_DEALY_FOR_UNFILL_REQUESTS", "DELAY"],
        label: "DELAY",
        type: "Input",
        default: 1,
        selectOptions: null,
        isNum: true,
        category: "Others",
        display: true,
      },
      {
        name: ["MANAGE_DEALY_FOR_UNFILL_REQUESTS", "DEFAULT"],
        label: "DEFAULT",
        type: "Input",
        default: 1,
        selectOptions: null,
        isNum: true,
        category: "Others",
        display: true,
      },
      {
        name: ["MANAGE_DEALY_FOR_UNFILL_REQUESTS", "PASSBACK_URL"],
        label: "PASSBACK_URL",
        type: "Input",
        default: "",
        selectOptions: null,
        category: "Others",
        display: true,
      },
      {
        name: ["MANAGE_DEALY_FOR_UNFILL_REQUESTS", "4C_COMPAITABLE"],
        label: "4C COMPAITABLE",
        type: "switch",
        default: false,
        isBool: true,
        category: "Others",
        display: true,
      },
    ],
    category: "Others",
    display: true,
  },
  {
    name: "CLICK_TO_PLAY",
    label: "CLICK TO PLAY",
    type: "objectgroup",
    default: {},
    fields: [
      {
        name: ["CLICK_TO_PLAY", "IS_ENABLE"],
        label: "IS ENABLE",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: true,
        category: "Others",
        display: true,
      },
      {
        name: ["CLICK_TO_PLAY", "WAITING"],
        label: "WAITING",
        type: "Input",
        default: 0,
        selectOptions: null,
        isNum: true,
        category: "Others",
        display: true,
      },
      {
        name: ["CLICK_TO_PLAY", "KEEP_FLOAT_AFTER_AUTOPLAY"],
        label: "KEEP FLOAT AFTER AUTOPLAY",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: true,
        category: "Others",
        display: true,
      },
    ],
    category: "Others",
    display: true,
  },
  {
    name: "CUSTOM_CONFIG",
    label: "CUSTOM CONFIG",
    type: "objectgroup",
    default: {},
    fields: [
      {
        name: ["CUSTOM_CONFIG", "IS_CUSTOM_CONFIG_ENABLE"],
        label: "IS ENABLE",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["CUSTOM_CONFIG", "INJECT_JS"],
        label: "INJECT JS",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["CUSTOM_CONFIG", "JS_VALUE"],
        label: "JS VALUE",
        type: "Input",
        default: "",
        selectOptions: null,
        category: "Others",
        display: true,
        style: { width: "35%" },
      },
      {
        name: ["CUSTOM_CONFIG", "INJECT_CSS"],
        label: "INJECT CSS",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["CUSTOM_CONFIG", "CSS_VALUE"],
        label: "CSS VALUE",
        type: "Input",
        default: "",
        selectOptions: null,
        category: "Others",
        display: true,
        style: { width: "40%" },
      },
    ],
    category: "Others",
    display: true,
  },
  {
    name: "IGNORE_POLICY_CHANGE",
    label: "IGNORE POLICY CHANGE",
    type: "objectgroup",
    default: {},
    fields: [
      {
        name: ["IGNORE_POLICY_CHNAGE", "IS_ENABLE"],
        label: "IS ENABLE",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["IGNORE_POLICY_CHNAGE", "PERCENT_USERS_TO_RUN"],
        label: "PERCENT USERS TO RUN",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["IGNORE_POLICY_CHNAGE", "USER_FREQUENCY_CAP"],
        label: "USER FREQUENCY CAP",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["IGNORE_POLICY_CHNAGE", "ENABLE_TIME"],
        label: "ENABLE TIME",
        type: "DropdownMulti",
        isRequired: false,
        category: "Others",
        selectOptions: [""],
        display: true,
        style: {
          width: "98%",
          height: "40px",
        },
        info: "Enter start and end times in the format 'HH:MM' (e.g., '01:00', '04:15', '12:00', '17:40')",
      },
      {
        name: ["IGNORE_POLICY_CHNAGE", "START_TIME"],
        label: "START TIME",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        saveType: "array",
      },
      {
        name: ["IGNORE_POLICY_CHNAGE", "END_TIME"],
        label: "END TIME",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        saveType: "array",
      },
    ],
    category: "Others",
    display: true,
    enabledclients: ["unibots", "test90", "user"],
  },
  {
    name: "COMPANION_AD",
    label: "COMPANION AD",
    type: "objectgroup",
    default: {},
    fields: [
      {
        name: ["COMPANION_AD", "IS_ENABLE"],
        label: "IS ENABLE",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "30px", width: "15%" },
      },
      {
        name: ["COMPANION_AD", "PIXEL_LOAD"],
        label: "PIXEL LOAD",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "28px", width: "15%" },
      },
      {
        name: ["COMPANION_AD", "AD_WIDTH_PC"],
        label: "AD WIDTH PC",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "30px", width: "15%" },
      },
      {
        name: ["COMPANION_AD", "AD_WIDTH_MOB"],
        label: "AD WIDTH MOB",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "28px", width: "15%" },
      },

      {
        name: ["COMPANION_AD", "DIV_ID"],
        label: "DIV ID",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "30px", width: "25%" },
      },
      {
        name: ["COMPANION_AD", "SLOT_ID"],
        label: "SLOT ID",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "25px", width: "98%", marginTop: "-25px" },
      },
      {
        name: ["COMPANION_AD", "SLOT_SIZE"],
        default: ["300x50", "320x100", "300x100", "320x50"],
        label: "SLOT SIZE",
        type: "DropdownMulti",
        isRequired: false,
        category: "Others",
        selectOptions: ["300x50", "320x100", "300x100", "320x50"],
        display: true,
        style: {
          marginLeft: "25px",
          width: "98%",
          marginTop: "-25px",
        },
      },
    ],
    category: "Others",
    display: true,
  },
  {
    name: "PLAY_FLOATING_MUTE_UNMUTE",
    label: "PLAY FLOATING MUTE UNMUTE",
    type: "objectgroup",
    default: {},
    fields: [
      {
        name: ["PLAY_FLOATING_MUTE_UNMUTE", "IS_ENABLE"],
        label: "IS ENABLE",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["PLAY_FLOATING_MUTE_UNMUTE", "RUN_INREAD"],
        label: "RUN INREAD",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
      {
        name: ["PLAY_FLOATING_MUTE_UNMUTE", "RUN_ON_AD"],
        label: "RUN ON AD",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
      },
    ],
    category: "Others",
    display: true,
  },
  {
    name: "NON_LINEAR_AD",
    label: "NON LINEAR AD",
    type: "objectgroup",
    default: {},
    fields: [
      {
        name: ["NON_LINEAR_AD", "IS_ENABLE"],
        label: "IS ENABLE",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "30px", width: "20%" },
      },
      {
        name: ["NON_LINEAR_AD", "IS_ENABLE_MB"],
        label: "IS ENABLE MB",
        type: "switch",
        default: false,
        isBool: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "40px", width: "20%" },
      },

      {
        name: ["NON_LINEAR_AD", "AD_REFRESH_TIME"],
        label: "AD REFRESH TIME",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "40px", width: "20%" },
      },
      {
        name: ["NON_LINEAR_AD", "REMOVE_AD_AFTER_DISPLAYED"],
        label: "REMOVE AD AFTER DISPLAYED",
        default: 0,
        type: "Input",
        isNum: true,
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "40px", width: "20%" },
      },
      {
        name: ["NON_LINEAR_AD", "DIV_ID"],
        label: "DIV ID",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        style: { marginLeft: "27px", width: "25%" },
      },
      {
        name: ["NON_LINEAR_AD", "SLOT_ID"],
        label: "SLOT ID",
        default: "",
        type: "Input",
        isRequired: false,
        category: "Others",
        display: true,
        style: { width: "78%", marginLeft: "-60px" },
      },

      {
        name: ["NON_LINEAR_AD", "SLOT_SIZE_PC"],
        default: ["300x50", "320x100", "300x100", "320x50"],
        label: "SLOT SIZE PC",
        type: "DropdownMulti",
        isRequired: false,
        category: "Others",
        selectOptions: ["300x50", "320x100", "300x100", "320x50"],
        display: true,
        style: {
          marginLeft: "25px",
          width: "98%",
          marginTop: "-20px",
        },
      },
      {
        name: ["NON_LINEAR_AD", "SLOT_SIZE_MB"],
        default: ["300x50", "320x100", "300x100", "320x50"],
        label: "SLOT SIZE MOB",
        type: "DropdownMulti",
        isRequired: false,
        category: "Others",
        selectOptions: ["300x50", "320x100", "300x100", "320x50"],
        display: true,
        style: {
          marginLeft: "25px",
          width: "98%",
          marginTop: "-20px",
        },
      },
    ],
    category: "Others",
    display: true,
  },
  {
    name: "UBP_DEBUG",
    label: "UBP DEBUG",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "ENABLE_HLS_PLAYER",
    label: "ENABLE HLS PLAYER",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "ENABLE_CUSTOM_GA",
    label: "Unibots Custom GA",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "AS_PER_NEW_POLICY",
    label: "AS_PER_NEW_POLICY",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "LOAD_PL_WITH_DELAY",
    label: "LOAD PL WITH DELAY",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "PLAYER_FLOATING",
    label: "Float on desktop",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    category: "FloatingHeader",
    description: "Enable floating on PC",
    style: { width: "40%" },
  },
  {
    name: "IS_FLOATING_ON_MOBILE",
    label: "Float on mobile",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    category: "FloatingMobileHeader",
    description: "Enable floating on Mobile",
    style: { width: "40%" },
  },
  {
    name: "FLOATING_ONLY_AFTER_INVIEW",
    label: "FLOATING ONLY AFTER INVIEW",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "FLOATING_ONLY_AFTER_INVIEW_MB",
    label: "FLOATING ONLY AFTER INVIEW MB",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "SHORT_TIME_FLOATING",
    label: "Float for only 7 seconds",
    type: "switch",
    default: false,
    isBool: true,
    isRequired: true,
    display: true,
    dependency: [{ PLAYER_FLOATING: true }, { IS_FLOATING_ON_MOBILE: true }],
    category: "Others",
    description: "Player float only for short time",
  },
  {
    name: "VIDEO_DYNAMIC",
    label: "VIDEO DYNAMIC",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "IFRAME_PLAYER",
    label: "IFRAME PLAYER",
    type: "switch",
    default: true,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "AUTO_DIV",
    label: "AUTO_DIV",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "PL_BUTTON_ENABLE",
    label: "PL BUTTON ENABLE",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "REQUEST_SINGLE_AD",
    label: "REQUEST SINGLE AD",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "CLOSE_BUTTON_DISPOSE_PLAYER",
    label: "CLOSE BUTTON DISPOSE PLAYER",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "IS_PERMANENT_STICKY",
    label: "IS PERMANENT STICKY",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "CHECK_BLOCKED_PAGES",
    label: "CHECK BLOCKED PAGES",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "RECREATE_PLAYER",
    label: "RECREATE PLAYER",
    type: "switch",
    default: true,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "NEW_CUSTOM_CSS_MODULE",
    label: "NEW_CUSTOM_CSS_MODULE",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "IS_FLOATING_BLOCK_ON_SOME_URLS",
    label: "IS FLOATING BLOCK ON SOME URLS",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "PL_RUN_AFTER_PAGE_SCROLL",
    label: "PL RUN AFTER PAGE SCROLL",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "AD_DELAY_ENABLED",
    label: "Enable Aggressive Mode",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    category: "Others",
    description: "Enable ad delay",
    disabledclients: ["unibots"],
    confirm: true,
  },
  {
    name: "STICKY_ONCE_VIEWED",
    label: "STICKY ONCE VIEWED",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "HIDE_CLOSE_BTN_INREAD",
    label: "HIDE CLOSE BTN INREAD",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "AD_TAGS_SHUFFLING",
    label: "AD TAGS SHUFFLING",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "AD_POD_REQUEST",
    label: "AD POD REQUEST",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "TEST_MODULES",
    label: "TEST MODULES",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "RUN_PLAY_ONLY_NEARBY_VIEWPORT",
    label: "RUN PLAY ONLY NEARBY VIEWPORT",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "HLS_ENABLED",
    label: "HLS ENABLED",
    type: "switch",
    default: true,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "Edit_Warning",
    label: "Edit Warning",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "UB_CUSTOM_CSS",
    label: "UB CUSTOM CSS",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "USE_OF_CUSTOM_CSS",
    label: "USE OF CUSTOM CSS",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "CUSTOM_JS",
    label: "CUSTOM JS",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "IS_PERMANENT_STICKY_POSITION",
    label: "IS PERMANENT STICKY POSITION",
    type: "Dropdown",
    selectOptions: ["left", "right"],
    default: false,
    category: "Others",
    display: true,
  },
  {
    name: "IS_PERMANENT_STICKY_POSITION_MB",
    label: "IS PERMANENT STICKY POSITION MB",
    type: "Dropdown",
    selectOptions: ["left", "right"],
    default: "right",
    category: "Others",
    display: true,
  },
  {
    name: "AD_MODE",
    label: "AD MODE",
    type: "Dropdown",
    selectOptions: ["live", "test"],
    default: "live",
    category: "Others",
    display: true,
  },
  {
    name: "AD_REQUEST_TYPE",
    label: "AD REQUEST TYPE",
    type: "Dropdown",
    selectOptions: ["old", "new"],
    default: "new",
    category: "Others",
    display: true,
  },
  {
    name: "DISABLE_LOGS",
    label: "DISABLE LOGS",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "ENABLE_CLARITY",
    label: "ENABLE CLARITY",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "CLARITY_CODE",
    label: "CLARITY CODE",
    type: "Input",
    placeholder: "Enter code",
    default: "",
    selectOptions: null,
    isRequired: false,
    display: true,
    category: "Others",
    style: { width: "30%" },
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "MARGIN_BOTTOM"],
    label: "MARGIN BOTTOM",
    type: "Input",
    placeholder: "Enter Margin Bottom",
    default: 12,
    selectOptions: null,
    isRequired: false,
    display: true,
    isNum: true,
    category: "Others",
    dependency: [{ PLAYER_FLOATING: true }],
  },
  {
    name: "SEND_AD_WITHOUT_DELAY",
    label: "SEND AD WITHOUT DELAY",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "PLAY_AD_UNMUTED",
    label: "PLAY AD UNMUTED",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "SEND_AD_WHEN_PAUSED",
    label: "SEND AD WHEN PAUSED",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "RUN_OUTSTREAM_PLAYER",
    label: "RUN OUTSTREAM PLAYER",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "OUTSTREAM_PLAYER_NAME",
    label: "OUTSTREAM PLAYER NAME",
    default: "",
    type: "Input",
    isRequired: false,
    category: "Others",
    display: true,
    style: { width: "30%" },
  },
  {
    name: "PLAY_ON_DOM_CLICK",
    label: "PLAY ON DOM CLICK",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },

  {
    name: "RUN_MULTI_PLAYER",
    label: "RUN MULTI PLAYER",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "MULTI_CLIENT_NAME",
    label: "MULTI CLIENT NAME",
    default: "",
    type: "Input",
    isRequired: false,
    category: "Others",
    display: true,
    style: { width: "30%" },
  },

  {
    name: "MUTE_PLAYER",
    label: "MUTE PLAYER",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "COLLECT_DATA",
    label: "COLLECT DATA",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "PLCMT",
    default: -2,
    label: "PLCMT",
    type: "Dropdown",
    isRequired: false,
    category: "Others",
    selectOptions: [-2, -1, 0, 1, 2],
    display: true,
  },
  {
    name: "DESTROY_AD_AFTER_7_SEC",
    label: "DESTROY AD AFTER 7 SEC",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "REMOVE_PLCMT_FOR_VPMUTE1",
    label: "REMOVE PLCMT FOR VPMUTE1",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "SEND_5_ADS_POLICY_VIOLATION",
    label: "SEND 5 ADS POLICY VIOLATION",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "SEND_FIRST_AD_PAUSED",
    label: "SEND FIRST AD PAUSED",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },
  {
    name: "SEND_DATA_TO_DB",
    label: "SEND DATA TO DB",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },{
    name: "DISABLE_DOUBLECLICK_FULLSCREEN",
    label: "DISABLE DOUBLECLICK FULLSCREEN",
    type: "switch",
    default: false,
    isBool: true,
    category: "Others",
    display: true,
  },

  {
    name: ["FLOATING_PLAYER_SETTINGS", "STICKY_V_POSITION"],
    label: "Vertical position",
    type: "Dropdown",
    default: "bottom",
    selectOptions: [
      { value: "bottom", label: "Floating bottom" },
      { value: "top", label: "Floating top" },
    ],
    display: true,
    dependency: [{ PLAYER_FLOATING: true }],
    category: "Floating",
    description: "Vertical position on desktop",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "BOTTOM_SPACING"],
    label: "Space from bottom",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [{ PLAYER_FLOATING: true }, { STICKY_V_POSITION: "bottom" }],
    category: "Floating",
    description: "Space from bottom on desktop",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "TOP_SPACING"],
    label: "Space from top",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [{ PLAYER_FLOATING: true }, { STICKY_V_POSITION: "top" }],
    category: "Floating",
    description: "Space from top on desktop",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "FLOATING_SIZE"],
    label: "Desktop floating size",
    type: "Dropdown",
    default: "400x225",
    split: ["STICKY_PLAYER_WIDTH", "STICKY_PLAYER_HEIGHT"],
    selectOptions: ["400x225", "360x203", "320x180", "256x144", "192x108"],
    isRequired: true,
    display: true,
    dependency: [{ PLAYER_FLOATING: true }],
    category: "Floating",
    description: "Size of floating player on PC",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "STICKY_H_POSITION"],
    label: "Horizontal position",
    type: "Dropdown",
    default: "right",
    selectOptions: [
      { value: "right", label: "Floating right" },
      { value: "left", label: "Floating left" },
    ],
    display: true,
    dependency: [{ PLAYER_FLOATING: true }],
    category: "Floating",
    description: "Horizontal position on desktop",
  },

  {
    name: ["FLOATING_PLAYER_SETTINGS", "LEFT_SPACING"],
    label: "Space from left",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [{ PLAYER_FLOATING: true }, { STICKY_H_POSITION: "left" }],
    category: "Floating",
    description: "Space from left on desktop",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "RIGHT_SPACING"],
    label: "Space from right",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [{ PLAYER_FLOATING: true }, { STICKY_H_POSITION: "right" }],
    category: "Floating",
    description: "Space from right on desktop",
  },

  {
    name: ["FLOATING_PLAYER_SETTINGS", "STICKY_V_POSITION_MB"],
    label: "Vertical position on mobile",
    type: "Dropdown",
    default: "bottom",
    selectOptions: [
      { value: "bottom", label: "Floating bottom" },
      { value: "top", label: "Floating top" },
    ],
    display: true,
    dependency: [{ IS_FLOATING_ON_MOBILE: true }],
    category: "FloatingMobile",
    description: "Vertical position on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "BOTTOM_SPACING_MB"],
    label: "Space from bottom on mobile",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [
      { IS_FLOATING_ON_MOBILE: true },
      { STICKY_V_POSITION_MB: "bottom" },
    ],
    category: "FloatingMobile",
    description: "Space from bottom on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "TOP_SPACING_MB"],
    label: "Space from top on mobile",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [
      { IS_FLOATING_ON_MOBILE: true },
      { STICKY_V_POSITION_MB: "top" },
    ],
    category: "FloatingMobile",
    description: "Space from top on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "FLOATING_SIZE_MB"],
    label: "Mobile floating size",
    type: "Dropdown",
    default: "256x144",
    split: ["STICKY_PLAYER_WIDTH_MB", "STICKY_PLAYER_HEIGHT_MB"],
    selectOptions: ["256x144", "192x108"],
    isRequired: true,
    display: true,
    dependency: [{ IS_FLOATING_ON_MOBILE: true }],
    category: "FloatingMobile",
    description: "Size of floating player on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "STICKY_H_POSITION_MB"],
    label: "Horizontal position on mobile",
    type: "Dropdown",
    default: "right",
    selectOptions: [
      { value: "right", label: "Floating right" },
      { value: "left", label: "Floating left" },
    ],
    display: true,
    dependency: [{ IS_FLOATING_ON_MOBILE: true }],
    category: "FloatingMobile",
    description: "Horizontal position on mobile",
  },

  {
    name: ["FLOATING_PLAYER_SETTINGS", "LEFT_SPACING_MB"],
    label: "Space from left on mobile",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [
      { IS_FLOATING_ON_MOBILE: true },
      { STICKY_H_POSITION_MB: "left" },
    ],
    category: "FloatingMobile",
    description: "Space from left on mobile",
  },
  {
    name: ["FLOATING_PLAYER_SETTINGS", "RIGHT_SPACING_MB"],
    label: "Space from right on mobile",
    type: "Input",
    default: 5,
    display: true,
    isNum: true,
    dependency: [
      { IS_FLOATING_ON_MOBILE: true },
      { STICKY_H_POSITION_MB: "right" },
    ],
    category: "FloatingMobile",
    description: "Space from right on mobile",
  },
  {
    name: "ENABLED_FOR_PC",
    label: "ENABLED FOR PC",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    category: "MainHeader",
    description: "Enabled for PC",
    style: { width: "40%" },
  },
  {
    name: "ENABLED_FOR_MB",
    label: "ENABLED FOR MOBILE",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    category: "MainMobileHeader",
    description: "Enabled for MB",
    style: { width: "40%" },
  },
  {
    name: "CLOSE_BUTTON_ENABLE",
    label: "Close button enable",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    dependency: [{ ENABLED_FOR_PC: true }],
    category: "Main",
    description: "Toggle close button visibility",
  },
  {
    name: "CLOSE_BUTTON_ENABLE_MB",
    label: "Close button enable on mobile",
    type: "switch",
    default: true,
    isBool: true,
    isRequired: true,
    display: true,
    dependency: [{ ENABLED_FOR_MB: true }],
    category: "MainMobile",
    description: "Toggle close button visibility on mobile",
  },
];
export { userFields, adminFields };
