import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Form, Button, Typography, Input, message, Result } from "antd";
import { resetClient } from "../../API/Clients";

const ResetPass = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Title } = Typography;
  const [token, setToken] = useState("");
  const [id, setId] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const success = () => {
    messageApi.success("Your password has been changed successfully!");
  };
  const error = () => {
    messageApi.error("Something went wrong!");
  };
  useEffect(() => {
    setToken(searchParams.get("token"));
    setId(searchParams.get("id"));
  }, []);

  const handleSubmit = async () => {
    let fields = form.getFieldsValue();
    if (fields.password) {
      setLoading(true);
      const res = await resetClient(token, id, fields.password);
      setLoading(false);
      if (res.err) {
        error();
      } else {
        success();
        setTimeout(() => {
          navigate("/admin");
        }, 2000);
      }
    }
  };
  return (
    <>
      {!token || !id ? (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button
              onClick={() => {
                navigate("/admin");
              }}
              type="primary"
            >
              Back Home
            </Button>
          }
        />
      ) : (
        <div className="h-[100vh]">
          {contextHolder}
          <nav
            className="shadow-sm flex items-center px-6"
            style={{ height: "10vh" }}
          >
            <div>
              <img
                className="w-6/7 cursor-pointer"
                src={`https://unibots.b-cdn.net/managed_vp_imgs/${
                  process.env.REACT_APP_MODE === "dev"
                    ? "unibots"
                    : window.location.hostname.split(".")[1]
                }.svg`}
                alt="logo-icon"
              />
            </div>
          </nav>
          <div
            className="flex justify-evenly items-center"
            style={{
              height: "90vh",
            }}
            id="login-bg"
          >
            <div
              style={{
                background:
                  "url(https://unibots.b-cdn.net/managed_vp_imgs/dashboard_login_screen.svg) center no-repeat",
                backgroundSize: "contain",
                width: "50%",
                height: "100%",
              }}
            ></div>
            {/* <div className="grow bg-slate-200"></div> */}
            <div className="flex justify-center items-center">
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 12,
                }}
                form={form}
                style={{
                  maxWidth: 600,
                  minWidth: 500,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                  boxShadow: "5px 8px 24px 5px rgba(100, 37, 254, 0.2)",
                  background: "white",
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={handleSubmit}
                autoComplete="off"
                onFieldsChange={() => {
                  // console.log(form.getFieldsValue());
                }}
              >
                <div className="h-[100px] font-semibold flex justify-center items-center">
                  <Title level={3}> Reset Password</Title>
                </div>

                <div>
                  <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter new password" />
                  </Form.Item>
                  <Form.Item
                    name="confpass"
                    label="Confirm Password"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm new password" />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item className="flex justify-center w-full">
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPass;
