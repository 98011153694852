import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form, Alert } from "antd";

const PlayerInfo = (props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [modalType, setModalType] = useState(null);

  const handleSubmit = async (values) => {
    props.setPlayerInfo(values.player_info);
    setModalType(null);
  };

  if (props.playerinfo?.length > 0) {
    form.setFieldsValue({ player_info: props.playerinfo });
  }

  return (
    <>
      {props.playerinfo && props.playerinfo?.length > 0 ? (
        <Alert
          message={
            <Button
              style={{ background: "#eab308", color: "white" }}
              className="sm:mt-0 mt-2"
              onClick={() => setModalType("view")}
            >
              View Player Info
            </Button>
          }
          type="warning"
          showIcon
        />
      ) : (
        <Button className="sm:mt-0 mt-2" onClick={() => setModalType("add")}>
          Add Player Info
        </Button>
      )}
      <Modal
        open={modalType !== null}
        onOk={modalType === "add" ? form.submit : null}
        onCancel={() => setModalType(null)}
        title={modalType === "add" ? "Add Player Info" : "Player Info"}
        footer={
          modalType === "add"
            ? [
                <Button key="cancel" onClick={() => setModalType(null)}>
                  Cancel
                </Button>,
                <Button
                  key="save"
                  type="primary"
                  htmlType="submit"
                  onClick={form.submit}
                >
                  Save
                </Button>,
              ]
            : [
                <Button key="cancel" onClick={() => setModalType(null)}>
                  Cancel
                </Button>,
                <Button onClick={() => setModalType("add")}>
                  Edit Player Info
                </Button>,
              ]
        }
      >
        {modalType === "add" ? (
          <Form form={form} onFinish={handleSubmit}>
            <div className="w-full h-[300px]">
              <Form.Item name="player_info">
                <TextArea rows={4} style={{ width: "100%", height: "250px" }} />
              </Form.Item>
            </div>
          </Form>
        ) : (
          <div>{props.playerinfo}</div>
        )}
      </Modal>
    </>
  );
};

export default PlayerInfo;
