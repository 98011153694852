import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPublishers } from "../API/Publishers";
import { getWebsites } from "../API/Websites";
import { updatePub } from "../redux/reducers/PubSlice";
import { updateWebsite } from "../redux/reducers/WebSlice";
import { updateClient } from "../redux/reducers/clientSlice";
import { getClient } from "./Clients";

const GetData = () => {
  const userName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const userRole = useSelector((state) => state.user.role);
  const userAccess = useSelector((state) => state.user.access);
  const websites = useSelector((state) => state.user.websites);
  const publishers = useSelector((state) => state.user.publishers);
  const dispatch = useDispatch();
  const publisherChange = useSelector(
    (state) => state.publisher.publisherChange
  );
  const websiteChange = useSelector((state) => state.website.websiteChange);
  const clientChange = useSelector((state) => state.client.clientChange);

  useEffect(() => {
    if (publisherChange && userName?.length > 0 && userType.length > 0) {
      // console.log("getting publishers!");
      const getPub = async () => {
        let response = {};
        if (userType === "user") {
          response = await getPublishers();
        } else {
          response = await getPublishers(userName);
        }
        let list = [];
        if (userRole === "adops") {
          list = response.data.msg.filter((pub) =>
            userAccess.client.includes(pub.parent)
          );
        } else if (
          publishers &&
          publishers.length > 0 &&
          userRole !== "admin"
        ) {
          list = response.data.msg.filter((pub) =>
            publishers.includes(pub.name)
          );
        } else {
          list = response.data.msg;
        }
        dispatch(
          updatePub({
            publisherList: list,
            publisherChange: false,
          })
        );
      };
      getPub();
    }
  }, [publisherChange, userName, userType, publishers, userRole, userAccess]);

  useEffect(() => {
    if (websiteChange && websites) {
      // console.log("getting websites!");
      const getWeb = async () => {
        const response = await getWebsites();
        let list = [];
        if (userRole === "adops") {
          list = response.data.msg;
        } else if (websites.length > 0 && userRole !== "admin") {
          let sites = websites.map((item) => item.url);
          sites = [...new Set(sites)];
          list = response.data.msg.filter((site) => sites.includes(site.url));
        } else {
          list = response.data.msg;
        }
        dispatch(
          updateWebsite({
            websiteList: list,
            websiteChange: false,
          })
        );
      };
      getWeb();
    }
  }, [websiteChange, websites, userRole, userAccess]);

  useEffect(() => {
    if (clientChange && userName?.length > 0 && userType?.length > 0) {
      // console.log("refetch data on logoupload");
      const clientData = async () => {
        let response = {};
        if (userType === "user") {
          response = await getClient();
        } else {
          response = await getClient(userName);
          // console.log("response in getdata", response);
        }
        dispatch(
          updateClient({
            name: response.data.msg.name,
            email: response.data.msg.email,
            clientLogo: response.data.msg.clientLogo,
            logoUrl: response.data.msg.logoUrl,
            redirectLink: response.data.msg.redirectLink,
            cdnLink: response.data.msg.cdnLink,
            cdnEnabled: response.data.msg?.cdnEnabled ?? true,
            clientChange: false,
            defaultUrls: response.data.msg?.defaultUrls,
            customerId: response.data.msg?.customerId,
            balance: response.data.msg?.balance,
            isPrepaid: response.data.msg?.isPrepaid,
            balanceLimit: response.data.msg?.balanceLimit,
            changes: response.data.msg.changes,
          })
        );
      };
      clientData();
    }
  }, [clientChange, userName, userType]);
  return <></>;
};

export default GetData;
