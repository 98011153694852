import { Table, Popconfirm, Button, message, Input, Dropdown } from "antd";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  RightOutlined,
  EditFilled,
  InfoCircleFilled,
  PlayCircleTwoTone,
  PlusCircleTwoTone,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Icon } from "@iconify/react";
import LoaderComp from "../Utility/LoaderComp";
import CreateWebsite from "./CreateWebsite";
import { deleteWebsites } from "../../API/Websites";
import { updateWebsite } from "../../redux/reducers/WebSlice";
import { getWebsites } from "../../API/Websites";
import SuccessPage from "../Utility/SuccessPage";
import ArchivedWebsites from "./ArchivedWebsites";
import { updatePub } from "../../redux/reducers/PubSlice";
import { patchPublishers , patchPubChanges} from "../../API/Publishers";
import { getDateTime } from "../Utility/utils";

const ModifyWebsite = (props) => {
  const { Search } = Input;
  const publisherList = useSelector((state) => state.publisher.publisherList);
  const userName = useSelector((state) => state.user.name);
  const userData = useSelector((state) => state.user);
  const websiteList = useSelector((state) => state.website.websiteList);
  const websiteChange = useSelector((state) => state.website.websiteChange);
  const [addBtn, setAddBtn] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [currPub, setCurrPub] = useState("");
  const [currPubData, setCurrPubData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [siteAdded, setSiteAdded] = useState(false);
  const [editWeb, setEditWeb] = useState(false);
  const [successPage, setSuccessPage] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [archiveModal, setArchiveModal] = useState(false);
  const [archOpen, setArchOpen] = useState(false);
  const handleArchived = () => {
    setArchiveModal(true);
    setArchOpen(true);
  };
  const addSite = () => {
    // console.log("site added addsite");
    setSiteAdded(true);
  };
  const success = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };
  const fail = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };
  const [popOpen, setPopOpen] = useState({});
  const dispatch = useDispatch();

  const handleConfirm = (key) => {
    // console.log("key", key);
    setConfirmLoading(true);
    let popconf = {};
    popconf[key.url] = false;
    setTimeout(() => {
      setPopOpen(popconf);
      setConfirmLoading(false);
      deleteSite(key);
    }, 2000);
  };
  const handleCancel = (key) => {
    let popconf = {};
    popconf[key.url] = false;
    setPopOpen(popconf);
  };
  const handlePopConfirm = (key) => {
    let popconf = {};
    popconf[key.url] = true;
    setPopOpen(popconf);
  };
  const handleModifyPlayer = (data, pub, url) => {
    if (data) {
      props.setCurrPub(data.parent);
      props.setCurrUrl(data.url);
      props.setFromWebsite(true);
    } else {
      props.setCurrPub(pub);
      props.setCurrUrl(url);
    }
    props.setCurrOption("player");
  };
  const handleLogs = (data, pub, url) => {
    if (data) {
      props.setCurrPub(data.parent);
      props.setCurrUrl(data.url);
      props.setFromWebsite(true);
    } else {
      props.setCurrPub(pub);
      props.setCurrUrl(url);
    }
    props.setCurrOption("logs");
  };
  const columns = [
    {
      title: "Publisher",
      dataIndex: "name",
      key: "name",
      width: { sm: "50%", md: "40%" },
      render: (text, record) => (
        <div className="flex justify-between cursor-pointer ">
          <span className="block self-center">{text}</span>{" "}
          <div className="w-32 hidden sm:flex justify-between items-center">
            {/* <PlusCircleOutlined />Add more */}
            <Button
              className="my-auto"
              onClick={(e) => {
                e.stopPropagation();
                setAddBtn(true);
                setOpen(true);
                setModalOpen(true);
                setCurrPub(text);
                setCurrPubData(record);
              }}
            >
              Add more
            </Button>
            <RightOutlined style={{ fontSize: "16px", outline: "none" }} />{" "}
          </div>
          <div className="w-32 flex sm:hidden justify-end  gap-4 items-center">
            <PlusCircleTwoTone
              onClick={(e) => {
                e.stopPropagation();
                setAddBtn(true);
                setOpen(true);
                setModalOpen(true);
                setCurrPub(text);
              }}
              style={{ fontSize: 16 }}
            />
            {/* <Button
              className="my-auto"
              onClick={(e) => {
                e.stopPropagation();
                setAddBtn(true);
                setOpen(true);
                setModalOpen(true);
                setCurrPub(text);
              }}
            >
              Add more
            </Button> */}
            <RightOutlined style={{ fontSize: "16px", outline: "none" }} />{" "}
          </div>
        </div>
      ),
    },
  ];
  const column2 = [
    {
      title: (
        <div className="flex w-[100%] justify-between items-center">
          <span className="inline-block text-[10px] sm:text-[12px]">
            Websites
          </span>
        </div>
      ),
      dataIndex: "url",
      key: "url",
      render: (text) => {
        return (
          <>
            <div className="flex justify-between cursor-pointer">
              <span className="block self-center">{text}</span>{" "}
              {/* <div>
                <RightOutlined
                  style={{
                    fontSize: "16px",
                    outline: "none",
                    padding: "4px 15px",
                    height: "32px",
                  }}
                />{" "}
              </div> */}
              {/* <div className="block sm:hidden"> */}
              {/* <Dropdown
                  trigger={["click"]}
                  className="absolute inset-0"
                  overlay={
                    <Table
                      columns={column3}
                      dataSource={filteredVideos}
                      pagination={false}
                    />
                  }
                >
                  <DashOutlined rotate={90} className="pl-[7rem]" />
                </Dropdown> */}
              {/* </div> */}
            </div>
          </>
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  const column3 = [
    {
      // title: "Action",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <div className="flex gap-2 flex-col sm:flex-row flex-wrap">
          <Button
            type="primary"
            onClick={() => {
              handleAddWebsite(record.parent);
              setAddBtn(false);
            }}
          >
            <InfoCircleFilled style={{ fontSize: "17px" }} />
            Info
          </Button>
          <Button
            disabled={
              !userData?.access?.menuItems?.includes("player") &&
              userData.type !== "client" &&
              userData.role !== "admin" &&
              userData.role !== "adops"
            }
            icon={<PlayCircleTwoTone />}
            // className="min-w-[50px]"
            onClick={() => {
              handleModifyPlayer(record);
            }}
          >
            Player
          </Button>
          <Button
            icon={<UnorderedListOutlined />}
            // className="min-w-[50px]"
            onClick={() => {
              handleLogs(record);
            }}
          >
            Logs
          </Button>
          <Popconfirm
            title="Are you sure you want to Archive this item?"
            // onConfirm={() => handleDelete(record)}
            open={popOpen[record.url] ?? false}
            onConfirm={() => {
              handleConfirm(record);
            }}
            onCancel={() => {
              handleCancel(record);
            }}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ loading: confirmLoading }}
          >
            <Button
              type="primary"
              danger
              className="flex justify-center items-center gap-1"
              onClick={() => {
                handlePopConfirm(record);
              }}
              icon={
                <Icon
                  icon="material-symbols:archive-rounded"
                  width="20"
                  height="20"
                />
              }
            >
              Archive
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  function handleAddMore(e) {
    e.stopPropagation();
    setAddBtn(true);
    setOpen(true);
    setModalOpen(true);
    setCurrPub(currentPub.current);
  }

  const inputRef = useRef(null);
  const currentPub = useRef(null);
  const [showWeb, setShowWeb] = useState(false);

  const [filteredVideos, setFilteredVideos] = useState(null);
  const [showVideo, setShowVideo] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedWebKeys, setSelectedWebKeys] = useState([]);
  const [spin, setSpin] = useState(true);
  const [pub, setPub] = useState([]);
  const [pubWeb, setPubWeb] = useState([]);
  const [searchPub, setSearchPub] = useState("");
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [archivedWebsites, setArchivedWebsites] = useState(null);
  const [refreshArchive, setRefreshArchive] = useState(false);
  useEffect(() => {
    if (publisherList.length > 0) {
      let pubs = publisherList.filter((pub) => {
        return pub.isArchived === "false";
      });
      setPub(pubs);
    }
  }, [publisherList]);

  useEffect(() => {
    setPubWeb(websiteList);
  }, [websiteList]);

  const [currWeb, setCurrWeb] = useState("");
  inputRef.current = publisherList.filter((pub) => {
    return pub.isArchived === "false";
  });

  const afterAdd = () => {
    // console.log("filtered website", filteredWebsites.length);
    // setShowWeb(false);
    // setShowVideo(false);
  };
  useEffect(() => {
    if (props.pubname) {
      setCurrPub(props.pubname);
      let pubData = publisherList.filter((pub) => pub.name === props.pubname);
      if (pubData.length > 0) {
        setCurrPubData(pubData[0]);
      }
      setAddBtn(true);
      setOpen(true);
      setModalOpen(true);
    }
    props.removepubname();
  }, [publisherList]);
  useEffect(() => {
    if (websiteList.length > 0 || !websiteChange) {
      setSpin(false);
    }
    filterWebsites(currentPub.current);
  }, [publisherList, websiteList]);

  useEffect(() => {}, [currPubData]);

  const locale = {
    emptyText: (text) => (
      <div className="flex flex-col sm:flex-row justify-around items-center gap-2 sm:gap-0">
        <span className="">No Website Added.</span>
        <Button type="primary" onClick={(e) => handleAddMore(e)}>
          <span className="">Add Website</span>
        </Button>
      </div>
    ),
  };

  const setLogs = async (data, kind, lhs, rhs) => {
    const { date, time } = getDateTime();
    if (kind === "added") {
      const pubData = {
        name : currPubData.name,
        changes: [
          {
            user: userName,
            name: data.url,
            date,
            time,
            kind,
            rhs: data,
          },
        ]
      }
      const res = await patchPubChanges(pubData)
      // const result = await patchPublishers({
      //   ...currPubData,
      //   changes: [
      //     ...(currPubData?.changes ?? []),
      //     {
      //       user: userName,
      //       name: data.url,
      //       date,
      //       time,
      //       kind,
      //       rhs: data,
      //     },
      //   ],
      // });
    } else if (kind === "edited") {
      const pubData = {
        name : currPubData.name,
        changes: [
          {
            user: userName,
            name: data.url,
            date,
            time,
            kind,
            lhs,
            rhs,
          },
        ]

      }
      const res = await patchPubChanges(pubData)
      // const result = await patchPublishers({
      //   ...currPubData,
      //   changes: [
      //     ...(currPubData?.changes ?? []),
      //     {
      //       user: userName,
      //       name: data.url,
      //       date,
      //       time,
      //       kind,
      //       lhs,
      //       rhs,
      //     },
      //   ],
      // });
    } else if (kind === "deleted") {
      const pubData = {
        name : currPubData.name,
        changes: [
          {
            user: userName,
            name: data.url,
            date,
            time,
            kind,
            lhs: data,
          },
        ]

      }
      const res = await patchPubChanges(pubData)
      // const result = await patchPublishers({
      //   ...currPubData,
      //   changes: [
      //     ...(currPubData?.changes ?? []),
      //     {
      //       user: userName,
      //       name: data.url,
      //       date,
      //       time,
      //       kind,
      //       lhs: data,
      //     },
      //   ],
      // });
    }
    dispatch(
      updatePub({ publisherList: publisherList, publisherChange: true })
    );
  };
  const deleteSite = async (website) => {
    setLogs(website, "deleted");
    const res = await deleteWebsites({
      url: website.url,
      parent: website.parent,
    });
    if (!res.err) {
      setLogs(website, "deleted");
    }
    let filter = filteredWebsites.filter((site) => site.url !== website.url);

    setfilteredWebsites(filter);
    filterWebsites(currentPub.current);
    dispatch(updateWebsite({ websiteList: websiteList, websiteChange: true }));
    setShowVideo(false);
    setShowWeb(true);
    success("Archived!");
  };

  const [filteredWebsites, setfilteredWebsites] = useState([]);

  const filterAdd = async (publisher) => {
    dispatch(updateWebsite({ websiteList, websiteChange: true }));
  };
  const filterWebsites = async (publisher) => {
    // const websiteList = await getWebsites();
    let filteredWebsites = websiteList.filter(
      (website) =>
        website.parent?.toLowerCase() === publisher?.toLowerCase() &&
        website.isArchived === "false"
    );
    let allwebsites = websiteList.filter(
      (website) =>
        website.parent?.toLowerCase() === publisher?.toLowerCase() &&
        website.isArchived === "true"
    );
    setArchivedWebsites(allwebsites);

    setfilteredWebsites(filteredWebsites);
  };

  useEffect(() => {
    // console.log("siteAdded", siteAdded);
    if (siteAdded) {
      // console.log("filter after ad site");
      filterWebsites(currentPub.current);
    }
  }, [siteAdded, setSiteAdded]);

  useEffect(() => {
    if (refreshArchive) {
      console.log("websites updated adter unarchive");
      filterWebsites(currentPub.current);
    }
  }, [websiteList]);

  const handleRefresh = () => {
    setRefreshArchive(true);
  };

  useEffect(() => {
    if (filteredWebsites.length > 0) {
      filterWebsites(currentPub.current);
    }
  }, [websiteList]);
  const handleRowClick = (record) => {
    setSelectedRowKeys([record.name]);
  };

  const handleWebClick = (record) => {
    setSelectedWebKeys([record.url]);
  };

  const handleFilteredVideos = (record) => {
    let filteredVids = websiteList?.filter(
      (website) =>
        website.url === record.url && website.parent === record.parent
    );
    setFilteredVideos(filteredVids);
    setSelectedWebKeys([filteredVids[0].url]);
  };

  const handleSearch = (text) => {
    setSearchPub(text);
    setShowWeb(false);
    setShowVideo(false);
    if (text.length === 0) {
      setPub(inputRef.current);
    } else {
      const filterPub = inputRef.current.filter((item) => {
        // filter the data based on the search text

        return item.name.toLowerCase().includes(text.toLowerCase());
      });

      if (filterPub.length !== 0) {
        setPub(filterPub);
      } else {
        handleWebsiteSearch(text);
      }
    }
  };
  const handleWebsiteSearch = (name) => {
    if (name.length === 0) {
      setPub(inputRef.current);
      setShowWeb(false);
      setShowVideo(false);
    } else {
      let filteredWebsites = pubWeb?.filter((website) => {
        return (
          website.url.toLowerCase().includes(name.toLowerCase()) &&
          website.isArchived === "false"
        );
      });
      const newPub = filteredWebsites.map((item) =>
        inputRef.current.filter((parent) => {
          return parent.name.toLowerCase() === item.parent.toLowerCase();
        })
      );

      const pubfilter = newPub.map((subArr) => subArr[0]);
      let newArr = pubfilter.filter(
        (obj, index, self) =>
          index ===
          self.findIndex((o) => o?._id === obj?._id && o?.name === obj?.name)
      );
      console.log("newArr", newArr);
      if (newArr.length !== 0) {
        newArr = newArr.filter((item) => item !== undefined);
      }
      if (
        newArr.length === 0 ||
        newArr[0] === undefined ||
        newPub[0].length === 0
      ) {
        setShowWeb(false);
        setPub(undefined);
      } else {
        setShowWeb(true);
        setPub(newArr);
      }

      setfilteredWebsites(filteredWebsites);
      if (filteredWebsites.length === 1) {
        handleFilteredVideos(filteredWebsites[0]);
        setShowVideo(true);
      }
    }
  };
  const handleAddWebsite = (key) => {
    setOpen(true);
    setModalOpen(true);
    setCurrPub(key);
    setEditWeb(true);
  };

  const footerContent = (
    <Button
      onClick={handleArchived}
      type="primary"
      danger
      className="flex justify-center gap-2 items-center"
    >
      {/* <UndoOutlined /> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#fff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <rect x="2" y="4" width="20" height="5" rx="2"></rect>
        <path d="M12 13v7"></path>
        <path d="m9 16 3-3 3 3"></path>
        <path d="M4 9v9a2 2 0 0 0 2 2h2"></path>
        <path d="M20 9v9a2 2 0 0 1-2 2h-2"></path>
      </svg>

      <p className="hidden md:block">Archived Websites</p>
      <p className="sm:hidden w-[100px]">Archived</p>
    </Button>
  );

  const tableHeight = `${(70 / 100) * window.innerHeight}px`;
  const tableHeight2 = `${(60 / 100) * window.innerHeight}px`;

  return (
    <>
      {successPage ? (
        <SuccessPage
          type="web"
          multi={successPage === "multi"}
          setCurrOption={props.setCurrOption}
          setSuccess={setSuccessPage}
          pubName={currPub}
          webName={currWeb}
          handleAddPlayer={handleModifyPlayer}
        />
      ) : spin ? (
        <LoaderComp />
      ) : (
        <div className="pl-5 pr-5 w-full">
          <div className="flex justify-between items-center">
            <h1 className="font-medium text-[20px]">Manage Websites</h1>
            {contextHolder}
            {/* <Button
              size="large"
              shape="round"
              onClick={handleAddWebsite}
              type="primary"
              style={{
                width: "200px",
                height: "60px",
                fontSize: "18px",
                fontWeight: "500",
                position: "fixed",
                right: "30px",
                bottom: "50px",
              }}
            >
              Edit Website
            </Button> */}
            <div>
              {/* <input
                className="p-2 w-[300px] border-0 outline-none text-sm text-gray-600 placeholder-gray-400"
                placeholder="Search for publishers or websites..."
                onChange={(e) => handleSearch(e.target.value)}
              />
              <SearchOutlined className="px-2 text-[#A49F9F] cursor-pointer" /> */}
              <Search
                placeholder="Search for publishers or websites..."
                allowClear
                // style={{
                //   width: 320,
                // }}
                className="sm:w-[320px] w-[100%]"
                value={searchPub}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-3 table-container">
            <Table
              columns={columns}
              style={{ width: "50%" }}
              className="my-table"
              dataSource={pub}
              scroll={{ y: tableHeight }}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    setShowWeb(true);
                    setShowVideo(false);
                    filterWebsites(record.name);
                    handleRowClick(record);
                    currentPub.current = record.name;
                    setCurrPubData(record);
                    // console.log(currentPub.current);
                    // do something with the clicked row data
                  },
                  style: {
                    backgroundColor: selectedRowKeys.includes(record?.name)
                      ? "#f7f0ff"
                      : "",
                  },
                };
              }}
            />

            {showWeb && (
              <Table
                columns={column2}
                style={{ width: "50%" }}
                className="my-table"
                footer={() => footerContent}
                id="collapse-table"
                locale={locale}
                scroll={{ y: tableHeight2 }}
                dataSource={filteredWebsites}
                showHeader={true}
                pagination={false}
                expandable={{
                  expandedRowRender: (record) => (
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      <Table
                        columns={column3}
                        dataSource={filteredVideos}
                        pagination={false}
                      />
                    </p>
                  ),
                  expandRowByClick: true,
                  onExpand: (expanded, record) => {
                    if (expanded) {
                      // do something when expanded
                      setShowVideo(true);
                      // setFilteredVideos(record.url);
                      handleFilteredVideos(record);
                      handleWebClick(record);
                      setExpandedRowKeys([record._id]);
                    } else {
                      setExpandedRowKeys([]);
                    }
                  },
                }}
                expandedRowKeys={expandedRowKeys}
                rowKey={(record, index) => record._id}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      setShowVideo(true);
                      // setFilteredVideos(record.url);
                      handleFilteredVideos(record);
                      handleWebClick(record);

                      // do something with the clicked row data
                    },
                    style: {
                      backgroundColor: selectedWebKeys.includes(record.url)
                        ? "#f7f0ff"
                        : "",
                    },
                  };
                }}
              />
            )}
            {showVideo && (
              <>
                {/* <Table
                  // style={{ width: "20%" }}
                  columns={column3}
                  dataSource={filteredVideos}
                  pagination={false}
                  className="hidden sm:block"
                /> */}
                {/* <Table
                  style={{ width: "20%" }}
                  columns={column3}
                  dataSource={filteredVideos}
                  pagination={false}
                  className="block sm:hidden"
                /> */}
              </>
            )}
          </div>
        </div>
      )}
      {modalOpen && (
        <CreateWebsite
          open={open}
          setOpen={setOpen}
          setModalOpen={setModalOpen}
          setCurrPub={props.setCurrPub}
          setCurrWeb={setCurrWeb}
          currentPub={currPub}
          edit={!addBtn}
          selectedSite={selectedWebKeys}
          success={success}
          setSuccessPage={setSuccessPage}
          filteredVideos={filteredVideos}
          fail={fail}
          editWeb={editWeb}
          filterWebsites={filterWebsites}
          filterAdd={filterAdd}
          addSite={addSite}
          afterAdd={afterAdd}
          setLogs={setLogs}
        />
      )}
      {archiveModal && (
        <ArchivedWebsites
          archOpen={archOpen}
          setArchOpen={setArchOpen}
          archivedWebsites={archivedWebsites}
          handleRefresh={handleRefresh}
          setLogs={setLogs}
        />
      )}
    </>
  );
};

export default ModifyWebsite;
