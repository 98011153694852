import {
  AreaChart,
  Area,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import graphData from "./graphData";

// helper to color graphs in pubDash
const fillData = {
  1: { id: "colorImp", fill: "url(#colorImp)" },
  2: { id: "colorRev", fill: "url(#colorRev)" },
  3: { id: "colorCpm", fill: "url(#colorCpm)" },
};

function AreaGraph(props) {
  return (
    // <Card className='w-1/4' bodyStyle={{padding:'12px'}} style={{backgroundColor:`${props.bgColor}`}}  bordered={false}>
    <div className="flex justify-center h-full w-full">
      <ResponsiveContainer
        width={props.width ? "95%" : "100%"}
        height={props.height ? "80%" : "100%"}
      >
        <AreaChart
          data={props.graphData ? props.graphData : graphData}
          margin={{ top: 10, bottom: 10, right: 20, left: 15 }}
          padding={{ left: 10 }}
          animationDuration={2700}
          isAnimationActive={true}
        >
          <defs>
            <linearGradient
              id={props.index ? fillData[props.index].id : "temp"}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={props.gradientColor}
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor={props.gradientColor}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Tooltip />
          <XAxis
            dataKey="date"
            minTickGap={8}
            tick={{ style: { fontSize: "10px" } }}
          />
          <YAxis
            tick={{
              style: {
                fontSize: "12px",
              },
            }}
            tickLine={true}
            tickMargin={5}
            width={60} // Adjust the width as needed
          />
          <CartesianGrid strokeDasharray="2 6" />
          {/* <Legend margin={{right:1}} /> */}
          <Area
            type="monotone"
            dataKey={props.dataKey ? props.dataKey : "scales"}
            stroke={props.graphColor}
            strokeWidth={1}
            fillOpacity={1}
            fill={props.index ? fillData[props.index].fill : "url(#temp)"}
            style={{ border: "2px solid blue" }}
            connectNulls={true}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
    // </Card>
  );
}

export default AreaGraph;
