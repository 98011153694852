import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  publisherList: [],
  publisherChange: true,
};

export const PubSlice = createSlice({
  name: "publisher",
  initialState,
  reducers: {
    updatePub: (state, action) => {
      state.publisherList = action.payload.publisherList;
      state.publisherChange = action.payload.publisherChange;
    },
    deletePub: (state, action) => ({ ...state, publisherChange: true }),
  },
});

// Action creators are generated for each case reducer function
export const { updatePub,deletePub } = PubSlice.actions;

export default PubSlice.reducer;
