import React, { useState, useEffect } from "react";
import { Form, Space, Collapse, Input, Button } from "antd";
import Objectgroup from "../Config/Objectgroup";
import UploadLogo from "./UploadLogo";
import { useSelector } from "react-redux";
import { userFields, adminFields } from "../Config/ConfigFields";
import CountryOrigin from "../Config/CountryOrigin";

const DrawerButton = (props) => {
  const { Panel } = Collapse;
  const [data, setData] = useState([]);
  const [form] = Form.useForm(props.form);
  const [fields, setFields] = useState(null);
  const [fieldChange, setFieldChange] = useState(true);
  const [initFields, setInitFields] = useState({});
  const [openPanel, setOpenPanel] = useState(["Others"]);
  const userName = useSelector((state) => state.user.name);
  const access = useSelector((state) => state.user.access);
  const userRole = useSelector((state) => state.user.role);
  const userType = useSelector((state) => state.user.type);
  const [syncLoader, setSyncLoader] = useState(false);
  const [jsonLink, setJsonLink] = useState(null);
  const [jsonFile, setJsonFile] = useState(null);
  const [confirm, setConfirm] = useState(false);

  const PanelSwitch = {
    ENABLED_FOR_PC: true,
    ENABLED_FOR_MB: true,
    PLAYER_FLOATING: true,
    IS_FLOATING_ON_MOBILE: true,
  };

  useEffect(() => {
    if (userRole === "admin") {
      setData(adminFields);
    } else {
      setData(userFields);
    }
  }, [userRole]);

  const JoinSlotSize = (data) => {
    const slotSizes = data?.COMPANION_AD?.SLOT_SIZE;
    let formattedData = slotSizes?.map((item) => item?.join("x"));
    return formattedData;
  };

  const joinSize = (fieldData, field, parent) => {
    let notfound = false;
    let size = field.split.map((val) => {
      if (!fieldData[val]) {
        notfound = true;
      }
      let value = fieldData[val]?.toString();
      if (!parent) {
        delete fieldData[val];
      }
      return value;
    });
    return notfound ? field.default : size.join("x");
  };
  const joinArray = (name, fieldData) => {
    let str = fieldData[name]
      ? typeof fieldData[name] !== "string"
        ? fieldData[name]?.join("\n")
        : fieldData[name]
      : "";
    return str;
  };
  useEffect(() => {
    if (props.currWebsite && data) {
      let fieldData = JSON.parse(
        JSON.stringify(jsonFile ?? props.currWebsite.config[0].playerConfig)
      );
      data.forEach((field) => {
        if (field?.saveType?.includes("array")) {
          fieldData[field.name] = joinArray(field.name, fieldData);
        }

        if (
          field.fields &&
          field.fields.find((item) => item.name.includes("SLOT_SIZE"))
        ) {
          if (
            fieldData["COMPANION_AD"] &&
            fieldData["COMPANION_AD"]["SLOT_SIZE"]
          ) {
            fieldData["COMPANION_AD"]["SLOT_SIZE"] = JoinSlotSize(fieldData);
          } else {
            console.log(
              "COMPANION_AD or SLOT_SIZE property does not exist in fieldData"
            );
          }
        }

        if (field.split && !fieldData[field.name]) {
          fieldData[field.name] = joinSize(fieldData, field);
        }
      });
      if (jsonFile) {
        props.setPlayerConfig(fieldData);
      }
      fieldData["AD_DELAY_ENABLED"] = !fieldData["AD_DELAY_ENABLED"];
      form.setFieldsValue(fieldData);
      setInitFields(fieldData);
    }
  }, [props.currWebsite, jsonFile, data]);

  useEffect(() => {
    setFieldChange((prev) => !prev);
  }, [initFields]);

  const handleCollapse = (values) => {
    setOpenPanel(values);
  };

  useEffect(() => {
    if (access) {
      let fields = form.getFieldsValue();
      props.setPlayerConfig((prev) => {
        return { ...prev, ...fields };
      });
      fields = Object.keys(fields).length > 0 ? fields : initFields;
      let activePanel = Object.keys(PanelSwitch).map((key, i) => {
        let enabled = access.player
          ? access?.player?.config?.includes(key)
          : true;
        if (
          (fields[key] === undefined || fields[key] === PanelSwitch[key]) &&
          (enabled || userRole === "admin" || userRole === "adops")
        ) {
          return categories[i];
        } else {
          return "";
        }
      });
      if (openPanel.includes("Others")) {
        activePanel.push("Others");
      }

      setFields(() => {
        return (
          <>
            <Collapse
              className="mb-5"
              activeKey={activePanel}
              onChange={handleCollapse}
              expandIcon={() => {
                return <></>;
              }}
            >
              {categories.map((cat) => {
                return (
                  <Panel
                    forceRender={true}
                    key={cat}
                    header={
                      cat === "Others" ? (
                        "Others"
                      ) : (
                        <Objectgroup
                          form={form}
                          data={data}
                          values={fields}
                          cat={cat + "Header"}
                          handleChange={handleChange}
                          confirm={confirm}
                          setConfirm={setConfirm}
                        />
                      )
                    }
                  >
                    <Objectgroup
                      data={data}
                      form={form}
                      values={fields}
                      cat={cat}
                      handleChange={handleChange}
                      confirm={confirm}
                      setConfirm={setConfirm}
                    />
                    {cat === "Others" && (
                      <>
                        <UploadLogo
                          currWebsite={props.currWebsite}
                          setPlayerConfig={props.setPlayerConfig}
                          handleChange={handleChange}
                          form={form}
                          initFields={initFields}
                        />
                      </>
                    )}
                    {cat === "Others" && (
                      <>
                        <CountryOrigin
                          currWebsite={props.currWebsite}
                          setPlayerConfig={props.setPlayerConfig}
                          handleChange={handleChange}
                          form={form}
                          initFields={initFields}
                        ></CountryOrigin>
                      </>
                    )}
                  </Panel>
                );
              })}
            </Collapse>
          </>
        );
      });
    }
  }, [fieldChange, openPanel, access, confirm, userRole, data]);

  const categories = [
    "Main",
    "MainMobile",
    "Floating",
    "FloatingMobile",
    "Others",
  ];

  const handleChange = () => {
    setFieldChange((prev) => !prev);
  };
  const handleSyncInput = (e) => {
    setJsonLink(e.target.value);
  };
  const handleSync = async () => {
    if (jsonLink) {
      setSyncLoader(true);
      try {
        const res = await fetch(jsonLink);
        let data = await res.json();
        if (data?.STICKY_POSITION) {
          data["FLOATING_PLAYER_SETTINGS"] = {
            STICKY_H_POSITION: data["STICKY_POSITION"],
          };
        }
        data.AD_CODES = [];
        data.GA_CODE =
          props?.currWebsite?.config[0]?.playerConfig?.AUTO_GA_ENABLED === true
            ? props?.currWebsite?.config[0]?.playerConfig?.GA_CODE
            : data.GA_CODE;
        data.USE_OF_CUSTOM_CSS = false;
        data.CUSTOM_JS = false;
        if (data?.FLOATING_POSITION_MB) {
          data["FLOATING_PLAYER_SETTINGS"]["STICKY_H_POSITION_MB"] =
            data["FLOATING_POSITION_MB"];
        }

        setJsonFile(data);
        props.success("File synced!");
      } catch (e) {
        props.fail("Error syncing file!");
        console.error(e);
      }
    }
    setTimeout(() => {
      setSyncLoader(false);
    }, 1000);
  };
  return (
    <div
      className="flex flex-col "
      style={{
        padding: "2vw",
        marginBottom: "10px",
      }}
    >
      <div style={{ width: "100%" }}>
        {(userName === "unibots" ||
          userName === "test90" ||
          userType === "user") && (
          <Space.Compact style={{ width: "80%", marginBottom: "10px" }}>
            <Input placeholder="Sync JSON" onChange={handleSyncInput} />
            <Button type="primary" loading={syncLoader} onClick={handleSync}>
              Sync
            </Button>
          </Space.Compact>
        )}
        <Form form={form} layout="horizontal" wrapperCol={{ span: 14 }}>
          {fields}
        </Form>
      </div>
    </div>
  );
};

export default DrawerButton;
