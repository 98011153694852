import React, { useEffect, useState } from "react";
import { Form, Select, theme } from "antd";
import { useSelector, useDispatch } from "react-redux";
import ConfigLogs from "../Video/ConfigLogs";
import { updateAllChanges } from "../../redux/reducers/WebSlice";
import { getClient} from "../../API/Clients";
import { getWebChangesByDate} from "../../API/Websites";
import { getPubChangesByDate } from "../../API/Publishers";
import { getClientChangesByDate } from "../../API/Clients";

const LogsPage = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [currPub, setCurrPub] = useState(props.currPub);
  const [currWebsite, setCurrWebsite] = useState(null);
  const websiteList = useSelector((state) => state.website.websiteList);
  const [websites, setWebsites] = useState([]);
  const publisherList = useSelector((state) =>
    state.publisher.publisherList.filter((list) => list.isArchived === "false")
  );
  const userName = useSelector((state) => state.user.name);
  const userData = useSelector((state) => state.user);
  const clientDetail = useSelector((state) => state.client);

  const [clients, setClients] = useState([]);
  const {
    token: { colorBgContainer },
  } = theme.useToken();


  const [date, setDate] = useState({ start: null, end: null });

  const [changes, setChanges] = useState([]);
  const [logLevel, setLogLevel] = useState(
    props.fromPub ? "publisher" : props.fromwebsite ? "website" : "client"
  );

  useEffect(() => {
    props.setFromPub(false);
    props.setFromWebsite(false);
  }, []);

  const setPubLogs = (pubName) => {
    let pubData = publisherList.filter((pub) => pub.name === pubName);
    if (pubData.length > 0) {
      setChanges(pubData[0].changes ?? []);
    }
  };
  const handlePubChange = (pubName) => {
    window.localStorage.setItem("pubname", pubName);
    if (props.fromwebsite) {
      form.setFieldsValue({ website: props.currUrl ?? currWebsite?.url });
    } else if (currWebsite && currWebsite.parent !== pubName) {
      form.setFieldsValue({ website: undefined });
      setCurrWebsite(null);
      window.localStorage.removeItem("currWebsite");
    }

    let filteredWebsites = websiteList.filter((website) => {
      return website.parent === pubName;
    });
    if (logLevel === "publisher") {
      setPubLogs(pubName);
    }
    if (logLevel === "website") {
      setChanges([]);
    }
    let urls = filteredWebsites
      .filter((websites) => websites.isArchived !== "true")
      .map((website) => website.url);
    setWebsites(urls);
    setCurrPub(pubName);
  };

  const handleChange = (value, values, site) => {
    let fields = form.getFieldsValue();
    let website = site ?? fields.website;
    let pub = fields.publisher;
    if (fields.website || website) {
      let filteredSite = websiteList.filter((site) => {
        return site.url === website && site.parent === pub;
      });

      setCurrWebsite(filteredSite[0]);
    }
    setCurrPub(pub);
  };
  const handleClientChange = async (value) => {
    let name = value
    function reverseDateFormat(dateString) {
      const [day, month, year] = dateString.split('-');
      return `${year}-${month}-${day}`;
    }

    let data = {
      name: name,
      startDate: date?.start,
      endDate: date?.end
    }

    const reversedStartDate = data.startDate && typeof data.startDate === 'string'
      ? reverseDateFormat(data.startDate.replace(/'/g, ''))
      : null;

    const reversedEndDate = data.endDate && typeof data.endDate === 'string'
      ? reverseDateFormat(data.endDate.replace(/'/g, ''))
      : null;

    const newobj = {
      ...data,
      startDate: reversedStartDate,
      endDate: reversedEndDate
    };
    let res

    if (name) {
      res = await getClientChangesByDate(newobj);

    }
    if (res && !res.error) {
      setChanges(res.msg?.changes ?? []);
    }
  };
  useEffect(() => {
    props.setFromPub(false);
    props.setFromWebsite(false);
  }, []);
  useEffect(() => {
    if (currPub?.length > 0) {
      handlePubChange(currPub);
    }
  }, [websiteList]);
  useEffect(() => {
    if (logLevel === "publisher") {
      if (currPub) {

        function reverseDateFormat(dateString) {
          const [day, month, year] = dateString.split('-');
          return `${year}-${month}-${day}`;
        }

        let data = {
          name: currPub,
          startDate: date?.start,
          endDate: date?.end
        }

        const reversedStartDate = data.startDate && typeof data.startDate === 'string'
          ? reverseDateFormat(data.startDate.replace(/'/g, ''))
          : null;

        const reversedEndDate = data.endDate && typeof data.endDate === 'string'
          ? reverseDateFormat(data.endDate.replace(/'/g, ''))
          : null;
        const newobj = {
          ...data,
          startDate: reversedStartDate,
          endDate: reversedEndDate
        };
        const getPubChanges = async () => {
          const res = await getPubChangesByDate(newobj);
          if (!res.error) {
            if (logLevel === "publisher") {
              setChanges(res.msg?.changes ?? []);
            }
          }
        };
        getPubChanges();
      }
    }

    if (currWebsite && currWebsite?.url) {


      function reverseDateFormat(dateString) {
        const [day, month, year] = dateString.split('-');
        return `${year}-${month}-${day}`;
      }

      let data = {
        url: currWebsite?.url,
        startDate: date?.start,
        endDate: date?.end
      }

      const reversedStartDate = data.startDate && typeof data.startDate === 'string'
        ? reverseDateFormat(data.startDate.replace(/'/g, ''))
        : null;

      const reversedEndDate = data.endDate && typeof data.endDate === 'string'
        ? reverseDateFormat(data.endDate.replace(/'/g, ''))
        : null;



      const newobj = {
        ...data,
        startDate: reversedStartDate,
        endDate: reversedEndDate
      };
      const getWebChanges = async () => {
        const res = await getWebChangesByDate(newobj);
        if (!res.error) {
          if (logLevel === "website") {
            setChanges(res.msg?.changes ?? []);
          }
        }
      };
      getWebChanges();
    }

    if (logLevel === "client" && userName?.length > 0) {
      handleClientChange(
        userData.type === "user" && (userData.role !== "adops" || userData.role !== "admin")
          ? (userData.role === "admin" || userData.role === "adops")
            ? form.getFieldValue("client")
            : userData.access.client
          : userName
      );
    }
  }, [logLevel, currPub, userName, currWebsite, date]);
  useEffect(() => {
    if (userData.role === "admin" || userData.role === "adops") {
      const getClientList = async () => {
        const result = await getClient();
        const filter = result.data?.msg?.filter((client) => {
          return client.isArchived === "false";
        });
        setClients(filter);
      };
      getClientList();
    }
  }, [userData]);
  useEffect(() => {
    if (props?.currUrl) {
      handleChange(null, null, props.currUrl);
    }
  }, []);

  return (
    <div className="px-5 h-full w-full flex flex-col">
      <h1 className="font-medium text-[20px]">Logs</h1>
      <div
        className="flex flex-col"
        style={{
          padding: 24,
          background: colorBgContainer,
          height: "100%",
        }}
      >
        <Form
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          style={{
            minWidth: "80vw",
            display: "flex",
            flexFlow: "wrap",
            columnGap: 50,
          }}
          form={form}
        >
          <Form.Item label="Logs level" style={{ width: "50%" }}>
            <Select
              value={logLevel}
              onChange={(value) => {
                setLogLevel(value);
              }}
              dropdownMatchSelectWidth={false}
            >
              <Select.Option value="client">Client</Select.Option>
              <Select.Option value="publisher">Publisher</Select.Option>
              <Select.Option value="website">Website</Select.Option>
            </Select>
          </Form.Item>
          {(userData.role === "admin" || userData.role === "adops") &&
            logLevel === "client" && (
              <Form.Item
                name="client"
                label="Select Client"
                style={{ width: "30%" }}
              >
                <Select
                  showSearch
                  placeholder="Search client"
                  onChange={(value) => {
                    handleClientChange(value);
                  }}
                >
                  {clients.map((client) => {
                    return (
                      <Select.Option value={client.name}>
                        {client.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
          {logLevel !== "client" && (
            <Form.Item
              name="publisher"
              label="Select Publisher"
              style={{ width: "40%", display: "inline-block" }}
              initialValue={currPub?.length > 0 ? currPub : null}
            >
              <Select
                showSearch
                placeholder="Select Publisher"
                style={
                  {
                    // width: "100%",
                  }
                }
                onChange={handlePubChange}
              // onChange={() => {
              //   handlePubChange();
              // }}
              >
                {publisherList.map((pub) => {
                  return (
                    <Select.Option value={pub.name}>{pub.name}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          {websites.length > 0 && logLevel === "website" && (
            <>
              <Form.Item
                name="website"
                label="Select Website"
                style={{
                  display: "inline-block",
                  width: "30%",
                }}
                initialValue={currWebsite?.url}
              >
                <Select
                  showSearch
                  placeholder="Select Website"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                >
                  {websites.map((url) => {
                    return <Select.Option value={url}>{url}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
        {<ConfigLogs changes={changes} setDate={setDate} />}
      </div>
    </div>
  );
};

export default LogsPage;
