import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  email: "",
  clientLogo: "",
  logoUrl: "",
  cdnLink: "",
  redirectLink: "",
  clientChange: true,
  cdnEnabled: true,
  defaultUrls: [],
  customerId: "",
  balance: "",
  impressions: "",
  isPrepaid: false,
  balanceLimit: "",
  changes: [],
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    updateClient: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.clientLogo = action.payload.clientLogo;
      state.logoUrl = action.payload.logoUrl;
      state.cdnLink = action.payload.cdnLink;
      state.redirectLink = action.payload.redirectLink;
      state.clientChange = action.payload.clientChange;
      state.cdnEnabled = action.payload.cdnEnabled;
      state.defaultUrls = action.payload.defaultUrls;
      state.customerId = action.payload.customerId;
      state.balance = action.payload.balance;
      state.isPrepaid = action.payload.isPrepaid;
      state.balanceLimit = action.payload.balanceLimit;
      state.changes = action.payload.changes;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateClient, updateAllChanges } = clientSlice.actions;

export default clientSlice.reducer;
