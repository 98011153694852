import axios from "../axiosInstance";

const getPublishers = async (parent) => {
  // console.log("parent", parent);
  let resolved = {
    data: null,
    error: null,
  };
  try {
    const res = await axios({
      url: "publisher",
      method: "GET",
      params: {
        parent: parent,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      resolved.error = err.response.data;
    } else {
      resolved.error = {
        message: "SomeThing went Wrong",
      };
    }
  }
  return resolved;
};


const getPublishersChanges = async (name) => {
  try {
    const res = await axios.get("publisher/publishersGetChanges", {
      params: { name },
    });
    return res.data;
  } catch (err) {
    const error = err.response
      ? err.response.data
      : { message: "Something went wrong" };
    console.error(error);
    return error;
  }
};

const getPubChangesByDate = async (data) => {
  try {
    const res = await axios({
      url: "publisher/getPubChangesByDate",
      method: "POST",
      data:data
    });
    return { err: false, msg: res.data };

  } catch (err) {
    const error = err.response
      ? err.response.data
      : { message: "Something went wrong" };
    console.error(error);
    return error;
  }
}

const getPublishersByName = async (name) => {
  // console.log("Name", name);
  let resolved = {
    data: null,
    error: null,
  };
  try {
    const res = await axios({
      url: "publisher/getPublishersByName",
      method: "GET",
      params: {
        name: name,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      resolved.error = err.response.data;
    } else {
      resolved.error = {
        message: "SomeThing went Wrong",
      };
    }
  }
  return resolved;
};

const patchPublishers = async (pubData) => {
  try {
    const res = await axios({
      url: "publisher",
      method: "PATCH",
      data: {
        name: pubData.name,
        username: pubData.username,
        password: pubData?.password?.length > 0 ? pubData.password : null,
        email: pubData.email,
        isArchived: pubData.isArchived,
        parent: pubData.parent,
        config: pubData.config,
        revSameForAll: pubData.revSameForAll,
        changes: pubData.changes ?? [],
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const postPublishers = async (pubData) => {
  try {
    const res = await axios({
      url: "publisher",
      method: "POST",
      data: {
        name: pubData.name,
        username: pubData.username,
        password: pubData.password,
        email: pubData.email,
        parent: pubData.parent,
        config: pubData.config,
        revShare: pubData.isFixed ? 0 : pubData.revShare,
        isFixed: pubData.isFixed,
        fixedCPM: pubData.isFixed ? pubData.revShare : 0,
        revSameForAll: pubData.revSameForAll,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const deletePublishers = async (pubData) => {
  try {
    const res = await axios({
      url: "publisher",
      method: "delete",
      data: {
        name: pubData,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const revDataPub = async (pubData) => {
  // console.log(pubData)
  try {
    const res = await axios({
      url: "publisher/rev",
      method: "POST",
      data: {
        name: pubData.name,
        startDate: pubData.startDate,
        endDate: pubData.endDate,
      },
    });
    return { err: false, msg: res };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const verifyPublisher = async (publisherData) => {
  // console.log(publisherData);
  try {
    const res = await axios({
      url: "publisher/login",
      method: "POST",
      data: {
        username: publisherData.username,
        password: publisherData.password,
        userinfo: publisherData.userinfo,
      },
    });
    return { err: false, msg: res.data, token: res.token };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const authPublisher = async (key) => {
  try {
    const res = await axios({
      url: "publisher/auth",
      method: "POST",
      data: {
        token: key,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const forgotPub = async (email) => {
  try {
    const res = await axios({
      url: "publisher/forgot",
      method: "POST",
      data: { email: email },
    });
    return { err: res.data.error, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const resetPub = async (token, id, password) => {
  try {
    const res = await axios({
      url: "publisher/reset",
      method: "POST",
      data: { token: token, id: id, password: password },
    });
    return { err: res.data.error, msg: res.data.msg };
  } catch (err) {
    return { err: false, msg: err };
  }
};

const getPlacementData = async (publisherData) => {
  // console.log(publisherData);
  try {
    const res = await axios({
      url: "placement/placement_DB",
      method: "POST",
      data: {
        dateStart: publisherData.startDate,
        dateEnd: publisherData.endDate,
        placementName: "Footballwebb Video",
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};
const publogOut = async () => {
  try {
    const res = await axios({
      url: "publisher/logout",
      method: "POST",
    });
    return { err: false, msg: res.data };
  } catch (err) { 
    return { err: true, msg: err };
  }
}

const patchPubChanges = async (pubData) => {
  try {
    const res = await axios({
      url: "publisher//patchPubChanges",
      method: "PATCH",
      data: {
        name: pubData.name,
        changes: pubData.changes
      }
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
}


export {
  getPublishers,
  patchPublishers,
  postPublishers,
  deletePublishers,
  verifyPublisher,
  authPublisher,
  revDataPub,
  getPublishersByName,
  forgotPub,
  resetPub,
  getPlacementData,
  getPublishersChanges,
  getPubChangesByDate,
  publogOut,
  patchPubChanges
};
