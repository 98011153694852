import React, { useState } from "react";
import {
  Form,
  Switch,
  Select,
  Button,
  Input,
  Radio,
  Collapse,
  Tooltip,
  InputNumber,
  Popconfirm,
} from "antd";
import { InfoCircleOutlined, WarningFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";

const Objectgroup = (props) => {
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const access = useSelector((state) => state.user.access);
  const username = useSelector((state) => state.user.name);
  const userRole = useSelector((state) => state.user.role);
  const userType = useSelector((state) => state.user.type);
  const [popOpen, setPopOpen] = useState(false);
  const confirm = props.confirm;
  const setConfirm = props.setConfirm;
  const values = props.values;

  const handleCancel = (name) => {
    props.form.setFieldValue(name, false);
    props.handleChange();
    setPopOpen(false);
  };
  const fields = props.data.map((field) => {
    if (field.dependency && field.category === "general") {
      let dependency = field.dependency?.some((dep) => {
        for (let key in dep) {
          return values[key] === dep[key];
        }
      });
      field.display = field.dependency ? dependency === true : field.display;
    } else {
      let dependency = field.dependency?.every((dep) => {
        for (let key in dep) {
          let field = props.data.filter((el) => el.name.includes(key));
          field = field[0];
          let value = props.form.getFieldValue(field.name);
          if (value) {
            return value === dep[key];
          } else {
            return field.default === dep[key];
          }
        }
      });

      field.display = field.dependency ? dependency === true : field.display;
    }
    let disabled = access?.player
      ? userRole !== "admin" &&
        !access?.player?.config?.includes(
          typeof field.name === "string" ? field.name : field.name[0]
        )
      : false;
    let isRequired =
      typeof field?.isRequired === "boolean"
        ? field?.isRequired
        : field?.isRequired?.includes(username);
    if (
      (field.display || props.obj) &&
      props.cat === field.category &&
      !field?.disabledclients?.includes(username) &&
      (field?.enabledclients
        ? field.enabledclients?.includes(username) ||
          field.enabledclients?.includes(userType)
        : true)
    ) {
      let style = {
        display: field.display && props.cat ? "inline-block" : "none",
        marginLeft: props.cat?.includes("Header") ? 0 : "10%",
        marginBottom: props.cat?.includes("Header") ? 0 : "30px",
        fontSize: "10px",
        ...field?.style,
      };
      let labelCol = props.cat?.includes("Header") ? {} : { span: 24 };
      if (field.type === "switch") {
        return (
          <>
            <Popconfirm
              title="Are you sure?"
              description="It may violate Google policies. Do you still want to continue?"
              open={field.confirm !== undefined && popOpen}
              icon={<WarningFilled twoToneColor="red" />}
              okText="Confirm"
              okButtonProps={{ danger: true }}
              onCancel={() => {
                handleCancel(field.name);
              }}
              onConfirm={() => {
                setPopOpen(false);
                props.form.setFieldValue(field.name, true);
                setConfirm(true);
              }}
            >
              {" "}
              <Form.Item
                name={field.name}
                labelCol={labelCol}
                className="md:w-2/12 w-6/12"
                style={style}
                label={
                  <div className="flex gap-2 text-xs">
                    {field.label}
                    <Tooltip placement="top" title={field.description}>
                      {" "}
                      <InfoCircleOutlined />{" "}
                    </Tooltip>
                  </div>
                }
                valuePropName="checked"
                initialValue={field.default}
              >
                <Switch
                  onChange={(value) => {
                    if (field?.confirm) {
                      if (!confirm) {
                        props.form.setFieldValue(field.name, false);
                      }
                      if (!value) {
                        setConfirm(value);
                      }
                      setPopOpen(value);
                    }
                    props.handleChange();
                  }}
                  disabled={disabled}
                />
              </Form.Item>
            </Popconfirm>
          </>
        );
      } else if (field.type === "radio") {
        return (
          <Form.Item
            name={field.name}
            labelCol={labelCol}
            className="md:w-2/12 w-6/12"
            label={
              <div className="flex gap-2 text-xs">
                {field.label}
                <Tooltip placement="top" title={field.description}>
                  {" "}
                  <InfoCircleOutlined />{" "}
                </Tooltip>
              </div>
            }
            style={style}
            initialValue={field.default}
          >
            <Radio.Group
              disabled={disabled}
              onChange={props.handleChange}
              options={field.selectOptions}
            />
          </Form.Item>
        );
      } else if (field.type === "Input") {
        return (
          <>
            {field.info && <div className="ops-warning">{field.info}</div>}
            <Form.Item
              name={field.name}
              className="md:w-2/12 w-6/12"
              style={{ ...style }}
              labelCol={labelCol}
              label={
                <div className="flex gap-2 text-xs">
                  {field.label}
                  <Tooltip placement="top" title={field.description}>
                    {" "}
                    <InfoCircleOutlined />{" "}
                  </Tooltip>
                </div>
              }
              initialValue={field.default}
              rules={[
                {
                  required: isRequired,
                  message: "This field is required",
                },
              ]}
            >
              {field.isNum ? (
                <InputNumber
                  onChange={props.handleChange}
                  placeholder={field.placeholder}
                  disabled={disabled}
                />
              ) : (
                <Input
                  onChange={props.handleChange}
                  placeholder={field.placeholder}
                  disabled={disabled}
                  onInput={(e) =>
                    field?.lower
                      ? (e.target.value = e.target.value.toLowerCase())
                      : field?.upper
                      ? (e.target.value = e.target.value.toUpperCase())
                      : e.target.value
                  }
                />
              )}
            </Form.Item>
            {/* {field.name === "GA_CODE" && (
              <Button type="primary" onClick={props.handleGenerate}>
                Generate
              </Button>
            )} */}
          </>
        );
      } else if (field.type === "Textarea") {
        return (
          <>
            {field.info && <div className="warning">{field.info}</div>}
            <Form.Item
              name={field.name}
              className="md:w-2/12 w-6/12"
              style={{ ...style }}
              labelCol={labelCol}
              label={
                <div className="flex gap-2 text-xs">
                  {field.label}
                  <Tooltip placement="top" title={field.description}>
                    {" "}
                    <InfoCircleOutlined />{" "}
                  </Tooltip>
                </div>
              }
              initialValue={field.default}
              rules={[
                {
                  required: isRequired,
                  message: "This field is required",
                },
              ]}
            >
              <TextArea
                rows={field.rows}
                style={{ whiteSpace: "nowrap" }}
                placeholder={field.placeholder}
                onChange={props.handleChange}
                disabled={disabled}
              />
            </Form.Item>
          </>
        );
      } else if (field.type.includes("Dropdown")) {
        return (
          <>
            {field.info && <div className="ops-warning">{field.info}</div>}
            <Form.Item
              name={field.name}
              className="md:w-2/12 w-6/12"
              style={style}
              labelCol={labelCol}
              label={
                <div className="flex gap-2 text-xs">
                  {field.label}
                  <Tooltip placement="top" title={field.description}>
                    {" "}
                    <InfoCircleOutlined />{" "}
                  </Tooltip>
                </div>
              }
              initialValue={field.default}
            >
              <Select
                mode={field.type === "Dropdown" ? "single" : "tags"}
                disabled={disabled}
                placeholder={field.placeholder}
                dropdownMatchSelectWidth={false}
                onChange={props.handleChange}
              >
                {field.selectOptions.map((opt) => {
                  return (
                    <Select.Option value={opt.value ?? opt}>
                      {opt.label ?? opt}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </>
        );
      } else if (field.type === "objectgroup") {
        return (
          <Collapse style={{ marginBottom: "40px", marginLeft: "30px" }}>
            <Panel header={field.label} key={field.name} forceRender={true}>
              <Objectgroup
                data={field.fields}
                cat={props.cat}
                obj={true}
                values={props.values}
                handleChange={props.handleChange}
              />
            </Panel>
          </Collapse>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  });
  return fields;
};

export default Objectgroup;
