import axios from "axios";
import { useEffect, useState } from "react";

const Instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

Instance.interceptors.request.use(
  (config) => {
    const token =
      "Bearer " +
      document.cookie
        .substring(document.cookie.indexOf("%20") + 3)
        .split("USER")[0];
    // console.log(token);
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Instance;
