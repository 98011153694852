import React, { useState } from "react";
import { Form, Input, Select, Radio, Alert } from "antd";

const Youtube = (props) => {
  const [type, setType] = useState(props.form.getFieldValue("yttype") ?? "url");
  const [urlType, setUrlType] = useState(props.form.getFieldValue("urltype"));

  return (
    <>
      <Alert
        message={"Please wait for a few minutes to see updates"}
        type="info"
        showIcon
        className="my-3"
      />
      <Form.Item name="urltype" label="Select type" initialValue={"channel"}>
        <Select
          onChange={(value) => {
            setUrlType(value);
          }}
        >
          <Select.Option value="channel">Channel</Select.Option>
          <Select.Option value="playlist">Playlist</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="yttype" initialValue={"url"}>
        <Radio.Group
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          <Radio value="url">Enter Url</Radio>
          <Radio value="id">Enter Id</Radio>
        </Radio.Group>
      </Form.Item>
      {type === "url" ? (
        <Form.Item
          name="yturl"
          label="Youtube URL"
          dependencies={["urltype"]}
          rules={[
            {
              validator(_, value) {
                console.log(urlType, value);
                if (
                  (urlType === "channel" && value.includes("@")) ||
                  (urlType === "playlist" && value.includes("playlist"))
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter suppored format")
                );
              },
            },
          ]}
        >
          <Input placeholder="Enter channel or playlist url" />
        </Form.Item>
      ) : (
        <Form.Item name="id" label="Youtube Id">
          <Input placeholder="Enter channel or playlist id" />
        </Form.Item>
      )}
      <Form.Item
        name="frequency"
        label="Update Frequency"
        initialValue={"weekly"}
      >
        <Select placeholder="Select frequency">
          <Select.Option value="daily">Daily</Select.Option>
          <Select.Option value="weekly">Weekly</Select.Option>
          <Select.Option value="monthly">Monthly</Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default Youtube;
