const getDateTime = () => {
  let today = new Date(Date.now());
  let date =
    ("0" + today.getDate()).slice(-2) +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    today.getFullYear();
  let time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  return { date, time };
};

export { getDateTime };
