import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Select } from "antd";
import { userFields } from "../../Video/Config/ConfigFields";
const EditAccess = (props) => {
  const [open, setOpen] = useState(false);
  const [menuItems, setMenuItems] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  let data = userFields;
  const [configFields, setConfigFields] = useState([
    ...data.map((item) =>
      typeof item.name === "string" ? item.name : item.name[0]
    ),
  ]);

  useEffect(() => {
    const uniqueStrings = configFields.filter(
      (item, index) => configFields.indexOf(item) === index
    );
    setConfigFields(uniqueStrings);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleOk = () => {
    handleCancel();
  };
  const [form] = Form.useForm(props.form);
  const handleItemsChange = (value) => {
    setMenuItems(value);
  };
  const handleTabsChange = (value) => {
    if (!tabs.includes(value)) {
      setTabs(value);
    }
  };
  const handleConfigChange = (value) => {
    if (value) {
      let config = data.map((item) =>
        typeof item.name === "string"
          ? item.name
          : item.name[item.name.length - 1]
      );
      if (value.includes("all")) {
        setAllSelected(true);
        form.setFieldValue(["access", "player", "config"], config);
        setConfigFields([]);
      } else if (value.includes("removeall")) {
        form.setFieldValue(["access", "player", "config"], []);
        setAllSelected(false);
        setConfigFields(config);
      } else {
        form.setFieldValue(["access", "player", "config"], value);
        let newFields = config.filter((item) => !value.includes(item));
        setConfigFields(newFields);
      }
    }
  };
  // useEffect(() => {
  //   if (tabs?.includes(2) && ) {
  //     let initConfig =
  //       props?.data?.access?.player?.config?.length > 0
  //         ? props?.data?.access?.player?.config
  //         : data;
  //     console.log(initConfig);
  //     form.setFieldValue(
  //       ["access", "player", "config"],
  //       [
  //         ...initConfig.map((item) =>
  //           typeof item.name === "string"
  //             ? item.name
  //             : item.name[item.name.length - 1]
  //         ),
  //       ]
  //     );
  //   }
  //   setConfigFields([]);
  //   setAllSelected(true);
  // }, [tabs]);
  useEffect(() => {
    if (props.edit && props?.data?.access) {
      setMenuItems(props.data.access.menuItems);
      setTabs(props.data.access.player.tabs);
    }

    handleConfigChange(
      props.data?.access?.player?.config?.length > 0
        ? props.data.access.config
        : "removeall"
    );
  }, [props.edit, props.data]);

  return (
    <>
      <div className="flex justify-end" style={{ width: "92%" }}>
        <Button style={{ marginLeft: "10%" }} onClick={handleOpen}>
          Edit Access
        </Button>
      </div>
      <Modal
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        bodyStyle={{ height: 400 }}
      >
        <Form form={form} preserve={false}>
          <Form.Item
            name={["access", "menuItems"]}
            label="Select menu"
            initialValue={["home"]}
          >
            <Select mode="multiple" onChange={handleItemsChange}>
              <Select.Option value="home">Home</Select.Option>
              <Select.Option value="publisher">Publisher</Select.Option>
              <Select.Option value="website">Website</Select.Option>
              <Select.Option value="player">Player</Select.Option>
              <Select.Option value="connection">Connections</Select.Option>
              <Select.Option value="logs">Logs</Select.Option>
            </Select>
          </Form.Item>
          {menuItems?.includes("player") && (
            <Form.Item
              name={["access", "player", "tabs"]}
              label="Select Player Tabs"
              initialValue={[0]}
            >
              <Select mode="multiple" onChange={handleTabsChange}>
                <Select.Option value={0}>Ad Sources</Select.Option>
                <Select.Option value={1}>Content Integration</Select.Option>
                <Select.Option value={2}>Video Player Config</Select.Option>
                <Select.Option value={3}>
                  Video Player Customisation
                </Select.Option>
              </Select>
            </Form.Item>
          )}
          {tabs?.includes(2) && (
            <Form.Item
              name={["access", "player", "config"]}
              label="Select Player Config"
            >
              <Select
                mode="multiple"
                onChange={handleConfigChange}
                style={{ maxHeight: "250px", overflow: "auto" }}
              >
                <Select.Option value={allSelected ? "removeall" : "all"}>
                  {allSelected ? "Remove All" : "Select All"}
                </Select.Option>
                {configFields.map((field) => {
                  return <Select.Option value={field}>{field}</Select.Option>;
                })}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default EditAccess;
