import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Upload,
  Form,
  Modal,
  Input,
  notification,
  Checkbox,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { cdnLogoUpload } from "../../API/Upload";
import { useSelector, useDispatch } from "react-redux";
import { getClient, patchClient } from "../../API/Clients";
import { updateClient } from "../../redux/reducers/clientSlice";
import { updateAll } from "../../API/Websites";
import { updateWebsite } from "../../redux/reducers/WebSlice";
import { getDateTime } from "../Utility/utils";

function ClientLogo(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [updatedData, setupdatedData] = useState({});
  const { name, email } = useSelector((state) => state.client);
  // const statedetails = useSelector((state) => state.user);
  const userName = useSelector((state) => state.user.name);
  const clientList = useSelector((state) => state.client);
  const [amount, setAmount] = useState();
  const [balance, setBalance] = useState(0.0);
  const [uploadCount, setUploadCount] = useState(0);
  const [urlLink, setUrlLink] = useState(clientList.redirectLink);
  const [uploadedImages, setuploadedImages] = useState(null);
  const [defaultUrls, setDefaultUrls] = useState();
  const websiteList = useSelector((state) => state.website.websiteList);
  const [cdnLink, setCDNLink] = useState("");
  const [changes, setChanges] = useState([]);
  function setUrls(fields) {
    console.log("inside seturls", fields);
    setDefaultUrls(fields);
  }

  useEffect(() => {
    if (clientList.redirectLink && clientList.logoUrl !== "removed") {
      setuploadedImages([
        { name: clientList.name, status: "done", url: clientList.logoUrl },
      ]);
    }
  }, []);

  useEffect(() => {
    setCDNLink(
      clientList?.cdnLink?.includes("https")
        ? clientList?.cdnLink?.split("https://")?.at(-1)
        : clientList?.cdnLink ?? ""
    );
  }, [clientList]);

  function refetchData(response) {
    dispatch(
      updateClient({
        name: name,
        email: email,
        clientLogo: true,
        redirectLink: urlLink,
        logoUrl: response,
        clientChange: true,
      })
    );
  }
  const handleNumberChange = (value) => {
    // Handle the number change event here
    setAmount(value);
    console.log(value);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    let linkChange = [];
    if (Object.keys(updatedData).length === 0) {
      if (urlLink !== clientList.redirectLink) {
        const { date, time } = getDateTime();
        linkChange.push({
          user: userName,
          name: "Global redirect link",
          date,
          time,
          kind: clientList?.redirectLink?.length > 0 ? "edited" : "added",
          lhs: clientList?.redirectLink ?? "",
          rhs: urlLink,
        });
      }
      let updatedData = {
        name: name,
        email: email,
        clientLogo: true,
        redirectLink: urlLink,
        logoUrl: clientList.logoUrl,
        changes: [...(clientList.changes ?? []), ...changes, ...linkChange],
        userrole: "client",
      };
      if (uploadedImages?.length === 0 || !uploadedImages) {
        let newUpdate = { ...updatedData, logoUrl: "removed" };
        const update = await patchClient(newUpdate);
        if (update.err === false) {
          api.info({
            message: `sucess`,
            description: "Client Logo removed",
            placement: "top",
          });
        }
        refetchData(clientList.logoUrl);
      } else {
        const update = await patchClient(updatedData);
        if (update.err === false) {
          api.info({
            message: `sucess`,
            description: "Client Updated",
            placement: "top",
          });
        } else {
          api.warning({
            message: `Fail`,
            description: "Client not updated",
            placement: "top",
          });
        }
        refetchData(clientList.logoUrl);
      }
    } else {
      if (!updatedData.logoUrl) {
        alert("plase upload the logo");
        return;
      }
      if (uploadedImages?.length === 0 || !uploadedImages) {
        let newUpdate = { ...updatedData, logoUrl: "removed" };

        const updateClient = await patchClient(newUpdate);
        if (updateClient.err === false) {
          api.info({
            message: `sucess`,
            description: "Client Logo removed",
            placement: "top",
          });
        } else {
          api.warning({
            message: `Fail`,
            description: "Client not updated",
            placement: "top",
          });
        }
        refetchData(newUpdate.logoUrl);
      } else {
        const updateClient = await patchClient(updatedData);

        if (updateClient.err === false) {
          api.info({
            message: `sucess`,
            description: "Client Updated",
            placement: "top",
          });
        } else {
          api.warning({
            message: `Fail`,
            description: "Client not updated",
            placement: "top",
          });
        }
        refetchData(updatedData.logoUrl);
      }
    }
    let webUpdate = {
      client: clientList.name,
      redirectLink: urlLink,
      logoUrl: updatedData.logoUrl ?? clientList.logoUrl,
      linkFlag: true,
      clientLogo: clientList.clientLogo,
      setLogoAll: form.getFieldValue("set_all"),
    };
    const response = await updateAll(webUpdate);
    dispatch(updateWebsite({ websiteList: websiteList, websiteChange: true }));
    setTimeout(() => {
      props.setIsModalOpen(false);
      setConfirmLoading(false);
    }, 3000);
  };

  const handleCancel = () => {
    props.setIsModalOpen(false);
  };
  // upload functions
  const handleUpload = (file) => {
    setUploadCount((prev) => prev + 1);
    setuploadedImages([{ name: file.name }]);
  };

  const handleRemove = (file) => {
    setUploadCount((prev) => prev - 1);
    const updatedFileList = uploadedImages.filter((f) => f.uid !== file.uid);
    setuploadedImages(updatedFileList);
  };
  const handleAmount = () => {
    if (amount) {
      navigate("/payment", { state: { amount: amount } });
    } else {
      alert("Please fill in the amount.");
    }
  };

  useEffect(() => {
    const getInfo = async () => {
      const res = await getClient(clientList.name);

      if (res.data.msg.balance) {
        setBalance(res.data.msg.balance);
      }
    };
    getInfo();
  }, []);

  return (
    <Modal
      open={props.isModalOpen}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ top: 10 }}
    >
      {contextHolder}
      <div>
        {/* <div className="flex flex-col gap-2">
          <p>Recharge Account</p>
          <InputNumber
            min={0} // Set the minimum value if needed
            // Set the maximum value if needed
            step={1} // Set the step value if needed
            addonAfter="$"
            onChange={handleNumberChange}
            style={{ width: "200px" }} // Set the desired width of the input
          />
          <Button type="primary" onClick={handleAmount}>
            Add Value
          </Button>
          <div>
            <p>Acount Balance</p>

            <p className="border-[4px] border-sky-500">{balance}</p>
          </div>
        </div> */}
        <div>
          <p>Set Client default Logo</p>
          <div className="bg-[#F4F4F5] p-4">
            <Form form={form} layout="vertical">
              <Form.Item
                name="LOGO_REDIRECT_LINK"
                label={<span className="text-xs">Logo Redirect Link</span>}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  // marginLeft: "10%",
                  // marginBottom: "30px",
                }}
              >
                <div>
                  <Input
                    value={urlLink}
                    placeholder="Enter url"
                    onChange={(e) => setUrlLink(e.target.value)}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="upload"
                style={{
                  marginTop: "2rem",
                }}
                className="block sm:inline-block ml-[24px] sm:ml-[10px]"
                initialValue={""}
              >
                <Upload
                  accept="image/png, image/svg+xml"
                  fileList={uploadedImages}
                  onRemove={handleRemove}
                  customRequest={(file) => {
                    let fileSize = file.file.size / 1000;
                    if (fileSize <= 100) {
                      const reader = new FileReader();
                      reader.onload = async (event) => {
                        const response = await cdnLogoUpload(
                          event.target.result,
                          name + "." + file.file.name.split(".").pop(),
                          "clientlogo"
                        );

                        if (response.err) {
                          file.onError("error");
                        } else {
                          //   setLink(response.link);
                          //   setRedirectLink(true);
                          console.log("file uploaded sucessfully");
                          file.onSuccess("ok");
                          const { date, time } = getDateTime();
                          let newChanges = [
                            {
                              user: userName,
                              name: clientList?.logoUrl
                                ? "Global logo"
                                : "Global logo",
                              date,
                              time,
                              kind: clientList?.logoUrl ? "edited" : "added",
                            },
                          ];
                          let updatedData = {
                            name: name,
                            email: email,
                            clientLogo: true,
                            redirectLink: urlLink,
                            logoUrl: response.link,
                            changes: [
                              ...(clientList?.changes ?? []),
                              ...newChanges,
                            ],
                            userrole: "client",
                          };

                          setChanges(newChanges);
                          setupdatedData(updatedData);

                          refetchData(response.link);
                        }
                      };
                      reader.readAsArrayBuffer(file.file);
                    } else {
                      alert("Image should be under 100Kb");
                      file.onError("error");
                    }
                  }}
                  maxCount={1}
                  beforeUpload={handleUpload}
                >
                  <Button icon={<UploadOutlined />}>Upload Global Logo</Button>
                </Upload>
              </Form.Item>
              <Form.Item
                name="set_all"
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox>Set global logo for all websites?</Checkbox>
              </Form.Item>
            </Form>
          </div>
        </div>
        <p>Set Custom CDN</p>
        <div className="bg-[#F4F4F5] p-4">
          <Form form={form} layout="vertical">
            <Form.Item
              name="CDN_LINK"
              label={<span className="text-xs">Custom CDN Link</span>}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                // marginLeft: "10%",
                // marginBottom: "30px",
              }}
            >
              <div>
                <Input
                  addonBefore="https://"
                  disabled={true}
                  value={cdnLink}
                  placeholder="Enter url"
                  onChange={(e) => setCDNLink(e.target.value)}
                />
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default ClientLogo;
