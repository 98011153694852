import React, { useEffect, useState } from "react";
import {
  Button,
  Upload,
  Form,
  Modal,
  message,
  Spin,
  Popconfirm,
  Input,
  Alert,
  Switch,
  Divider,
  Typography,
} from "antd";

import {
  LoadingOutlined,
  InboxOutlined,
  DeleteOutlined,
  PlayCircleTwoTone,
  CloseOutlined,
} from "@ant-design/icons";
import { cdnUpload, getVideos, deleteVideo } from "../../../API/Upload";

const UploadVid = (props) => {
  const { Dragger } = Upload;
  const [messageApi, contextHolder] = message.useMessage();
  const [vidElem, setVidElem] = useState(null);
  const [open, setOpen] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hls, setHls] = useState(props.content.hls);
  const [fileList, setFileList] = useState([]);
  const [removeController, setRemoveController] = useState(null);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg ?? "Maximum upload limit reached!",
    });
  };
  const success = (msg) => {
    messageApi.open({
      type: "success",
      content: msg ?? "",
    });
  };
  const handlePreview = (i) => {
    let vid = props.videoList[i];
    setVidElem(() => {
      return (
        <img
          width={320}
          height={240}
          src={`https://vz-c152e544-43b.b-cdn.net/${vid.uid}/preview.webp`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
              "https://unibots.b-cdn.net/managed_vp_imgs/borken_image.svg";
          }}
          alt={vid.name}
        />
      );
    });
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleUpload = (file) => {
    setFileList((prev) => [
      { name: file.name, status: "uploading", percent: 0 },
    ]);
    setUploadCount((prev) => prev + 1);
  };
  const handleRemoveVideo = async (i) => {
    setLoading(true);
    const resposne = await deleteVideo(props.videoList[i].uid);
    setLoading(false);
    let links = [...props.uploadLinks];
    links.splice(i, 1);
    props.setUploadLinks(links);
    let list = [...props.videoList];
    list.splice(i, 1);
    let switchList = [...props.enableLink];
    switchList.splice(i, 1);
    props.setEnableLink(switchList);
    props.setVideoList(list);
    setUploadCount((prev) => prev - 1);
  };
  const handleRemoveLink = (i) => {
    let links = [...props.uploadLinks];
    links.splice(i, 1);
    props.setUploadLinks(links);
    let switchList = [...props.enableLink];
    switchList.splice(i, 1);
    props.setEnableLink(switchList);
  };

  useEffect(() => {
    if (uploadCount > 10) {
      error();
    }
  }, [uploadCount]);

  // useEffect(() => {
  //   if (props.cdnEnabled) {
  //     const getVideoList = async () => {
  //       setLoading(true);
  //       const res = await getVideos(props.currWebsite.url);
  //       let defaultVideos = [];
  //       if (!res.err) {
  //         defaultVideos = res.videos;
  //       }
  //       let links = defaultVideos.map((vid) => {
  //         return `https://mvpstream.unibotscdn.com/${vid.uid}/playlist.m3u8`;
  //       });
  //       props.setUploadLinks(links);
  //       let enableLink = links.map((link) => {
  //         let flag = props.content.links.every((val) => {
  //           return typeof val === "object"
  //             ? link === val.link
  //               ? val.enabled
  //               : true
  //             : true;
  //         });
  //         return flag;
  //       });
  //       props.setEnableLink(enableLink);
  //       setVideoList(defaultVideos);
  //       setLoading(false);
  //     };
  //     getVideoList();
  //   } else {
  //     let customLinks = props.content.links.map((link, i) => {
  //       props.form.setFieldValue(
  //         "url" + i,
  //         typeof link === "object" ? link?.link : link
  //       );
  //       return typeof link === "object" ? link?.link : link;
  //     });
  //     console.log(props.content);
  //     props.setUploadLinks(customLinks);
  //     let enableLink = props.content.links.map((link) => {
  //       return link?.enabled ?? true;
  //     });
  //     props.setEnableLink(enableLink);
  //   }
  // }, [props.cdnEnabled]);

  const handleHls = (value) => {
    setHls(value);
  };

  const videoFormats = [
    "mp4",
    "avi",
    "mov",
    "wmv",
    "flv",
    "mkv",
    "webm",
    "m4v",
  ];

  useEffect(() => {
    props.videoList.forEach((vid, i) => {
      props.form.setFieldValue("url" + i, vid.name);
    });
  }, [props.videoList]);

  useEffect(() => {
    props.enableLink.forEach((value, i) => {
      props.form.setFieldValue("enable" + i, value);
    });
  }, [props.enableLink]);

  const handleSwitchChange = (value, i) => {
    let links = JSON.parse(JSON.stringify(props.enableLink));
    links[i] = value;
    props.setEnableLink(links);
  };

  const handleAddLink = () => {
    props.setUploadLinks((prev) => [...prev, ""]);
    props.setEnableLink((prev) => [...prev, true]);
  };

  const handleLinkChange = (e, i) => {
    let links = [...props.uploadLinks];
    links[i] = e.target.value;
    props.setUploadLinks(links);
  };

  return (
    <>
      <Alert
        message={"Atleast one media should be added!"}
        type="info"
        showIcon
        className="my-3"
      />
      <Alert
        message={"Uploaded media might take a few minutes to process!"}
        type="warning"
        showIcon
        className="my-3"
      />

      {props.cdnEnabled ? (
        <>
          <Form.Item>
            {contextHolder}
            <Dragger
              accept="video/mp4,video/x-m4v,video/*"
              fileList={fileList}
              showUploadList={{ removeIcon: <CloseOutlined /> }}
              customRequest={(file) => {
                let fileSize = file.file.size / 1000000;
                if (uploadCount <= 10 && fileSize <= 200) {
                  const reader = new FileReader();
                  const controller = new AbortController();
                  setRemoveController(controller);
                  reader.onload = async (event) => {
                    const response = await cdnUpload(
                      event.target.result,
                      file.file.name,
                      props.currWebsite.url,
                      (progressEvent) => {
                        const progressPercentage = Math.round(
                          (progressEvent.loaded / progressEvent.total) * 100
                        );
                        setFileList((prev) => [
                          { ...prev[0], percent: progressPercentage },
                        ]);
                      },
                      controller
                    );
                    if (response.err) {
                      file.onError("error");
                      error("Upload failed! Please check file name and fromat");
                    } else {
                      props.setUploadLinks((prev) => [
                        ...prev,
                        `https://mvpstream.unibotscdn.com/${response.id}/playlist.m3u8`,
                      ]);
                      props.setVideoList((prev) => [
                        {
                          name: file.file.name,
                          uid: response.id,
                          status: "done",
                        },
                        ...prev,
                      ]);
                      setFileList([]);
                      props.setEnableLink((prev) => [...prev, true]);
                      file.onSuccess("ok");
                      success("Video uploaded!");
                    }
                  };
                  reader.readAsArrayBuffer(file.file);
                } else if (fileSize > 200) {
                  error("Video should be under 200 Mb");
                  setFileList((prev) => [{ ...prev[0], status: "error" }]);
                  file.onError("error");
                }
              }}
              parallal={false}
              maxCount={10}
              beforeUpload={handleUpload}
              onRemove={() => {
                removeController.abort();
                setFileList([]);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
            <Modal
              title="Preview"
              width={370}
              open={open}
              footer={null}
              onCancel={handleCancel}
              destroyOnClose
            >
              {vidElem}
            </Modal>
          </Form.Item>
          {loading ? (
            <div className="w-full flex justify-center">
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <>
              {props.videoList.map((vid, i) => {
                return (
                  <div>
                    <div className="flex justify-between gap-2">
                      <Form.Item
                        name={"enable" + i}
                        valuePropName="checked"
                        style={{ margin: 0 }}
                        initialValue={true}
                      >
                        <Switch
                          size="small"
                          onChange={(value) => {
                            handleSwitchChange(value, i);
                          }}
                        />
                      </Form.Item>
                      <Typography className="w-full p-1 ">
                        {vid.name}
                      </Typography>
                      <Button
                        type="text"
                        icon={<PlayCircleTwoTone />}
                        onClick={() => {
                          handlePreview(i);
                        }}
                      />
                      <Popconfirm
                        title="Confirm"
                        description="Are you sure you want to delete this item?"
                        okText="Delete"
                        okButtonProps={{ danger: true, loading: loading }}
                        onConfirm={() => {
                          handleRemoveVideo(i);
                        }}
                      >
                        <Button danger type="text" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </div>
                    {i < props.videoList.length - 1 && (
                      <Divider style={{ margin: 2 }} />
                    )}
                  </div>
                );
              })}
            </>
          )}
        </>
      ) : (
        <>
          {props.uploadLinks.map((link, i) => {
            return (
              <div>
                <div className="flex justify-between gap-2">
                  <Form.Item name={"enable" + i} valuePropName="checked">
                    <Switch
                      size="small"
                      defaultChecked
                      onChange={(value) => {
                        handleSwitchChange(value, i);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"url" + i}
                    style={{ width: "90%" }}
                    rules={[
                      {
                        validator(_, value) {
                          if (
                            !hls &&
                            value &&
                            (videoFormats.some((format) =>
                              value.includes("." + format)
                            ) ||
                              value.includes(".m3u8"))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Please enter suppored format")
                          );
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter url here"
                      onBlur={(e) => {
                        handleLinkChange(e, i);
                      }}
                    />
                  </Form.Item>

                  <Popconfirm
                    title="Confirm"
                    description="Are you sure you want to delete this item?"
                    okText="Delete"
                    okButtonProps={{ danger: true, loading: loading }}
                    onConfirm={() => {
                      handleRemoveLink(i);
                    }}
                  >
                    <Button danger type="text" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </div>
              </div>
            );
          })}
          <div className="flex justify-end">
            <Button onClick={handleAddLink}>Add more</Button>
          </div>
        </>
      )}
    </>
  );
};

export default UploadVid;
