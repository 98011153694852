import axios from "../axiosInstance";

const getWebsites = async (url, parent) => {
  let resolved = {
    data: null,
    error: null,
  };
  try {
    const res = await axios({
      url: "website",
      method: "GET",
      params: { url: url, parent: parent },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      resolved.error = err.response.data;
    } else {
      resolved.error = {
        message: "SomeThing went Wrong",
      };
    }
  }
  return resolved;
};


const getWebsitesByPlayerConfig = async (parent, url) => {
  let resolved = {
    data: null,
    error: null,
  };
  try {
    const res = await axios({
      url: "website/websiteConfigByUrl",
      method: "GET",
      params: { parent: parent, url: url },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      resolved.error = err.response.data;
    } else {
      resolved.error = {
        message: "SomeThing went Wrong",
      };
    }
  }
  return resolved;
};

const getWebChangesByDate = async (data) => {
  try {
    const res = await axios({
      url: "website/getWebChangesByDate",
      method: "POST",
      data:data
    });
    return { err: false, msg: res.data };

  } catch (err) {
    const error = err.response
      ? err.response.data
      : { message: "Something went wrong" };
    console.error(error);
    return error;
  }
}

const postWebsites = async (webData) => {
  // console.log(webData);
  try {
    const res = await axios({
      url: "website",
      method: "POST",
      data: {
        url: webData.url,
        parent: webData.parent,
        config: webData.config,
        revShare: webData.revShare,
        isFixed: webData.isFixed,
        fixedCPM: webData.fixedCPM,
        timezone: webData.timezone,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const patchWebsites = async (webData) => {
  try {
    const res = await axios({
      url: "website",
      method: "PATCH",
      data: {
        url: webData.url,
        parent: webData.parent,
        config: webData.config,
        revShare: webData.revShare,
        isFixed: webData.isFixed,
        fixedCPM: webData.fixedCPM,
        isArchived: webData.isArchived,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const deleteWebsites = async (webData) => {
  // console.log(pubData)
  try {
    const res = await axios({
      url: "website",
      method: "DELETE",
      data: {
        url: webData.url,
        parent: webData.parent,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const patchChanges = async (data) => {
  try {
    const res = await axios({
      url: "website/patchwebChanges",
      method: "PATCH",
      data: {
        url: data?.url,
        changes: data?.changes
      },
    });
    return { err: false, msg: res.data };
  } catch (error) {
    return { err: true, msg: error };
  }
}

const updateAll = async (data) => {
  try {
    const res = await axios({
      url: "website/updateall",
      method: "PATCH",
      data: {
        client: data?.client,
        videoSrcDefault: data?.videoSrcDefault,
        website: data?.website,
        linkFlag: data?.linkFlag,
        logoUrl: data?.logoUrl,
        redirectLink: data?.redirectLink,
        cdnLink: data?.cdnLink,
        cdnEnabled: data?.cdnEnabled,
        setLogoAll: data?.setLogoAll,
        clientLogo: data?.clientLogo,
        defaultUrls: data?.defaultUrls,
      },
    });
    return { err: false, msg: res.data };
  } catch (error) {
    return { err: true, msg: error };
  }
};

const playerStatus = async (data) => {
  try {
    const res = await axios({
      url: "website/playerStatus",
      method: "PATCH",
      data: {
        client: data.client,
      },
    });
    return { err: false, msg: res.data };
  } catch (error) {
    return { err: true, msg: error };
  }
};

const getSspData = async (graphDate) => {
  try {
    const res = await axios({
      url: "website/ssp",
      method: "POST",
      data: graphDate,
    });
    // console.log("response ssp", res);
    return { err: false, msg: res.data };
  } catch (error) {
    return { err: true, msg: error };
  }
};

const getVarianceData = async () => {
  try {
    const res = await axios({
      url: "website/variance",
      method: "GET",
    });
    return { err: false, data: res.data };
  } catch (error) {
    return { err: true, msg: error };
  }
};

const getWebsiteByPub = async (publisher) => {
  try {
    const res = await axios({
      url: "website/getByPub",
      method: "POST",
      data: {
        parent: publisher.parent,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

export {
  getWebsites,
  postWebsites,
  patchWebsites,
  deleteWebsites,
  // getAdunitData,
  updateAll,
  playerStatus,
  getSspData,
  getVarianceData,
  getWebsiteByPub,
  getWebsitesByPlayerConfig,
  getWebChangesByDate,
  patchChanges
};
