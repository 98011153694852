import { useEffect, useState, useRef } from "react";
import { Modal, Button, Table, message, Space, Popconfirm, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { updatePub } from "../../redux/reducers/PubSlice";
import { getPublishers, patchPublishers } from "../../API/Publishers";
function ArchivePub(props) {
  const { Search } = Input;
  const [messageApi, contextHolder] = message.useMessage();
  const publisherList = useSelector((state) => state.publisher.publisherList);
  const userName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const publisherChange = useSelector(
    (state) => state.publisher.publisherChange
  );
  const pubList = useRef(null);
  const getPub = async () => {
    let response = {};
    if (userType === "user") {
      response = await getPublishers();
    } else {
      response = await getPublishers(userName);
    }
    // console.log(response.data.msg);
    let filteredPub = response.data.msg.filter(
      (pub) => pub.isArchived === "true"
    );
    // console.log(filteredPub);
    setPublishers(filteredPub);
    pubList.current = filteredPub;
  };
  const fail = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };
  const successful = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };
  useEffect(() => {
    setSearchPub("");
    getPub();
  }, [props.archOpen]);

  const [publishers, setPublishers] = useState();
  const [popOpen, setPopOpen] = useState({});
  const [searchPub, setSearchPub] = useState("");
  const handleSearch = (text) => {
    setSearchPub(text);

    let searchedPubs = pubList.current.filter((pub) =>
      pub.name.toLowerCase().includes(text.toLowerCase())
    );

    setPublishers(searchedPubs);
  };

  const handleCancel = () => {
    if (props.setArchOpen) {
      props.setArchOpen((prev) => false);
    }
  };

  const handleCancelPop = (key) => {
    let popconf = {};
    popconf[key] = false;
    setPopOpen(popconf);
  };

  const dispatch = useDispatch();
  const handleUnarchive = async (record) => {
    let publisherData = { ...record, isArchived: "false" };

    let res = await patchPublishers(publisherData);
    if (res.err) {
      fail("error occured");
    } else {
      props.setLogs(record, "edited", "archive", "unarchived");
      dispatch(
        updatePub({ publisherList: publisherList, publisherChange: true })
      );
      getPub();
      successful("Publisher Unarchived");
    }
  };

  const columns = [
    {
      title: "Names",
      dataIndex: "name",
      key: "name",
      style: { fontSize: 20 },
      render: (text) => <span>{text}</span>,
      // width: 150,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure you want to UnArchive this Publisher?"
            onConfirm={() => handleUnarchive(record)}
            // open={true}
            okText="Yes"
            cancelText="No"
            // okButtonProps={{
            //   loading: confirmLoading,
            // }}
            onCancel={(e) => handleCancelPop(e)}
          >
            <Button>unarchive</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      open={props.archOpen}
      style={{ maxWidth: "100%" }}
      width={800}
      onOk={handleCancel}
      onCancel={handleCancel}
      closable={false}
    >
      {contextHolder}
      <div className="flex justify-between items-center">
        <h2 className="text-[12px] sm:text-[1.5rem]">
          Archived Publishers List
        </h2>
        <Search
          placeholder="Search for Publishers"
          allowClear
          // style={{
          //   width: 320,
          // }}

          className="sm:w-[320px] w-[100%]"
          value={searchPub}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <Table dataSource={publishers} columns={columns} scroll={{ y: 400 }} />
    </Modal>
  );
}

export default ArchivePub;
