import { Button, Form, Input, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { verifyClient } from "../../API/Clients";
import { verifyPublisher } from "../../API/Publishers";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/reducers/userSlice";
import { updatePub } from "../../redux/reducers/PubSlice";
import { forgotClient } from "../../API/Clients";
import { Skeleton } from "antd";
import axios from "axios";
import UAParser from "ua-parser-js";
const { Title } = Typography;

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [forgot, setForgot] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [cookieCheck, setCookieCheck] = useState(true);
  useEffect(() => {
    function getCookie(name) {
      const cookieArray = document.cookie.split(";");
      for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i].trim();
        // Check if this cookie has the name "access_token"
        if (cookie.startsWith(name + "=")) {
          // Extract and return the cookie value
          return cookie.substring(name.length + 1);
        }
      }
      // If the "access_token" cookie is not found, return null
      return null;
    }

    // Example usage to get the "access_token" cookie value:
    const accessToken = getCookie("access_token");

    if (accessToken !== null && accessToken !== "") {
      setCookieCheck(false);

      let type = accessToken?.split("USER")[1];
      console.log("type---", type);

      navigate("/");
    } else {
      setCookieCheck(false);
      console.log("Access Token cookie not found.");
    }
  }, []);

  const success = () => {
    messageApi.success("Email sent!");
  };
  const error = (type) => {
    if (type === "adblocker") {
      messageApi.error("Please Disable Your AdBlocker And Try Again");
    } else {
      messageApi.error(type);
    }
  };
  const publisher = useSelector((state) => state.publisher);

  // const client = useSelector((state)=>state.client.username)
  const [formData, setFormData] = useState({
    username: "",
    password: "",

  });
  const getUserInfo = async () => {
    try {
      const parser = new UAParser();
      const userAgent = window.navigator.userAgent;
      const userDevice = parser.getDevice();
      const userBrowser = parser.getBrowser();

      let userinfo = {};
      // Browser
      if (userBrowser && userBrowser.name && userBrowser.version) {
        userinfo['browserVersion'] = `${userBrowser.name} ${userBrowser.version}`;
      }
      // Device Type
      if (userDevice && userDevice.type) {
        userinfo['deviceType'] = userDevice.type === 'mobile' ? 'Mobile' : 'PC';
      } else {
        if (/Mobi|Android/i.test(userAgent)) {
          userinfo['deviceType'] = 'Mobile';
        } else {
          userinfo['deviceType'] = 'PC';
        }
      }
      const API_URL = process.env.REACT_APP_PRO_IP_API_URL;
      const ipdata = await axios.get(API_URL);
      userinfo['ip'] = ipdata.data
      return userinfo;
    } catch (error) {
      console.error("Error fetching user info:", error);
      return { error: "Unable to fetch user info" };
    }
  };
  const handleSubmit = async () => {
    let userinfo = await getUserInfo();
    let data = {
      ...formData,
      userinfo: userinfo
    }

    if (forgot) {
      let fields = form.getFieldsValue();
      if (fields.email) {
        setLoading(true);
        const res = await forgotClient(fields.email);
        setLoading(false);
        success();
      }
    } else {
      let type = "client";
      let res;
      if (type === "client") {
        res = await verifyClient(data);
      } else if (type === "publisher") {
        res = await verifyPublisher(data);
      }
      if (!res.err) {
        props.setCookieStatus(res.msg.token, type);
        navigate("/");
        window.localStorage.clear();
        dispatch(updateUser({ name: res.msg.name, type: type }));
        dispatch(updatePub({ ...publisher, publisherChange: true }));
      } else {
        console.log("Failed");
        if (res.msg.message === "Network Error") {
          error("adblocker");
        } else {
          // error();
          error(res?.msg?.response?.data?.msg);
        }
      }
    }
  };
  const handleFogot = (e) => {
    e.preventDefault();
    setForgot(true);
  };
  return !cookieCheck ? (
    <div className="h-[100vh]">
      {contextHolder}
      <nav
        className="shadow-sm flex items-center px-6"
        style={{ height: "10vh" }}
      >
        <div>
          {/* {console.log(window.location.hostname, process.env.REACT_APP_MODE)} */}
          <img
            className="w-6/7 cursor-pointer"
            src={`https://unibots.b-cdn.net/managed_vp_imgs/${process.env.REACT_APP_MODE === "dev"
                ? "unibots"
                : window.location.hostname.split(".")[1]
              }.svg`}
            alt="logo-icon"
          />
        </div>
      </nav>
      <div
        className="flex flex-col sm:flex-row sm:justify-evenly justify-start items-center"
        style={{
          height: "90vh",
        }}
        id="login-bg"
      >
        <div
          className="w-screen max-w-screen-sm sm:h-full h-1/3 sm:w-2/3"
          style={{
            background:
              "url(https://unibots.b-cdn.net/managed_vp_imgs/dashboard_login_screen.svg) center no-repeat",
            backgroundSize: "contain",
          }}
        ></div>
        {/* <div className="grow bg-slate-200"></div> */}
        <div className="flex justify-center items-center">
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 15,
            }}
            form={form}
            className="sm:w-full w-[80vw]"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              borderRadius: "10px",
              boxShadow: "5px 8px 24px 5px rgba(100, 37, 254, 0.2)",
              background: "white",
              padding: "10px",
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            onFieldsChange={() => {
              // console.log(form.getFieldsValue());
            }}
          >
            <div className="font-semibold flex justify-center items-center my-1">
              <Title level={3}> {forgot ? "Forgot Password" : "Login"} </Title>
            </div>
            {forgot ? (
              <div>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input placeholder="Enter your email" />
                </Form.Item>
              </div>
            ) : (
              <>
                <div>
                  {/* <Form.Item name="type" label="Account Type" initialValue="client">
                <Radio.Group buttonStyle="solid" optionType="button">
                  <Radio value="client">Client</Radio>
                  <Radio value="publisher">Publisher</Radio>
                </Radio.Group>
              </Form.Item> */}
                  <Form.Item
                    label="Username"
                    name="username"
                    onChange={(e) => {
                      // console.log(formData)
                      const val = e.target.value;
                      setFormData((prevValue) => {
                        return { ...prevValue, username: val };
                      });
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    onChange={(e) => {
                      // console.log(formData)
                      const val = e.target.value;
                      setFormData((prevValue) => {
                        return { ...prevValue, password: val };
                      });
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <div className="flex justify-center my-5">
                    <a href="" onClick={handleFogot}>
                      Forgot password?
                    </a>
                  </div>
                </div>
              </>
            )}
            <div>
              <Form.Item className="flex justify-center w-full">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                // onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-[100vh] w-[75%] ml-auto">
      <Skeleton />
    </div>
  );
};
export default Login;
