import React, { useState } from "react";

import { Form, Switch, Input, Button, Modal, Tooltip } from "antd";
const GamAdUnit = ({
  setGamData,
  gamData,
  setGamDataSubmit,
  placementSwitch,
  setPlacementSwitch,
  currWebsite,
  fail,
}) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (values) => {
    const hasMainAdUnit = placementSwitch
      ? form.getFieldValue("p_main") && form.getFieldValue("P_idMain")
      : form.getFieldValue("Ad_Main") && form.getFieldValue("Ad_idMain");
    const hasNewMainAdUnit = placementSwitch
      ? form.getFieldValue("P_NMain") && form.getFieldValue("P_idNewMain")
      : form.getFieldValue("Ad_NewMain") && form.getFieldValue("Ad_IdNewMain");

    if (!hasMainAdUnit && !hasNewMainAdUnit) {
      return fail("Either Main Value or New Main Value is required.");
    } else {
      setGamData({
        adNameM: placementSwitch ? values.p_main : values.Ad_Main,
        adIdM: placementSwitch ? values.P_idMain : values.Ad_idMain,

        adNameNM: placementSwitch ? values.P_NMain : values.Ad_NewMain,
        adIdNM: placementSwitch ? values.P_idNewMain : values.Ad_IdNewMain,

        client: values.client,
        url: values.url,
      });
      setGamDataSubmit(true);
      handleCancel();
    }
    form.resetFields(); // Reset form fields after submission
  };

  let isGamButtonDisabled =
    currWebsite?.config[0]?.playerConfig?.AUTO_GA_ENABLED === false ||
    currWebsite?.config[0]?.playerConfig?.GA_CODE.length > 0;

  return (
    <div>
      <Tooltip
        placement="bottom"
        title={
          isGamButtonDisabled
            ? "GAM Data has been already added or Auto GA is disabled for this player"
            : ""
        }
      >
        <Button
          className="sm:mt-0 mt-2"
          onClick={showModal}
          disabled={isGamButtonDisabled}
        >
          Add GAM Data
        </Button>
      </Tooltip>
      <Modal
        width={600}
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        title={
          <div>
            <span>
              {placementSwitch ? "Add Placement Data" : "Add AdUnit Data"}
            </span>
            <Switch
              style={{ marginLeft: "10px" }}
              checked={placementSwitch}
              onChange={() => setPlacementSwitch(!placementSwitch)}
            />
          </div>
        }
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            Save
          </Button>,
        ]}
      >
        <div>
          <Form
            form={form}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 11 }}
            layout="horizontal"
            onFinish={handleSubmit}
          >
            <Form.Item label="Client" name="client" initialValue="unibots">
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Website URL"
              name="url"
              initialValue={currWebsite?.url}
              hasFeedback
              validateFirst
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name={placementSwitch ? "p_main" : "Ad_Main"}
              label={
                placementSwitch ? "Placement Name Main" : "Ad Unit Name Main"
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={placementSwitch ? "Placement ID Main" : "Ad Unit ID Main"}
              name={placementSwitch ? "P_idMain" : "Ad_idMain"}
              hasFeedback
              validateFirst
              rules={[
                { required: false },
                {
                  validator: async (_, value) => {
                    if (value && isNaN(value)) {
                      throw new Error("Value should be a number");
                    }
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                placementSwitch
                  ? "Placement Name NewMain"
                  : "AdUnit Name NewMain"
              }
              name={placementSwitch ? "P_NMain" : "Ad_NewMain"}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                placementSwitch ? "Placement ID NewMain" : "Ad Unit ID NewMain"
              }
              name={placementSwitch ? "P_idNewMain" : "Ad_IdNewMain"}
              hasFeedback
              validateFirst
              rules={[
                { required: false },
                {
                  validator: async (_, value) => {
                    if (value && isNaN(value)) {
                      throw new Error("Value should be a number");
                    }
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default GamAdUnit;
