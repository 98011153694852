import { createAvatar } from "@dicebear/core";
import { thumbs } from "@dicebear/collection";
import React, { useState } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { Popover, Button } from "antd";
const BotAvatar = (props) => {
  const [open, setOpen] = useState(false);
  const avatar = createAvatar(thumbs, {
    seed: props.userName,
    backgroundColor: ["b6e3f4", "c0aede", "d1d4f9"],
    size: 32,
    radius: 50,
    scale: 80,
    backgroundType: ["solid"],
    backgroundColor: ["b6e3f4"],
    baseColor: [
      "00acc1",
      "1e88e5",
      "5e35b1",
      "6d4c41",
      "7cb342",
      "8e24aa",
      "039be5",
      "43a047",
      "546e7a",
      "00897b",
      "3949ab",
      "757575",
      "c0ca33",
      "d81b60",
      "e53935",
      "f4511e",
      "fb8c00",
      "fdd835",
      "ffb300",
    ],
  });
  const dataUri = avatar.toDataUriSync();
  const content = (
    <div>
      <Button
        danger
        type="text"
        icon={<LogoutOutlined />}
        size="small"
        onClick={props.handleLogout}
      >
        Logout
      </Button>
    </div>
  );
  return (
    <div
      className="w-full flex"
      onClick={() => {
        setOpen((prev) => !prev);
      }}
    >
     
        <img
          className="w-full h-full rounded cursor-pointer"
          src={dataUri}
          alt="avatar"
        />

    </div>
  );
};

export default BotAvatar;
