import React, { useEffect, useState } from "react";
import { theme, Form, Input, InputNumber, Select, Modal } from "antd";
import { postPublishers, patchPublishers, patchPubChanges } from "../../API/Publishers";
import { useSelector, useDispatch } from "react-redux";
import { updatePub } from "../../redux/reducers/PubSlice";
import { updateUser } from "../../redux/reducers/userSlice";
import { getClient } from "../../API/Clients";
import { observableDiff } from "deep-diff";
import { getDateTime } from "../Utility/utils";
import { patchUser } from "../../API/User";

const Create = (props) => {
  const dispatch = useDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const websiteList = useSelector((state) => state.website.websiteList);
  const publisherList = useSelector((state) => state.publisher.publisherList);
  const userData = useSelector((state) => state.user);
  const user = useSelector((state) => state.user.name);
  const userAccess = useSelector((state) => state.user.access);
  const [revSwitch, setRevSwitch] = useState(true);
  const [dealType, setDealType] = useState("");
  const [dealInput, setDealInput] = useState(null);
  const [dealModify, setDealModify] = useState(); // to keep track of revShare/ fixedCPM for modify pub
  const [filteredList, setFilteredList] = useState([]);
  const [clients, setClients] = useState([]);

  const formItems = [
    [
      {
        label: "Publisher Name",
        name: "name",
        placeholder: "Enter publisher name",
        rules: [
          {
            required: true,
            message: "Publisher name is required!",
          },
        ],
        onInput: (e) => (e.target.value = e.target.value.toLowerCase().trim()),
      },
      // {
      //   label: "Email",
      //   name: "email",
      //   placeholder: "Enter email",
      //   rules: [
      //     {
      //       type: "email",
      //       required: true,
      //    d   message: "The input is not valid E-mail!",
      //     },
      //   ],
      // },
      {
        label: props.edit
          ? props.data.isFixed === "true"
            ? "Fixed Deal"
            : "Revenue Share"
          : "Select Deal Type",
        name: "revShare",
        placeholder: revSwitch ? "Publisher Share" : "Fixed CPM",
        rules: [
          {
            required: true,
            message: revSwitch
              ? "Please enter Revenue share!"
              : "Please enter Fixed CPM!",
          },
          {
            validator: (_, value) => {
              if (isNaN(value)) {
                return Promise.reject(
                  new Error("Please enter a valid number.")
                );
              }
              return Promise.resolve();
            },
          },
        ],
      },
    ],
    [
      {
        label: "Publisher Login",
        name: "username",
        placeholder: "Enter username",
        rules: [
          {
            required: true,
            message: "Username Required",
          },
        ],
        onInput: (e) => (e.target.value = e.target.value.toLowerCase().trim()),
      },
      {
        label: "Publisher Password",
        name: "password",
        placeholder: "Enter password",
        rules: [
          {
            required: !props.edit,
            message: "Please input your password!",
          },
          {
            min: 12,
            message: "Password must be at least 12 characters long",
          },
          {
            pattern:
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/,
            message:
              "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (#?!@$%^&*-)",
          },
        ],
      },
      {
        label: "Confirm Password",
        placeholder: "Confirm password",
        name: "confpass",
        dependencies: ["password"],
        rules: [
          {
            required: !props.edit,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ],
      },
    ],
  ];
  useEffect(() => {
    if (props.edit) {
      if (props.data.isFixed === "true") {
        setRevSwitch(false);
        setDealModify(props.data.fixedCPM);
      } else {
        setDealModify(props.data.revShare);
      }

      if (props.data.revShare) {
        setDealType("revShare");
      } else if (props.data.isFixed) {
        setDealType("fixedDeal");
      }
      form.setFieldsValue({
        ...props.data,
        password: "",
        client: props.data?.parent,
      });
      let filteredWebsites = websiteList.filter((website) => {
        return website.parent === props.data.name;
      });
      let urls = filteredWebsites.map((websites) => websites.url);
      setWebsites(urls);
    }
  }, [props.edit, props.data]);

  useEffect(() => {
    const fetchClientList = async () => {
      const result = await getClient();
      if (userData.role === "admin") {
        setClients(result.data.msg);
      } else if (userData.role === "adops") {
        const clientList = result.data.msg.map((obj) => obj.name);
        const filteredClients = clientList.filter((item) =>
          userAccess.client.includes(item)
        );

        setClients(filteredClients);
      }
    };

    fetchClientList();
  }, [userData, userAccess]);

  const [websites, setWebsites] = useState([""]);
  const [columns, setColumns] = useState([
    { value: "pv", label: "Page Views" },
    { value: "imp", label: "Impressions" },
    { value: "rev", label: "Net Revenue" },
    { value: "grev", label: "Gross Revenue" },
    { value: "ecpm", label: "Average ECPM" },
    { value: "gecpm", label: "Gross ECPM" },
    { value: "fill_rate", label: "Fill Rate" },
    { value: "eff_fill_rate", label: "Eff Fill Rate" },
    { value: "adreq", label: "Ad Requests" },
    { value: "rpm", label: "RPM" },
  ]);
  const initCols = [
    "pv",
    "imp",
    "rev",
    "ecpm",
    "fill_rate",
    "eff_fill_rate",
    "adreq",
    "rpm",
  ];
  const handleSubmit = (values) => {
    setConfirmLoading(true);
    handleOk();
  };
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleCancel = () => {
    if (props.setOpen) {
      props.setOpen((prev) => false);
    }
  };
  const handleClose = () => {
    props.setModalOpen(false);
  };

  const getChange = (d) => {
    let { date, time } = getDateTime();
    let name = d.path.join(".");

    if (d.kind === "E") {
      return {
        user,
        name,
        date,
        time,
        lhs: d.lhs,
        rhs: d.rhs,
        kind: "edited",
      };
    } else if ((d.kind === "A" && d.item.kind === "N") || d.kind === "N") {
      return {
        user,
        name,
        date,
        time,
        rhs: d?.item?.rhs ?? d.rhs,
        kind: "added",
      };
    } else if ((d.kind === "A" && d.item.kind === "D") || d.kind === "D") {
      return {
        user,
        name,
        date,
        time,
        lhs: d?.item?.lhs ?? d.lhs,
        kind: "deleted",
      };
    }
    return d;
  };

  const handleOk = async () => {
    let res = "";
    let publisherData = form.getFieldsValue();
    if (!revSwitch) {
      publisherData = { ...publisherData, isFixed: true };
    } else {
      publisherData = {
        ...publisherData,
        isFixed: false,
        revShare: Math.abs(publisherData.revShare),
      };
    }
    if (props.edit) {
      let changes = [];
      observableDiff(
        { config: props.data.config, username: props.data.username },
        { config: publisherData.config, username: publisherData.username },
        (d) => {
          changes.push(getChange(d));
        },
        (path, key) => key === "VIDEO_SRC_DEFAULT"
      );
      if (publisherData.password.length > 0) {
        let { date, time } = getDateTime();
        changes.push({ user, name: "passowrd", date, time, kind: "edited" });
      }
      // publisherData["changes"] = [...props.data.changes, ...changes];
      res = await patchPublishers(publisherData);
      // update publisher changes 
      const pubData = {
        name : props.data.name,
        changes: changes
      }
      let response = await patchPubChanges(pubData);
      if(response.err) {
        props.fail("Modify Failed - " + res.msg.message);
      }
      if (res.err) {
        if (res.msg.message === "Network Error") {
          props.fail("Modify Failed - " + res.msg.message);
          setTimeout(() => {
            setConfirmLoading(false);
            if (!props.edit) {
              props.setPubName(publisherData.name);
            }
          }, 2000);
        } else {
          props.fail("Modify Failed - " + res.msg.response.data.msg);
          setTimeout(() => {
            setConfirmLoading(false);
            if (!props.edit) {
              props.setPubName(publisherData.name);
            }
          }, 2000);
        }
      } else {
        dispatch(
          updatePub({ publisherList: publisherList, publisherChange: true })
        );
        setTimeout(() => {
          props.successful("Modified Sucessfully");
          props.afterModify();
          if (props.setOpen) {
            props.setOpen(false);
          }
          setConfirmLoading(false);

          if (!props.edit) {
            props.setPubName(publisherData.name);
          }
          props.handleSearch("");
        }, 2000);
      }
      if (!props.edit) {
        props.setPubName(publisherData.name);
      }
    } else {
      res = await postPublishers({
        ...publisherData,
        parent:
          userData.role === "admin" || userData.role === "adops"
            ? form.getFieldValue("client")
            : userData.type === "user"
            ? userData.access.client
            : user,
      });

      if (res.err) {
        if (res.msg.message === "Network Error") {
          props.fail(res.msg.message);
          setTimeout(() => {
            setConfirmLoading(false);
          }, 2000);
        } else {
          props.fail(res.msg.response.data.msg);
          setTimeout(() => {
            setConfirmLoading(false);
          }, 2000);
        }
      } else {
        if (userData.type === "user") {
          const result = await patchUser({
            ...userData,
            publishers: [...userData.publishers, publisherData.name],
            userrole: "user",
          });
          dispatch(
            updateUser({
              ...userData,
              publishers: [...userData.publishers, publisherData.name],
            })
          );
        }
        props.setLogs(publisherData, "added");
        dispatch(
          updatePub({ publisherList: publisherList, publisherChange: true })
        );
        setTimeout(() => {
          props.setSuccess(true);
          setConfirmLoading(false);
          props.setModalOpen(false);
        }, 2000);
      }
      if (!props.edit) {
        props.setPubName(publisherData.name);
      }
    }
  };

  useEffect(() => {
    switch (dealType) {
      case "revShare":
      case "revSameForAll":
        setDealInput(() => {
          return (
            <Form.Item
              label="Publisher Share"
              name="revShare"
              style={{
                display: "inline-block",
                marginLeft: "20px",
                width: "60%",
              }}
            >
              <Input placeholder="Enter share" />
            </Form.Item>
          );
        });
        break;
      case "fixedDeal":
        setDealInput(() => {
          return (
            <Form.Item
              label="Fixed Deal"
              name="fixedCPM"
              style={{
                display: "inline-block",
                marginLeft: "20px",
                width: "60%",
              }}
            >
              <Input placeholder="Enter deal" />
            </Form.Item>
          );
        });
        break;
      default:
        setDealInput(null);
    }
  }, [dealType]);

  return (
    <Modal
      title={props.edit ? "Edit Publisher" : "Add Publisher"}
      open={props.open}
      confirmLoading={confirmLoading}
      style={{ maxWidth: "100%" }}
      width={800}
      // style={{ minWidth: 800, left: 100 }}
      bodyStyle={{
        height: props.edit ? 390 : 420,
        overflowY: "auto",
        paddingTop: "20px",
      }}
      onOk={() => {
        form.submit();
      }}
      onCancel={handleCancel}
      afterClose={handleClose}
      centered={true}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        form={form}
        onFinish={handleSubmit}
      >
        {formItems[0].map((item) => {
          return (
            <Form.Item name={item.name} label={item.label} rules={item.rules}>
              {props.edit && item.name === "name" ? (
                <Input disabled={true} />
              ) : item.name === "revShare" ? (
                <div className="flex space-x-4 items-center">
                  {!props.edit && (
                    // <Switch
                    //   onChange={() => {
                    //     setRevSwitch(!revSwitch);
                    //   }}
                    // ></Switch>
                    <Select
                      placeholder="Change Deal Type"
                      onChange={(val) => {
                        setRevSwitch(val);
                      }}
                      defaultValue={true}
                      options={[
                        { label: "Publisher Share", value: true },
                        { label: "Fixed Deal", value: false },
                      ]}
                    >
                      {/* <Select.Option
                        label="Rev Share"
                        value="Rev Share"
                      ></Select.Option>
                      <Select.Option
                        label="Fixed Deal"
                        value="Fixed Deal"
                      ></Select.Option> */}
                    </Select>
                  )}
                  {/* <Input
                    style={{ width: "200px" }}
                    placeholder={item.placeholder}
                    disabled={props.edit}
                    value={dealModify}
                  /> */}
                  <InputNumber
                    style={{ width: "200px" }}
                    placeholder={item.placeholder}
                    disabled={props.edit}
                    value={dealModify}
                    min={0} // This restricts negative values
                    step={0.01} // This allows float values
                  />
                </div>
              ) : (
                <Input
                  placeholder={item.placeholder}
                  onInput={item.onInput ?? (() => {})}
                />
              )}
            </Form.Item>
          );
        })}
        {(userData.role === "adops" || userData.role === "admin") && (
          <Form.Item
            name="client"
            label="Select client"
            rules={[
              {
                required: true,
                message: "Client name is required!",
              },
            ]}
          >
            <Select showSearch>
              {clients.map((client) => {
                return userData.role === "adops" ? (
                  <Select.Option value={client}>{client}</Select.Option>
                ) : (
                  <Select.Option value={client.name}>
                    {client.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name={["config", "columns"]}
          label="Viewable Data"
          initialValue={initCols}
        >
          <Select mode="multiple">
            {columns.map((col) => {
              return (
                <Select.Option value={col.value}>{col.label}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {formItems[1].map((item) => {
          return (
            <Form.Item
              name={item.name}
              label={item.label}
              rules={item?.rules}
              dependencies={item?.dependencies}
            >
              {item.name === "password" ? (
                <Input.Password />
              ) : (
                <Input
                  placeholder={item.placeholder}
                  onInput={item.onInput ?? (() => {})}
                />
              )}
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
};

export default Create;
