import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Input,
  Card,
  Radio,
  InputNumber,
  Switch,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Bidders from "./Bidders";

const ManageBidder = (props) => {
  const field = props.field;
  const form = props.form;
  const paramsType = {
    placement_id: { all: "number", nextMillennium: "string" },
    zoneId: { all: "number" },
    aid: { all: "number" },
    accountId: { all: "number" },
    networkId: { all: "number" },
    siteId: { all: "number", "33across": "string" },
    pageId: { all: "number" },
    formatId: { all: "number" },
    bidFloor: { all: "number" },
    mid: { all: "number" },
  };
  const [bidderName, setBidderName] = useState(null);
  const [params, setParams] = useState(null);
  const [paramsForm, setParamsForm] = useState(null);
  const [type, setType] = useState(props.type);
  const options = [
    {
      label: "Net",
      value: "net",
    },
    { label: "Gross", value: "gross" },
  ];
  const BiddersList = props.biddersList;
  useEffect(() => {
    let fields = form.getFieldsValue();
    if (
      Object.keys(fields).length > 0 &&
      fields["Bidders"].length > 0 &&
      field.name !== undefined
    ) {
      handleChange(fields["Bidders"][field.name]["BIDDER"]);
    }
  }, [field]);

  const handleChange = (value) => {
    setBidderName(value);
  };
  useEffect(() => {
    if (bidderName) {
      let bidder = BiddersList.filter((bidder) => {
        return bidder.name === bidderName;
      });
      bidder = bidder[0];
      setParams((prev) => {
        return {
          params: bidder.params,
          optparams: bidder.optparams,
        };
      });
    } else {
      setParams(null);
    }
  }, [bidderName]);
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  useEffect(() => {
    if (params) {
      let reqParams = params.params.map((param, i) => {
        const data = param.split("|");
        let name = data[0];
        let type;
        if (!paramsType[name]) {
          type = "string";
        } else if (bidderName in paramsType[name]) {
          type = paramsType[name][bidderName];
        } else {
          type = paramsType[name]["all"];
        }
        let value = type === "number" ? parseInt(data[1]) ?? 0 : data[1] ?? "";
        let inputForm;

        if (type === "number") {
          inputForm = <InputNumber style={{ width: "100%" }} />;
        } else {
          inputForm = <Input style={{ width: "100%" }} />;
        }
        return (
          <Form.Item
            name={[field.name, "PARAMS", name]}
            key={field.name + name}
            label={name}
            initialValue={value}
            rules={[
              {
                required: true,
                message: `${name} is required!`,
              },
            ]}
            style={{
              minWidth: "30%",
            }}
          >
            {inputForm}
          </Form.Item>
        );
      });
      let optparams = params.optparams.map((param, i) => {
        const data = param.split("|");
        let name = data[0];
        let type;
        if (!paramsType[name]) {
          type = "string";
        } else if (bidderName in paramsType[name]) {
          type = paramsType[name][bidderName];
        } else {
          type = paramsType[name]["all"];
        }
        let value = type === "number" ? parseInt(data[1]) ?? 0 : data[1] ?? "";
        let inputForm;

        if (type === "number") {
          inputForm = <InputNumber style={{ width: "100%" }} />;
        } else {
          inputForm = <Input style={{ width: "100%" }} />;
        }
        return (
          <Form.Item
            name={[field.name, "PARAMS", name]}
            key={field.name + name}
            label={name}
            initialValue={value}
            style={{
              minWidth: "30%",
            }}
          >
            {inputForm}
          </Form.Item>
        );
      });
      let allParams = (
        <Card title="Parameters" bodyStyle={{ padding: "10px" }} size="small">
          <div className="flex flex-wrap gap-x-3">
            <>{reqParams}</>
            <>{optparams}</>
          </div>
        </Card>
      );
      setParamsForm(allParams);
    } else {
      setParamsForm(<></>);
    }
  }, [params]);
  return (
    <>
      <div className="flex flex-wrap gap-5 w-6/7">
        <Form.Item
          name={[field.name, "BIDDER"]}
          label="Bidders"
          style={{ width: "40%" }}
        >
          <Select
            placeholder="Select Bidder"
            onChange={handleChange}
            showSearch
          >
            {BiddersList.map((bidder) => {
              return (
                <Select.Option value={bidder.name}>
                  {bidder?.label ?? bidder.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name={[field.name, "FLOOR"]}
          label="Floor"
          style={{ width: "auto" }}
          initialValue={0}
        >
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        </Form.Item>
        <Form.Item
          label="Enable Bidder"
          name={[field.name, "IS_ACTIVE"]}
          style={{ width: "auto" }}
          valuePropName="checked"
          initialValue={true}
        >
          <Switch style={{ width: "30%" }} />
        </Form.Item>
      </div>
      <Form.Item
        name={[field.name, "TYPE"]}
        initialValue={options[0].value}
        style={{ display: "inline-block" }}
      >
        <Radio.Group options={options} onChange={handleTypeChange} />
      </Form.Item>

      {form.getFieldsValue(["Bidders"])["Bidders"][field.name]?.TYPE ===
      "gross" ? (
        <Form.Item
          name={[field.name, "GROSS_PERCENTAGE"]}
          initialValue={0}
          style={{ display: "inline-block" }}
          label={
            <div className="flex gap-2">
              Gross percentage
              <Tooltip
                placement="top"
                title={
                  "This is the percentage that publisher receives from the partner"
                }
              >
                <InfoCircleOutlined />{" "}
              </Tooltip>
            </div>
          }
        >
          <InputNumber
            defaultValue={0}
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace("%", "")}
          />
        </Form.Item>
      ) : (
        <> </>
      )}

      {paramsForm}
      <Card
        title="Supply Chain Config"
        bodyStyle={{ padding: "10px" }}
        style={{ marginTop: "10px" }}
        size="small"
      >
        <div className="flex gap-5">
          <Form.Item
            name={[field.name, "supply_chain_config", "asi"]}
            label="Advertising System Identifier"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={[field.name, "supply_chain_config", "sid"]}
            label="Seller Id"
          >
            <Input />
          </Form.Item>
        </div>
      </Card>
    </>
  );
};

export default ManageBidder;
