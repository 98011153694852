import axios from "../axiosInstance";

const postGA = async (gaDataName, gaDataUrl) => {
  try {
    const res = await axios({
      url: "ga/generate/",
      method: "POST",
      data: {
        name: gaDataName,
        url: gaDataUrl,
      },
    });
    return {
      err: res.data.err,
      msg: res.data.msg,
      id: res.data.id,
      propertyid: res.data.propertyid,
    };
  } catch (err) {
    return { err: true, msg: err };
  }
};


const pvData = async (data) => {
  try {
    const res = await axios({
      url: "ga/pvdata/",
      method: "POST",
      data: {
        client: data.client,
        gacode: data.gacode,
        GA_PROPERTY_ID: data.GA_PROPERTY_ID
      }
    });
    return {
      err: res.data.err,
      msg: res.data.msg,}

  } catch (err) {
    return { err: true, msg: err };
  }
  
      
 
}
export { postGA , pvData};
