import React, { useEffect, useRef, useState } from "react";
import { Radio, Button, DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import LoaderComp from "../Utility/LoaderComp";
import { revDataClient } from "../../API/Clients";
import io from "socket.io-client";
import TimezoneDropdown from "./TimezoneDropdown";
import moment from "moment-timezone";
import { Alert, Space } from "antd";
import { getWebsites, getSspData, getVarianceData } from "../../API/Websites";
import { getAdunitData } from "../../API/Adunits";
import { updateData } from "../../redux/reducers/dataSlice";
import { template } from "handlebars/runtime";

// let socket = "";
dayjs.extend(dayjsPluginUTC);

function transformBidderData(data) {
  const transformedData = [];

  for (const entry of data) {
    if (entry.name === "google" && entry.children) {
      for (const child of entry.children) {
        const childCopy = { ...child };
        childCopy.name = entry.name + "-" + child.name;
        transformedData.push(childCopy);
      }
    } else {
      transformedData.push(entry);
    }
  }

  return transformedData;
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function convertTimezone(dateString, timezone) {
  // Create a Date object from the input date string in UTC timezone
  const date = new Date(dateString);

  // Get the timezone offset in minutes from UTC for the input timezone
  const timezoneOffset = moment.tz(date, timezone).utcOffset();

  // Convert the offset to milliseconds and subtract it from the input date to get the new date in the input timezone
  const newDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);

  // Format the new date string in ISO format with UTC timezone
  const newDateString = newDate.toISOString().replace("Z", "+00:00");

  return newDateString;
}

function addTimezone(dateString, timezone) {
  // Create a Date object from the input date string in UTC timezone
  const date = new Date(dateString);

  // Get the timezone offset in minutes from UTC for the input timezone
  const timezoneOffset = moment.tz(date, timezone).utcOffset();

  // Convert the offset to milliseconds and subtract it from the input date to get the new date in the input timezone
  const newDate = new Date(date.getTime() + timezoneOffset * 60 * 1000);

  // Format the new date string in ISO format with UTC timezone
  const newDateString = newDate.toISOString().replace("Z", "+00:00");

  return newDateString;
}

let cnt = 100000;

const GraphOptions = (props) => {
  const clientName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const userRole = useSelector((state) => state.user.role);
  const archivedData = useSelector((state) => state.data);

  const websiteList = useSelector((state) => state.website.websiteList);
  const [filterWebsites, setFilterWebsites] = useState([]);
  const [currWebsite, setCurrWebsite] = useState(null);

  const dispatch = useDispatch();
  const info = useSelector((state) => state.user);
  // const publisherList = useSelector((state) => state.publisher.publisherList);
  const [filterPub, setFilterPub] = useState(null);
  const { RangePicker } = DatePicker;
  const [radioValue, setRadioValue] = useState(6);
  const [searchDate, setSearchDate] = useState(0);
  const [spin, setSpin] = useState(false);
  const [sitemap, setSitemap] = useState({});
  // const [rawData, setRawData] = useState([]);
  const [publisherDrop, setPubisherDrop] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("Asia/Kolkata");
  const [flag, setFlag] = useState(true);
  const [oldInterval, setOldInterval] = useState(null);
  const intervalRef = useRef(null);
  const [gamTableData, setGamTableData] = useState([]);
  const [variance, setVariance] = useState({});
  const [graphData, setGraphData] = useState({
    impG: [],
    revG: [],
    ecpmG: [],
    fillG: [],
    efillG: [],
    rpmG: [],
    adG: [],
    pvG: [],
  });
  const [totalData, setTotalData] = useState({
    imp: 0,
    rev: 0,
    adreq: 0,
    pv: 0,
    ecpm: 0,
  });
  const [changeFlag, setChangeFlag] = useState(false);
  const [archiveTemp, setArchiveTemp] = useState([]);
  const [parentGamData, SetparentGamData] = useState([]);

  const archiveSocketData = (data) => {
    dispatch(updateData({ socketData: data }));
  };

  // const [intervalSt, setIntervalSt] = useState('')

  const [graphFilter, setGraphFilter] = useState({
    startDate: "",
    endDate: "",
    client: "",
  });
  const options = [
    { label: "1D", value: 0 },
    { label: "7D", value: 6 },
    { label: "1M", value: 29 },
    { label: "1Y", value: 359 },
  ];
  const getLastDate = (days) => {
    let date = new Date();
    let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
    last.setUTCHours(0, 0, 0);
    setGraphFilter((prev) => {
      return {
        ...prev,
        startDate: last.toISOString(),
        endDate: date.toISOString(),
      };
    });
    setChangeFlag(false);
    setSearchDate(searchDate + 1);
    // setSearchDate(searchDate + 1);
  };

  function filterDataByTodayDate(data) {
    const today = new Date();
    const todayTimestamp = today.toISOString().split("T")[0];

    return data.filter((obj) => {
      const adjustedTimestamp = addTimezone(obj.createdAt, selectedTimezone);
      const objTimestamp = adjustedTimestamp.split("T")[0];

      // const start = convertTimezone(graphFilter["startDate"], selectedTimezone);
      // console.log("GraphFilter start, end: ", start, graphFilter["endDate"]);

      //   const objTimestamp = obj.createdAt.split("T")[0];

      return objTimestamp !== todayTimestamp;
    });
  }

  function filterDataByGraphDate(data) {
    // const today = new Date();
    // const todayTimestamp = today.toISOString().split("T")[0];

    return data.filter((obj) => {
      const adjustedTimestamp = addTimezone(obj.createdAt, selectedTimezone);
      const objTimestamp = adjustedTimestamp.split("T")[0];

      const start = convertTimezone(graphFilter["startDate"], selectedTimezone);
      // console.log("GraphFilter start, end: ", start, graphFilter["endDate"]);

      // const objTimestamp = obj.createdAt.split("T")[0];

      return (
        // objTimestamp !== todayTimestamp &&
        objTimestamp >= start.split("T")[0] &&
        objTimestamp <= graphFilter["endDate"].split("T")[0]
      );
    });
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}-${month}`;
  }

  const round = (value, decimals) => {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  };

  const handleChange = (e) => {
    setRadioValue(e.target.value);
    getLastDate(e.target.value);
  };

  function compareDates(a, b) {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }
  const getDataArr = (data, site, siteMap) => {
    // console.log(props.webRevMap);
    let diff = {
      imp: 0,
      adreq: 0,
      pv: 0,
      rev: 0,
    };
    // if (variance.hasOwnProperty(site)) {
    //   diff = variance[site];
    // }

    let arr = [];
    // let cnt = 0;
    data.forEach((obj) => {
      if (filterPub) {
        if (sitemap[obj.website] !== filterPub) {
          return;
        }
      }

      const dt = obj.createdAt.split("T")[0];
      const found = arr.find((o) => o.date === dt);

      if (!found) {
        const temp = { pv: 0 };
        // temp["key"] = getRandomInt(0, Number.MAX_SAFE_INTEGER);
        temp["key"] = ++cnt;
        temp["date"] = dt;
        if (
          !(
            (clientName === "unibots" || userType === "user") &&
            userRole !== "admin"
          )
        )
          // temp["pv"] = parseInt((obj.player_load * (100 - diff?.pv)) / 100);
          temp["pv"] = parseInt(obj.player_load)
        let imp = obj.imp_tags;
        let count = 0,
          cpm = 0,
          pcpm = 0,
          ccpm = 0;
        for (const key in imp) {
          // const impD = key.includes("Unibot") ? diff.imp : 0;
          const impD =  0;
          if (
            (clientName === "unibots" || userType === "user") &&
            userRole !== "admin" &&
            filterPub === null
          )
            continue;
          if (imp[key].count)
            count += parseInt((imp[key].count * (100 - impD)) / 100);
          cpm += Number(imp[key].cpm) * Number(imp[key].count);
          pcpm +=
            Number(imp[key].cpm) *
            Number(imp[key].count) *
            (Number(props.webRevMap[obj.website]) / 100);
          ccpm = cpm - pcpm;
        }
        temp["total_imp"] = count;
        // console.log(temp["total_imp"]);
        // temp["avg_ecpm"] = round(cpm / count, 2);
        if (count !== 0) temp["avg_ecpm"] = cpm / count;
        else temp["avg_ecpm"] = 0;

        let adreq = obj.ad_request_tags;
        let adreqCnt = 0;
        for (const key in adreq) {
          // const adreqD = key.includes("Unibot") ? diff.adreq : 0;
          const adreqD =  0;
          if (
            key.startsWith("google") &&
            (clientName === "unibots" || userType === "user") &&
            userRole !== "admin" &&
            filterPub === null
          )
            continue;
          adreqCnt += parseInt((adreq[key] * (100 - adreqD)) / 100);
        }
        temp["adreq"] = Number(adreqCnt);
        temp["fill_rate"] = round(temp["total_imp"] / temp["adreq"], 4);
        // temp["total_rev"] = round(
        //   (temp["avg_ecpm"] * temp["total_imp"]) / 1000,
        //   2
        // );
        // console.log(obj.website, props.webRevMap[obj.website]);
        temp["total_rev"] = (temp["avg_ecpm"] * temp["total_imp"]) / 1000;
        if (props.webRevMap[obj.website][0] === "f") {
          let fCPM = props.webRevMap[obj.website].toString().split("f")[1];
          if (fCPM.toString()[0] === "$") fCPM = fCPM.toString().split("$")[1];
          temp["pub_rev"] = (Number(fCPM) * temp["total_imp"]) / 1000;
        } else
          temp["pub_rev"] =
            ((Number(props.webRevMap[obj.website].split(":")[0]) / 100) *
              (temp["avg_ecpm"] * temp["total_imp"])) /
            1000;

        temp["client_rev"] = temp["total_rev"] - temp["pub_rev"];
        temp["rpm"] = round((temp["total_rev"] * 1000) / temp["pv"], 2);
        temp["eff_fill_rate"] = round(temp["total_imp"] / temp["pv"], 4);
        if (site) {
          temp["website"] = site;
          temp["name"] = siteMap[site];
        }
        arr.push(temp);
      } else {
        if (
          !(
            (clientName === "unibots" || userType === "user") &&
            userRole !== "admin"
          )
        )
          // found["pv"] += parseInt((obj.player_load * (100 - diff?.pv)) / 100);
          found["pv"] += parseInt(obj.player_load)
          let imp = obj.imp_tags;
        let count = 0,
          cpm = 0;
        for (const key in imp) {
          // const impD = key.includes("Unibot") ? diff.imp : 0;
          const impD = 0;
          if (
            key.startsWith("google") &&
            (clientName === "unibots" || userType === "user") &&
            userRole !== "admin" &&
            filterPub === null
          )
            continue;
          if (imp[key].count)
            count += parseInt((imp[key].count * (100 - impD)) / 100);
          cpm += Number(imp[key].cpm) * Number(imp[key].count);
        }
        const oldCnt = found["total_imp"];
        const oldEcpm = found["avg_ecpm"] * oldCnt;
        found["total_imp"] += count;
        // console.log(found["total_imp"]);
        // found["avg_ecpm"] = round((oldEcpm + cpm) / (oldCnt + count), 2);
        if (oldCnt + count !== 0)
          found["avg_ecpm"] = (oldEcpm + cpm) / (oldCnt + count);
        let adreq = obj.ad_request_tags;
        let adreqCnt = 0;
        for (const key in adreq) {
          // const adreqD = key.includes("Unibot") ? diff.adreq : 0;
          const adreqD = 0;
          if (
            key.startsWith("google") &&
            (clientName === "unibots" || userType === "user") &&
            userRole !== "admin" &&
            filterPub === null
          )
            continue;
          adreqCnt += parseInt((adreq[key] * (100 - adreqD)) / 100);
        }
        found["adreq"] += adreqCnt;
        found["fill_rate"] = round(found["total_imp"] / found["adreq"], 4);
        // found["total_rev"] = round(
        //   (found["avg_ecpm"] * found["total_imp"]) / 1000,
        //   2
        // );
        found["total_rev"] = (found["avg_ecpm"] * found["total_imp"]) / 1000;
        if (props.webRevMap[obj.website][0] === "f") {
          let fCPM = props.webRevMap[obj.website].toString().split("f")[1];
          if (fCPM.toString()[0] === "$") fCPM = fCPM.toString().split("$")[1];
          found["pub_rev"] = (Number(fCPM) * found["total_imp"]) / 1000;
        } else
          found["pub_rev"] =
            ((Number(props.webRevMap[obj.website].split(":")[0]) / 100) *
              (found["avg_ecpm"] * found["total_imp"])) /
            1000;
        // found["pub_rev"] =
        //   ((Number(props.webRevMap[obj.website].split(":")[0]) / 100) *
        //     (found["avg_ecpm"] * found["total_imp"])) /
        //   1000;
        found["client_rev"] = found["total_rev"] - found["pub_rev"];
        found["rpm"] = round((found["total_rev"] * 1000) / found["pv"], 2);
        found["eff_fill_rate"] = round(found["total_imp"] / found["pv"], 4);
      }
    });
    return arr;
  };
  const getBidderData = (data) => {
    let diff = {
      imp: 0,
      adreq: 0,
      pv: 0,
      rev: 0,
    };
    let arr = [];
    let pageviews = {};
    // let cnt1 = Number.MAX_SAFE_INTEGER;
    data.forEach((obj) => {
      const site = obj?.website;

      // if (variance.hasOwnProperty(site)) {
      //   diff = variance[site];
      // }

      const adreq = obj.ad_request_tags;
      const imp = obj.imp_tags;
      // console.log(imp);
      const dt = obj.createdAt.split("T")[0] + "_" + site;
      // const dt = obj.createdAt.split("T")[0];
      if (!(dt in pageviews)) pageviews[dt] = 0;
      // pageviews[dt] += parseInt((obj.player_load * (100 - diff?.pv)) / 100);
      pageviews[dt] += parseInt(obj.player_load)
      const found = arr.find((o) => {
        return dt in o;
      });
      const temp = found ?? {};
      if (Object.keys(temp).length <= 0) {
        temp[dt] = {};
      }
      // if (obj.website) temp[dt]["website"] = obj.website;
      for (let key in adreq) {
        // const adreqD = key.includes("Unibot") ? diff.adreq : 0;
        const adreqD = 0
        if (key in temp[dt] && temp[dt][key]["adreq"]) {
          temp[dt][key]["adreq"] += parseInt(
            (adreq[key] * (100 - adreqD)) / 100
          );
        } else {
          temp[dt][key] = {
            ...temp[dt][key],
            adreq: parseInt((adreq[key] * (100 - adreqD)) / 100),
            site: obj.website,
          };
        }
      }
      for (let key in imp) {
        // const impD = key.includes("Unibot") ? diff.imp : 0;
        const impD =  0;
        if (key in temp[dt] && temp[dt][key]["total_imp"]) {
          temp[dt][key]["total_imp"] += parseInt(
            (imp[key]["count"] * (100 - impD)) / 100
          );
          temp[dt][key]["cpm"] += imp[key]["count"] * imp[key]["cpm"];
        } else {
          temp[dt][key] = {
            ...temp[dt][key],
            total_imp: parseInt((imp[key]["count"] * (100 - impD)) / 100),
            cpm: imp[key]["count"] * imp[key]["cpm"],
            site: obj.website,
          };
        }
        // console.log(imp[key]["count"], key);
      }
      if (!found) {
        arr.push(temp);
      }
    });

    // const cnsle = JSON.parse(JSON.stringify(arr));
    // console.log(cnsle);

    let pv = 0;
    arr = arr.map((data) => {
      for (let key in data) {
        pv = pageviews[key];
        for (let subkey in data[key]) {
          const name = subkey.replace("google~", "");
          data[key][subkey]["avg_ecpm"] =
            data[key][subkey]["cpm"] / data[key][subkey]["total_imp"];
          // console.log(data[key][subkey]["total_imp"]);
          data[key][subkey]["fill_rate"] =
            data[key][subkey]["total_imp"] / data[key][subkey]["adreq"];
          if (isNaN(data[key][subkey]["fill_rate"]))
            data[key][subkey]["fill_rate"] = 0;
          data[key][subkey]["total_rev"] =
            (data[key][subkey]["avg_ecpm"] * data[key][subkey]["total_imp"]) /
            1000;
          data[key][subkey]["rpm"] =
            (data[key][subkey]["total_rev"] * 1000) / pv;
          data[key][subkey]["eff_fill_rate"] =
            data[key][subkey]["total_imp"] / pv;
          data[key][subkey]["name"] = name;
          data[key][subkey]["date"] = key.split("_")[0];
          data[key][subkey]["key"] = ++cnt;
        }
        return data[key];
      }
    });

    const processedArr = {};
    // const cnsle = JSON.parse(JSON.stringify(arr));
    // console.log(cnsle);
    // console.log(gamTableData);

    arr.forEach((item) => {
      if (!("google" in item)) {
        let temp = {};
        item["google"] = {};

        if (
          (clientName === "unibots" || userType === "user") &&
          userRole !== "admin"
        ) {
          for (let key in item) {
            // console.log("key in bidder", key);
            // console.log("item in bidder", item);
            // console.log("value in bidder", item[key]);
            if (key.startsWith("google~")) {
              // const cnsle = JSON.parse(JSON.stringify(item[key]));
              // console.log(cnsle);

              const bidderKey = item[key]["date"] + "#" + item[key]["site"];
              // console.log(bidderKey, gamTableData[bidderKey]);

              // temp["site"] = item[key]["website"];

              if (gamTableData[bidderKey] && !(bidderKey in processedArr)) {
                // console.log(gamTableData[bidderKey]);
                processedArr[bidderKey] = {
                  ...gamTableData[bidderKey],
                  name: "google",
                };
                // processedArr.push(temp);
              }

              // item["google"]["date"] = item[key]["date"];
              // console.log(item[key]["website"]);
              // item["google"]["site"] = item[key]["site"];
              delete item[key]; // delete the item[key] once item["google"] is populated
            } else {
              let data = props?.sspAPI?.filter((ssp) => {
                return (
                  ssp.Date === item[key]["date"] &&
                  ssp.Domain?.split(".")[0]?.toLowerCase() ===
                    item[key]["site"]?.split(".")[0]?.toLowerCase() &&
                  ssp.Publisher === key &&
                  !(
                    ssp.Publisher === "rubicon" &&
                    ssp.Impressions === 0 &&
                    ssp.Revenue === 0
                  )
                );
              });
              if (data && data.length !== 0) {
                data.forEach((ssp) => {
                  item[key]["api_adreq"] = Number(ssp["Ad_Requests"]);
                  if (
                    typeof ssp["Revenue"] === "string" &&
                    ssp["Revenue"].startsWith("$")
                  ) {
                    item[key]["api_total_rev"] = parseFloat(
                      ssp["Revenue"].replace("$", "")
                    );
                  } else {
                    item[key]["api_total_rev"] = Number(ssp["Revenue"]);
                  }
                  item[key]["api_total_imp"] = Number(ssp["Impressions"]);
                  if (
                    typeof ssp["eCPM"] === "string" &&
                    ssp["eCPM"].startsWith("$")
                  ) {
                    item[key]["api_avg_ecpm"] = parseFloat(
                      ssp["eCPM"].replace("$", "")
                    );
                  } else {
                    item[key]["api_avg_ecpm"] = Number(ssp["eCPM"]);
                  }
                  item[key]["api_fill_rate"] =
                    Number(ssp["Impressions"]) / Number(ssp["Ad_Requests"]);
                });
              }
            }
          }
          // if (
          //   gamTableData[item["google"]["date"] + "#" + item["google"]["site"]]
          // ) {
          //   item["google"] =
          //     gamTableData[
          //       item["google"]["date"] + "#" + item["google"]["site"]
          //     ];
          // }

          item["google"]["key"] = ++cnt;
          item["google"]["name"] = "google";

          delete item["google"]["children"];
        } else {
          item["google"]["key"] = ++cnt;
          item["google"]["name"] = "google";
          let impr = 0,
            rev = 0,
            adreq = 0,
            cpm = 0;
          for (let key in item) {
            if (key.startsWith("google~")) {
              item["google"]["date"] = item[key]["date"];
              if (!isNaN(item[key]["total_imp"]))
                impr += item[key]["total_imp"];
              if (!isNaN(item[key]["total_rev"])) rev += item[key]["total_rev"];
              if (!isNaN(item[key]["adreq"])) adreq += item[key]["adreq"];
              if (
                !isNaN(item[key]["avg_ecpm"]) &&
                !isNaN(item[key]["total_imp"])
              )
                cpm += item[key]["avg_ecpm"] * item[key]["total_imp"];
              if ("children" in item["google"])
                item["google"]["children"].push(item[key]);
              else item["google"]["children"] = [item[key]];

              delete item[key]; // delete the item[key] once item["google"] is populated
            }
            // console.log(item["google"]["children"]);
          }
          pv = pageviews[item["google"]["date"]];
          item["google"]["total_imp"] = impr;
          item["google"]["total_rev"] = rev;
          item["google"]["adreq"] = adreq;
          if (adreq !== 0) item["google"]["fill_rate"] = impr / adreq;
          item["google"]["avg_ecpm"] = cpm / impr;
          item["google"]["rpm"] = (rev * 1000) / pv;
          item["google"]["eff_fill_rate"] = impr / pv;
        }
      }
    });

    // console.log(processedArr);

    let childData = [];
    arr.forEach((item) => {
      for (let key in item) {
        if (
          (clientName === "unibots" || userType === "user") &&
          userRole !== "admin" &&
          key === "google"
        )
          continue;
        // if (key !== "google")
        childData.push(item[key]);
      }
    });

    for (const key in processedArr) {
      const temp = JSON.parse(JSON.stringify(processedArr[key]));
      const site = JSON.parse(JSON.stringify(temp.website));
      delete temp.website;

      temp["site"] = site;

      childData.push(temp);
    }

    // const cnsle = JSON.parse(JSON.stringify(childData));
    // console.log(cnsle);

    childData.map((obj) => {
      if (obj.name === "google" && "children" in obj) {
        obj["children"].sort((a, b) => b.avg_ecpm - a.avg_ecpm);
      }
    });

    return childData;
  };

  const calcData = (msg) => {
    // console.log("Message data", msg);
    const today = new Date();

    if (
      graphFilter["endDate"].split("T")[0] === today.toISOString().split("T")[0]
    )
      graphFilter["endDate"] = today.toISOString();
    const res = { msg: msg };

    let siteMap = res.msg.siteMap;
    setSitemap(siteMap);
    res.msg.data.sort(compareDates);

    res.msg.data.forEach((obj) => {
      obj.createdAt = addTimezone(obj.createdAt, selectedTimezone);
    });

    cnt = 100000;
    let websites = Object.keys(siteMap);
    // let pubData = [];
    // websites.forEach((site) => {
    //   let data = res.msg.data.filter((obj) => {
    //     return obj.website === site;
    //   });
    //   let arr = getDataArr(data, site, siteMap);
    //   pubData = [...pubData, ...arr];
    // });

    let pubData = websites.reduce((accumulator, site) => {
      let data = res.msg.data.filter((obj) => obj.website === site);
      let arr = getDataArr(data, site, siteMap);
      return accumulator.concat(arr);
    }, []);

    // const cnsle = JSON.parse(JSON.stringify(pubData));
    // console.log(cnsle);

    let mergedData = {},
      gamData = [];
    if (
      (clientName === "unibots" || userType === "user") &&
      userRole !== "admin" &&
      filterPub === null
    ) {
      Object.values(parentGamData)
        // .flatMap((obj) => obj.children)
        .forEach((child) => gamData.push(child));
      pubData.push(...gamData);
    }

    // pubData = pubData.concat(gamData);
    // console.log(pubData);
    // console.log(gamData);

    pubData.forEach((obj) => {
      const date = obj.date;
      if (!mergedData[date]) {
        // mergedData[date] = { ...obj, type: "parent" };
        mergedData[date] = {
          ...obj,
          type: "parent",
          name: undefined,
          website: undefined,
        };
      } else {
        for (const key in obj) {
          if (key !== "date") {
            if (key === "key") mergedData[date][key] = ++cnt;
            else if (key === "rpm")
              mergedData[date][key] =
                (mergedData[date]["total_rev"] * 1000) / mergedData[date]["pv"];
            else if (key === "fill_rate")
              mergedData[date][key] =
                mergedData[date]["total_imp"] / mergedData[date]["adreq"];
            else if (key === "eff_fill_rate")
              mergedData[date][key] =
                mergedData[date]["total_imp"] / mergedData[date]["pv"];
            else if (key === "total_imp") {
              const total_imp = mergedData[date][key] + obj[key];
              const avg_ecpm1 = mergedData[date]["avg_ecpm"];
              const avg_ecpm2 = obj["avg_ecpm"];
              const total_imp1 = mergedData[date][key];
              const total_imp2 = obj[key];
              // if (total_imp1 + total_imp2 !== 0) {
              mergedData[date]["avg_ecpm"] =
                (avg_ecpm1 * total_imp1 + avg_ecpm2 * total_imp2) /
                (total_imp1 + total_imp2);
              // }
              //  else mergedData[date]["avg_ecpm"] = 0;
              mergedData[date][key] = total_imp;

              if (isNaN(mergedData[date]["avg_ecpm"]))
                mergedData[date]["avg_ecpm"] = 0;
              // console.log(mergedData[date]["avg_ecpm"]);
            } else if (
              key !== "avg_ecpm" &&
              key !== "name" &&
              key !== "website" &&
              key !== "client"
            ) {
              // if (key === "pub_rev") console.log(obj[key]);
              if (!isNaN(obj[key])) {
                mergedData[date][key] += obj[key];
              }
            }
          }
        }
      }
    });
    let arr = Object.values(mergedData);
    // console.log(mergedData);

    const MpubData = Object.values(
      pubData.reduce((acc, curr) => {
        // const cnsle = JSON.parse(JSON.stringify(curr));
        // console.log(cnsle);
        // console.log(curr.name, curr);
        const key = `${curr.date}-${curr.name}`;
        if (!acc[key]) {
          acc[key] = { ...curr, player_load: curr.pv };
          acc[key].key = ++cnt;
          acc[key].type = "pub";
          acc[key].eff_fill_rate = 0;
          acc[key].rpm = 0;
          // if (!isNaN(curr.total_imp)) acc[key].total_imp = 0;
        } else {
          acc[key].key = ++cnt;
          acc[key].total_rev += curr.total_rev;
          acc[key].pub_rev += curr.pub_rev;
          acc[key].client_rev += curr.client_rev;
          acc[key].pv += curr.pv;
          acc[key].adreq += curr.adreq;
          // if(curr.total_imp)
          const total_imp = acc[key].total_imp + curr.total_imp;
          const avg_ecpm1 = acc[key].avg_ecpm;
          const avg_ecpm2 = curr.avg_ecpm;
          const total_imp1 = acc[key].total_imp;
          const total_imp2 = isNaN(curr.total_imp) ? 0 : curr.total_imp;
          if (total_imp1 + total_imp2 !== 0)
            acc[key].avg_ecpm =
              (avg_ecpm1 * total_imp1 + avg_ecpm2 * total_imp2) /
              (total_imp1 + total_imp2);
          else acc[key].avg_ecpm = 0;
          acc[key].total_imp = total_imp;

          if (acc[key].adreq !== 0)
            acc[key].fill_rate = acc[key].total_imp / acc[key].adreq;
          // if (isNaN(acc[key].fill_rate)) acc[key].fill_rate = 0;
          if (acc[key].pv !== 0) {
            acc[key].eff_fill_rate = acc[key].total_imp / acc[key].pv;
            acc[key].rpm = (acc[key].total_rev * 1000) / acc[key].pv;
          }
        }
        return acc;
      }, {})
    );
    // console.log(MpubData);
    // console.log(arr);
    // console.log(props.tableData);

    if (filterPub) {
      // if (clientName === "unibots") props.setFilterBy("website");
      let data;
      props.setFilterBy("website");
      if (
        (clientName === "unibots" || userType === "user") &&
        currWebsite !== null
      ) {
        data = res.msg.data.filter((obj) => {
          return obj.website === currWebsite;
        });
      } else {
        data = res.msg.data.filter((obj) => {
          return siteMap[obj.website] === filterPub;
        });
      }

      props.setTableData(MpubData);
      // props.setTableData((prevValue) => {
      //   const today = new Date();
      //   const uniquePrev = prevValue.filter((obj) => {
      //     return obj.date !== today.toISOString().split("T")[0];
      //   });
      //   return [...uniquePrev, ...MpubData];
      // });

      if (
        (clientName === "unibots" || userType === "user") &&
        userRole !== "admin"
      ) {
        let bidderData = getBidderData(data);

        let bidders = [];

        pubData.forEach((objA) => {
          // console.log("pubdata map obj", objA);
          const matchingObjects = bidderData.filter(
            (objB) => objB.date === objA.date && objB.site === objA.website
          );
          bidders.push(matchingObjects);

          if (matchingObjects.length > 0) {
            objA.children = matchingObjects;
          }
        });

        // bidders wise
        if (props.filterBy === "bidders") {
          props.setFilterBy("bidders");
          // Flatten the bidders array
          const mergedArray = bidders.flat();

          // Group the merged array data by name
          const formattedData = mergedArray.reduce(
            (acc, { name, date, ...rest }) => {
              if (name && date) {
                if (!acc.has(name)) acc.set(name, []);
                acc.get(name).push({ name, date, ...rest });
              }
              return acc;
            },
            new Map()
          );

          // Map formatted data to new items with aggregations
          const newitem = Array.from(formattedData.entries()).map(
            ([key, children]) => {
              // Find unique objects based on name and site
              const uniqueObjects = Array.from(
                new Set(
                  children.map(({ name, site }) =>
                    JSON.stringify({ name, site })
                  )
                ),
                JSON.parse
              );

              // Aggregate child data
              const childAggregatedData = uniqueObjects.map((parent) => {
                const { name, site } = parent;
                const childrenData = children
                  .filter((child) => child.name === name && child.site === site)
                  .reduce(
                    (acc, child) => {
                      acc.pv += child.pv || 0;
                      acc.total_rev += child.total_rev || 0;
                      acc.total_imp += child.total_imp || 0;
                      acc.pub_rev += child.pub_rev || 0;
                      acc.client_rev += child.client_rev || 0;
                      acc.adreq += child.adreq || 0;
                      acc.avg_ecpm =
                        (child.total_rev / child.total_imp) * 1000 || 0;
                      acc.rpm = (child["total_rev"] * 1000) / child["pv"] || 0;
                      return acc;
                    },
                    {
                      pv: 0,
                      total_rev: 0,
                      total_imp: 0,
                      pub_rev: 0,
                      client_rev: 0,
                      adreq: 0,
                      avg_ecpm: 0,
                      rpm: 0,
                    }
                  );

                return {
                  ...parent,
                  ...childrenData,
                  children: children.filter(
                    (child) => child.name === name && child.site === site
                  ),
                  key: site,
                };
              });

              // Aggregate parent data
              const aggregatedData = childAggregatedData.reduce(
                (acc, child) => {
                  acc.pv += child.pv || 0;
                  acc.total_rev += child.total_rev || 0;
                  acc.total_imp += child.total_imp || 0;
                  acc.pub_rev += child.pub_rev || 0;
                  acc.client_rev += child.client_rev || 0;
                  acc.adreq += child.adreq || 0;
                  acc.avg_ecpm =
                    (child.total_rev / child.total_imp) * 1000 || 0;

                  acc.rpm = (child["total_rev"] * 1000) / child["pv"] || 0;
                  return acc;
                },
                {
                  pv: 0,
                  total_rev: 0,
                  total_imp: 0,
                  pub_rev: 0,
                  client_rev: 0,
                  adreq: 0,
                  avg_ecpm: 0,
                  rpm: 0,
                }
              );

              aggregatedData.key = key;

              return {
                name: key,
                children: childAggregatedData,
                ...aggregatedData,
                type: "parent",
              };
            }
          );
          props.setBiddersTableData(newitem);
        }

        // const cnsle = JSON.parse(JSON.stringify(pubData));
        // console.log(cnsle);
        props.setChildTableData(pubData);
        // props.setChildTableData(bidderData);
      } else {
        // if (props.filterBy === "bidders") {
        //   let bidderData = getBidderData(data);
        //   props.setChildTableData(bidderData);
        // } else props.setChildTableData(pubData);

        let bidderData = getBidderData(data);
        const transformedBidderData = transformBidderData(bidderData);
        // const cnsle = JSON.parse(JSON.stringify(bidderData));
        // console.log(cnsle);

        pubData.forEach((objA) => {
          const matchingObjects = transformedBidderData.filter(
            (objB) => objB.date === objA.date && objB.site === objA.website
          );

          if (matchingObjects.length > 0) {
            objA.children = matchingObjects;
          }
        });

        props.setChildTableData(pubData);
      }
    } else {
      if (
        (clientName === "unibots" || userType === "user") &&
        userRole !== "admin"
      )
        props.setFilterBy("bidders");
      // props.setTableData((prevValue) => {
      //   const today = new Date();
      //   const uniquePrev = prevValue.filter((obj) => {
      //     return obj.date !== today.toISOString().split("T")[0];
      //   });
      //   return [...uniquePrev, ...arr];
      // });
      // console.log(MpubData);
      props.setTableData(arr);

      props.setChildTableData(MpubData);
    }

    let impG = [],
      revG = [],
      ecpmG = [],
      fillG = [],
      adG = [],
      pvG = [],
      eFillG = [],
      rpmG = [];

    // console.log(totalData);
    let total_imp = totalData.imp,
      total_rev = totalData.rev,
      avg_ecpm = totalData.ecpm,
      total_adreq = totalData.adreq,
      fill_rate = 0,
      total_pv = totalData.pv,
      eff_fill_rate = 0,
      total_rpm = 0;

    // to fix ordering of date in imp/rev/etc charts
    arr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    arr.forEach((obj) => {
      // console.log(obj.name, obj.rpm);
      total_imp += obj.total_imp;
      if (obj.total_rev) total_rev += obj?.total_rev;
      if (obj.avg_ecpm) avg_ecpm += obj.avg_ecpm * obj.total_imp;
      if (obj.adreq) total_adreq += obj.adreq;
      if (obj.pv) total_pv += obj.pv;
      impG.push({ date: formatDate(obj.date), Impression: obj.total_imp });
      if (obj.total_rev)
        revG.push({
          date: formatDate(obj.date),
          Revenue: round(obj.total_rev, 2),
        });
      if (obj.avg_ecpm)
        ecpmG.push({
          date: formatDate(obj.date),
          eCPM: round(obj.avg_ecpm, 2),
        });
      if (obj.fill_rate)
        fillG.push({
          date: formatDate(obj.date),
          "Fill Rate": round(Number(obj.fill_rate * 100), 2),
        });
      if (obj.eff_fill_rate)
        eFillG.push({
          date: formatDate(obj.date),
          "Effective Fill": round(Number(obj.eff_fill_rate * 100), 2),
        });
      if (obj.adreq)
        adG.push({ date: formatDate(obj.date), "Ad Requests": obj.adreq });
      if (obj.pv)
        pvG.push({ date: formatDate(obj.date), "Page Views": obj.pv });
      if (obj.rpm)
        rpmG.push({ date: formatDate(obj.date), RPM: obj.rpm.toFixed(4) });
    });

    props.setImpGraph(impG);
    props.setRevGraph(revG);
    props.setEcpmGraph(ecpmG);
    props.setFillGraph(fillG);
    props.seteFillGraph(eFillG);
    props.setAdGraph(adG);
    props.setRpmGraph(rpmG);
    props.setPvGraph(pvG);

    // props.setImpGraph((prevValue) => {
    //   const today = new Date();
    //   const uniquePrev = prevValue.filter(
    //     (obj) =>
    //       formatDate(obj.date) !== formatDate(today.toISOString().split("T")[0])
    //   );
    //   return [...uniquePrev, ...impG];
    // });
    // props.setRevGraph((prevValue) => {
    //   const today = new Date();
    //   const uniquePrev = prevValue.filter(
    //     (obj) =>
    //       formatDate(obj.date) !== formatDate(today.toISOString().split("T")[0])
    //   );
    //   return [...uniquePrev, ...revG];
    // });
    // props.setEcpmGraph((prevValue) => {
    //   const today = new Date();
    //   const uniquePrev = prevValue.filter(
    //     (obj) =>
    //       formatDate(obj.date) !== formatDate(today.toISOString().split("T")[0])
    //   );
    //   return [...uniquePrev, ...ecpmG];
    // });
    // props.setFillGraph((prevValue) => {
    //   const today = new Date();
    //   const uniquePrev = prevValue.filter(
    //     (obj) =>
    //       formatDate(obj.date) !== formatDate(today.toISOString().split("T")[0])
    //   );
    //   return [...uniquePrev, ...fillG];
    // });
    // props.seteFillGraph((prevValue) => {
    //   const today = new Date();
    //   const uniquePrev = prevValue.filter(
    //     (obj) =>
    //       formatDate(obj.date) !== formatDate(today.toISOString().split("T")[0])
    //   );
    //   return [...uniquePrev, ...eFillG];
    // });
    // props.setAdGraph((prevValue) => {
    //   const today = new Date();
    //   const uniquePrev = prevValue.filter(
    //     (obj) =>
    //       formatDate(obj.date) !== formatDate(today.toISOString().split("T")[0])
    //   );
    //   return [...uniquePrev, ...adG];
    // });
    // props.setRpmGraph((prevValue) => {
    //   const today = new Date();
    //   const uniquePrev = prevValue.filter(
    //     (obj) =>
    //       formatDate(obj.date) !== formatDate(today.toISOString().split("T")[0])
    //   );
    //   return [...uniquePrev, ...rpmG];
    // });
    // props.setPvGraph((prevValue) => {
    //   const today = new Date();
    //   const uniquePrev = prevValue.filter(
    //     (obj) =>
    //       formatDate(obj.date) !== formatDate(today.toISOString().split("T")[0])
    //   );
    //   return [...uniquePrev, ...pvG];
    // });
    total_rev = round(total_rev, 2);
    total_imp = round(total_imp, 2);
    total_imp.toFixed(2);

    if (avg_ecpm !== 0 && total_imp !== 0) {
      avg_ecpm = round(avg_ecpm / total_imp, 2);
      avg_ecpm.toFixed(2);
    }
    if (total_adreq !== 0) {
      fill_rate = total_imp / total_adreq;
      fill_rate = round(fill_rate, 4);
      fill_rate.toFixed(4);
    }
    if (total_pv !== 0) {
      eff_fill_rate = total_imp / total_pv;
      eff_fill_rate = round(eff_fill_rate, 4);
      eff_fill_rate.toFixed(4);
    }
    if (total_pv !== 0) total_rpm = round((total_rev * 1000) / total_pv, 2);

    props.setTotalImp((prev) => {
      let obj = { ...prev, new: total_imp };
      if (prev.new !== total_imp) {
        obj["old"] = prev.new;
      }
      return obj;
    });
    props.setTotalRev((prev) => {
      let obj = { ...prev, new: total_rev };
      if (prev.new !== total_rev) {
        obj["old"] = prev.new;
      }
      return obj;
    });
    props.setAverageEcpm((prev) => {
      let obj = { ...prev, new: avg_ecpm };
      if (prev.new !== avg_ecpm) {
        obj["old"] = prev.new;
      }
      return obj;
    });
    props.setNetFillRate((prev) => {
      let obj = { ...prev, new: fill_rate * 100 };
      if (prev.new !== fill_rate * 100) {
        obj["old"] = prev.new;
      }
      return obj;
    });
    props.seteFillRate((prev) => {
      let obj = { ...prev, new: eff_fill_rate * 100 };
      if (prev.new !== eff_fill_rate * 100) {
        obj["old"] = prev.new;
      }
      return obj;
    });
    props.setTotalAd((prev) => {
      let obj = { ...prev, new: total_adreq };
      if (prev.new !== total_adreq) {
        obj["old"] = prev.new;
      }
      return obj;
    });
    props.setTotalPv((prev) => {
      let obj = { ...prev, new: total_pv };
      if (prev.new !== total_pv) {
        obj["old"] = prev.new;
      }
      return obj;
    });
    props.setTotalRpm((prev) => {
      let obj = { ...prev, new: total_rpm };
      if (prev.new !== total_rpm) {
        obj["old"] = prev.new;
      }
      return obj;
    });
    // setFlag(false)
    const uniquePub = [];
    for (const prop in res.msg.siteMap) {
      uniquePub.push(res.msg.siteMap[prop]);
    }

    let uniqueChars = [...new Set(uniquePub)];
    uniqueChars.push("All Publishers");
    uniqueChars.sort();

    setPubisherDrop(uniqueChars);
    if (changeFlag) setSpin(false);
    if (changeFlag) props.setSpin(false);
    // console.log(res)
    // console.log('useeffect')
    // setTimeout(() => {
    //   setSpin(false);
    // }, 2000);
  };

  // const getWeb = async () => {
  //   const res = await getWebsites();
  //   // console.log(res.data);
  //   const temp = {};
  //   res.data.msg.forEach((obj) => {
  //     // console.log(obj.revShare);
  //     if (obj.fixedCPM !== "0") temp[obj["url"]] = "f" + obj["fixedCPM"];
  //     else temp[obj["url"]] = obj["revShare"];
  //   });
  //   props.setWebRevMap(temp);
  // };

  useEffect(() => {
    const getWeb = async () => {
      // const res = await getWebsites();
      const temp = {};
      websiteList.forEach((obj) => {
        // console.log(obj.revShare);
        if (obj.fixedCPM !== "0") temp[obj["url"]] = "f" + obj["fixedCPM"];
        else temp[obj["url"]] = obj["revShare"];
      });
      console.log("webRevMap filled");
      props.setWebRevMap(temp);
    };
    // const getVariance = async () => {
    //   const res = await getVarianceData();
    //   const temp = {};

    //   res?.data.data.forEach((obj) => {
    //     temp[obj.website] = {
    //       adreq: obj.adreq,
    //       imp: obj.imp,
    //       pv: obj.pv,
    //       rev: obj.rev,
    //     };
    //   });
    //   setVariance(temp);
    //   console.log("variance filled");
    // };
    getWeb();
    // getVariance();
  }, [websiteList]);

  useEffect(() => {
    // console.log(clientName, userType, userRole);
    const today = new Date();
    // console.log("date useEffect");
    const firstDay = new Date(
      Date.UTC(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 6,
        0,
        0,
        0
      )
    );

    setGraphFilter({
      startDate: firstDay.toISOString(),
      endDate: today.toISOString(),
      client: clientName,
    });
    setSearchDate(searchDate + 1);
  }, [userType]);

  useEffect(() => {
    // setChangeFlag(false);
    setSpin(true);
    props.setSpin(true);
    const socket = io(process.env.REACT_APP_GRAPH_API_URL, {
      transports: ["websocket"],
    });
    const fetchData = async () => {
      // if (clientName === "unibots" && !changeFlag) {
      //   console.log("Do nothing, eat fiveStar");
      // } else {
      if (
        graphFilter["startDate"] !== "" &&
        graphFilter["endDate"] !== "" &&
        userType.length !== 0
      ) {
        // const socket = io(process.env.REACT_APP_API_URL, {
        //   transports: ["websocket"],
        // });
        console.log("Socket data");

        const getAdData = async () => {
          props.setGraphDate({
            startDate: graphFilter["startDate"].split("T")[0],
            endDate: graphFilter["endDate"].split("T")[0],
          });
          console.log("GAM data");
          const res = await getAdunitData({
            startDate: graphFilter["startDate"].split("T")[0],
            endDate: graphFilter["endDate"].split("T")[0],
            client: clientName,
            pub: filterPub,
          });

          setGamTableData([]);
          SetparentGamData(res.msg.data);

          res.msg.data.forEach((obj) => {
            obj.children &&
              obj.children.forEach((child) => {
                // if (filterPub === null) {
                //   child.pv = 0;
                // }
                let temp = child.date + "#" + child.website;

                // let childWebsite = JSON.parse(JSON.stringify(child.website));
                // changes[temp] = { ...child, site: child.website };
                // delete child.website;
                setGamTableData((prevValue) => ({
                  ...prevValue,
                  [temp]: { ...child },
                }));
                // console.log({ ...child, site: childWebsite });
                // setGamTableData((prevValue) => ({
                //   ...prevValue,
                //   [dt]: obj,
                // }));
              });
            // setGamTableData((prevValue) => ({
            //   ...prevValue,
            //   ...changes,
            // }));
            //  else {
            //   obj.children.forEach((child) => {
            //     let temp = child.date + "#" + child.website;
            //     child.site = child.website;
            //     delete child.website;
            //     setGamTableData((prevValue) => ({
            //       ...prevValue,
            //       [temp]: child,
            //     }));
            //   });
            // }
          });

          setTotalData({
            imp: res.msg.Timp,
            rev: res.msg.Trev,
            adreq: res.msg.Tadreq,
            pv: res.msg.Tpv,
            ecpm: res.msg.Tecpm,
          });
          setGraphData({
            impG: res.msg.impGraph,
            revG: res.msg.revGraph,
            fillG: res.msg.fillGraph,
            efillG: res.msg.eFillGraph,
            rpmG: res.msg.rpmGraph,
            ecpmG: res.msg.ecpmGraph,
            pvG: res.msg.pvGraph,
            adG: res.msg.adreqGraph,
          });
        };

        // const fetchData = async () => {
        //   // setChangeFlag(false);
        //   if (clientName === "unibots") {
        //     const res = await getAdData();
        //     // setChangeFlag(true);
        //   }
        // };

        if (
          (clientName === "unibots" || userType === "user") &&
          userRole !== "admin" &&
          !changeFlag
        ) {
          await getAdData();
        }
        const getData = () => {
          let start = convertTimezone(
            graphFilter["startDate"],
            selectedTimezone
          );
          // let start = graphFilter["startDate"];
          let end = graphFilter["endDate"];

          // if (archivedData.socketData.length === 0) {
          if (!changeFlag) {
            console.log("empty archivedData");
            // console.log("flag status: ", changeFlag);
            // if (start.split("T")[0] === new Date().toISOString().split("T")[0])
            // dispatch({ socketData: [] });
            socket.emit("data", {
              name: clientName,
              startDate: start,
              endDate: end,
              type: userType,
              role: userRole,
            });
          } else {
            console.log("filled archivedData");
            //   const currDate = graphFilter["endDate"];
            const currDate = new Date();
            let first = new Date();
            first.setUTCHours(0, 0, 0, 0);
            first = convertTimezone(first, selectedTimezone);
            // console.log("first, today: ", first, currDate.toISOString());
            socket.emit("data", {
              name: clientName,
              startDate: first,
              endDate: currDate.toISOString(),
              type: userType,
              role: userRole,
            });
          }
          // }
        };
        getData();
        intervalRef.current = setInterval(() => {
          getData();
        }, 120000);

        props.resetGraphValues();
        // props.setSpin(true);
        socket.on("data_received", (msg) => {
          // console.log("Data in msg: ", msg.data);
          let message = JSON.parse(JSON.stringify(msg));
          let temp;
          if (!changeFlag) {
            temp = JSON.parse(JSON.stringify(filterDataByTodayDate(msg.data)));
            setChangeFlag(true);
            // setArchiveTemp(temp);
            dispatch(updateData({ socketData: temp }));
            // archiveSocketData(temp);
          } else {
            message.data = msg.data.concat(
              JSON.parse(JSON.stringify(archivedData.socketData))
            );
            const weburl = websiteList.map((website) => website.url);
            const filterData = message.data.filter((data) => {
              return weburl.includes(data.website);
            });
            message.data = filterDataByGraphDate(filterData);
            calcData(message);
          }
          // console.log("Data in message: ", message.data);
        });
        return () => {};
      }
    };
    if (Object.keys(props.webRevMap).length) fetchData();
    return () => {
      socket.off("data_received");
      socket.close();
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, [
    // graphFilter,
    searchDate,
    filterPub,
    selectedTimezone,
    // props.webRevMap,
    props.filterBy,
    props.webRevMap,
    // variance,
    // gamTableData,
    // archivedData,
    changeFlag,
  ]);

  // useEffect(() => {
  //   // dispatch(updateData({ socketData: [] }));
  //   if (archivedData.socketData.length !== 0) {
  //     console.log("Reset Data");
  //     // setArchiveTemp([]);
  //     dispatch(updateData({ socketData: [] }));
  //   }

  //   // setChangeFlag(true);

  //   if (clientName && clientName !== "unibots") {
  //     console.log("change flag via reset UF: ", clientName);
  //     setChangeFlag(true);
  //   }
  // }, [graphFilter]);

  // useEffect(() => {
  //   if (archiveTemp.length !== 0) {
  //     console.log("Archive useEffect");
  //     dispatch(updateData({ socketData: archiveTemp }));
  //   }
  // }, [archiveTemp]);

  const handleDateChange = (value) => {
    setRadioValue(-1);
    const today = new Date();

    if (
      value[1]["$d"].toISOString().split("T")[0] ===
      today.toISOString().split("T")[0]
    )
      value[1]["$d"] = today;
    else value[1]["$d"].setHours(23, 59, 59);

    setGraphFilter((prev) => {
      return {
        ...prev,
        startDate: value[0]["$d"].toISOString(),
        endDate: value[1]["$d"].toISOString(),
      };
    });
    // setChangeFlag(false);
  };

  const handlePubChange = (value) => {
    // console.log(value)
    // props.setTableData();
    // props.setChildTableData();
    if (value === "All Publishers") value = null;

    // Extract websites from the websiteList based on the selected publisher
    let filteredWebsites = websiteList.filter((website) => {
      return website.parent === value;
    });
    // Extract non-archived website from the filtered Websites
    let urls = filteredWebsites
      .filter((websites) => websites.isArchived !== "true")
      .map((website) => website.url);
    setFilterWebsites(urls);

    setFilterPub(value);
    props.setFilterPub(value);
    props.setpubChange(true);
    setChangeFlag(false);
  };

  const handleWebChange = (value) => {
    props.setCurrWeb(value);
    setCurrWebsite(value);
    props.setpubChange(false);
    setChangeFlag(false);
  };

  return (
    <>
      {spin ? (
        <LoaderComp />
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-4 md:space-x-2 justify-between items-center">
          <div className="flex justify-start gap-10 ">
            <Select
              size="small"
              showSearch
              onChange={handlePubChange}
              placeholder="Select Publisher"
              loading={publisherDrop.length === 0}
              className="w-1/2 md:w-full min-w-[150px] md:mb-3"
              defaultValue={filterPub}
            >
              {publisherDrop.map((pub) => {
                return <Select.Option value={pub}>{pub}</Select.Option>;
              })}
            </Select>
            {(clientName === "unibots" || userType === "user") && filterPub && (
              <Select
                size="small"
                showSearch
                onChange={handleWebChange}
                placeholder="Select Website"
                loading={filterPub.length === 0}
                className="w-1/2 md:w-full min-w-[150px] md:mb-3"
                value={props.pubChange ? "All Websites" : currWebsite}
              >
                {filterWebsites.map((pub) => {
                  return <Select.Option value={pub}>{pub}</Select.Option>;
                })}
              </Select>
            )}
          </div>
          <div className="w-full"></div>
          {/* <TimezoneDropdown
            className="w-full"
            // onChange={handleTimezoneChange}
            selectedTimezone={selectedTimezone}
            setSelectedTimezone={setSelectedTimezone}
          /> */}
          <div className="flex justify-start md:justify-end mt-1 mb-4">
            <Radio.Group
              size="small"
              options={options}
              value={radioValue}
              optionType="button"
              buttonStyle="solid"
              onChange={handleChange}
            />
          </div>

          <div className="flex gap-x-1 mt-1 mb-4 w-11/12">
            <RangePicker
              size="small"
              onChange={handleDateChange}
              // onChange={(val) => console.log(val)}
              value={[
                dayjs.utc(graphFilter["startDate"].split("T")[0]),
                dayjs.utc(graphFilter["endDate"].split("T")[0]),
              ]}
              format={"YY-MM-DD"}
            />
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setSearchDate(searchDate + 1);
                setChangeFlag(false);
              }}
            >
              Go
            </Button>
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default GraphOptions;
