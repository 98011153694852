import {
  UserOutlined,
  ProfileOutlined,
  PlayCircleOutlined,
  ApartmentOutlined,
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Button, message, Alert } from "antd";
import { useState, useEffect } from "react";
import { Typography } from "antd";
import LandingPage from "../Graphs/LandingPage";
import Modify from "../Publisher/Modify";
import ModifyConnection from "../Connections/ModifyConnection";
import CreatePlayer from "../Video/CreatePlayer";
import ModifyWebsite from "../Website/ModifyWebsite";
import GetData from "../../API/GetData";
import { getClient, patchClient, clientlogOut } from "../../API/Clients";
import { userlogOut } from "../../API/User";
import { playerStatus } from "../../API/Websites";
import { useSelector } from "react-redux";
import NoticeBox from "./NoticeBox";
import BotAvatar from "./BotAvatar";
import AdminPanel from "../Admin/AdminPanel";
import ClientLogo from "./ClientLogo";
import AddPayment from "./AddPayment";
import ConfigLogs from "../Video/ConfigLogs";
import LogsPage from "../Utility/LogsPage";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const SiderLayout = (props) => {
  const [currOption, setCurrOption] = useState(
    window.localStorage.getItem("currOption") ?? "home"
  );
  const panelOptions = ["users", "clients"];
  const [currPage, setCurrPage] = useState(null);
  const userClient = useSelector((state) => state.client);
  const userName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const userRole = useSelector((state) => state.user.role);
  const access = useSelector((state) => state.user.access);
  const [lowbalance, setLowBalance] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  const [pubname, setPubName] = useState(null);
  const [currPub, setCurrPub] = useState(null);
  const [currUrl, setCurrUrl] = useState(null);
  const [fromwebsite, setFromWebsite] = useState(false);
  const [fromPub, setFromPub] = useState(false);
  const [saved, setSaved] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [trailLeft, setTrailLeft] = useState(null);
  const [open, setOpen] = useState(
    panelOptions.includes(window.localStorage.getItem("adminOption"))
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentmodal, setPaymentmodal] = useState(false);
  const [currentBalance, setCurrentbalance] = useState();
  const [isPrepaid, setIsPrepaid] = useState(false);
  const [trialOver, setTrialOver] = useState(null);

  useEffect(() => {
    if (userType === "client" && userClient.isPrepaid === true) {
      const getInfo = async () => {
        const res = await getClient(userName);
        if (res.error === null) {
          setClientDetails(res.data.msg);

          setIsPrepaid(true);
          let balance = res.data.msg.balance;
          setCurrentbalance(balance);
          if (balance < 1) {
            setLowBalance(true);
          }
        }
      };
      getInfo();
      const interval = setInterval(getInfo, 300000); // interval after every 5 minutes

      return () => {
        clearInterval(interval); // Clean up the interval when the component unmounts
      };
    }
  }, [userClient]);

  // useEffect(() => {
  //   if (currentBalance <= clientDetails?.balanceLimit) {
  //     console.log("current balance low useEffect");
  //     let data = {
  //       client: userName,
  //     };

  //     const changeStatus = async () => {
  //       const res = await playerStatus(data);
  //       console.log("player status change", res);
  //     };
  //     // changeStatus();
  //   }
  // }, [currentBalance, clientDetails]);

  useEffect(() => {
    // console.log("trail useeffect ran");
    if (clientDetails) {
      let endDateString;
      let startDateString;
      if (clientDetails?.endTrial) {
        let endDate = new Date(clientDetails?.endTrial);
        let startDate = new Date(clientDetails?.startTrial);

        endDateString = endDate.toISOString().split("T")[0];
        startDateString = startDate.toISOString().split("T")[0];
      }

      if (endDateString) {
        const endDateCheck = new Date(endDateString);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        endDateCheck.setHours(0, 0, 0, 0);
        if (today > endDateCheck) {
          setTrialOver(true);
        } else {
          setTrialOver(false);
          let startDateCheck = new Date();
          const timeDiff = Math.abs(
            endDateCheck.getTime() - startDateCheck.getTime()
          );
          const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          setTrailLeft(diffDays);
        }
      }
    }
  }, [clientDetails]);

  let items = [
    {
      label: "Home",
      key: "home",
      icon: <HomeOutlined />,
      disabled: access?.menuItems
        ? !access?.menuItems?.includes("home")
        : false,
    },
    {
      label: "Publisher",
      key: "publisher",
      icon: <UserOutlined />,
      disabled:
        (userRole !== "admin" && userRole !== "adops" && access?.menuItems
          ? !access?.menuItems?.includes("publisher")
          : false) ||
        userName === "adipolo" ||
        currentBalance <= clientDetails?.balanceLimit ||
        (!clientDetails?.firstPayment && trialOver),
    },
    {
      label: "Website",
      key: "website",
      icon: <ProfileOutlined />,
      disabled:
        (userRole !== "admin" && userRole !== "adops" && access?.menuItems
          ? !access?.menuItems?.includes("website")
          : false) ||
        userName === "adipolo" ||
        currentBalance <= clientDetails?.balanceLimit ||
        (!clientDetails?.firstPayment && trialOver),
    },
    {
      label: "Player",
      key: "player",
      icon: <PlayCircleOutlined />,
      disabled:
        (userRole !== "admin" && userRole !== "adops" && access?.menuItems
          ? !access?.menuItems?.includes("player")
          : false) ||
        userName === "adipolo" ||
        currentBalance <= clientDetails?.balanceLimit ||
        (!clientDetails?.firstPayment && trialOver),
    },
    {
      label: "Connections",
      key: "connection",
      icon: <ApartmentOutlined />,
      disabled:
        (userRole !== "admin" && userRole !== "adops" && access?.menuItems
          ? !access?.menuItems?.includes("connection")
          : false) ||
        userName === "adipolo" ||
        currentBalance <= clientDetails?.balanceLimit ||
        (!clientDetails?.firstPayment && trialOver),
    },
    {
      label: "Logs",
      key: "logs",
      icon: <UnorderedListOutlined />,
      disabled:
        (userRole !== "admin" && userRole !== "adops" && access?.menuItems
          ? !access?.menuItems?.includes("logs")
          : false) ||
        userName === "adipolo" ||
        currentBalance <= clientDetails?.balanceLimit ||
        (!clientDetails?.firstPayment && trialOver),
    },
  ];

  function changeContent(currentOption) {
    setCurrOption(currentOption);
  }
  function getpubname(name) {
    setPubName(name);
  }
  function removepubname() {
    setPubName(null);
  }
  const warning = (msg) => {
    messageApi.open({
      type: "warning",
      content: msg ?? "Please save changes before exiting!",
    });
  };
  const toggleCollapsed = () => {
    if (window.innerWidth < 1000) {
      // let elements = document.getElementsByClassName("ant-tooltip-content");
      // for (let i = 0; i < elements.length; i++) {
      //   elements[i].style.display = "none";
      // }
      setCollapsed(!collapsed);
    }
  };
  useEffect(() => {
    // if (currOption && currOption !== "player") {
    //   window.localStorage.clear();
    // }
    window.localStorage.setItem("currOption", currOption);
    if (currOption !== "player") {
      setCurrPub("");
      setCurrUrl(null);
    }
    switch (currOption) {
      case "home":
        setCurrPage(() => {
          return <LandingPage />;
        });
        break;
      case "publisher":
        setCurrPage(() => {
          return (
            <Modify
              changeContent={changeContent}
              setCurrOption={setCurrOption}
              getpubname={getpubname}
              setCurrPub={setCurrPub}
              setFromPub={setFromPub}
            />
          );
        });
        break;
      case "website":
        setCurrPage(() => {
          return (
            <ModifyWebsite
              setCurrOption={setCurrOption}
              setCurrPub={setCurrPub}
              setCurrUrl={setCurrUrl}
              pubname={pubname}
              removepubname={removepubname}
              setFromWebsite={setFromWebsite}
            />
          );
        });
        break;
      case "player":
        setCurrPage(() => {
          return (
            <CreatePlayer
              currPub={currPub}
              currUrl={currUrl}
              setCurrPub={setCurrPub}
              setCurrUrl={setCurrUrl}
              setCurrOption={setCurrOption}
              fromwebsite={fromwebsite}
              setFromWebsite={setFromWebsite}
              setSaved={setSaved}
            />
          );
        });
        break;
      case "connection":
        setCurrPage(() => {
          return <ModifyConnection />;
        });
        break;
      case "logs":
        setCurrPage(() => {
          return (
            <LogsPage
              currPub={currPub}
              currUrl={currUrl}
              fromwebsite={fromwebsite}
              fromPub={fromPub}
              setFromPub={setFromPub}
              setFromWebsite={setFromWebsite}
            />
          );
        });
        break;
      default:
        setCurrPage(() => {
          return <LandingPage />;
        });
        break;
    }
  }, [currOption, currPub, fromwebsite]);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleItemClick = (e) => {
    if (saved) {
      setCurrOption(e.key);
    } else {
      warning();
    }
  };
  const handleLogout = async () => {
    let UserType = props.cookies?.access_token?.split("USER")[1];
    let res;
    if (UserType === "client") {
      res = await clientlogOut();
    } else if (UserType === "user") {
      res = await userlogOut();
    } 
    if (!res.err) {
      props.setCookie("access_token", "");
      window.localStorage.clear();
      messageApi.open({
        type: "success",
        content: "Logged out successfully!",
      });
    } else {
      messageApi.open({
        type: "error",
        content: res.msg,
      });
    }

  };
  const [collapsed, setCollapsed] = useState(true);

  const openPanel = () => {
    setOpen(true);
  };
  const closePanel = () => {
    window.localStorage.removeItem("adminOption");
    setOpen(false);
  };
  function openPayment() {
    // setPaymentmodal(true);
    if (userClient.name) {
      window.location.href = `https://buy.stripe.com/test_28oeWZgcv7uqebe7ss?client_reference_id=${userClient.name}`;
    } else {
      alert("Please refresh page and try again");
    }
  }
  function closePaymentModal() {
    setPaymentmodal(false);
  }
  const alertStyles = {
    position: "fixed",
    top: "0", // Adjust the top position as needed
    right: "0", // Adjust the right position as needed
    zIndex: 9999, // Ensure the Alert appears above other elements
    width: "100%",
  };
  // console.log("current balance", currentBalance);
  return (
    <>
      {lowbalance && (
        <>
          {trailLeft === false && (
            <div style={alertStyles}>
              <Alert
                message="Warning"
                description="Account balance Low.Please recharge your account to continue using the dashboard"
                type="warning"
                showIcon
                closable
              />
            </div>
          )}
        </>
      )}

      {open ? (
        <AdminPanel closePanel={closePanel} handleLogout={handleLogout} />
      ) : (
        <>
          {contextHolder}
          <Layout
            style={{
              minHeight: "100vh",
            }}
          >
            <Sider
              theme="light"
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                setCollapsed(broken);
              }}
              onClick={toggleCollapsed}
              collapsed={collapsed}
              className="sidebar"
              style={{
                position: "fixed",
                height: "100%",
                left: 0,
                zIndex: 15,
              }}
            >
              <div className="flex flex-col justify-between h-screen">
                <div className="flex flex-col gap-3 items-start">
                  <div className="flex justify-start pl-5 w-full h-16">
                    <img
                      className="w-4/5 cursor-pointer"
                      src={`https://unibots.b-cdn.net/managed_vp_imgs/${
                        process.env.REACT_APP_MODE === "dev"
                          ? "unibots"
                          : window.location.hostname.split(".")[1]
                      }.svg`}
                      alt="logo-icon"
                      onClick={() => handleItemClick({ key: "home" })}
                    />
                  </div>

                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "21px",
                      marginLeft: "20px",
                      letterSpacing: "0em",
                      textSlign: "left",
                      color: "#6b7280",
                    }}
                  >
                    Publisher Panel
                  </Typography>

                  <Menu
                    theme="light"
                    selectedKeys={[currOption]}
                    mode="inline"
                    items={items}
                    onClick={handleItemClick}
                  />
                </div>
              </div>
            </Sider>
            <Layout className="site-layout">
              <Header
                style={{
                  padding: "0 20px",
                  background: colorBgContainer,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  zIndex: 1,
                }}
                className="sticky lg:relative w-[100%] top-0"
              >
                <Typography className="ml-[2rem] lg:ml-0">
                  Hello {userName}
                </Typography>
                <div className="flex items-center justify-center sm:gap-4 ">
                  {isPrepaid && (
                    <>
                      {trailLeft && (
                        <p
                          className={`sm:flex justify-center items-center hidden   px-[1rem] ${
                            trailLeft < 2 ? "text-red-500" : ""
                          } `}
                        >
                          <div>
                            Trial ends in : {trailLeft}{" "}
                            {trailLeft > 1 ? "days" : "day"}{" "}
                          </div>
                        </p>
                      )}
                      <p
                        className={`sm:flex justify-center items-center hidden balance px-[1rem] ${
                          currentBalance < 5 ? "text-red-500" : ""
                        }`}
                      >
                        <div>Balance : ${currentBalance?.toFixed(2) ?? 0}</div>
                      </p>
                      <Button
                        type="primary"
                        className="mt-0 text-sm"
                        onClick={openPayment}
                      >
                        Add Money
                      </Button>
                    </>
                  )}
                  {userRole === "admin" && (
                    <Button
                      icon={<SettingOutlined />}
                      onClick={openPanel}
                      type="text"
                      shape="circle"
                      className="mt-0"
                    />
                  )}
                  <div onClick={() => setIsModalOpen(true)}>
                    {userName?.length > 0 && userType?.length > 0 && (
                      <BotAvatar
                        userName={userName}
                        userType={userType}
                        // handleLogout={handleLogout}
                      />
                    )}
                  </div>
                  <div>
                    <Button
                      danger
                      type="text"
                      icon={<LogoutOutlined />}
                      size="small"
                      onClick={handleLogout}
                    >
                      Logout
                    </Button>
                  </div>
                  {/* <Popconfirm
              placement="bottomRight"
              description={<NoticeBox />}
              icon={<></>}
              okButtonProps={{ style: { display: "none" } }}
              showCancel={false}
              style={{ padding: 0, lineHeight: 0 }}
            >
              <Button icon={<BellOutlined />} shape="circle" />
            </Popconfirm> */}
                </div>
              </Header>

              <Content
                style={{
                  margin: "0",
                  backgroundColor: "#F7F6F9",

                  display: "flex",
                }}
              >
                {/* <div
            style={{
              padding: 20,
            }}
          >
            <Typography>Dashboard</Typography>
          </div> */}
                {/* {currOption === "home" ? <LandingPage /> : currPage} */}
                <div
                  className={
                    currOption !== "home"
                      ? "landing-page-hidden"
                      : "w-full h-full flex"
                  }
                >
                  <LandingPage />
                </div>
                {currOption !== "home" && currPage}
              </Content>
              {isModalOpen && userType !== "user" && (
                <ClientLogo
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                />
              )}
              {paymentmodal && (
                <AddPayment
                  isModalOpen={paymentmodal}
                  closePaymentModal={closePaymentModal}
                />
              )}
              <Footer
                style={{
                  textAlign: "center",
                  padding: "4px 50px",
                }}
              ></Footer>
            </Layout>
          </Layout>
          <GetData />
        </>
      )}
    </>
  );
};
export default SiderLayout;
