import React from "react";
import moment from "moment-timezone";
import { Select } from "antd";

const majorTimezones = moment.tz
  .names()
  .filter(
    (tz) =>
      tz.startsWith("America/") ||
      tz.startsWith("Europe/") ||
      tz.startsWith("Asia/") ||
      tz.startsWith("Africa/") ||
      tz.startsWith("Australia/") ||
      tz.startsWith("Pacific/")
  );

function TimezoneDropdown(props) {
  const handleSelectChange = (timezone) => {
    // console.log(timezone);
    props.setSelectedTimezone(timezone);
  };

  return (
    <Select
      className="w-[175px] md:w-[200px] md:mb-3"
      size="small"
      showSearch
      placeholder="Time Zone"
      onChange={handleSelectChange}
      // style={{
      //   width: 230,
      // }}
      value={props.selectedTimezone}
    >
      {majorTimezones.map((timezone) => (
        <Select.Option key={timezone} value={timezone}>
          {timezone}
        </Select.Option>
      ))}
    </Select>
  );
}

export default TimezoneDropdown;
