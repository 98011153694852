import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  websiteList: [],
  websiteChange: true,
};

export const WebSlice = createSlice({
  name: "website",
  initialState,
  reducers: {
    updateWebsite: (state, action) => {
      state.websiteList = action.payload.websiteList;
      state.websiteChange = action.payload.websiteChange;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateWebsite, updateAllChanges } = WebSlice.actions;

export default WebSlice.reducer;
