import { Typography } from "antd";
import { LineChart, Line, ResponsiveContainer } from "recharts";
import graphData from "./graphData";
import CountUp, { useCountUp } from "react-countup";
import { useEffect, useState } from "react";
import Counter from "./Counter";

function StatsGraph(props) {
  return (
    <div
      className="m-2 rounded-lg p-1 ]"
      style={{ backgroundColor: props.bgColor }}
    >
      {/* {props.dataKey === "Ad Requests" &&
        console.log("old val, new val: ", props.value.old, props.value.new)} */}
      <div>
        <Typography
          className="ml-1 mb-2"
          style={{ fontFamily: "Poppins", color: props.textColor }}
          level={5}
        >
          {props.title}
        </Typography>
      </div>
      <div className="block ">
        <div className="inline-block w-full ml-1 align-middle mb-2">
          <Typography
            className="text-md"
            style={{ fontFamily: "Poppins", color: props.textColor }}
            level={5}
          >
            {props.dollar && "$"}
            {props.value.old ? (
              <CountUp
                decimals={props.decimal ? 2 : 0}
                start={Number(props.value.old).toFixed(props.decimal ? 2 : 0)}
                end={Number(props.value.new).toFixed(props.decimal ? 2 : 0)}
                duration={118}
                // duration={8}
              />
            ) : (
              // <Counter
              //   decimalPlaces={props.decimal ? 2 : 0}
              //   start={Number(props.value.old).toFixed(props.decimal ? 2 : 0)}
              //   end={Number(props.value.new).toFixed(props.decimal ? 2 : 0)}
              //   interval={8000}
              // />
              Number(props.value.new).toFixed(props.decimal ? 2 : 0)
            )}
            {props.percent ? "%" : null}
          </Typography>
        </div>
        <div className="w-full h-8 flex justify-center">
          <div className="w-full h-full">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={props.data}>
                <Line
                  dataKey={props.dataKey}
                  stroke={props.graphColor}
                  strokeWidth={1}
                  // activeDot={false}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsGraph;
