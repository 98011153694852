import React from "react";
import { Select, Form, Typography, Divider, Switch, Tooltip } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

const DeviceSpecificKV = (props) => {
  return (
    <>
      <div className="mt-[-20px] mb-10">
        <div className="flex flex-col w-full ">
          <div className="flex justify-between">
            <div className="flex gap-10 w-[500px]">
              <div>
                <Typography className="font-bold">Country:</Typography>
                <Form.Item>
                  <Select
                    dropdownMatchSelectWidth={true}
                    value={props.item.COUNTRY}
                    showSearch
                    onChange={(value) => {
                      props.handleChange(value, props.i);
                    }}
                    placeholder="Select Country"
                    filterOption={(inputValue, option) => {
                      return (
                        option.value
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()) ||
                        option.children
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      );
                    }}
                  >
                    {props.codes.map((code) => (
                      <Select.Option key={code.value}>
                        {code.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="mt-[20px]">
                <Tooltip
                  placement="bottom"
                  title={
                    props.deviceVolume[props.i]
                      ? "Once enabled, it cannot be disabled. Only you can edit or delete the entire field."
                      : ""
                  }
                >
                  <Form.Item label={"Device Volume Specific KV"}>
                    <Switch
                      checked={props.deviceVolume[props.i]}
                      onChange={(value) =>
                        props.handleDeviceVolumeChange(value, props.i)
                      }
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            </div>
            <div className="mr-4 mt-4">
              <DeleteTwoTone
                twoToneColor="#DC2626"
                onClick={(e) => props.handleDelete(props.i)}
                style={{ fontSize: "20px" }}
              />
            </div>
          </div>
        </div>

        {props.deviceVolume[props.i] ? (
          <>
            <div className="w-full bg-slate-50 rounded-2xl mb-2">
              <div className="flex flex-col">
                <div className="w-[95%] flex gap-6 p-2 justify-center">
                  <div className="w-[45%]">
                    <Typography>Main PC Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.MAIN && props.item.MAIN.PC
                            ? props.item.MAIN.PC.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange("MAIN_PC_MUTED", value, props.i);
                        }}
                        mode="multiple"
                        placeholder="Select Main PC Muted"
                        showSearch
                        dropdownMatchSelectWidth={true}
                      >
                        {props.kvdata &&
                          props.kvdata.mainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>Main PC Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.MAIN && props.item.MAIN.PC
                            ? props.item.MAIN.PC.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "MAIN_PC_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Main PC Unmuted"
                        showSearch
                        dropdownMatchSelectWidth={true}
                      >
                        {props.kvdata &&
                          props.kvdata.mainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>Main MB Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.MAIN && props.item.MAIN.MB
                            ? props.item.MAIN.MB.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange("MAIN_MB_MUTED", value, props.i);
                        }}
                        mode="multiple"
                        placeholder="Select Main MB Muted"
                        showSearch
                        dropdownMatchSelectWidth={true}
                      >
                        {props.kvdata &&
                          props.kvdata.mainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>Main MB Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.MAIN && props.item.MAIN.MB
                            ? props.item.MAIN.MB.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "MAIN_MB_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Main MB Unmuted"
                        showSearch
                        dropdownMatchSelectWidth={true}
                      >
                        {props.kvdata &&
                          props.kvdata.mainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="w-[95%] flex gap-6 p-2 justify-center mt-[-30px] mb-[-20px]">
                  <div className="w-[45%] ">
                    <Typography>Lower Limit PC Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_MAIN &&
                          props.item.LOWER_LIMIT_MAIN.PC
                            ? props.item.LOWER_LIMIT_MAIN.PC.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_MAIN_PC_MUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit Main PC Muted"
                      >
                        {props.kvdata &&
                          props.kvdata.mainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>Lower Limit PC Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_MAIN &&
                          props.item.LOWER_LIMIT_MAIN.PC
                            ? props.item.LOWER_LIMIT_MAIN.PC.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_MAIN_PC_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit Main PC Unmuted"
                      >
                        {props.kvdata &&
                          props.kvdata.mainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%] ">
                    <Typography>Lower Limit Mob Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_MAIN &&
                          props.item.LOWER_LIMIT_MAIN.MB
                            ? props.item.LOWER_LIMIT_MAIN.MB.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_MAIN_MB_MUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit Main MB Muted "
                      >
                        {props.kvdata &&
                          props.kvdata.mainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>Lower Limit Mob Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_MAIN &&
                          props.item.LOWER_LIMIT_MAIN.MB
                            ? props.item.LOWER_LIMIT_MAIN.MB.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_MAIN_MB_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit Main MB Unmuted"
                      >
                        {props.kvdata &&
                          props.kvdata.mainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full bg-slate-100 rounded-2xl mb-2 ">
              <div className="flex flex-col">
                <div className="w-[95%] flex gap-6 p-2 justify-center">
                  <div className="w-[45%]">
                    <Typography>New Main PC Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.NEW_MAIN && props.item.NEW_MAIN.PC
                            ? props.item.NEW_MAIN.PC.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "NEW_MAIN_PC_MUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select New Main PC Muted"
                      >
                        {props.kvdata &&
                          props.kvdata.newmainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>New Main PC Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.NEW_MAIN && props.item.NEW_MAIN.PC
                            ? props.item.NEW_MAIN.PC.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "NEW_MAIN_PC_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select New Main PC Unmuted"
                      >
                        {props.kvdata &&
                          props.kvdata.newmainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>New Main Mob Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.NEW_MAIN && props.item.NEW_MAIN.MB
                            ? props.item.NEW_MAIN.MB.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "NEW_MAIN_MB_MUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select New Main MB Muted"
                      >
                        {props.kvdata &&
                          props.kvdata.newmainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>New Main Mob Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.NEW_MAIN && props.item.NEW_MAIN.MB
                            ? props.item.NEW_MAIN.MB.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "NEW_MAIN_MB_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select New Main MB Unmuted"
                      >
                        {props.kvdata &&
                          props.kvdata.newmainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="w-[95%] flex gap-6 p-2 justify-center mt-[-30px] mb-[-20px]">
                  <div className="w-[45%] ">
                    <Typography>Lower Limit PC Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_NEW_MAIN &&
                          props.item.LOWER_LIMIT_NEW_MAIN.PC
                            ? props.item.LOWER_LIMIT_NEW_MAIN.PC.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_NEW_MAIN_PC_MUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit New Main PC Muted"
                      >
                        {props.kvdata &&
                          props.kvdata.newmainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>Lower Limit PC Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_NEW_MAIN &&
                          props.item.LOWER_LIMIT_NEW_MAIN.PC
                            ? props.item.LOWER_LIMIT_NEW_MAIN.PC.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_NEW_MAIN_PC_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit New Main PC Unmuted"
                      >
                        {props.kvdata &&
                          props.kvdata.newmainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%] ">
                    <Typography>Lower Limit Mob Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_NEW_MAIN &&
                          props.item.LOWER_LIMIT_NEW_MAIN.MB
                            ? props.item.LOWER_LIMIT_NEW_MAIN.MB.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_NEW_MAIN_MB_MUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit New Main MB Muted"
                      >
                        {props.kvdata &&
                          props.kvdata.newmainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>Lower Limit Mob Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_NEW_MAIN &&
                          props.item.LOWER_LIMIT_NEW_MAIN.MB
                            ? props.item.LOWER_LIMIT_NEW_MAIN.MB.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_NEW_MAIN_MB_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit New Main MB Unmuted"
                      >
                        {props.kvdata &&
                          props.kvdata.newmainkv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full bg-slate-100 rounded-2xl mb-2 ">
              <div className="flex flex-col">
                <div className="w-[95%] flex gap-6 p-2 justify-center">
                  <div className="w-[45%]">
                    <Typography>CR ADS PC Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.CR_ADS && props.item.CR_ADS.PC
                            ? props.item.CR_ADS.PC.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "CR_ADS_PC_MUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select New CR ADS PC Muted"
                      >
                        {props.kvdata &&
                          props.kvdata.cradskv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>CR ADS PC Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.CR_ADS && props.item.CR_ADS.PC
                            ? props.item.CR_ADS.PC.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "CR_ADS_PC_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select CR ADS PC Unmuted"
                      >
                        {props.kvdata &&
                          props.kvdata.cradskv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>CR ADS Mob Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.CR_ADS && props.item.CR_ADS.MB
                            ? props.item.CR_ADS.MB.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "CR_ADS_MB_MUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select CR ADS MB Muted"
                      >
                        {props.kvdata &&
                          props.kvdata.cradskv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>CR ADS Mob Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.CR_ADS && props.item.CR_ADS.MB
                            ? props.item.CR_ADS.MB.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "CR_ADS_MB_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select CR ADS MB Unmuted"
                      >
                        {props.kvdata &&
                          props.kvdata.cradskv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="w-[95%] flex gap-6 p-2 justify-center mt-[-30px] mb-[-20px]">
                  <div className="w-[45%] ">
                    <Typography>Lower Limit PC Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_CR_ADS &&
                          props.item.LOWER_LIMIT_CR_ADS.PC
                            ? props.item.LOWER_LIMIT_CR_ADS.PC.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_CR_ADS_PC_MUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit New Main PC Muted"
                      >
                        {props.kvdata &&
                          props.kvdata.cradskv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>Lower Limit PC Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_CR_ADS &&
                          props.item.LOWER_LIMIT_CR_ADS.PC
                            ? props.item.LOWER_LIMIT_CR_ADS.PC.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_CR_ADS_PC_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit New Main PC Unmuted"
                      >
                        {props.kvdata &&
                          props.kvdata.cradskv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%] ">
                    <Typography>Lower Limit Mob Muted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_CR_ADS &&
                          props.item.LOWER_LIMIT_CR_ADS.MB
                            ? props.item.LOWER_LIMIT_CR_ADS.MB.MUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_CR_ADS_MB_MUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit New Main MB Muted"
                      >
                        {props.kvdata &&
                          props.kvdata.cradskv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="w-[45%]">
                    <Typography>Lower Limit Mob Unmuted:</Typography>
                    <Form.Item>
                      <Select
                        value={
                          props.item.LOWER_LIMIT_CR_ADS &&
                          props.item.LOWER_LIMIT_CR_ADS.MB
                            ? props.item.LOWER_LIMIT_CR_ADS.MB.UNMUTED
                            : []
                        }
                        onChange={(value) => {
                          props.handleKvChange(
                            "LOWER_LIMIT_CR_ADS_MB_UNMUTED",
                            value,
                            props.i
                          );
                        }}
                        mode="multiple"
                        placeholder="Select Lower Limit New Main MB Unmuted"
                      >
                        {props.kvdata &&
                          props.kvdata.cradskv.map((data) => {
                            return (
                              <Select.Option value={data}>{data}</Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full bg-slate-100 rounded-2xl">
              <div className="w-[90%] flex gap-6 mt-[-20px] p-2 justify-center">
                <div className="w-[45%]">
                  <Typography>Main PC:</Typography>
                  <Form.Item>
                    <Select
                      value={props.item.MAIN ? props.item.MAIN.PC : []}
                      onChange={(value) => {
                        props.handleKvChange("MAIN_PC", value, props.i);
                      }}
                      mode="multiple"
                      placeholder="Select Main PC"
                      showSearch
                      dropdownMatchSelectWidth={true}
                    >
                      {props.kvdata &&
                        props.kvdata.mainkv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="w-[45%]">
                  <Typography>Main Mob:</Typography>
                  <Form.Item>
                    <Select
                      value={props.item.MAIN ? props.item.MAIN.MB : []}
                      onChange={(value) => {
                        props.handleKvChange("MAIN_MOB", value, props.i);
                      }}
                      mode="multiple"
                      placeholder="Select Main MOB "
                    >
                      {props.kvdata &&
                        props.kvdata.mainkv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="w-[90%] flex gap-6 mt-[-20px] p-2 justify-center">
                <div className="w-[45%] ">
                  <Typography>Lower Limit Main PC:</Typography>
                  <Form.Item>
                    <Select
                      value={
                        props.item.LOWER_LIMIT_MAIN
                          ? props.item.LOWER_LIMIT_MAIN.PC
                          : []
                      }
                      onChange={(value) => {
                        props.handleKvChange(
                          "LOWER_LIMIT_MAIN_PC",
                          value,
                          props.i
                        );
                      }}
                      mode="multiple"
                      placeholder="Select Lower Limit Main PC "
                    >
                      {props.kvdata &&
                        props.kvdata.mainkv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="w-[45%]">
                  <Typography>Lower Limit Main Mob:</Typography>
                  <Form.Item>
                    <Select
                      value={
                        props.item.LOWER_LIMIT_MAIN
                          ? props.item.LOWER_LIMIT_MAIN.MB
                          : []
                      }
                      onChange={(value) => {
                        props.handleKvChange(
                          "LOWER_LIMIT_MAIN_MOB",
                          value,
                          props.i
                        );
                      }}
                      mode="multiple"
                      placeholder="Select Lower Limit Main MOB"
                    >
                      {props.kvdata &&
                        props.kvdata.mainkv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="w-full bg-slate-100 rounded-2xl">
              <div className="w-[90%] flex gap-6 mt-[-20px] p-2 justify-center">
                <div className="w-[45%]">
                  <Typography>New Main PC:</Typography>
                  <Form.Item>
                    <Select
                      value={props.item.NEW_MAIN ? props.item.NEW_MAIN.PC : []}
                      onChange={(value) => {
                        props.handleKvChange("NEW_MAIN_PC", value, props.i);
                      }}
                      mode="multiple"
                      placeholder="Select New Main PC"
                    >
                      {props.kvdata &&
                        props.kvdata.newmainkv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="w-[45%]">
                  <Typography>New Main Mob:</Typography>
                  <Form.Item>
                    <Select
                      value={props.item.NEW_MAIN ? props.item.NEW_MAIN.MB : []}
                      onChange={(value) => {
                        props.handleKvChange("NEW_MAIN_MOB", value, props.i);
                      }}
                      mode="multiple"
                      placeholder="Select New Main MOB"
                    >
                      {props.kvdata &&
                        props.kvdata.newmainkv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="w-[90%] flex gap-6 mt-[-20px] p-2 justify-center">
                <div className="w-[45%] ">
                  <Typography>Lower Limit New Main PC:</Typography>
                  <Form.Item>
                    <Select
                      value={
                        props.item.LOWER_LIMIT_NEW_MAIN
                          ? props.item.LOWER_LIMIT_NEW_MAIN.PC
                          : []
                      }
                      onChange={(value) => {
                        props.handleKvChange(
                          "LOWER_LIMIT_NEW_MAIN_PC",
                          value,
                          props.i
                        );
                      }}
                      mode="multiple"
                      placeholder="Select Lower Limit New Main PC"
                    >
                      {props.kvdata &&
                        props.kvdata.newmainkv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="w-[45%]">
                  <Typography>Lower Limit New Main Mob:</Typography>
                  <Form.Item>
                    <Select
                      value={
                        props.item.LOWER_LIMIT_NEW_MAIN
                          ? props.item.LOWER_LIMIT_NEW_MAIN.MB
                          : []
                      }
                      onChange={(value) => {
                        props.handleKvChange(
                          "LOWER_LIMIT_NEW_MAIN_MOB",
                          value,
                          props.i
                        );
                      }}
                      mode="multiple"
                      placeholder="Select Lower Limit New Main MOB"
                    >
                      {props.kvdata &&
                        props.kvdata.newmainkv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="w-full bg-slate-100 rounded-2xl">
              <div className="w-[90%] flex gap-6 mt-[-20px] p-2 justify-center">
                <div className="w-[45%]">
                  <Typography>CR ADS PC:</Typography>
                  <Form.Item>
                    <Select
                      value={props.item.CR_ADS ? props.item.CR_ADS.PC : []}
                      onChange={(value) => {
                        props.handleKvChange("CR_ADS_PC", value, props.i);
                      }}
                      mode="multiple"
                      placeholder="Select CR ADS PC"
                    >
                      {props.kvdata &&
                        props.kvdata.cradskv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="w-[45%]">
                  <Typography>CR ADS Mob:</Typography>
                  <Form.Item>
                    <Select
                      value={props.item.CR_ADS ? props.item.CR_ADS.MB : []}
                      onChange={(value) => {
                        props.handleKvChange("CR_ADS_MOB", value, props.i);
                      }}
                      mode="multiple"
                      placeholder="Select CR ADS Mob"
                    >
                      {props.kvdata &&
                        props.kvdata.cradskv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="w-[90%] flex gap-6 mt-[-20px] p-2 justify-center">
                <div className="w-[45%] ">
                  <Typography>Lower Limit CR ADS PC:</Typography>
                  <Form.Item>
                    <Select
                      value={
                        props.item.LOWER_LIMIT_CR_ADS
                          ? props.item.LOWER_LIMIT_CR_ADS.PC
                          : []
                      }
                      onChange={(value) => {
                        props.handleKvChange(
                          "LOWER_LIMIT_CR_ADS_PC",
                          value,
                          props.i
                        );
                      }}
                      mode="multiple"
                      placeholder="Select Lower Limit CR ADS PC"
                    >
                      {props.kvdata &&
                        props.kvdata.cradskv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="w-[45%]">
                  <Typography>Lower Limit CR ADS Mob:</Typography>
                  <Form.Item>
                    <Select
                      value={
                        props.item.LOWER_LIMIT_CR_ADS
                          ? props.item.LOWER_LIMIT_CR_ADS.MB
                          : []
                      }
                      onChange={(value) => {
                        props.handleKvChange(
                          "LOWER_LIMIT_CR_ADS_MOB",
                          value,
                          props.i
                        );
                      }}
                      mode="multiple"
                      placeholder="Select Lower Limit CR ADS MOB"
                    >
                      {props.kvdata &&
                        props.kvdata.cradskv.map((data) => {
                          return (
                            <Select.Option value={data}>{data}</Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Divider className="mt-[-30px]" />
    </>
  );
};

export default DeviceSpecificKV;
