import React, { useState, useEffect } from "react";
import { Button, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getClient } from "../../../API/Clients";
function DefaultContent(props) {
  const [fields, setFields] = useState([""]);

  useEffect(() => {
    setFields([...props.content?.defaultUrls]);
  }, [props.content]);
  const addField = () => {
    setFields([...fields, ""]);
  };

  const removeField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
    props.defaultClientUrls(updatedFields);
  };

  const handleChange = (index, value) => {
    const updatedFields = [...fields];
    updatedFields[index] = value;
    setFields(updatedFields);
    props.defaultClientUrls(updatedFields);
  };

  return (
    <div>
      <p>Default Content</p>
      <div className="bg-[#F4F4F5] w-[100%] h-[26.5rem] p-2">
        <p className="font-medium text-[#A1A1AA]">Hosted Content URL</p>
        <div className="gap-2 flex flex-col">
          {fields.map((field, index) => (
            <div key={index} className="flex gap-4">
              <Input
                key={index}
                value={field}
                onChange={(e) => handleChange(index, e.target.value)}
              />
              {fields.length > 1 ? (
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => removeField(index)}
                />
              ) : null}
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4">
          <Button onClick={addField}>Add Field</Button>
        </div>
      </div>
    </div>
  );
}

export default DefaultContent;
