import axios from "../axiosInstance";

const getAdunitData = async (siteData) => {
  // console.log(siteData);
  try {
    const res = await axios({
      url: "adunit/adunit_DB",
      method: "POST",
      data: {
        dateStart: siteData.startDate,
        dateEnd: siteData.endDate,
        client: siteData.client,
        pub: siteData.pub,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const updateGamData = async (gamData) => {
  console.log("API se gamData: ", gamData);
  try {
    const res = await axios({
      url: "adunit/add_adunits",
      method: "POST",
      data: {
        nameM: gamData.adNameM,
        nameNM: gamData.adNameNM,
        idM: gamData.adIdM,
        idNM: gamData.adIdNM,
        GA: gamData.GaPropId,
        url: gamData.url,
        client: gamData.client,
        isPlacement: gamData.placement,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

export { getAdunitData, updateGamData };
