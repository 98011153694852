import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Switch, Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const Xml = (props) => {
  const userName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const userRole = useSelector((state) => state.user.role);

  const [selectAll, setSelectAll] = useState({
    allowed: false,
    disallowed: false,
  });
  return (
    <>
      {" "}
      {(userName === "unibots" ||
        userType === "user" ||
        userName === "test90" ||
        userName === "adgebra" ||
        userName === "test4") && (
        <Form.Item
          label="Enable XML Ad"
          name="XML"
          valuePropName="checked"
          style={{ display: "inline-block" }}
          className="sm:w-[30%]	w-[50%]"
        >
          <Switch />
        </Form.Item>
      )}
      {((userType === "user" && userRole === "admin") ||
        userName === "test90") && (
        <div className="flex gap-5">
          <Form.Item
            name={["ALLOWED", "XML"]}
            label="Allowed Countries"
            style={{ width: "50%" }}
          >
            <Select
              style={{ maxHeight: "200px", overflow: "auto" }}
              mode="multiple"
              allowClear
              placeholder="Select allowed countries"
              showSearch
              onChange={(value) => {
                if (value.includes("all")) {
                  props.form.setFieldValue(
                    ["ALLOWED", "XML"],
                    props.countryCodes.map((code) => code.value)
                  );
                  setSelectAll((prev) => {
                    return { ...prev, allowed: true };
                  });
                } else if (value.includes("remove")) {
                  props.form.setFieldValue(["ALLOWED", "XML"], []);
                  setSelectAll((prev) => {
                    return { ...prev, allowed: false };
                  });
                } else {
                  setSelectAll((prev) => {
                    return { ...prev, allowed: false };
                  });
                }
              }}
              filterOption={(inputValue, option) => {
                return (
                  option.value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  option.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                );
              }}
            >
              <Select.Option value={selectAll.allowed ? "remove" : "all"}>
                {selectAll.allowed ? "Remove All" : "Select All"}
              </Select.Option>
              {props.countryCodes.map((code) => {
                return (
                  <Select.Option value={code.value}>{code.label}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name={["DISALLOWED", "XML"]}
            label="Disallowed Countries"
            style={{ width: "50%" }}
          >
            <Select
              mode="multiple"
              style={{ maxHeight: "200px", overflow: "auto" }}
              placeholder="Select disallowed countries"
              showSearch
              onChange={(value) => {
                if (value.includes("all")) {
                  props.form.setFieldValue(
                    ["DISALLOWED", "XML"],
                    props.countryCodes.map((code) => code.value)
                  );
                  setSelectAll((prev) => {
                    return { ...prev, disallowed: true };
                  });
                } else if (value.includes("remove")) {
                  props.form.setFieldValue(["DISALLOWED", "XML"], []);
                  setSelectAll((prev) => {
                    return { ...prev, disallowed: false };
                  });
                } else {
                  setSelectAll((prev) => {
                    return { ...prev, allowed: false };
                  });
                }
              }}
              filterOption={(inputValue, option) => {
                return (
                  option.value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  option.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                );
              }}
            >
              <Select.Option value={selectAll.disallowed ? "remove" : "all"}>
                {selectAll.disallowed ? "Remove All" : "Select All"}
              </Select.Option>
              {props.countryCodes.map((code) => {
                return (
                  <Select.Option value={code.value}>{code.label}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      )}
      {(userName === "unibots" ||
        userType === "user" ||
        userName === "test90" ||
        userName === "adgebra" ||
        userName === "test4") && (
        <div className="flex gap-2">
          <Button
            onClick={() => {
              let flag = props.dataTable.some(
                (tag) =>
                  props.selectedTags.includes(tag.AD_TAG_NAME) &&
                  tag.VAST_TAG_TYPE === "XML"
              );
              if (flag) {
                props.error("Cannot create XML from XML tags!");
              } else {
                props.setOpenXml(true);
                props.setEditXml(false);
              }
            }}
            icon={<PlusOutlined />}
            className="adTagBtn"
          >
            Add XML
          </Button>
          <Button
            onClick={() => {
              props.setOpenXml(true);
              props.setEditXml(true);
            }}
          >
            Edit XML
          </Button>
        </div>
      )}
    </>
  );
};

export default Xml;
