import React, { useEffect, useState } from "react";
import { theme, Form, Input, Modal, Switch, InputNumber, Checkbox } from "antd";
import { postClient, patchClient } from "../../../API/Clients";
import { DatePicker } from "antd";
import { updateAll } from "../../../API/Websites";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { updateClient } from "../../../redux/reducers/clientSlice";
import { updateWebsite } from "../../../redux/reducers/WebSlice";

const { RangePicker } = DatePicker;
const CreateClient = (props) => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.user.role);
  const clientData = useSelector((state) => state.client);
  const website = useSelector((state) => state.website);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const formItems = [
    [
      {
        label: "User Name",
        name: "name",
        placeholder: "Enter user name",
        rules: [
          {
            required: true,
            message: "User name is required!",
          },
        ],
      },
      {
        label: "Email",
        name: "email",
        placeholder: "Enter email",
        rules: [
          {
            type: "email",
            required: true,
            message: "The input is not valid E-mail!",
          },
        ],
      },
    ],
    [
      {
        label: "Account Login",
        name: "username",
        placeholder: "Enter username",
        rules: [
          {
            required: true,
            message: "Username Required",
          },
        ],
      },
      {
        label: "Account Password",
        name: "password",
        placeholder: "Enter password",
        rules: [
          {
            required: !props.edit,
            message: "Please input your password!",
          },
          {
            min: 12,
            message: "Password must be at least 12 characters long",
          },
          {
            pattern:
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/,
            message:
              "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (#?!@$%^&*-)",
          },
        ],
      },
      {
        label: "Confirm Password",
        placeholder: "Confirm password",
        name: "confpass",
        dependencies: ["password"],
        rules: [
          {
            required: !props.edit,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ],
      },
    ],
  ];

  useEffect(() => {
    if (props.edit) {
      setClientLogo(props?.data?.clientLogo ?? false);
      form.setFieldsValue(props.data);
      form.setFieldValue("password", "");
      form.setFieldValue("cdnEnabled", props?.data?.cdnEnabled ?? true);
    }
  }, [props.edit, props.data]);

  const handleSubmit = () => {
    // setConfirmLoading(true);
    let data = form.getFieldsValue();
    let sites = data?.websites?.map((site) => {
      return JSON.parse(site);
    });
    if (sites) {
      data["websites"] = sites;
    }
    if (userRole === "admin") {
      data["userrole"] = "admin";
    }
    handleOk(data);
  };
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [clientLogo, setClientLogo] = useState(false);
  const handleCancel = () => {
    if (props.setOpen) {
      props.setOpen((prev) => false);
    }
  };
  const handleChange = (value) => {
    setClientLogo(value);
  };
  const handleClose = () => {
    props.setModalOpen(false);
  };

  const handleOk = async (data) => {
    if (!props.edit) {
      const res = await postClient(data);
      if (!res.err) {
        props.successful("Client added successfully!");
      } else {
        props.fail("Client not added!");
      }
    } else {
      const res = await patchClient(data);
      if (!res.err) {
        props.successful("Client modified successfully!");
      } else {
        props.fail("Client not modified!");
      }
    }
    let webUpdate = {
      client: data.name,
      cdnLink: data.cdnLink,
      clientLogo: data.clientLogo,
      setLogoAll: form.getFieldValue("set_all"),
      cdnEnabled: data.cdnEnabled,
      logoUrl: data.logoUrl,
      redirectLink: data.redirectLink,
      linkFlag: true,
    };
    const response = await updateAll(webUpdate);

    handleCancel();
    props.setClientChange((prev) => !prev);
    dispatch(updateClient({ ...clientData, clientChange: true }));
    dispatch(updateWebsite({ ...website, websiteChange: true }));
  };
  return (
    <Modal
      title={props.edit ? "Edit Client" : "Add Client"}
      open={props.open}
      confirmLoading={confirmLoading}
      style={{ maxWidth: "100%" }}
      width={800}
      bodyStyle={{
        height: 500,
        overflowY: "auto",
        paddingTop: "20px",
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit();
          })
          .catch((error) => {
            console.log(error);
          });
      }}
      onCancel={handleCancel}
      afterClose={handleClose}
      centered={true}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        form={form}
      >
        {formItems[0].map((item) => {
          return (
            <Form.Item name={item.name} label={item.label} rules={item.rules}>
              <Input placeholder={item.placeholder} />
            </Form.Item>
          );
        })}
        {formItems[1].map((item) => {
          return (
            <Form.Item
              name={item.name}
              label={item.label}
              rules={item?.rules}
              dependencies={item?.dependencies}
            >
              {item.name === "password" ? (
                <Input.Password />
              ) : (
                <Input placeholder={item.placeholder} />
              )}
            </Form.Item>
          );
        })}
        <div className="flex px-11 gap-5">
          <Form.Item
            name="clientLogo"
            label="Client Logo Enable"
            labelCol={8}
            valuePropName="checked"
            style={{ width: "40%" }}
          >
            <Switch onChange={handleChange} />
          </Form.Item>
          <Form.Item
            name="set_all"
            valuePropName="checked"
            initialValue={false}
            style={{ width: "100%" }}
          >
            <Checkbox>Set this for all websites?</Checkbox>
          </Form.Item>
        </div>
        {clientLogo && (
          <>
            <Form.Item name="logoUrl" label="Logo Url">
              <Input />
            </Form.Item>
            <Form.Item name="redirectLink" label="Redirect Link">
              <Input />
            </Form.Item>
          </>
        )}
        <Form.Item name="cdnEnabled" label="Enable Cdn" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="cdnLink" label="Custom Cdn Link">
          <Input />
        </Form.Item>
        <Form.Item name="isPrepaid" label="Is Prepaid" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="balanceLimit" label="Balance Limit">
          <InputNumber />
        </Form.Item>
        <Form.Item name="trialPeriod" label="trial Period">
          <RangePicker
            defaultValue={
              props.data?.startTrial && props.data?.endTrial
                ? [dayjs(props.data.startTrial), dayjs(props.data.endTrial)]
                : null
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateClient;
