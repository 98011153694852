import React, { useEffect, useState } from "react";
import {
  Space,
  Table,
  Button,
  message,
  Popconfirm,
  Badge,
  Form,
  Select,
  Input,
  Tooltip,
  Tabs,
  Divider,
} from "antd";

import { SettingTwoTone, QuestionCircleOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import Bidders from "./Bidders";
import { InfoCircleTwoTone } from "@ant-design/icons";
import UploadTags from "./UploadTags";
import Google from "./AdTypes/Google";
import Direct from "./AdTypes/Direct";
import Prebid from "./AdTypes/Prebid";
import Xml from "./AdTypes/Xml";
import { useSelector } from "react-redux";

const AdSources = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [popOpen, setPopOpen] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [adType, setAdType] = useState(
    localStorage.getItem("adtype") ?? "google"
  );
  const { Search } = Input;
  const [selectedRows, setSelectedRows] = useState([]);
  const [checked, setChecked] = useState(true);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const userName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const error = (msg) => {
    messageApi.open({ type: "error", content: msg });
  };

  const checkXml = (key) => {
    let xml = {};
    let found = props.xmlData.some((data) => {
      xml = data;
      return data.TAGS.includes(key);
    });
    return { found, xml };
  };
  const handleConfirm = (key) => {
    const { found, xml } = checkXml(key);
    if (!found) {
      setConfirmLoading(true);
      let popconf = {};
      popconf[key] = false;
      setTimeout(() => {
        setPopOpen(popconf);
        setConfirmLoading(false);
        if (key) {
          handleDelete(key);
        } else {
          handleDeleteMulti();
        }

        messageApi.open({
          type: "success",
          content: "Archived!",
          className: "custom-class",
          style: {
            marginTop: "80vh",
          },
        });
      }, 500);
    } else {
      messageApi.open({
        type: "error",
        content: `Remove this tag from ${xml.NAME}.xml first`,
      });
    }
  };

  const [dataTable, setDataTable] = useState(props.adSourceData);

  const handleDelete = (key) => {
    const newData = dataTable.filter((item) => item.AD_TAG_NAME !== key);
    props.setAdSourceData(newData);
    setDataTable(newData);
  };

  const handleDeleteMulti = () => {
    const newData = dataTable.filter(
      (item) => !selectedRows.includes(item.AD_TAG_NAME)
    );
    props.setAdSourceData(newData);
    setDataTable(newData);
  };

  const handlePopConfirm = (key) => {
    let popconf = {};
    popconf[key] = true;
    setPopOpen(popconf);
  };

  const handleCancel = (key) => {
    let popconf = {};
    popconf[key] = false;
    setPopOpen(popconf);
  };

  const handleEdit = (name) => {
    let index;
    dataTable.forEach((row, i) => {
      if (row.AD_TAG_NAME === name) {
        index = i;
      }
    });
    props.setEditTagIndex(index + currentPage * pageSize);
    props.setEditTag(true);
    props.setOpenAdTags(true);
    props.setMultiEditTag(null);

    // alert("Key of concerned row: " + key)
    // e.preventDefault();
  };

  const handleMultiEdit = () => {
    props.setEditTag(true);
    props.setOpenAdTags(true);
    props.setMultiEditTag(selectedRows);
  };

  useEffect(() => {
    let disableChecked = dataTable.some(
      (row) => selectedRows.includes(row.AD_TAG_NAME) && row.IS_ACTIVE === false
    );
    setChecked(!disableChecked);
  }, [selectedRows]);

  const columns = [
    {
      title: "Ad Tag Name",
      dataIndex: "AD_TAG_NAME",
      key: "AD_TAG_NAME",
      width: "15%",
    },
    {
      title: "Vast Tag Type",
      dataIndex: "VAST_TAG_TYPE",
      key: "VAST_TAG_TYPE",
      render: (_, record) => {
        return (
          <div className="flex items-center gap-1">
            {record.VAST_TAG_TYPE}
            {record?.DIRECT_CAMPAIGN && (
              <Tooltip placement="top" title={"Priority Tag"}>
                <InfoCircleTwoTone />
              </Tooltip>
            )}
          </div>
        );
      },
      width: "10%",
    },
    {
      title: "Advertiser",
      dataIndex: "ADVERTISER_NAME",
      key: "ADVERTISER_NAME",
      width: "15%",
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      width: "10%",
    },
    {
      title: "Tag Status",
      key: "IS_ACTIVE",
      sorter: (a, b) => (a.IS_ACTIVE ? 1 : -1),
      defaultSortOrder: "descend",
      render: (_, record) => {
        return (
          <Badge
            color={record["IS_ACTIVE"] ? "green" : "red"}
            text={record["IS_ACTIVE"] ? "Live" : "Not Live"}
          />
        );
      },
      width: "20%",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Space size="middle">
            <Button
              type="text"
              onClick={() => {
                handleEdit(record.AD_TAG_NAME);
              }}
              icon={<SettingTwoTone twoToneColor="#6425FE" />}
            >
              {/* Edit */}
            </Button>
            <Popconfirm
              placement="bottomRight"
              title="Confirm"
              description="Are you sure you want to delete this item?"
              open={popOpen[record.name]}
              onConfirm={() => {
                handleConfirm(record.AD_TAG_NAME);
              }}
              onCancel={() => {
                handleCancel(record.AD_TAG_NAME);
              }}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              style={{ width: "100px" }}
              okButtonProps={{ loading: confirmLoading }}
            >
              {contextHolder}
              <Button
                type="text"
                onClick={() => {
                  handlePopConfirm(record.AD_TAG_NAME);
                }}
                className="flex items-center justify-center"
                danger
                icon={
                  <Icon
                    icon="material-symbols:archive-rounded"
                    width="20"
                    height="20"
                  />
                }
              >
                {/* Archive */}
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  useEffect(() => {
    setDataTable(props.adSourceData);
    if (searchText) {
      handleSearch(searchText);
    }
    if (
      JSON.stringify(props.adSourceData) !== JSON.stringify(props.adSourceData)
    ) {
      props.setAdSourceData(props.adSourceData);
    }
  }, [props.adSourceData]);

  const [form] = Form.useForm();

  const handleFieldsChange = () => {
    let fields = form.getFieldsValue();
    props.setActiveAdData(fields);
  };
  useEffect(() => {
    props.setSelectedTags([]);
    form.setFieldsValue(props.activeAdData);
  }, []);

  const handleSearch = (text) => {
    setSearchText(text);
    let searchedTags = props.adSourceData.filter((tag) =>
      tag.AD_TAG_NAME.toLowerCase().includes(text.toLowerCase())
    );
    setDataTable(searchedTags);
  };

  const handleSelect = (record, selected) => {
    if (selected) {
      props.setSelectedTags((prev) => {
        return prev.includes(record.AD_TAG_NAME)
          ? prev
          : [...prev, record.AD_TAG_NAME];
      });
    } else {
      props.setSelectedTags((prev) =>
        prev.filter((tag) => tag !== record.AD_TAG_NAME)
      );
    }
    if (selected && !selectedRows.includes(record.AD_TAG_NAME)) {
      setSelectedRows((prev) => [...prev, record.AD_TAG_NAME]);
    } else if (!selected) {
      let rows = selectedRows.filter((tag) => {
        return tag !== record.AD_TAG_NAME;
      });
      setSelectedRows(rows);
    }
  };
  const handleSelectAll = (selected, rows) => {
    if (selected) {
      props.setSelectedTags(dataTable.map((row) => row.AD_TAG_NAME));
    } else {
      props.setSelectedTags([]);
    }
    if (selected) {
      let rows = dataTable.map((row) => {
        return row.AD_TAG_NAME;
      });
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };
  const handleEnableTags = (value) => {
    let oldData = JSON.parse(JSON.stringify(dataTable));
    let newData = oldData.map((row) => {
      if (selectedRows.includes(row.AD_TAG_NAME)) {
        row.IS_ACTIVE = value === "live";
      }
      return row;
    });
    props.setAdSourceData(newData);
  };
  const handlePageChange = (page, size) => {
    setCurrentPage(page - 1);
    setPageSize(size);
  };

  const tabs = [
    {
      key: "google",
      label: "Google",
      children: (
        <Google
          form={form}
          countryCodes={props.countryCodes}
          countryList={props.countryList}
          setCountryList={props.setCountryList}
        />
      ),
    },
    {
      key: "direct",
      label: "Direct",
      children: <Direct form={form} countryCodes={props.countryCodes} />,
    },
    userName === "unibots" ||
    userType === "user" ||
    userName === "test90" ||
    userName === "adgebra" ||
    userName === "test4"
      ? {
          key: "xml",
          label: "Xml",
          children: (
            <Xml
              form={form}
              countryCodes={props.countryCodes}
              dataTable={dataTable}
              selectedTags={props.selectedTags}
              setOpenXml={props.setOpenXml}
              setEditXml={props.setEditXml}
              error={error}
            />
          ),
        }
      : {},
    {
      key: "prebid",
      label: "Prebid",
      children: <Prebid form={form} countryCodes={props.countryCodes} />,
    },
  ];

  return (
    <div className="flex flex-col">
      <Form form={form} onFieldsChange={handleFieldsChange} layout="horizontal">
        <Tabs
          tabPosition={window.innerWidth < 640 ? "top" : "left"}
          items={tabs}
          activeKey={adType}
          onChange={(key) => {
            localStorage.setItem("adtype", key);
            setAdType(key);
          }}
        />
        <Divider />
        <div className="flex flex-col">
          {adType !== "prebid" ? (
            <>
              <div className="flex flex-col">
                <div className="flex flex-col items-start sm:items-center gap-8 sm:flex-row">
                  <div className="flex justify-between py-2">
                    <Button onClick={props.handleAddNewTag} type="primary">
                      + Add New Tag
                    </Button>
                  </div>
                  <div>
                    <UploadTags setAdSourceData={props.setAdSourceData} />
                  </div>
                  <div>
                    <Search
                      placeholder="Search for adtag name"
                      allowClear
                      className="sm:w-[320px] w-[100%]"
                      style={{ width: "100%" }}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                  {selectedRows.length > 0 && (
                    <div className="flex gap-3 sm:w-1/2 w-full">
                      <Form.Item
                        label="Enable Tags"
                        style={{ marginBottom: 0 }}
                      >
                        <Select
                          onChange={handleEnableTags}
                          placeholder="Change Status"
                          dropdownMatchSelectWidth={false}
                        >
                          <Select.Option value={"live"}>
                            <Badge color="green" text="Live" />
                          </Select.Option>
                          <Select.Option value={"notlive"}>
                            <Badge color="red" text="Not Live" />
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Button
                        type="text"
                        onClick={() => {
                          handleMultiEdit();
                        }}
                        icon={<SettingTwoTone twoToneColor="#6425FE" />}
                      >
                        {/* Edit */}
                      </Button>
                      <Popconfirm
                        placement="bottomRight"
                        title="Confirm"
                        description="Are you sure you want to delete this item?"
                        onConfirm={() => {
                          handleConfirm();
                        }}
                        onCancel={() => {
                          handleCancel();
                        }}
                        okText="Yes"
                        cancelText="No"
                        icon={
                          <QuestionCircleOutlined style={{ color: "red" }} />
                        }
                        style={{ width: "100px" }}
                        okButtonProps={{ loading: confirmLoading }}
                      >
                        {contextHolder}
                        <Button
                          type="text"
                          className="flex items-center justify-center"
                          danger
                          icon={
                            <Icon
                              icon="material-symbols:archive-rounded"
                              width="20"
                              height="20"
                            />
                          }
                        >
                          {/* Archive */}
                        </Button>
                      </Popconfirm>
                    </div>
                  )}
                </div>
                <div>
                  <Table
                    columns={columns}
                    dataSource={dataTable}
                    rowSelection={{
                      onSelect: handleSelect,
                      onSelectAll: handleSelectAll,
                    }}
                    pagination={{
                      pageSize: pageSize,
                      onChange: handlePageChange,
                    }}
                    size="small"
                    scroll={{ x: 500 }}
                    style={{ marginTop: "1rem", width: "100%" }}
                    rowKey={(row) => row.AD_TAG_NAME}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col">
              <Bidders
                initBidderData={props.initBidderData}
                setBiddersData={props.setBiddersData}
                handleCreatePlayer={props.handleCreatePlayer}
              />
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default AdSources;
