import axios from "../axiosInstance";
import { Buffer } from "buffer";

const cdnUpload = async (file, name, website, onUploadProgress, controller) => {
  try {
    const res = await axios({
      url: "cdnupload/upload",
      method: "POST",
      data: file,
      onUploadProgress: onUploadProgress,
      headers: {
        "Content-Type": "application/octet-stream", // set the Content-Type header
        "X-File-Name": name, // send the file name as a custom header
        "X-Website-Name": website, // send the website name as a custom header
      },
      signal: controller.signal,
    });
    return { err: false, msg: res.data.msg, id: res.data.id };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const cdnYtUpload = async (url, website) => {
  let obj = { url: url, website: website.url };
  try {
    const res = await axios({
      url: "cdnupload/uploadyt",
      method: "POST",
      data: obj,
    });
    return { err: false, msg: res.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const cdnLogoUpload = async (file, name, from) => {
  try {
    const res = await axios({
      url: "cdnupload/uploadLogo",
      method: "POST",
      data: Buffer.from(file),
      headers: {
        "Content-Type": "application/octet-stream",
        "X-File-Name": name,
        "X-My-Variable": from,
      },
    });
    return { err: false, msg: res.data.msg, link: res.data.link };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const getVideos = async (website) => {
  try {
    const res = await axios({
      url: "cdnupload/getvideos",
      method: "GET",
      params: { website: website },
    });
    return { err: false, videos: res.data.videos };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const deleteVideo = async (videoId) => {
  try {
    const res = await axios({
      url: "cdnupload/deletevideo",
      method: "DELETE",
      data: { videoId: videoId },
    });
    return { err: res.data.error, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const cdnScriptUpload = async (file, path, fileCheck, clientName) => {
  try {
    const res = await axios({
      url: "cdnupload/uploadscript",
      method: "POST",
      data: { file: file, path: path, fileCheck, clientName },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};
const cdnGetScript = async (name, path) => {
  try {
    const res = await axios({
      url: "cdnupload/getscript",
      method: "GET",
      params: { name: name, path: path },
    });
    return {
      err: res.data.error,
      msg: res.data.msg,
      exist: res.data.exist,
      file: res.data.file,
    };
  } catch (err) {
    return { err: true, msg: err };
  }
};
const getYtInfo = async (url) => {
  const res = await axios({
    url: "cdnupload/getytinfo",
    method: "POST",
    data: { url: url },
    validateStatus: () => true,
  });
  return { err: res.data.err, msg: res.data.msg };
};
const createCronJob = async (playerName, frequency, uploadYtToken) => {
  try {
    const res = await axios({
      url: "content/createcronjob",
      method: "POST",
      data: {
        playerName,
        frequency,
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
        day: new Date().getDay(),
        date: new Date().getDate(),
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const updateCronJob = async (jobId, frequency) => {
  try {
    const res = await axios({
      url: "content/updatecronjob",
      method: "PATCH",
      data: {
        jobId,
        frequency,
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
        day: new Date().getDay(),
        date: new Date().getDate(),
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const deleteCronJob = async (jobId) => {
  try {
    const res = await axios({
      url: "content/deletecronjob",
      method: "DELETE",
      data: { jobId },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const getCronJobs = async () => {
  try {
    const res = await axios({
      url: "content/getcronjobs",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};
export {
  cdnUpload,
  cdnYtUpload,
  cdnLogoUpload,
  getVideos,
  getYtInfo,
  deleteVideo,
  cdnScriptUpload,
  cdnGetScript,
  createCronJob,
  getCronJobs,
  deleteCronJob,
  updateCronJob,
};
