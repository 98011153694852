import React, { useEffect, useState } from "react";
import { Button, Form, Input, Alert, message, Upload, Spin } from "antd";
import { cdnScriptUpload } from "../../../API/Upload";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";

const PlayerCustom = (props) => {
  const [form] = Form.useForm(props.form);
  const [messageApi, contextHolder] = message.useMessage();
  const [change, setChange] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cssFile, setCssFile] = useState("");
  const [disabled, setDisabled] = useState(false);
  const setSaved = props.setSaved;
  const saved = props.saved;

  // const handleSubmit = () => {
  //   let fields = form.getFieldsValue();
  //   let csstext = fields.csstext;
  //   if (props.initFile !== csstext && !saved) {
  //     warning();
  //   } else {
  //     handleCancel();
  //   }
  // };
  useEffect(() => {
    form.setFieldValue("csstext", cssFile);
  }, [cssFile]);

  const handleSave = () => {
    let fields = form.getFieldsValue();
    setSaved(true);
    setLoading(true);
    let csstext = fields.csstext;
    if (csstext && csstext.length > 0) {
      const uploadCssFile = async () => {
        const response = await cdnScriptUpload(
          csstext,
          `/mvp/css/${props.currWebsite.config[0].name}.css`,
          false
        );
        if (response.err) {
          error("File not uploaded!");
        } else {
          success();
        }
        setLoading(false);
      };
      uploadCssFile();
      props.setInitFile(csstext);
    } else {
      setLoading(false);
    }
  };
  const { TextArea } = Input;
  const handleChange = () => {
    setChange((prev) => !prev);
    if (saved) {
      setSaved(false);
    }
  };
  useEffect(() => {
    let fields = form.getFieldsValue();
    props.setPlayerConfig((prev) => {
      return {
        ...prev,
        CLIENTSITE_TARGETED_DIV_ID_CLASSES:
          fields?.CLIENTSITE_TARGETED_DIV_ID_CLASSES ?? "",
      };
    });
  }, [change]);

  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg ?? "Maximum upload limit reached!",
    });
  };
  const success = (msg) => {
    messageApi.open({
      type: "success",
      content: msg ?? "File uploaded succesfully!",
    });
  };

  const handleUpload = (file) => {
    setUploadCount((prev) => prev + 1);
  };
  const handleRemove = () => {
    setUploadCount((prev) => prev - 1);
    setCssFile("");
  };
  useEffect(() => {
    if (uploadCount > 10) {
      error();
    }
  }, [uploadCount]);
  useEffect(() => {
    if (props.initFile || props.initFile.length === 0) {
      form.setFieldValue("csstext", props.initFile);
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [props.initFile]);

  useEffect(() => {
    let targetDiv = props.initConfig["CLIENTSITE_TARGETED_DIV_ID_CLASSES"];
    if (targetDiv && typeof targetDiv !== "string") {
      form.setFieldValue(
        "CLIENTSITE_TARGETED_DIV_ID_CLASSES",
        targetDiv.join("\n")
      );
    }
  }, [props.initConfig]);
  return (
    <>
      {contextHolder}
      <Alert
        message={
          "Enter one id per line without identifier ('.', '#'). Player will be present after that div id or class."
        }
        type="info"
        showIcon
        className="my-3"
      />

      <Form form={form} layout="vertical">
        <Form.Item
          name="CLIENTSITE_TARGETED_DIV_ID_CLASSES"
          label="Target on specific div id or class"
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 3 }}
            style={{ whiteSpace: "nowrap" }}
            placeholder="Enter ids here"
            onChange={handleChange}
            allowClear={true}
          />
        </Form.Item>
        {disabled && (
          <Alert
            message={"CSS file not loaded. Please refresh the page!"}
            type="error"
            showIcon
            className="my-3"
          />
        )}

        {!props.cssLoad ? (
          <Form.Item
            name="csstext"
            label={
              <div className="flex flex-col">
                <div>Add Custom CSS</div>
                {form.getFieldValue("csstext")?.length <= 0 && (
                  <p className="warning" style={{ marginLeft: 0 }}>
                    Empty file would not be saved!
                  </p>
                )}
              </div>
            }
          >
            <TextArea
              prefix={<LoadingOutlined />}
              autoSize={{ minRows: 5, maxRows: 5 }}
              style={{ whiteSpace: "nowrap" }}
              placeholder="Enter css here"
              onChange={handleChange}
              allowClear={true}
              disabled={disabled}
            />
          </Form.Item>
        ) : (
          <div className="h-[25vh] flex justify-center items-center">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                  }}
                  spin
                />
              }
            />
          </div>
        )}
        <div className="flex justify-between">
          <div style={{ width: "50%" }}>
            <Upload
              accept=".css"
              customRequest={(file) => {
                let fileSize = file.file.size / 1000;
                if (fileSize <= 100) {
                  const reader = new FileReader();
                  reader.onload = async (event) => {
                    setCssFile(event.target.result);
                    file.onSuccess("ok");
                  };
                  reader.readAsText(file.file);
                } else {
                  error("Image should be under 100 Kb");
                  file.onError("error");
                }
              }}
              disabled={disabled}
              maxCount={1}
              onRemove={handleRemove}
              beforeUpload={handleUpload}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </div>
          <Button
            disabled={disabled}
            loading={loading}
            onClick={handleSave}
            type="primary"
          >
            Save CSS
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PlayerCustom;
