import React, { useEffect, useState } from "react";
import { Collapse, Card, DatePicker, Select, Typography } from "antd";
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import dayjs from "dayjs";

const ConfigLogs = (props) => {
  const { Panel } = Collapse;
  const { RangePicker } = DatePicker;
  const changes = props.changes;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [dates, setDates] = useState({ start: null, end: null });
  const [kind, setKind] = useState("all");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const date = new Date(Date.now());
    const today =
      ("0" + date.getDate()).slice(-2) +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getFullYear();
    setDates({
      start: today,
      end: today,
    });
  }, []);

  useEffect(() => {
    if (dates.start && dates.end) {
      let obj = {};
      changes.forEach((change) => {
        let changeDate = new Date(change.date.split("-").reverse().join("-"));
        let startDate = new Date(dates.start.split("-").reverse().join("-"));
        let endDate = new Date(dates.end.split("-").reverse().join("-"));

        if (
          changeDate >= startDate &&
          changeDate <= endDate &&
          (change.kind === kind || kind === "all")
        ) {
          if (obj[change.date]) {
            obj[change.date].push(change);
          } else {
            obj[change.date] = [change];
          }
        }
      });
      setData(obj);
    }
  }, [dates, changes, kind]);

  const handleDateChange = (dates, dateStrings) => {
    const start =
      dateStrings[0].length > 0 ? dateStrings[0].split("/").join("-") : null;
    const end =
      dateStrings[1].length > 0 ? dateStrings[1].split("/").join("-") : null;
    setDates({
      start: start,
      end: end,
    });
    props.setDate && props.setDate({ start: start, end: end });
  };

  return (
    <>
      <div className="flex items-center gap-5">
        <RangePicker
          onCalendarChange={handleDateChange}
          format={"DD/MM/YYYY"}
          defaultValue={[
            dayjs.utc(new Date().toISOString().split("T")[0]),
            dayjs.utc(new Date().toISOString().split("T")[0]),
          ]}
          style={{ width: "30%" }}
        />
        <Select
          value={kind}
          placeholder="Select kind"
          onChange={(value) => {
            setKind(value);
          }}
          dropdownMatchSelectWidth={false}
        >
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="added">Added</Select.Option>
          <Select.Option value="edited">Edited</Select.Option>
          <Select.Option value="deleted">Deleted</Select.Option>
        </Select>
      </div>
      {Object.keys(data)
        .slice(0)
        .reverse()
        .map((date) => {
          return (
            <>
              <h2>Changes: {date}</h2>
              <div
                style={{
                  overflowX: "auto",
                  height: "auto",
                  maxHeight: "300px",
                }}
              >
                {data[date]
                  .slice(0)
                  .reverse()
                  .map((change) => {
                    return (
                      <>
                        <Card
                          title={
                            <div className="flex justify-between">
                              <div className="flex gap-2">
                                <div className="flex gap-1 text-slate-500">
                                  <UserOutlined />
                                  {change.user}
                                </div>
                                <div>
                                  {change.kind[0].toUpperCase() +
                                    change.kind.slice(1)}
                                </div>
                                <div className="text-green-600">
                                  {change.name}
                                </div>
                              </div>
                              <div className="flex gap-1 text-slate-500">
                                <ClockCircleOutlined />
                                {change.time}
                              </div>
                            </div>
                          }
                          size="small"
                        >
                          {change.lhs || change.rhs ? (
                            <>
                              {change.kind === "edited" ? (
                                <>
                                  {change.lhs &&
                                  JSON.stringify(change.lhs).length > 50 ? (
                                    <Collapse>
                                      <Panel
                                        header={"Description"}
                                        key={"key"}
                                        forceRender={true}
                                      >
                                        <div className="flex gap-5">
                                          <SyntaxHighlighter
                                            language="javascript"
                                            style={docco}
                                          >
                                            {JSON.stringify(
                                              change.lhs,
                                              null,
                                              4
                                            )}
                                          </SyntaxHighlighter>
                                          <div className="flex items-center">
                                            {">"}
                                          </div>
                                          <SyntaxHighlighter
                                            language="javascript"
                                            style={docco}
                                          >
                                            {JSON.stringify(
                                              change.rhs,
                                              null,
                                              4
                                            )}
                                          </SyntaxHighlighter>
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  ) : (
                                    <div className="flex gap-5">
                                      <SyntaxHighlighter
                                        language="javascript"
                                        style={docco}
                                      >
                                        {JSON.stringify(change.lhs, null, 4)}
                                      </SyntaxHighlighter>
                                      <div className="flex items-center">
                                        {">"}
                                      </div>
                                      <SyntaxHighlighter
                                        language="javascript"
                                        style={docco}
                                      >
                                        {JSON.stringify(change.rhs, null, 4)}
                                      </SyntaxHighlighter>
                                    </div>
                                  )}
                                </>
                              ) : change.kind === "added" ? (
                                <>
                                  Added new value
                                  {change.rhs &&
                                  JSON.stringify(change.rhs).length > 50 ? (
                                    <Collapse>
                                      <Panel
                                        header={"Description"}
                                        key={"key"}
                                        forceRender={true}
                                      >
                                        <SyntaxHighlighter
                                          language="javascript"
                                          style={docco}
                                        >
                                          {JSON.stringify(change.rhs, null, 4)}
                                        </SyntaxHighlighter>
                                      </Panel>
                                    </Collapse>
                                  ) : (
                                    <SyntaxHighlighter
                                      language="javascript"
                                      style={docco}
                                    >
                                      {JSON.stringify(change.rhs, null, 4)}
                                    </SyntaxHighlighter>
                                  )}
                                </>
                              ) : change.kind === "deleted" ? (
                                <>
                                  Deleted value
                                  {JSON.stringify(change.lhs).length > 50 ? (
                                    <Collapse>
                                      <Panel
                                        header={"Description"}
                                        key={"key"}
                                        forceRender={true}
                                      >
                                        <SyntaxHighlighter
                                          language="javascript"
                                          style={docco}
                                        >
                                          {JSON.stringify(change.lhs, null, 4)}
                                        </SyntaxHighlighter>
                                      </Panel>
                                    </Collapse>
                                  ) : (
                                    <SyntaxHighlighter
                                      language="javascript"
                                      style={docco}
                                    >
                                      {JSON.stringify(change.lhs, null, 4)}
                                    </SyntaxHighlighter>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </Card>
                      </>
                    );
                  })}
              </div>
            </>
          );
        })}
      {Object.keys(data).length <= 0 && (
        <div className="flex h-full w-full justify-center items-center">
          <Typography className="text-gray-500">
            No logs availabe for this time period. Try changing the date
          </Typography>
        </div>
      )}
    </>
  );
};

export default ConfigLogs;
