import React, { useEffect, useState } from "react";
import {
  theme,
  Form,
  Select,
  Tabs,
  Button,
  Alert,
  message,
  Modal,
  Affix,
  Skeleton,
} from "antd";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useSelector, useDispatch } from "react-redux";
import AdSources from "./Ads/AdSources";
import AdTag from "./Ads/AdTag";
import ContIntegeration from "./Integration/ContIntegeration";
import DrawerButton from "./Panels/DrawerButton";
import {
  getWebsites,
  patchWebsites,
  getWebsitesByPlayerConfig,
  patchChanges
} from "../../API/Websites";
import { updateWebsite } from "../../redux/reducers/WebSlice";
import { getDfpCode } from "./Config/dfpscript";
import { cdnScriptUpload } from "../../API/Upload";
import PlayerCustom from "./Panels/PlayerCustom";
import { categoryApi, contentApi, getClient } from "../../API/Clients";
import {
  cdnGetScript,
  createCronJob,
  deleteCronJob,
  getCronJobs,
  updateCronJob,
} from "../../API/Upload";
import { userFields, adminFields } from "./Config/ConfigFields";
import { getPublishersByName } from "../../API/Publishers";
import { updateClient } from "../../redux/reducers/clientSlice";
import { observableDiff } from "deep-diff";
import axios from "axios";
import AddXml from "./Ads/AddXml";
import { getDateTime } from "../Utility/utils";
import { postGA , pvData} from "../../API/Ga";
import { updateGamData } from "../../API/Adunits";
import GamAdUnit from "./Ads/GamAdUnit";
import LoaderComp from "../Utility/LoaderComp";
import PlayerInfo from "./Panels/PlayerInfo";

const CreatePlayer = (props) => {
  const dispatch = useDispatch();
  const clientDetail = useSelector((state) => state.client);
  const [generateTag, setGenerateTag] = useState();
  const [form] = Form.useForm();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const publisherList = useSelector((state) =>
    state.publisher.publisherList.filter((list) => list.isArchived === "false")
  );
  const [data, setData] = useState([]);
  const websiteList = useSelector((state) => state.website.websiteList);
  const access = useSelector((state) => state.user.access);
  const userRole = useSelector((state) => state.user.role);
  const userType = useSelector((state) => state.user.type);
  const userName = useSelector((state) => state.user.name);
  const userAccess = useSelector((state) => state.user.access);
  const [changes, setChanges] = useState([]);
  const [playerConfig, setPlayerConfig] = useState({});
  const [openAdTags, setOpenAdTags] = useState(false);
  const [websites, setWebsites] = useState([]);
  const [currWebsite, setCurrWebsite] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [saved, setSaved] = useState(true);
  const [gamData, setGamData] = useState();
  const [placementSwitch, setPlacementSwitch] = useState(false);
  const [gamDataSubmit, setGamDataSubmit] = useState(false);
  const [gaLoading, setGaLoading] = useState(false);
  const [clientname, setClientName] = useState(null);
  const [contentDisable, setContentDisable] = useState(false);

  const [tabKey, setTabKey] = useState();
  useEffect(() => {
    setTabKey(
      parseInt(
        window.localStorage.getItem("tab") ??
          (userType === "client" || userRole === "admin"
            ? 0
            : access?.player?.tabs.length > 0
            ? access?.player?.tabs[0]
            : null)
      )
    );
  }, [userType, userRole, access]);

  const [currPub, setCurrPub] = useState(
    props.currPub ?? window.localStorage.getItem("pubname")
  );
  const [adSourceData, setAdSourceData] = useState([]);
  const [editTag, setEditTag] = useState(false);
  const [editTagIndex, setEditTagIndex] = useState(0);
  const [multiEditTag, setMultiEditTag] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [modalOpen, setModalOpen] = useState(false);
  const [biddersData, setBiddersData] = useState([]);
  const [activeAdData, setActiveAdData] = useState({});
  const [loader, setLoader] = useState(true);
  const [openXml, setOpenXml] = useState(false);
  const [xmlData, setXmlData] = useState([]);
  const [editXml, setEditXml] = useState([]);
  const [saveXml, setSaveXml] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [updateAllWeb, setUpdateAllWeb] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryList, setCountryList] = useState([{}]);
  const [playerinfo, setPlayerInfo] = useState("");

  function videoUrlUpdate() {
    setUpdateAllWeb(true);
  }

  const [content, setContent] = useState({
    video: [],
    categories: [],
    type: "vidlib",
    links: [],
    defaultUrls: [],
    youtube: {},
    hls: false,
  });
  useEffect(() => {
    if (userRole === "admin") {
      setData(adminFields);
    } else {
      setData(userFields);
    }
  }, [userRole]);

  const [dropButton, setDropButton] = useState(null);
  const [cdnPath, setCdnPath] = useState(null);
  const [scriptPath, setScriptPath] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [dfpLoading, setDfpLoading] = useState(false);
  // const [dfpEnabled, setDfpEnabled] = useState(false);
  const [inlineLoading, setInlineLoading] = useState(false);
  const [saveTags, setSaveTags] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);
  const [cssFile, setCssFile] = useState("");
  const [affixed, setAffixed] = useState(false);
  const [cssLoad, setCssLoad] = useState(true);

  // const [webChanges, setWebChanges] = useState([]); // store

  const handleClose = () => {
    setModalOpen((prev) => false);
  };
  const handleCancel = () => {
    setModalOpen((prev) => false);
  };

  // VIEW JSON
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handlemodalCancel = () => {
    setIsModalOpen((prev) => false);
  };
  // Copy JSON to clipboard and show success message
  const copyJson = () => {
    const codeElement = document.querySelector("#json-code");
    if (codeElement) {
      const codeText = codeElement.innerText;
      navigator.clipboard.writeText(codeText);
      message.success("Text copied to clipboard");
    }
  };
  // Function to generate Google Analytics (GA) for the current website
  const generateGa = async () => {
    try {
      const webname = currWebsite?.config[0]?.name;
      const weburl = currWebsite?.url;

      if (!webname || !weburl) {
        throw new Error("Invalid website configuration");
      }
      const res = await postGA(webname, weburl);
      if (!res.err) {
        console.log(
          "Google Analytics generated successfully",
          res.id,
          res.propertyid
        );
        return { id: res.id, propertyid: res.propertyid };
      } else {
        throw new Error("Error generating GA");
      }
    } catch (error) {
      console.error("Error generating GA:", error);
    }
  };

  /*
   * fetch client data for the current publisher
   */
  useEffect(() => {
    const getClientData = async () => {
      let clientData 
      if (websiteList?.length > 0 && currPub){
        clientData = await getClientDetails(currPub);
      }   
      setClientName(clientData);
      if (websiteList?.length > 0 && currPub) {
        props.setFromWebsite(false);
        if (
          (currPub?.length > 0 &&
            ((userType === "user" &&
              clientData.data.msg.name === userAccess.client) ||
              (userRole === "adops" &&
                userAccess.client.includes(clientData.data.msg.name)) ||
              (userType !== "user" &&
                clientData.data.msg.name === clientDetail.name))) ||
          userRole === "admin"
        ) {
          handlePubChange(currPub);
          form.setFieldValue("publisher", currPub);
        }
        if (window.localStorage.getItem("url") !== "undefined") {
          let site = window.localStorage.getItem("url");
          handleChange(null, null, site);
        }
      }
    };
    getClientData();
  }, [websiteList, userAccess, userRole, userType, clientDetail]);

  useEffect(() => {
    if (props.currUrl) {
      handleChange(null, null, props.currUrl);
    }
  }, []);

  useEffect(() => {
    if (currWebsite) {
      setLoader(true);
      let bidder_data = [];
      let adsData = [];
      let player_config = [];
      let generate_text = [];
      let adsCheckActive = {};
      let other_config = {};
      let country_kv = [{}];
      let player_info;
      if (currWebsite?.config[0]["playerConfig"]) {
        player_config = currWebsite?.config[0]["playerConfig"];
        generate_text.push(
          `window.${clientDetail?.cdnLink ? "mvPlayer" : "unibots"} = window.${
            clientDetail?.cdnLink ? "mvPlayer" : "unibots"
          } || { cmd: [] };`
        );
        generate_text.push(
          `${
            clientDetail?.cdnLink ? "mvPlayer" : "unibots"
          }.cmd.push(function() { ${
            clientDetail?.cdnLink ? "initMV" : "unibots"
          }Player("${currWebsite.config[0]["name"]}") });`
        );
      }
      if (currWebsite?.config[0]["playerConfig"]["AD_SOURCES"]) {
        bidder_data =
          currWebsite.config[0]["playerConfig"]["AD_SOURCES"]["PREBID"]["DATA"];
        country_kv =
          currWebsite.config[0]["playerConfig"]["AD_SOURCES"]["GOOGLE"][
            "COUNTRY_KV"
          ];
        adsData = [
          ...currWebsite.config[0]["playerConfig"]["AD_SOURCES"]["GOOGLE"][
            "DATA"
          ],
          ...currWebsite.config[0]["playerConfig"]["AD_SOURCES"]["DIRECT"][
            "DATA"
          ],
          ...(currWebsite.config[0]["playerConfig"]["AD_SOURCES"]?.XML?.DATA ??
            []),
        ];
        adsCheckActive = currWebsite.config[0]["playerConfig"]["AD_SOURCES"];
        setXmlData(
          currWebsite.config[0]["playerConfig"]["AD_SOURCES"]?.XML?.XMLDATA ??
            []
        );
      }

      if (currWebsite?.config[0]["playerConfig"]["PLAYER_INFO"]) {
        player_info = currWebsite?.config[0]["playerConfig"]["PLAYER_INFO"];
      }
      setPlayerInfo(player_info);
      setCountryList(country_kv?.length > 0 ? country_kv : [{}]);
      if (currWebsite?.config[0]["playerConfig"]["OTHER_AD_CONFIGS"]) {
        other_config =
          currWebsite?.config[0]["playerConfig"]["OTHER_AD_CONFIGS"];
      }
      // if (webChanges) {
      //   setChanges(webChanges ?? []);
      // }

      let initContent = currWebsite?.config[0]?.content ?? {};
      // console.log("check cdn", clientDetail);
      if (initContent) {
        setContent((prev) => {
          return {
            ...prev,
            categories: initContent.categories ?? [],
            type: clientDetail.cdnEnabled
              ? initContent?.type ?? "vidlib"
              : initContent?.type ?? "defaultUrls",
            links: initContent?.links ?? [],
            defaultUrls: initContent?.defaultUrls ?? [],
            youtube: initContent?.youtube ?? {},
          };
        });
      }
      if (player_config["VIDEO_SRC_DEFAULT"]) {
        setContent((prev) => {
          return { ...prev, video: player_config["VIDEO_SRC_DEFAULT"] };
        });
      }
      if (
        player_config["HLS_ENABLED"] !== undefined &&
        clientDetail?.cdnEnabled
      ) {
        setContent((prev) => {
          return { ...prev, hls: player_config["HLS_ENABLED"] };
        });
      }
      setCdnPath(
        clientDetail?.cdnLink
          ? clientDetail.cdnLink
          : "https://cdn.unibotscdn.com"
      );

      setActiveAdData({
        PREBID: adsCheckActive?.PREBID?.IS_ACTIVE ?? false,
        GOOGLE: adsCheckActive?.GOOGLE?.IS_ACTIVE ?? true,
        DIRECT: adsCheckActive?.DIRECT?.IS_ACTIVE ?? false,
        ALLOWED: {
          GOOGLE: adsCheckActive?.GOOGLE?.ALLOWED,
          PREBID: adsCheckActive?.PREBID?.ALLOWED,
          DIRECT: adsCheckActive?.DIRECT?.ALLOWED,
          XML: adsCheckActive?.XML?.ALLOWED,
        },
        DISALLOWED: {
          GOOGLE: adsCheckActive?.GOOGLE?.DISALLOWED,
          PREBID: adsCheckActive?.PREBID?.DISALLOWED,
          DIRECT: adsCheckActive?.DIRECT?.DISALLOWED,
          XML: adsCheckActive?.XML?.DISALLOWED,
        },
        XML: adsCheckActive?.XML?.IS_ACTIVE ?? false,
        DEFAULT_CPM: adsCheckActive?.PREBID?.DEFAULT_CPM ?? 0,
        USE_CUSTOM_PARAMS: other_config?.USE_CUSTOM_PARAMS ?? {},
        DISABLE_BASIC_UPR: adsCheckActive?.GOOGLE?.DISABLE_BASIC_UPR ?? false,
      });
      setBiddersData(bidder_data);
      setAdSourceData([...adsData]);
      console.log(player_config);
      setPlayerConfig({
        ...player_config,
        AD_DELAY_ENABLED: !player_config["AD_DELAY_ENABLED"],
      });
      setGenerateTag(generate_text);
      form.setFieldValue("website", currWebsite?.url ?? "");
      let playerName = currWebsite?.config[0].name;
      if (playerName) {
        const getScript = async () => {
          const res = await cdnGetScript(playerName + ".css", "css/");
          setCssLoad(false);
          if (res.err) {
            setCssFile(res.exist ? null : "");
          } else {
            setCssFile(res?.file ?? "");
          }
        };
        getScript();
      }
      let initAdverts = currWebsite?.config[0]?.advertisers;
      if (initAdverts) {
        setAdvertisers(initAdverts);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [currWebsite, clientDetail]);

  const getClientDetails = async (pub) => {
    const res = await getPublishersByName(pub ?? currWebsite?.parent);
    if (!res?.data?.err) {
      const response = await getClient(res?.data?.msg?.parent);
      return response;
    }
  };

  useEffect(() => {
    const ClientUpdate = async () => {
      if (currWebsite) {
        if (userRole === "admin") {
          let response = await getClientDetails();
          dispatch(
            updateClient({
              name: response?.data?.msg?.name,
              email: response?.data?.msg?.email,
              clientLogo: response?.data?.msg?.clientLogo,
              logoUrl: response?.data?.msg?.logoUrl,
              redirectLink: response?.data?.msg?.redirectLink,
              cdnLink: response?.data?.msg?.cdnLink,
              cdnEnabled: response?.data?.msg?.cdnEnabled ?? true,
              clientChange: false,
              defaultUrls: response?.data?.msg?.defaultUrls,
              customerId: response?.data?.msg?.customerId,
              balance: response?.data?.msg?.balance,
              isPrepaid: response?.data?.msg?.isPrepaid,
              balanceLimit: response?.data?.msg?.balanceLimit,
              changes: response?.data?.msg?.changes,
            })
          );
        }
      }
    };
    ClientUpdate();
  }, [currWebsite]);

  useEffect(() => {
    // Function to fetch country codes from the API
  
    if(currWebsite) {
      const getCountryCodes = async () => {
        // let url = "https://restcountries.com/v3.1/all?fields=name,cca2";
        let url = `${process.env.REACT_APP_CDN_URL}/${process.env.REACT_APP_CDN_DIR}/mvp/countrycodes.json`;
        const res = await axios.get(url);
        let data = [];
        if (res.status === 200) {
          data = res.data.countries;
          setCountryCodes(data);
        }
  
        // if (res.status === 200) {
        //   data = res.data.map((code) => {
        //     return { label: code.name.common, value: code.cca2 };
        //   });
        //   data.sort((a, b) => {
        //     return a.label.localeCompare(b.label);
        //   });
        //   setCountryCodes(data);
        // }
      };
      getCountryCodes();
    }
  }, [currWebsite]);

  useEffect(() => {
    // console.log(activeAdData);
    // console.log(
    //   userRole !== "admin" && access?.menuItems
    //     ? !access?.player?.tabs?.includes(0)
    //     : false
    // );
    setTabs([
      {
        key: 0,
        label: "Ad Sources",
        children: (
          <>
            {loader ? (
              <div className="flex flex-col justify-center items-center h-full">
                <Skeleton active={true} />
                <Skeleton active={true} />
              </div>
            ) : (
              <AdSources
                setOpenAdTags={setOpenAdTags}
                setSelectedTags={setSelectedTags}
                setOpenXml={setOpenXml}
                adSourceData={adSourceData}
                setAdSourceData={setAdSourceData}
                setEditTag={setEditTag}
                setEditXml={setEditXml}
                setEditTagIndex={setEditTagIndex}
                setActiveAdData={setActiveAdData}
                activeAdData={activeAdData}
                setBiddersData={setBiddersData}
                initBidderData={biddersData}
                handleCreatePlayer={handleCreatePlayer}
                handleAddNewTag={handleAddNewTag}
                setMultiEditTag={setMultiEditTag}
                selectedTags={selectedTags}
                xmlData={xmlData}
                countryCodes={countryCodes}
                countryList={countryList}
                setCountryList={setCountryList}
              />
            )}
          </>
        ),
        disabled:
          userRole !== "admin" && access?.menuItems
            ? !access?.player?.tabs?.includes(0)
            : false,
      },
      {
        key: 1,
        label: "Content Integration",
        children: (
          <>
            {loader ? (
              <div className="flex flex-col justify-center items-center h-full">
                <Skeleton active={true} />
                <Skeleton active={true} />
              </div>
            ) : (
              <ContIntegeration
                videoUrlUpdate={videoUrlUpdate}
                currWebsite={currWebsite}
                setContent={setContent}
                content={content}
                activeAdData={activeAdData}
                setContentDisable={setContentDisable}
                contentDisable={contentDisable}
              />
            )}
          </>
        ),
        disabled:
          userRole !== "admin" && access?.menuItems
            ? !access?.player?.tabs?.includes(1)
            : false,
      },
      {
        key: 2,
        label: "Video Player Config",
        children: (
          <>
            {loader ? (
              <div className="flex flex-col justify-center items-center h-full">
                <Skeleton active={true} />
                <Skeleton active={true} />
              </div>
            ) : (
              <DrawerButton
                setPlayerConfig={setPlayerConfig}
                playerConfig={playerConfig}
                currWebsite={currWebsite}
                success={success}
                fail={fail}
                form={form}
              />
            )}
          </>
        ),
        disabled:
          userRole !== "admin" && access?.menuItems
            ? !access?.player?.tabs?.includes(2)
            : false,
      },
      {
        key: 3,
        label: "Video Player Customisation",
        children: (
          <>
            {loader ? (
              <div className="flex flex-col justify-center items-center h-full">
                <Skeleton active={true} />
                <Skeleton active={true} />
              </div>
            ) : (
              <div className="w-full sm:w-3/4">
                <PlayerCustom
                  form={form}
                  setPlayerConfig={setPlayerConfig}
                  initConfig={playerConfig}
                  currWebsite={currWebsite}
                  initFile={cssFile}
                  setInitFile={setCssFile}
                  setSaved={setSaved}
                  saved={saved}
                  cssLoad={cssLoad}
                />
              </div>
            )}
          </>
        ),
        disabled:
          userRole !== "admin" && access?.menuItems
            ? !access?.player?.tabs?.includes(3)
            : false,
      },
    ]);
  }, [
    loader,
    biddersData,
    adSourceData,
    activeAdData,
    currWebsite,
    content,
    playerConfig,
    cssFile,
    cssLoad,
    selectedTags,
    xmlData,
    countryCodes,
    countryList,
  ]);

  /*
   * Handle changes in the selected Publisher
   */
  const handlePubChange = (pubName) => {
    props.setCurrPub(window.localStorage.getItem("pubname"));
    window.localStorage.setItem("pubname", pubName);
    setCurrPub(pubName);
    if (props.fromwebsite) {
      form.setFieldsValue({ website: props.currUrl ?? currWebsite?.url });
    } else if (currWebsite && currWebsite.parent !== pubName) {
      form.setFieldsValue({ website: undefined });
      setCurrWebsite(null);
      window.localStorage.removeItem("currWebsite");
    }

    // Filter the list of websites based on the selected Publisher

    let filteredWebsites = websiteList.filter((website) => {
      return website.parent === pubName;
    });

    // Extract non-archived website from the filtered Websites
    let urls = filteredWebsites
      .filter((websites) => websites.isArchived !== "true")
      .map((website) => website.url);
    setWebsites(urls);
  };

  const handleAddNewTag = () => {
    setEditTag(false);
    setOpenAdTags(true);
    setMultiEditTag(null);
  };
  const handleSubmit = async (type) => {
    // console.log("update all web", updateAllWeb);
    const res = await getWebsitesByPlayerConfig(
      currWebsite.parent,
      currWebsite.url
    );
    let flag = false;
    if (!res.err) {
      const latestWebsite = res.data.msg;
      if (JSON.stringify(latestWebsite[0]) === JSON.stringify(currWebsite)) {
        flag = true;
      }
    } else {
      fail("Failed to fetch website!");
    }
    if (flag) {
      form
        .validateFields()
        .then((values) => {
          handleCreatePlayer(type);
        })
        .catch((error) => {
          if (tabKey !== 2) {
            setTabKey(2);
          }
          setErrorField(error.errorFields[0].name);
        });
    } else if (!flag && !res.err) {
      warning("Data has been changed recently, Please refresh to update!");
    }
  };
  useEffect(() => {
    if (errorField && tabKey === 2) {
      setTimeout(() => {
        form.scrollToField(errorField, {
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }, 100);
      setErrorField(null);
    }
  }, [tabKey, errorField]);

  const warning = (msg) => {
    messageApi.open({
      type: "warning",
      content: msg ?? "Please save file before exiting!",
    });
  };

  const handleTabClick = (key) => {
    key = parseInt(key);
    window.localStorage.setItem("tab", key);
    let fields = form.getFieldsValue();
    let csstext = fields.csstext;
    if (tabKey === 3 && cssFile !== csstext && !saved) {
      warning();
      props.setSaved(false);
    } else {
      setTabKey(key);
    }
  };

  /*
   * Handles the change of value and updates the website and publisher accordingly.
   */

  // const handleChange = async (value, values, site) => {
  //   let fields = form.getFieldsValue();
  //   let website = site ?? fields.website;
  //   window.localStorage.setItem("url", website);
  //   let pub = fields.publisher;
  //   if (fields.website || website) {
  //     console.log(fields.website, pub);
  //     if ((fields.website || website, pub)) {
  //       const res = await getWebsitesByPlayerConfig(pub, website);
  //       let websitex = res.data.msg[0];
  //       console.log("websitex", websitex[0]);
  //       setCurrWebsite(websitex[0]);
  //     }

  //     // let filteredSite = websiteList.filter((site) => {
  //     //   return site.url === website && site.parent === pub;
  //     // });

  //     // console.log("filteredSite", filteredSite);
  //   }
  // };

  const handleChange = async (value, values, site) => {
    let fields = form.getFieldsValue();
    try {
      // Determine the website URL
      let website = site ?? fields?.website;
      if (!website) {
        console.log("Website is missing");
        return;
      }

      // Store the URL in localStorage
      window.localStorage.setItem("url", website);

      // Retrieve the publisher from form values
      let pub = fields.publisher;
      if (!pub) {
        console.log("Publisher is missing");
        return;
      }
      const res = await getWebsitesByPlayerConfig(pub, website);
      const currWebsite = res?.data?.msg?.[0];

      if (currWebsite) {
        console.log("Fetched website details");
        setCurrWebsite(currWebsite);
      } else {
        console.error("No data found in response");
      }
    } catch (error) {
      console.error("Error fetching website details:", error);
    }
  };

  const success = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };
  const fail = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const getSize = (fieldData, field) => {
    let obj = {};
    let size = fieldData[field.name].split("x");
    size.forEach((val, i) => {
      obj[field.split[i]] = parseInt(val);
    });
    return obj;
  };
  const getArray = (field, fieldData) => {
    let name = field.name;
    let saveType = field.saveType;
    let arr =
      fieldData[name]?.length === 0
        ? []
        : typeof fieldData[name] === "string"
        ? fieldData[name]?.split("\n")
        : fieldData[name];
    if (saveType === "array(int)") {
      arr = arr.map((el) => parseInt(el));
    }
    if (name === "CLIENTSITE_TARGETED_DIV_ID_CLASSES") {
      let selectors = ["#", "."];
      arr = arr.map((id) => {
        if (selectors.includes(id[0])) {
          return id.slice(1);
        } else return id;
      });
    }
    return arr;
  };
  const formatPlayerConfig = (fieldData) => {
    delete fieldData["publisher"];
    delete fieldData["website"];
    delete fieldData["upload"];
    delete fieldData["csstext"];
    data.forEach((field) => {
      if (fieldData[field.name]) {
        if (field?.saveType?.includes("array")) {
          fieldData[field.name] = getArray(field, fieldData);
        } else if (field.split) {
          fieldData = { ...fieldData, ...getSize(fieldData, field) };
          delete fieldData[field.name];
        }
      }
    });
    if (fieldData["FLOATING_PLAYER_SETTINGS"]?.STICKY_H_POSITION) {
      fieldData["STICKY_POSITION"] =
        fieldData["FLOATING_PLAYER_SETTINGS"]["STICKY_H_POSITION"];
    }
    if (fieldData["FLOATING_PLAYER_SETTINGS"]?.STICKY_H_POSITION_MB) {
      fieldData["FLOATING_POSITION_MB"] =
        fieldData["FLOATING_PLAYER_SETTINGS"]["STICKY_H_POSITION_MB"];
    }

    fieldData["AD_DELAY_ENABLED"] = !fieldData["AD_DELAY_ENABLED"];

    // if (fieldData["AD_DELAY_ENABLED"] === true) {
    //   fieldData["AD_REQUEST_DURATION"] = 0;
    // }
    // fieldData["HLS_ENABLED"] = content?.hls;

    if (fieldData?.CUSTOM_AD_DELAY) {
      fieldData["CUSTOM_AD_DELAY"] = fieldData?.CUSTOM_AD_DELAY?.toString();
    }
    if (fieldData?.LOGO_POSITION) {
      let logoposition = fieldData.LOGO_POSITION;
      let closebutton;
      switch (logoposition) {
        case "topright":
          closebutton = "topleft";
          break;
        case "topleft":
          closebutton = "topright";
          break;
        case "bottomright":
          closebutton = "topleft";
          break;
        default:
          closebutton = "";
      }
      fieldData["CLOSE_BUTTON_POSITION"] = closebutton;
    }

    if (content.type === "upload") {
      fieldData["VIDEO_DYNAMIC"] = false;
    }

    // https in redirect link

    if (fieldData?.COMPANION_AD?.SLOT_SIZE) {
      fieldData.COMPANION_AD.SLOT_SIZE = fieldData?.COMPANION_AD.SLOT_SIZE?.map(
        (dimensions) => {
          if (typeof dimensions === "string") {
            const [width, height] = dimensions?.split("x")?.map(Number);
            return [width, height];
          } else return dimensions;
        }
      );
    }

    const key = fieldData?.DEFAULT_LOGO_ENABLE
      ? "DEFAULT_LOGO_REDIRECT_LINK"
      : "LOGO_REDIRECT_LINK";
    let redirectUrl = fieldData[key];

    if (redirectUrl && !redirectUrl.includes("http")) {
      fieldData[key] = `https://${redirectUrl}`;
    }
    return fieldData;
  };

  const handleDfp = () => {
    let code = getDfpCode(currWebsite.config[0]["name"]);
    const respone = cdnScriptUpload(
      code,
      `/mvp/dfp_scripts/${currWebsite.config[0]["name"]}.js`,
      true
    );
    // console.log("Script Uploaded!");
    return respone;
  };

  const getNewPath = (path, index, i, data) => {
    let newPath = [...path];
    let value = data;
    for (let key of path) {
      if (key === index) {
        break;
      } else {
        value = value[key];
      }
    }
    if (
      path.includes("GOOGLE") ||
      path.includes("DIRECT") ||
      path.includes("XML")
    ) {
      newPath[i] = value[index].AD_TAG_NAME;
    }
    if (path.includes("XMLDATA")) {
      newPath[i] = value[index].NAME;
    }
    if (path.includes("PREBID")) {
      newPath[i] = value[index].BIDDER;
    }
    return newPath;
  };

  const getChange = (d) => {
    const { date, time } = getDateTime();
    let newPath = [];
    d?.path?.forEach((key, i) => {
      if (Number.isInteger(key)) {
        newPath = getNewPath(
          d.path,
          key,
          i,
          currWebsite.config[0].playerConfig
        );
      }
    });
    let name = newPath.length > 0 ? newPath.join(".") : d?.path?.join(".");
    if (d.kind === "E") {
      return {
        name,
        date,
        time,
        lhs: d.lhs,
        rhs: d.rhs,
        kind: "edited",
      };
    } else if ((d.kind === "A" && d.item.kind === "N") || d.kind === "N") {
      return { name, date, time, rhs: d?.item?.rhs ?? d.rhs, kind: "added" };
    } else if ((d.kind === "A" && d.item.kind === "D") || d.kind === "D") {
      return { name, date, time, lhs: d?.item?.lhs ?? d.lhs, kind: "deleted" };
    }
    return d;
  };

  const handleCreatePlayer = async (type) => {
    /*
     * Check if playerConfig GA_CODE is empty
     */
    if (
      clientname && clientname.data.msg.name === "unibots" &&
      !playerConfig?.GA_CODE &&
      playerConfig?.AUTO_GA_ENABLED === true
    ) {
      // Check if gamDataSubmit doesn't exist
      if (
        !gamDataSubmit &&
        (userType === "user" ||
          userName === "test90" ||
          userName === "unibots") &&
        userRole !== "admin"
      ) {
        return fail("Please add GAM data first");
      } else {
        // Generate GA CODE asynchronously and update playerConfig
        let GACODE;
        if (
          userName === "unibots" ||
          userType === "user" ||
          userName === "test90"
        ) {
          setGaLoading(true);
          GACODE = await generateGa();
          setGaLoading(false);
          playerConfig.GA_CODE = GACODE.id;
        }

        // Update pvData with Client Name (player name), GA Property ID and GACODE
        let data = {
          client: currWebsite?.config[0]?.name,
          gacode: GACODE.id,
          GA_PROPERTY_ID: GACODE.propertyid
        }
        let url = process.env.REACT_APP_PVDATA_API_URL;
        const res = await axios.post(url, data)
        // let gapvdata = await pvData (data)
        if (res.err) {
          fail(res.msg)
        }
        // Update GAM data with placement and GA Property ID
        if (
          userRole !== "admin" &&
          (userName === "unibots" ||
            userType === "user" ||
            userName === "test90")
        ) {
          let res = await updateGamData({
            ...gamData,
            placement: placementSwitch,
            GaPropId: GACODE.propertyid,
          });
          if (res.err) {
            const errorMsg = res.msg.response?.data.msg;
            const networkmsg = res.msg?.message;
            if (errorMsg) {
              fail(errorMsg);
            } else {
              fail(networkmsg);
            }
          } else {
            success(res.msg.msg);
          }
        }
      }
    }

    if (type === "dfp") {
      setDfpLoading(true);
    } else if (type === "inline") {
      setInlineLoading(true);
    } else {
      setSaveLoading(true);
    }
    let timeElapsed = 0;
    const val = setInterval(() => {
      timeElapsed++;
    }, 1000);
    let isPlayerActive = currWebsite.config[0].isActive;

    let player_config = {};
    let new_fields = JSON.parse(JSON.stringify(playerConfig));
    player_config = formatPlayerConfig(new_fields) ?? {};
    player_config["AD_SOURCES"] = {};
    let CUSTOM_PARAMS = {
      IS_ENABLE: activeAdData?.USE_CUSTOM_PARAMS?.IS_ENABLE ?? true,
      ENABLE_MAX_AD_DURATION: true,
    };
    player_config["OTHER_AD_CONFIGS"] = {
      USE_CUSTOM_PARAMS: CUSTOM_PARAMS,
    };
    player_config["DISABLE_CONTENT"] = contentDisable
    player_config["PLAYER_INFO"] = playerinfo;

    if (activeAdData) {
      let sources = {
        PREBID: { IS_ACTIVE: false },
        GOOGLE: { IS_ACTIVE: true },
        DIRECT: { IS_ACTIVE: false },
        XML: { IS_ACTIVE: false },
      };
      for (let key in sources) {
        let data = [];
        if (key === "PREBID") {
          data = biddersData;
        } else {
          data = adSourceData.filter((adTag) => {
            return adTag["VAST_TAG_TYPE"] === key;
          });
        }
        player_config["AD_SOURCES"][key] = {
          IS_ACTIVE: activeAdData[key] ?? sources[key]?.IS_ACTIVE,
          DATA: data,

          ALLOWED: activeAdData?.ALLOWED ? activeAdData.ALLOWED[key] ?? [] : [],
          DISALLOWED: activeAdData?.DISALLOWED
            ? activeAdData.DISALLOWED[key] ?? []
            : [],
        };
        if (key === "GOOGLE") {
          player_config["AD_SOURCES"][key]["USE_CUSTOM_PARAMS"] =
            activeAdData?.USE_CUSTOM_PARAMS?.IS_ENABLE ?? true;
          player_config["AD_SOURCES"][key]["DISABLE_BASIC_UPR"] =
            activeAdData?.DISABLE_BASIC_UPR;
          player_config["AD_SOURCES"][key]["COUNTRY_KV"] = Object.keys(
            countryList[0] ?? {}
          ).length
            ? countryList
            : [];
        }
        if (key === "PREBID") {
          player_config["AD_SOURCES"][key]["DEFAULT_CPM"] =
            activeAdData?.DEFAULT_CPM ?? 0;
        }
        if (key === "XML") {
          player_config["AD_SOURCES"][key]["XMLDATA"] = xmlData;
        }
      }
    } else {
      for (let key in activeAdData) {
        let data = [];
        if (key === "PREBID") {
          data = biddersData;
        } else {
          data = adSourceData.filter((adTag) => {
            return adTag["VAST_TAG_TYPE"] === key;
          });
        }
        player_config["AD_SOURCES"][key] = {
          IS_ACTIVE: activeAdData[key],
          DATA: data,
        };
      }
    }
    if (type === "dfp") {
      const res = await handleDfp();
      // dfp = !res.err;
      setScriptPath(
        cdnPath +
          `/${process.env.REACT_APP_CDN_DIR}/mvp/dfp_scripts/${currWebsite.config[0]["name"]}.js`
      );
    } else if (type === "inline") {
      setScriptPath(
        cdnPath + `/${process.env.REACT_APP_CDN_DIR}/mvp/player.js`
      );
    }
    let advts = advertisers.map((item) =>
      typeof item === "string" ? item : item.value
    );
    let { categories } = content;
    if (content.type === "defaultUrls") {
      if (
        content.defaultUrls.length === 0 ||
        (content?.video?.length === 1 &&
          (content.video[0].trim() === "" || content.video === undefined))
      ) {
        // console.log("default url is empty");
        player_config["VIDEO_SRC_DEFAULT"] =
          player_config?.CUSTOM_VIDEO_SOURCE?.length > 0
            ? []
            : [
                "https://stream.unibotscdn.com/25acc3a8-9b3c-41a6-92f3-216becb358f6/playlist.m3u8",
              ];
      } else {
        player_config["VIDEO_SRC_DEFAULT"] = content.defaultUrls;
      }
    } else {
      player_config["VIDEO_SRC_DEFAULT"] =
        content.video && content.video.length > 0
          ? content?.video
          : player_config?.CUSTOM_VIDEO_SOURCE?.length > 0
          ? []
          : [
              "https://stream.unibotscdn.com/25acc3a8-9b3c-41a6-92f3-216becb358f6/playlist.m3u8",
            ];
    }
    if (player_config?.CUSTOM_VIDEO_SOURCE ) {
      let vidSrc = contentDisable ? [] : [...player_config["VIDEO_SRC_DEFAULT"]];
      player_config?.CUSTOM_VIDEO_SOURCE?.forEach((src) => {
        if (!vidSrc.includes(src)) {
          vidSrc.push(src);
        }
      });
      player_config["VIDEO_SRC_DEFAULT"] = [...vidSrc];
    }
    let youtube = JSON.parse(JSON.stringify(content.youtube));
    if (content.type === "youtube") {
      if (currWebsite.config[0].content.type !== "youtube") {
        player_config["VIDEO_SRC_DEFAULT"] = [];
      }
      const result = await getCronJobs();
      const jobData = result.msg.jobs.filter(
        (job) => job.title === currWebsite.config[0].name
      );
      if (jobData.length <= 0) {
        const response = await createCronJob(
          currWebsite.config[0].name,
          content.youtube.frequency,
        );
      } else if (
        content?.youtube?.frequency !==
        currWebsite?.config[0]?.content?.youtube?.frequency
      ) {
        const response = await updateCronJob(
          jobData[0].jobId,
          content.youtube.frequency
        );
      }
    }
    if (
      currWebsite?.config[0]?.content?.type === "youtube" &&
      content?.type !== "youtube"
    ) {
      const result = await getCronJobs();
      const jobData = result.msg.jobs.filter(
        (job) => job.title === currWebsite.config[0].name
      );
      const response = await deleteCronJob(jobData[0].jobId);
      youtube.latest = "";
    }
    let newChanges = [];
    let skipFields = ["VIDEO_SRC_DEFAULT"];
    // observableDiff(
    //   currWebsite.config[0].playerConfig,
    //   player_config,
    //   (d) => {
    //     let data = getChange(d);
    //     let allChanges = [...newChanges];
    //     let found = false;
    //     for (let [i, change] of allChanges.entries()) {
    //       if (change.name === data.name) {
    //         found = true;
    //         let keys = [];
    //         if (change.lhs) {
    //           keys.push("lhs");
    //         }
    //         if (change.rhs) {
    //           keys.push("rhs");
    //         }
    //         keys.forEach((key) => {
    //           newChanges[i][key] =
    //             newChanges[i][key]?.length > 0
    // ? [newChanges[i][key], data[key]]
    //               : [newChanges[i][key], data[key]];
    //         });
    //       }
    //     }
    //     if (!found) {
    //       newChanges.push({ user: userName, ...data });
    //     }
    //   },
    //   (path, key) => skipFields.includes(key)
    // );

    observableDiff(
      currWebsite.config[0].playerConfig,
      player_config,
      (d) => {
        let data = getChange(d);
        let found = false;

        for (let [i, change] of newChanges.entries()) {
          if (change.name === data.name) {
            found = true;
            let keys = ["lhs", "rhs"].filter((key) => change[key]);
            keys.forEach((key) => {
              newChanges[i][key] = [newChanges[i][key], data[key]]
                .flat()
                .filter(Boolean);
            });
          }
        }
        if (!found) {
          newChanges.push({ user: userName, ...data });
        }
      },
      (path, key) => skipFields.includes(key)
    );
    let newContent = {
      categories: categories?.length ? categories : [],
      type: content?.type ?? "vidlib",
      links: content?.links ?? [],
      defaultUrls: content?.defaultUrls,
      youtube: youtube ?? {},
    };
    if (currWebsite?.config[0]?.content) {
      observableDiff(currWebsite.config[0].content, newContent, (d) => {
        let data = getChange(d);
        newChanges.push({
          user: userName,
          ...data,
          name: "content." + data.name,
        });
      });
    }
    console.log(
      "%cPlayer Config:",
      "color: white; background: orange; font-size: 12px; font-weight: bold; padding: 2px 4px; border-radius: 4px;",
      player_config
    );
    let dates = changes.map((change) => {
      return change.date;
    });
    dates = [...new Set(dates)];

    const patchSite = {
      url: currWebsite.url,
      parent: currWebsite.parent,
      config: [
        {
          playerConfig: player_config,
          content: newContent,
          advertisers: advts ?? [],
          isActive: isPlayerActive,
          // changes:
          //   dates.length > 30 || changes.length > 500
          //     ? [...changes.slice(1), ...newChanges]
          //     : [...changes, ...newChanges],
          // dfp: dfp,
        },
      ],
    };
    const res = await patchWebsites(patchSite);
    const  updatedata = {
      url: currWebsite.url,      
      changes: newChanges,
    }
    const patchWebChanges = await patchChanges(updatedata);
    if (patchWebChanges.err) {
      fail("Error Occured - config not saved!");
    }
    dispatch(
      updateWebsite({ websiteList: [...websiteList], websiteChange: true })
    );
 
    if (res.err) {
      fail("Error Occured - config not saved!");
    } else {
      setTimeout(
        () => {
          if (type === "dfp" || type === "inline") {
            setModalOpen(true);
          } else if (type === "tags") {
            success(editTag ? "Tag modified!" : "Tag added!");
          } else if (type === "xml") {
            success(editXml ? "Xml modified!" : "Xml added!");
          } else {
            success("Player config saved!");
          }
        },
        timeElapsed < 1 ? 1000 : 0
      );
    }
    setTimeout(
      () => {
        if (type === "dfp") {
          setDfpLoading(false);
        } else if (type === "inline") {
          setInlineLoading(false);
        } else {
          setSaveLoading(false);
        }
        clearInterval(val);
      },
      timeElapsed < 1 ? 1000 : 0
    );
  };

  const handleCopy = () => {
    const codeElement = document.querySelector("#code-element");
    if (codeElement) {
      const codeText = codeElement.innerText;
      navigator.clipboard.writeText(codeText); // write text to clipboard
      message.success("Text copied to clipboard"); // show success message
    }
  };

  useEffect(() => {
    if (saveTags) {
      handleSubmit("tags");
      setSaveTags(false);
    }
  }, [adSourceData, advertisers, saveTags]);

  useEffect(() => {
    if (saveXml) {
      handleSubmit("xml");
      setSaveXml(false);
    }
  }, [xmlData, saveXml]);

  useEffect(() => {
    props.setSaved(saved);
  }, [saved]);
  const handleAffix = (value) => {
    setAffixed(value);
  };
  const isMobile = () => {
    return window.innerWidth < 1026; // assuming mobile breakpoint is 768px
  };
  return (
    <>
      <div className="px-5 w-full flex flex-col">
        <h1 className="font-medium text-[20px] ">Manage Video Player</h1>

        {contextHolder}

        <div
          className="flex flex-col"
          style={{
            padding: 24,
            background: colorBgContainer,
            height: "100%",
          }}
        >
          <div className="flex justify-between">
            <Form
              wrapperCol={{ span: 18 }}
              layout="horizontal"
              style={{
                minWidth: "80vw",
                display: "flex",
                flexFlow: "wrap",
                columnGap: 50,
              }}
              form={form}
            >
              <Form.Item
                name="publisher"
                label="Select Publisher"
                style={{ width: "400px", display: "inline-block" }}
              >
                <Select
                  showSearch
                  placeholder="Select Publisher"
                  style={
                    {
                      // width: "100%",
                    }
                  }
                  onChange={handlePubChange}
                  // onChange={() => {
                  //   handlePubChange();
                  // }}
                >
                  {publisherList.map((pub) => {
                    return (
                      <Select.Option value={pub.name}>{pub.name}</Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              {websites.length > 0 && (
                <>
                  <Form.Item
                    name="website"
                    label="Select Website"
                    style={{
                      display: "inline-block",
                      width: "400px",
                    }}
                    initialValue={currWebsite?.url}
                  >
                    <Select
                      showSearch
                      placeholder="Select Website"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleChange}
                    >
                      {websites.map((url) => {
                        return <Select.Option value={url}>{url}</Select.Option>;
                      })}
                    </Select>
                  </Form.Item>
                </>
              )}
              {currWebsite && (
                <>
                  <Button
                    onClick={() => {
                      props.setCurrPub(currPub);
                      props.setCurrUrl(currWebsite.url);
                      props.setFromWebsite(true);
                      props.setCurrOption("logs");
                    }}
                  >
                    Logs
                  </Button>
                  {(userName === "unibots" ||
                    userType === "user" ||
                    userName === "test90") && (
                    <>
                      <Button onClick={() => setIsModalOpen(true)}>
                        VIEW JSON
                      </Button>
                      <PlayerInfo
                        setPlayerInfo={setPlayerInfo}
                        currWebsite={currWebsite}
                        form={form}
                        playerinfo={playerinfo}
                      ></PlayerInfo>
                    </>
                  )}
                  {userRole !== "admin" &&
                    (userName === "unibots" ||
                      userType === "user" ||
                      userName === "test90") && (
                      <GamAdUnit
                        setGamData={setGamData}
                        gamData={gamData}
                        placementSwitch={placementSwitch}
                        setPlacementSwitch={setPlacementSwitch}
                        gamDataSubmit={gamDataSubmit}
                        setGamDataSubmit={setGamDataSubmit}
                        currWebsite={currWebsite}
                        fail={fail}
                        clientname={clientname}
                      />
                    )}
                </>
              )}
            </Form>
          </div>
          {currWebsite ? (
            <Affix
              offsetTop={isMobile() ? window.innerHeight * 0.1 - 10 : 0}
              onChange={handleAffix}
            >
              <div
                className="flex justify-end p-3 gap-4"
                style={{
                  background: "#FFFFFF",
                }}
              >
                <div>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    loading={saveLoading}
                    className="text-xs px-[11px]  sm:px-[15px]  sm:text-sm"
                  >
                    Save
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setDropButton("inline");
                      handleSubmit("inline");
                    }}
                    className="text-xs px-[11px]  sm:px-[15px]  sm:text-sm"
                    loading={inlineLoading}
                    style={{
                      background: "#22c55e",
                      color: "white",
                    }}
                  >
                    Generate Tag
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setDropButton("dfp");
                      handleSubmit("dfp");
                    }}
                    className="text-xs px-[11px]  sm:px-[15px]  sm:text-sm"
                    loading={dfpLoading}
                    style={{ background: "#eab308", color: "white" }}
                  >
                    DFP Tag
                  </Button>
                </div>
              </div>
            </Affix>
          ) : (
            <></>
          )}
          {currWebsite &&
            (gaLoading ? (
              <LoaderComp />
            ) : (
              <Tabs
                type="card"
                items={tabs}
                activeKey={tabKey}
                onTabClick={handleTabClick}
                style={{ height: "100%", fontSize: "10px" }}
              />
            ))}
          <AdTag
            open={openAdTags}
            setOpen={setOpenAdTags}
            adSourceData={adSourceData}
            setAdSourceData={setAdSourceData}
            edit={editTag}
            editTagIndex={editTagIndex}
            setSaveTags={setSaveTags}
            multiEditTag={multiEditTag}
            advertisers={advertisers}
            tabKey={tabKey}
            setAdvertisers={setAdvertisers}
            xmlData={xmlData}
            currWebsite={currWebsite}
            countryCodes={countryCodes}
          />
          <AddXml
            open={openXml}
            editXml={editXml}
            setOpen={setOpenXml}
            selectedTags={selectedTags}
            adSourceData={adSourceData}
            currWebsite={currWebsite}
            xmlData={xmlData}
            setXmlData={setXmlData}
            setSaveXml={setSaveXml}
            currPub={currPub}
            clientname={clientname}
          />
          <>
            <Modal
              title={`Video Tag (${
                dropButton
                  ? dropButton[0]?.toUpperCase() + dropButton?.slice(1)
                  : ""
              })`}
              open={modalOpen}
              // style={{ minWidth: 800, left: 100 }}
              style={{ maxWidth: "100%" }}
              width={800}
              bodyStyle={{
                height: 400,
                overflowY: "auto",
                paddingTop: "20px",
              }}
              footer={[
                <Button key="Copy" onClick={handleCopy}>
                  Copy Code
                </Button>,
                <Button key="submit" type="primary" onClick={handleClose}>
                  Ok
                </Button>,
              ]}
              onCancel={handleClose}
              onOk={handleClose}
              afterClose={handleClose}
              centered={true}
              destroyOnClose
            >
              {dropButton === "dfp" && (
                <Alert
                  message={
                    <>
                      Add this code to your DFP Ad unit as HTML and make sure{" "}
                      <b>"Safe frame" </b>
                      is disabled.
                    </>
                  }
                  type="info"
                  showIcon
                  className="my-3"
                />
              )}
              <code id="code-element">
                <span
                  style={{ color: "#f8c28f" }}
                >{`<!-- Place this in your head tag -->`}</span>
                <br />
                {`<script async src="${scriptPath}"></script>`}
                <br />
                <span style={{ color: "#f8c28f" }}>
                  {dropButton === "inline" &&
                    `<!-- Place this in your body tag -->`}
                </span>
                <br />
                {dropButton === "inline" && (
                  <>
                    {`<div id="div-ub-${
                      currWebsite ? currWebsite?.config[0]["name"] : ""
                    }">`}
                    {<br />}
                    &nbsp;&nbsp;&nbsp; {`<script>`}
                    <br />
                    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                    {generateTag?.length > 0 ? generateTag[0] : ""}
                    <br />
                    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                    {generateTag?.length > 0 ? generateTag[1] : ""}
                    <br />
                    &nbsp;&nbsp;&nbsp;{`</script>`}
                    <br />
                    {`</div>`}
                  </>
                )}
              </code>
            </Modal>
            <Modal
              title="Player JSON"
              style={{ maxWidth: "100%" }}
              width={800}
              bodyStyle={{
                height: 400,
                overflowY: "auto",
              }}
              open={isModalOpen}
              footer={[
                <Button onClick={copyJson}>Copy JSON</Button>,
                <Button key="submit" type="primary" onClick={handlemodalCancel}>
                  Ok
                </Button>,
              ]}
              onCancel={handlemodalCancel}
              onOk={handlemodalCancel}
              afterClose={handlemodalCancel}
            >
              <div id="json-code">
                <SyntaxHighlighter language="javascript" style={docco}>
                  {JSON.stringify(
                    currWebsite?.config[0]["playerConfig"],
                    null,
                    4
                  )}
                </SyntaxHighlighter>
              </div>
            </Modal>
          </>
        </div>
      </div>
    </>
  );
};

export default CreatePlayer;
