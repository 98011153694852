import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  socketData: [],
  //   dateChange: true,
};

export const DataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    updateData: (state, action) => {
      state.socketData = action.payload.socketData;
      //   state.publisherChange = action.payload.publisherChange;
    },
    // deletePub: (state, action) => ({ ...state, publisherChange: true }),
  },
});

// Action creators are generated for each case reducer function
export const { updateData } = DataSlice.actions;

export default DataSlice.reducer;
