import axios from "../axiosInstance";

const getClient = async (clientData) => {
  let resolved = {
    data: null,
    error: null,
  };
  try {
    const res = await axios({
      url: "client",
      method: "GET",
      params: { name: clientData ?? {} },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      resolved.error = err.response.data;
    } else {
      resolved.error = {
        message: "SomeThing went Wrong",
      };
    }
  }
  return resolved;
};

const getClientsList = async (clientData) => {
  let resolved = {
    data: null,
    error: null,
  };
  try {
    const res = await axios({
      url: "client/clientList",
      method: "GET",
      params: { name: clientData ?? {} },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      resolved.error = err.response.data;
    } else {
      resolved.error = {
        message: "SomeThing went Wrong",
      };
    }
  }
  return resolved;
};

const getClientChangesByDate = async (data) => {
  try {
    const res = await axios({
      url: "client/getClientChangesByDate",
      method: "POST",
      data:data
    });
    return { err: false, msg: res.data };

  } catch (err) {
    const error = err.response
      ? err.response.data
      : { message: "Something went wrong" };
    return error;
  }
}

const getclientinfo = async (name) => {
  try {
    const res = await axios({
      url: "client/getclientinfo",
      method: "POST",
      data: { name },
    });
    return { err: false, msg: res.data };

  } catch (err) {
    const error = err.response
      ? err.response.data
      : { message: "Something went wrong" };
    return error;
  }
}



const getClientChanges = async (name) => {
  try {
    const res = await axios.get("client/getclientChanges", {
      params: { name },
    });
    return res.data;
  } catch (err) {
    const error = err.response
      ? err.response.data
      : { message: "Something went wrong" };
    return error;
  }
};

const verifyClient = async (clientData) => {
  // console.log(pubData)
  try {
    const res = await axios({
      url: "client/login",
      method: "POST",
      data: {
        username: clientData.username,
        password: clientData.password,
        userinfo: clientData.userinfo
      },
    });
    return { err: false, msg: res.data, token: res.token };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const postClient = async (clientData) => {
  const requestData = {
    name: clientData.name,
    email: clientData.email,
    username: clientData.username,
    password: clientData.password,
    clientLogo: clientData.clientLogo,
    redirectLink: clientData.redirectLink,
    logoUrl: clientData.logoUrl,
    cdnEnabled: clientData.cdnEnabled,
    isPrepaid: clientData.isPrepaid,
    balanceLimit: clientData.balanceLimit,
    userrole: clientData.userrole,
  };
  if (clientData?.trialPeriod?.[0]) {
    requestData.startTrial = clientData.trialPeriod[0]?.split("T")[0];
  }

  if (clientData?.trialPeriod?.[1]) {
    requestData.endTrial = clientData.trialPeriod[1]?.split("T")[0];
  }
  try {
    const res = await axios({
      url: "client",
      method: "POST",
      data: requestData,
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const patchClient = async (clientData) => {
  try {
    const requestData = {
      flag: clientData.flag,
      name: clientData.name,
      email: clientData.email,
      username: clientData.username,
      password: clientData?.password?.length > 0 ? clientData?.password : null,
      clientLogo: clientData.clientLogo,
      redirectLink: clientData.redirectLink,
      cdnLink: clientData.cdnLink,
      logoUrl: clientData.logoUrl,
      cdnEnabled: clientData.cdnEnabled,
      changes: clientData.changes,
      isArchived: clientData.isArchived,
      defaultUrls: clientData?.defaultUrls,
      customerId: clientData?.customerId,
      balance: clientData?.balance,
      playerStatus: clientData.playerStatus,
      isPrepaid: clientData.isPrepaid,
      balanceLimit: clientData.balanceLimit,
      userrole: clientData.userrole,
    };
    if (
      clientData.trialPeriod &&
      clientData.trialPeriod[0] &&
      clientData.trialPeriod[1]
    ) {
      requestData.startTrial = clientData.trialPeriod[0].format("YYYY-MM-DD");
      requestData.endTrial = clientData.trialPeriod[1].format("YYYY-MM-DD");
    }
    const res = await axios({
      url: "client",
      method: "PATCH",
      data: requestData,
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const authClient = async (key) => {
  try {
    const res = await axios({
      url: "client/auth",
      method: "POST",
      data: {
        token: key,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const revDataClient = async (clientData) => {
  // console.log(pubData)
  try {
    const res = await axios({
      url: "client/rev",
      method: "POST",
      data: {
        name: clientData.name,
        startDate: clientData.startDate,
        endDate: clientData.endDate,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const categoryApi = async () => {
  let resolved = {
    data: null,
    error: null,
  };
  try {
    const res = await axios({
      url: "content/categories/",
      method: "GET",
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      resolved.error = err.response.data;
    } else {
      resolved.error = {
        message: "SomeThing went Wrong",
      };
    }
  }
  return resolved;
};

const contentApi = async (categories) => {
  let resolved = {
    data: null,
    error: null,
  };
  try {
    const res = await axios({
      url: "content/",
      method: "GET",
      params: { categories: categories.join(",") },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      resolved.error = err.response.data;
    } else {
      resolved.error = {
        message: "SomeThing went Wrong",
      };
    }
  }
  return resolved;
};

const forgotClient = async (email) => {
  try {
    const res = await axios({
      url: "client/forgot",
      method: "POST",
      data: { email: email },
    });
    return { err: res.data.error, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const resetClient = async (token, id, password) => {
  try {
    const res = await axios({
      url: "client/reset",
      method: "POST",
      data: { token: token, id: id, password: password },
    });
    return { err: res.data.error, msg: res.data.msg };
  } catch (err) {
    return { err: false, msg: err };
  }
};

// const setnewPassword = async (clientData) => {
//   try {
//     const res = await axios({
//       url: "client/set-new-password",
//       method: "POST",
//       data: clientData,
//     });
//     return { err: false, msg: res.data };
//   } catch (err) {
//     return { err: true, msg: err };
//   }
// };


const clientlogOut = async () => {
  try {
    const res = await axios({
      url: "client/logout",
      method: "POST",
    });
    return { err: false, msg: res.data };
  } catch (err) { 
    return { err: true, msg: err };
  }
}

export {
  verifyClient,
  postClient,
  authClient,
  revDataClient,
  categoryApi,
  contentApi,
  forgotClient,
  resetClient,
  patchClient,
  getClient,
  getClientChanges,
  getClientsList,
  getClientChangesByDate,
  getclientinfo,
  clientlogOut
};
