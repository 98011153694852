import { useEffect, useState, useRef } from "react";
import { Modal, Button, Table, message, Space, Popconfirm, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getClient, patchClient } from "../../../API/Clients";

// import { updateClient } from "../../redux/reducers/clientSlice";

function ArchiveClients(props) {
  const userRole = useSelector((state) => state.user.role);
  const { Search } = Input;
  const [messageApi, contextHolder] = message.useMessage();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [searchPub, setSearchPub] = useState("");
  const [update, setUpdate] = useState(false);
  const [popOpen, setPopOpen] = useState({});
  const clientref = useRef(null);
  const handleSearch = (text) => {
    setSearchPub(text);

    let searchedPubs = clientref.current.filter((pub) =>
      pub.name.toLowerCase().includes(text.toLowerCase())
    );

    setClientList(searchedPubs);
  };
  const fail = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };
  const successful = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };
  const handleCancel = () => {
    if (props.setArchOpen) {
      props.setArchOpen((prev) => false);
    }
  };
  const handleUnarchive = async (key) => {
    setConfirmLoading(true);
    let popconf = {};
    popconf[key.name] = false;
    let unarchiveClient = { ...key, isArchived: "false" };
    if (userRole === "admin") {
      unarchiveClient["userrole"] = "admin";
    }
    let res = await patchClient(unarchiveClient);
    setTimeout(() => {
      setConfirmLoading(false);
      setPopOpen(popconf);
      if (res.err === false) {
        successful("Client Unarchived");
        getData();
      } else {
        fail("error occured");
      }
    }, 2000);
  };
  const handlePopConfirm = async (key) => {
    let popconf = {};
    popconf[key] = true;
    setPopOpen(popconf);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      style: { fontSize: 20 },
      render: (text) => <span>{text}</span>,
      // width: 150,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure you want to UnArchive this Client?"
            onConfirm={() => handleUnarchive(record)}
            // open={true}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              loading: confirmLoading,
            }}
          >
            <Button
              onClick={() => {
                handlePopConfirm(record.name);
              }}
            >
              unarchive
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const getData = async () => {
    const res = await getClient();

    if (!res?.err) {
      let archivedClients = res.data.msg.filter(
        (client) => client.isArchived === "true"
      );
      setClientList(archivedClients ?? []);
      clientref.current = archivedClients;
    } else {
      setClientList([]);
    }
  };
  useEffect(() => {
    getData();
  }, [update, props.archOpen]);

  return (
    <Modal
      open={props.archOpen}
      style={{ maxWidth: "100%" }}
      width={800}
      onOk={handleCancel}
      onCancel={handleCancel}
      closable={false}
    >
      {contextHolder}
      <div className="flex justify-between items-center">
        <h2 className="text-[12px] sm:text-[1.5rem]">
          Archived Publishers List
        </h2>
        {/* <Search
          placeholder="Search for Publishers"
          allowClear
          // style={{
          //   width: 320,
          // }}

          className="sm:w-[320px] w-[100%]"
          value={searchPub}
          onChange={(e) => handleSearch(e.target.value)}
        /> */}
        <Search
          placeholder="Search for Publishers"
          allowClear
          // style={{
          //   width: 320,
          // }}

          className="sm:w-[320px] w-[100%]"
          value={searchPub}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <Table dataSource={clientList} columns={columns} scroll={{ y: 400 }} />
    </Modal>
  );
}

export default ArchiveClients;
