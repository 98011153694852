import React from "react";
import { Modal, Form, InputNumber } from "antd";
import Objectgrouop from "../Config/Objectgroup";

const Macros = (props) => {
  let data = require("./MacroConfig");
  data = data.default;
  const [form] = Form.useForm();
  const handleSubmit = () => {
    let fields = form.getFieldsValue();
    props.setMacroData(fields);
    handleCancel();
  };
  const handleCancel = () => {
    if (props.setOpen) {
      props.setOpen(false);
    }
  };
  return (
    <Modal
      title="Macros"
      open={props.open}
      onOk={form.submit}
      onCancel={handleCancel}
      
    >
      <Form form={form} onFinish={handleSubmit}>
        <Objectgrouop
          data={data}
          values={form.getFieldsValue()}
          cat="general"
        />
      </Form>
    </Modal>
  );
};

export default Macros;
