import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  theme,
  Form,
  Input,
  Select,
  Button,
  Typography,
  Modal,
  message,
  Collapse,
  Switch,
  InputNumber,
  notification,
  TimePicker,
} from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  postWebsites,
  deleteWebsites,
  patchWebsites,
} from "../../API/Websites";
import { updateWebsite } from "../../redux/reducers/WebSlice";
import { getPublishersByName, patchPublishers } from "../../API/Publishers";
import { updateGamData } from "../../API/Adunits";
import { patchUser } from "../../API/User";
import { updateUser } from "../../redux/reducers/userSlice";
import { getClient } from "../../API/Clients";

const { Panel } = Collapse;

const CreateWebsite = (props) => {
  const psl = require("psl");
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.type);
  const userName = useSelector((state) => state.user.name);
  // const [WebsitesData, setWebsitesData] = useState({});
  const [gamModalOpen, setGamModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmGamLoading, setConfirmGamLoading] = useState(false);
  const [placementSwitch, setPlacementSwitch] = useState(false);
  const publisherList = useSelector((state) => state.publisher.publisherList);
  const websiteList = useSelector((state) => state.website.websiteList);
  const clientDetail = useSelector((state) => state.client);
  const userData = useSelector((state) => state.user);
  const [pubOptions, setPubOptions] = useState([]);
  const [isFixed, setIsFixed] = useState();
  const [websites, setWebsites] = useState([""]);
  const [totalSites, setTotalSites] = useState([]);
  const [fixedWebsites, setFixedWebsites] = useState([""]);
  const [pub, setPub] = useState("");
  const [revSwitch, setRevSwitch] = useState([false]);
  const [cpmSwitch, setCpmSwitch] = useState([false]);
  const [revPub, setRevPub] = useState();
  const [cpmPub, setCpmPub] = useState();
  const [dealType, setDealType] = useState();

  // auto ga flag
  const [confirmDisable, setConfirmDisable] = useState(false);
  const [autoGA, setAutoGA] = useState(true);

  const handleSwitchChange = (val) => {
    setAutoGA(val);
    if (val === false) {
      setConfirmDisable(true);
    }
  };

  const handleDisableConfirm = () => {
    setAutoGA(false);
    setConfirmDisable(false);
  };

  const handleDisableCancel = () => {
    setAutoGA(true);
    setConfirmDisable(false);
  };

  const [newForm, setNewForm] = useState([
    {
      gmt: "GMT+",
      time: "",
      revShare: "",
      fixedCPM: "",
    },
  ]);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, share) => {
    if (share === "rev") {
      api.info({
        message: `Fill Custom Rev Share`,
        description:
          "Custom Rev Share field is empty. Please Give a rev share or change it to default",
        placement,
      });
    } else {
      api.info({
        message: `Fill Custom CPM Share`,
        description:
          "Custom CPM Share field is empty. Please Give a CPM share or change it to default",
        placement,
      });
    }
  };
  const [editFormData, setEditFormData] = useState({
    timezone: "GMT+00:00",
    revShare: "0",
    fixedCPM: "0",
  });
  // console.log("new form", newForm);
  useEffect(() => {
    const getPub = async () => {
      const res = await getPublishersByName(props.currentPub);
      setRevPub(res.data.msg.revShare);
      setCpmPub(res.data.msg.fixedCPM);
      setIsFixed(res.data.msg.isFixed);
      if (res.data.msg.isFixed === "true") {
        setDealType("fixedCPM");
      } else {
        setDealType("revShare");
      }
    };
    getPub();
    if (!props.edit) {
      publisherList.forEach((item) => {
        const temp = {};
        temp["value"] = item.name;
        temp["label"] = item.name;
        setPubOptions((prevValue) => {
          return [...prevValue, temp];
        });
      });
      setPub(props.currentPub);
    }

    let filteredWebsites = websiteList.filter((website) => {
      return (
        website.url === props.selectedSite[0] &&
        website.parent === props.currentPub
      );
    });
    if (filteredWebsites.length > 0) {
      setEditFormData((prevValue) => {
        return {
          ...prevValue,
          timezone: filteredWebsites[0]?.timezone || "GMT+00:00",
          revShare: filteredWebsites[0].revShare,
          fixedCPM: filteredWebsites[0].fixedCPM,
        };
      });
    }
  }, [publisherList, props.currentPub, websiteList, props.selectedSite]);
  const removeProtocol = (url) => {
    if (!url) {
      return "";
    }

    return url.replace(/(^\w+:|^)\/\//, "");
  };
  const validateInput = (rule, value, callback) => {
    // console.log("inside validate input");
    if (value === "" || value.trim() === "") {
      callback("Value cannot be empty or contain only spaces");
    } else {
      callback();
    }
  };
  // console.log("revSwitch", revSwitch);
  const handlePost = async () => {
    if (!props.edit) {
      const toAdd = {};
      let revCheck;
      let cpmCheck;
      websites.forEach((item, i) => {
        if (dealType === "revShare") {
          revCheck =
            dealType === "revShare" &&
            newForm[i].revShare === "" &&
            revSwitch[i] === false
              ? revPub
              : dealType === "revShare" &&
                newForm[i].revShare !== "" &&
                revSwitch[i] === true
              ? newForm[i].revShare
              : undefined;
        } else {
          revCheck =
            newForm[i].fixedCPM === "" && cpmSwitch[i] === false
              ? cpmPub
              : newForm[i].fixedCPM !== "" && cpmSwitch[i] === true
              ? newForm[i].fixedCPM
              : undefined;
        }
        if (revCheck === undefined) {
          console.log("revcheck", revCheck);
          if (dealType === "revShare") {
            openNotification("top", "rev");
          } else {
            openNotification("top");
          }
        }
      });
      if (revCheck !== undefined) {
        websites.forEach(async (item, i) => {
          if (!fixedWebsites.includes(item)) {
            toAdd["url"] = psl.parse(removeProtocol(item)).domain;
            toAdd["parent"] = pub;
            // toAdd["revShare"] =
            // dealType === "revShare"
            // ? newForm[i].revShare === ""
            //   ? revPub
            //   : newForm[i].revShare
            // : "0";
            toAdd["revShare"] =
              dealType === "revShare" &&
              newForm[i].revShare === "" &&
              revSwitch[i] === false
                ? revPub
                : dealType === "revShare" &&
                  newForm[i].revShare !== "" &&
                  revSwitch[i] === true
                ? newForm[i].revShare
                : "0";
            if (
              toAdd["revShare"] === undefined ||
              toAdd["revShare"] === "" ||
              toAdd["revShare"] === null
            ) {
              console.log("inside rev share");
              // openNotification("top");
              return;
            }
            toAdd["fixedCPM"] =
              dealType === "fixedCPM"
                ? newForm[i].fixedCPM === ""
                  ? cpmPub
                  : newForm[i].fixedCPM
                : "0";
            toAdd["timezone"] = newForm[i]["gmt"] + newForm[i]["time"];
            toAdd["isFixed"] = newForm[i].revShare === "" ? "true" : "false";
            let userClientData;
            if (userData.type === "user") {
              let name = userData?.access?.client;
              if (userData.role === "admin" || userData.role === "adops") {
                const result = await getPublishersByName(pub);
                if (!result.err && !result.data.err) {
                  name = result.data.msg.parent;
                }
              }
              const result = await getClient(name);
              if (!result.error && !result.data.err) {
                userClientData = result.data.msg;
              }
            }
            toAdd["config"] = [
              {
                playerConfig: {
                  AUTO_GA_ENABLED: autoGA,
                  HLS_ENABLED:
                    userClientData?.cdnEnabled ??
                    clientDetail?.cdnEnabled ??
                    true,
                  CDN_ENABLED:
                    userClientData?.cdnEnabled ??
                    clientDetail?.cdnEnabled ??
                    true,
                  CUSTOM_CDN_LINK:
                    userClientData?.cdnLink ?? clientDetail?.cdnLink ?? "",
                  DEFAULT_LOGO_ENABLE:
                    userClientData?.clientLogo ??
                    clientDetail?.clientLogo ??
                    false,
                  DEFAULT_LOGO_URL:
                    userClientData?.logoUrl ?? clientDetail?.logoUrl ?? "",
                  DEFAULT_LOGO_REDIRECT_LINK:
                    userClientData?.redirectLink ??
                    clientDetail?.redirectLink ??
                    "",
                },
              },
            ];
          }
          let res = await postWebsites(toAdd);

          if (res.err) {
            const errorMsg = res.msg.response?.data.msg;
            const networkmsg = res.msg?.message;
            if (errorMsg) {
              props.fail(errorMsg);
            } else {
              props.fail(networkmsg);
            }
            setTimeout(() => {
              // console.log("inside timeout function");
              setConfirmLoading(false);
            }, [2000]);
          } else {
            if (userType === "user") {
              const result = await patchUser({
                ...userData,
                userrole: "user",
                websites: [
                  ...userData.websites,
                  { url: toAdd.url, parent: toAdd.parent },
                ],
              });
            }
            dispatch(
              updateUser({
                ...userData,
                websites: [
                  ...userData.websites,
                  { url: toAdd.url, parent: toAdd.parent },
                ],
              })
            );
            props.setLogs(toAdd, "added");
            props.success("Saved Successfully!");
            props.setCurrWeb(toAdd["url"]);
            dispatch(
              updateWebsite({
                websiteList: [...websiteList],
                websiteChange: true,
              })
            );

            setTimeout(() => {
              setConfirmLoading(false);
              if (websites.length > 1) {
                props.setSuccessPage("multi");
              } else {
                props.setSuccessPage(true);
              }
              props.filterAdd(props.currentPub);
              if (props.setOpen) {
                props.setOpen(false);
              }
            }, [2000]);
          }
        });
      }
      // props.afterAdd();
    } else if (props.editWeb) {
      const res = await patchWebsites({
        url: props.selectedSite[0],
        parent: props.currentPub,
        revShare: editFormData.revShare,
        fixedCPM: editFormData.fixedCPM,
        isFixed: editFormData.revShare === "0" ? "true" : "false",
        config: props.filteredVideos[0].config,
      });
      if (res.err) {
        props.fail("Update Failed");
        setTimeout(() => {
          setConfirmLoading(false);
        }, [2000]);
      } else {
        props.success("Updated Sucessfuly");
        dispatch(
          updateWebsite({ websiteList: [...websiteList], websiteChange: true })
        );
        setTimeout(() => {
          setConfirmLoading(false);
          props.filterAdd(props.currentPub);
          if (props.setOpen) {
            props.setOpen(false);
          }
        }, [2000]);
      }
    } else {
      const res = await deleteWebsites({
        url: props.selectedSite,
        parent: props.currentPub,
      });
      // console.log("response", res);
    }
  };

  const handleChange = (e, type) => {
    setPub(e.target.value);
    // let filteredWebsites = websiteList.filter((website) => {
    //   return website.parent === e.target.value;
    // });
    // let urls = filteredWebsites.map((websites) => websites.url);
    // setWebsites(urls);
    // setFixedWebsites(urls);
  };

  const handleWebsiteChange = (e, i) => {
    let sites = [...websites];
    sites[i] = e.target.value.toLowerCase();
    setWebsites(sites);
  };

  const handleWebsiteDelete = (e, i) => {
    let sites = [...websites];
    sites.splice(i, 1);
    setWebsites(sites);
    let revS = revSwitch;
    revS.splice(i, 1);
    setRevSwitch(revS);
    let cpmS = revSwitch;
    cpmS.splice(i, 1);
    setCpmSwitch(cpmS);
    let form = newForm;
    form.splice(i, 1);
    setNewForm(form);
  };
  // console.log("cpm switch", cpmSwitch);
  const handleWebsiteCancel = () => {
    let sites = [...websites];
    const len = websites.length - 1;
    if (len > 0) {
      sites.splice(websites.length - 1, 1);
      setWebsites(sites);
    }
  };

  const handleTimezoneChange = (val, i) => {
    const updatedForm = [...newForm];
    updatedForm[i] = { ...updatedForm[i], gmt: val };
    setNewForm(updatedForm);
  };

  const handleTimeChange = (val, i) => {
    const updatedForm = [...newForm];
    updatedForm[i] = {
      ...updatedForm[i],
      time: val?.format("HH:mm") ?? dayjs("00:00", "HH:mm"),
    };
    setNewForm(updatedForm);
  };

  const handleSubmit = (values) => {
    setConfirmLoading(true);
    handlePost();

    // props.addSite();

    // dispatch(
    //   updateWebsite({ websiteList: [...websiteList], websiteChange: true })
    // );
    // props.filterWebsites(props.currentPub);
    // props.setShowWeb(false);
    // props.setShowWeb(true);
  };

  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setOpen(false);
  };
  const handleClose = () => {
    props.setModalOpen(false);
  };
  return (
    <>
      {contextHolder}
      <Modal
        title={props.edit ? "Website Info" : "Add Websites"}
        open={props.open}
        confirmLoading={confirmLoading}
        // style={{ minWidth: 800 }}
        style={{ maxWidth: "100%" }}
        width={800}
        onOk={form.submit}
        onCancel={handleCancel}
        afterClose={handleClose}
        okText="Save"
        footer={
          !props.edit
            ? [
                <Button
                  style={{ marginRight: 4 }}
                  onClick={() => {
                    setWebsites((prev) => [...prev, ""]);
                    setRevSwitch((prev) => [...prev, false]);
                    setCpmSwitch((prev) => [...prev, false]);
                    setNewForm((prev) => [
                      ...prev,
                      { revShare: "", fixedCPM: "", gmt: "GMT+", time: "" },
                    ]);
                  }}
                >
                  Add More Websites
                </Button>,
                <Button key="save" type="primary" onClick={form.submit}>
                  Save
                </Button>,
                <Button key="cancel" onClick={handleCancel} danger>
                  Cancel
                </Button>,
              ]
            : [
                <Button key="save" type="primary" onClick={form.submit}>
                  Save
                </Button>,
                <Button key="cancel" onClick={handleCancel} danger>
                  Cancel
                </Button>,
              ]
        }
      >
        <div
          style={{
            margin: "20px",
          }}
        >
          <div
            style={{
              padding: 12,
              minHeight: 80,
              background: colorBgContainer,
              marginTop: "15px",
            }}
          >
            {!props.edit ? (
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                layout="horizontal"
                style={{ maxWidth: 1000 }}
                form={form}
                onFinish={handleSubmit}
              >
                <Form.Item label="Select Publisher">
                  {/* <Select
                    placeholder="Select"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      handleChange({ target: { value: e } });
                    }}
                    options={pubOptions}
                    value={pub}
                  > */}
                  <Input disabled value={pub}></Input>
                  {/* {props.publishers.map((pub) => {
                  return (
                    <Select.Option value={pub.name}>{pub.name}</Select.Option>
                  );
                })} */}
                  {/* </Select> */}
                </Form.Item>

                {websites.map((el, i) => {
                  return (
                    <>
                      <Collapse defaultActiveKey={["0"]}>
                        <Panel
                          key={0}
                          // style={{marginBlock:16}}
                          header={
                            <div className="flex justify-between items-center">
                              <Typography>Website {i + 1}</Typography>
                              {i !== 0 && (
                                <Button
                                  onClick={(e) => {
                                    handleWebsiteDelete(e, i);
                                  }}
                                  icon={
                                    <DeleteTwoTone twoToneColor="#DC2626" />
                                  }
                                />
                              )}
                            </div>
                          }
                        >
                          <Form.Item
                            label="Website"
                            name="website"
                            hasFeedback
                            // validateFirst
                            validateTrigger="onBlur"
                            rules={[
                              {
                                required: true,
                                message: "Please enter a value for website",
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) => {
                                handleWebsiteChange(e, i);
                              }}
                              placeholder="eg. example.com"
                              // disabled={fixedWebsites.includes(websites[i])}
                              value={websites[i]}
                            />
                          </Form.Item>
                          {(userType === "user" ||
                            userName === "test90" ||
                            userName === "unibots") && (
                            <Form.Item label="Auto GA" name="autoga">
                              <Switch
                                checked={autoGA}
                                onChange={handleSwitchChange}
                              />
                            </Form.Item>
                          )}
                          <Form.Item label="Deal Type">
                            <Select
                              options={[
                                { label: "Revenue Share", value: "revShare" },
                                { label: "Fixed CPM", value: "fixedCPM" },
                              ]}
                              placeholder="Select Deal Type"
                              onChange={(val) => setDealType(val)}
                              value={dealType}
                              disabled
                            ></Select>
                          </Form.Item>
                          <Form.Item style={{ marginBottom: 0 }}>
                            {/* {newForm.map((item, i) => ( */}
                            <div key={i}>
                              <Form.Item
                                label="Timezone:"
                                style={{
                                  display: "inline-block",
                                  width: "40%",
                                  marginLeft: 80,
                                }}
                              >
                                <Select
                                  style={{ width: "100%" }}
                                  className="w-1/12"
                                  options={[
                                    { label: "GMT+", value: "GMT+" },
                                    { label: "GMT-", value: "GMT-" },
                                  ]}
                                  onChange={(val) =>
                                    handleTimezoneChange(val, i)
                                  }
                                  value={newForm[i]?.gmt || undefined}
                                ></Select>
                              </Form.Item>
                              <Form.Item
                                style={{
                                  display: "inline-block",
                                  marginLeft: 5,
                                }}
                              >
                                <TimePicker
                                  onChange={(val) => handleTimeChange(val, i)}
                                  value={
                                    newForm[i]?.time
                                      ? dayjs(newForm[i].time, "HH:mm")
                                      : undefined
                                  }
                                  format="HH:mm"
                                />
                              </Form.Item>
                            </div>
                            {/* ))} */}
                          </Form.Item>
                          {dealType !== undefined &&
                            (dealType === "revShare" ? (
                              <Form.Item
                                label={
                                  !revSwitch[i]
                                    ? "Same as Default"
                                    : "Custom rev share"
                                }
                              >
                                <Form.Item
                                  style={{
                                    display: "inline-block",
                                    // marginLeft: 4,
                                    // width: "30%",
                                    marginRight: 10,
                                  }}
                                >
                                  <Switch
                                    defaultChecked
                                    onChange={() => {
                                      const arr = [...revSwitch];
                                      const form = [...newForm];
                                      arr[i] = !arr[i];
                                      if (!arr[i])
                                        form[i] = {
                                          ...form[i],
                                          revShare: "",
                                          fixedCPM: "",
                                        };
                                      setRevSwitch(arr);
                                      setNewForm(form);
                                    }}
                                  ></Switch>
                                </Form.Item>
                                <Form.Item
                                  style={{
                                    display: "inline-block",
                                    width: "50%",
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input custom revshare",
                                    },
                                    { validator: validateInput },
                                  ]}
                                >
                                  {/* <Typography>Rev Share: </Typography> */}
                                  <InputNumber
                                    className="w-56 "
                                    // min={0}
                                    // max={100}
                                    onChange={(val) => {
                                      const form = [...newForm];
                                      form[i] = {
                                        ...form[i],
                                        revShare: val,
                                        fixedCPM: "",
                                      };
                                      setNewForm(form);
                                      // *** LOGIC FOR SINGLE WEBSITE ***
                                      // setNewForm((prevValue) => {
                                      //   return {
                                      //     ...prevValue,
                                      //     revShare: val,
                                      //     fixedCPM: "",
                                      //   };
                                      // });
                                    }}
                                    disabled={!revSwitch[i]}
                                    placeholder={
                                      !revSwitch[i]
                                        ? "Same as Default"
                                        : "Enter custom rev share"
                                    }
                                    // disabled={fixedWebsites.includes(websites[i])}
                                    value={newForm[i].revShare}
                                  />
                                </Form.Item>
                                {/* <Form.Item
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 16,
                                    width: "30%",
                                  }}
                                >
                                  <Switch
                                    onChange={() => {
                                      setRevSwitch(!revSwitch);
                                    }}
                                  ></Switch>
                                </Form.Item> */}
                              </Form.Item>
                            ) : (
                              <Form.Item
                                style={
                                  {
                                    // display: "inline-block",
                                  }
                                }
                                label={
                                  !cpmSwitch[i]
                                    ? "Same as Default"
                                    : "Custom Fixed CPM"
                                }
                              >
                                <Form.Item
                                  style={{
                                    display: "inline-block",
                                    // marginLeft: 4,
                                    // width: "30%",
                                    marginRight: 10,
                                  }}
                                >
                                  <Switch
                                    defaultChecked
                                    onChange={() => {
                                      const arr = [...cpmSwitch];
                                      const form = [...newForm];
                                      arr[i] = !arr[i];
                                      if (!arr[i])
                                        form[i] = {
                                          ...form[i],
                                          revShare: "",
                                          fixedCPM: "",
                                        };
                                      setCpmSwitch(arr);
                                      setNewForm(form);
                                    }}
                                  ></Switch>
                                </Form.Item>
                                <Form.Item
                                  style={{
                                    display: "inline-block",
                                    width: "50%",
                                  }}
                                >
                                  {/* <Typography>Rev Share: </Typography> */}
                                  <InputNumber
                                    className="w-56 "
                                    onChange={(val) => {
                                      const form = [...newForm];
                                      form[i] = {
                                        ...form[i],
                                        revShare: "",
                                        fixedCPM: val,
                                      };
                                      setNewForm(form);
                                      // *** LOGIC FOR SINGLE WEBSITE ***
                                      // setNewForm((prevValue) => {
                                      //   return {
                                      //     ...prevValue,
                                      //     fixedCPM: val,
                                      //     revShare: "",
                                      //   };
                                      // });
                                    }}
                                    disabled={!cpmSwitch[i]}
                                    placeholder={
                                      !cpmSwitch[i]
                                        ? "Same as Default"
                                        : "Enter custom Fixed CPM"
                                    }
                                    // disabled={fixedWebsites.includes(websites[i])}
                                    value={newForm[i].fixedCPM}
                                  />
                                </Form.Item>
                                {/* <Form.Item
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 16,
                                    width: "30%",
                                  }}
                                >
                                  <Switch
                                    onChange={() => {
                                      setCpmSwitch(!cpmSwitch);
                                    }}
                                  ></Switch>
                                </Form.Item>*/}
                              </Form.Item>
                            ))}
                        </Panel>
                      </Collapse>
                    </>
                  );
                })}
              </Form>
            ) : (
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                layout="horizontal"
                style={{ maxWidth: 1000 }}
                form={form}
                onFinish={handleSubmit}
              >
                <Form.Item
                  label="Selected Website"
                  style={{ marginBottom: "5px" }}
                >
                  <Form.Item style={{ display: "inline-block", width: "50%" }}>
                    <Typography>{props.selectedSite}</Typography>
                    {/* <Input
                    // onChange={(e) => {
                    //   handleWebsiteChange(e, i);
                    // }}
                    // placeholder="Enter url"
                    disabled={true}
                    value={props.selectedSite}
                  /> */}
                  </Form.Item>
                </Form.Item>
                <Form.Item label="Deal Type">
                  <Select
                    options={[
                      { label: "Revenue Share", value: "revShare" },
                      { label: "Fixed CPM", value: "fixedCPM" },
                    ]}
                    placeholder="Select Deal Type"
                    onChange={(val) => setDealType(val)}
                    // defaultValue={
                    //   editFormData.revShare === "0" ? "fixedCPM" : "revShare"
                    // }
                    value={dealType}
                    disabled
                  ></Select>
                </Form.Item>
                {dealType !== undefined &&
                  (dealType === "revShare" ? (
                    <Form.Item
                      style={{
                        marginBottom: 0,
                      }}
                      label="Publisher Share"
                    >
                      <Form.Item
                        style={{
                          display: "inline-block",
                          width: "50%",
                        }}
                      >
                        {/* <Typography>Rev Share: </Typography> */}
                        <Input
                          className="w-56 "
                          onChange={(val) => {
                            setEditFormData((prevValue) => {
                              return {
                                ...prevValue,
                                revShare: val,
                                fixedCPM: "0",
                              };
                            });
                          }}
                          placeholder="Enter custom rev share"
                          disabled
                          // disabled={fixedWebsites.includes(websites[i])}
                          value={editFormData.revShare}
                        />
                      </Form.Item>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      style={{
                        marginBottom: 0,
                      }}
                      label="Fixed Deal"
                    >
                      <Form.Item
                        style={{
                          display: "inline-block",
                          width: "50%",
                        }}
                      >
                        {/* <Typography>Rev Share: </Typography> */}
                        <Input
                          className="w-56 "
                          onChange={(val) => {
                            // console.log(val);
                            setEditFormData((prevValue) => {
                              return {
                                ...prevValue,
                                fixedCPM: val,
                                revShare: "0",
                              };
                            });
                          }}
                          placeholder="Enter custom FixedCPM"
                          disabled
                          // disabled={fixedWebsites.includes(websites[i])}
                          value={editFormData.fixedCPM}
                        />
                      </Form.Item>
                    </Form.Item>
                  ))}
                <Form.Item label="Timezone">
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "50%",
                    }}
                  >
                    {/* <Typography>Rev Share: </Typography> */}
                    <Input
                      className="w-56 "
                      onChange={(val) => {
                        setEditFormData((prevValue) => {
                          return {
                            ...prevValue,
                            revShare: val,
                            fixedCPM: "0",
                          };
                        });
                      }}
                      placeholder="Enter custom rev share"
                      disabled
                      // disabled={fixedWebsites.includes(websites[i])}
                      value={editFormData.timezone}
                    />
                  </Form.Item>
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title="Confirmation"
        open={confirmDisable}
        onOk={handleDisableConfirm}
        onCancel={handleDisableCancel}
      >
        <p>Are you sure you want to disable Auto GA?</p>
      </Modal>
    </>
  );
};

export default CreateWebsite;
