import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Select,
  Input,
  InputNumber,
  message,
  Switch,
  Button,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { cdnScriptUpload } from "../../../API/Upload";
const AddXml = (props) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [delValue, setDelValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [data, setData] = useState(null);

  const [adtagdata, setAdTagdata] = useState(null);
  const [tagscout, setTagscout] = useState(null);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [duplicateTag, setDuplicateTag] = useState(null);

  const handleDuplicateChange = (checked) => {
    setIsDuplicate(checked);
  };

  const handleCancel = () => {
    props.setOpen(false);
    setData(null);
    setEditIndex(null);
    form.resetFields();
  };
  const [messageApi, contextHolder] = message.useMessage();
  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };
  const success = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };
  const handleSubmit = () => {
    handleGenerateXml();
  };
  const handleClear = (value) => {
    setDelValue(value);
  };

  let weburl = props.currWebsite?.url;
  if (weburl && !weburl.includes("http")) {
    weburl = `https://${weburl}`;
  }
  const handleFieldChange = () => {};
  const handleGenerateXml = async () => {
    let clientdata = props.clientname;
    let clientname = clientdata.data.msg.name;
    setLoading(true);
    let fields = form.getFieldsValue();
    let prefix = `<VAST xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="3.0" xsi:noNamespaceSchemaLocation="vast.xsd">`;
    let suffix = `</VAST>`;
    let extensionTemp = `<Extensions>
                        <Extension type="waterfall" fallback_index="0"/>
                      </Extensions>`;
    let tempXML = "";
    const wrapper = (adTag, useExtension = true) => {
      return `<Ad id="${uuidv4()}">
              <Wrapper fallbackOnNoAd="true" followAdditonalWrappers="true">
              <AdSystem>${clientname.toUpperCase()}</AdSystem>
              <VASTAdTagURI>
                  <![CDATA[ ${adTag} ]]>
              </VASTAdTagURI>
              <Error id="${
                clientname.charAt(0).toUpperCase() + clientname.slice(1)
              }">
                  ${
                    clientname === "unibots" || clientname === "test90"
                      ? "https://unibots.com"
                      : weburl
                  }
              </Error>
              <Impression>
                 ${
                   clientname === "unibots" || clientname === "test90"
                     ? "https://unibots.com"
                     : weburl
                 }
              </Impression>
              ${useExtension ? extensionTemp : ""}
          </Wrapper>
          </Ad>`;
    };
    let selectedTags = props.adSourceData.filter((tag) =>
      fields?.TAGS?.includes(tag?.AD_TAG_NAME)
    );
    let duplicateTags = fields.AD_TAGS.split("\n\n")
      .map((tag) => tag.split(":\n")[1])
      .filter(Boolean);
    if (tagscout && tagscout.length > 0 && isDuplicate === true) {
      duplicateTags.forEach((tag) => {
        let adTag = tag.replace(
          "description_url=[placeholder]",
          "description_url="
        );
        adTag = adTag.replace(
          "description_url=",
          `description_url=${encodeURIComponent(fields.WEBSITE)}`
        );
        tempXML += wrapper(adTag);
      });
    } else {
      selectedTags.forEach((tag) => {
        let adTag = tag.AD_TAGS.replace(
          "description_url=[placeholder]",
          "description_url="
        );
        adTag = adTag.replace(
          "description_url=",
          `description_url=${encodeURIComponent(fields.WEBSITE)}`
        );
        tempXML += wrapper(adTag);
      });
    }

    let finalXml = prefix + tempXML + suffix;
    const response = await cdnScriptUpload(
      finalXml,
      `/vast/${props.currWebsite.config[0]["name"]}/${fields.NAME}.xml`,
      !props.editXml,
      clientname
    );
    setLoading(false);
    if (response.err) {
      error("XML exists already!");
    } else {
      let tagList = selectedTags.map((tag) => tag.AD_TAG_NAME);
      delete fields["AD_TAGS"];
      if (props.editXml) {
        let newXmlData = [...props.xmlData];
        newXmlData[editIndex] = { ...fields, TAGS: tagList };
        props.setXmlData(newXmlData);
      } else {
        props.setXmlData((prev) => [
          ...prev,
          {
            ...fields,
            TAGS: tagList,
          },
        ]);
      }
      props.setSaveXml(true);
      handleCancel();
    }
  };

  useEffect(() => {
    setData(props.xmlData[editIndex]);
  }, [editIndex]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      handleSetTag(data.TAGS);
      handleDuplicateChange(data.IS_DUPLICATE);
      form.setFieldValue("DUPLICATE_TAGS_NAME", "");
      form.setFieldValue("DUPLICATE_COUNT", "");
    }
  }, [data]);

  const handleSetTag = (value) => {
    let tagData = props.adSourceData.filter((tag) =>
      value.includes(tag.AD_TAG_NAME)
    );
    setDuplicateTag(tagData);
    let tags = "";
    tagData.forEach((tag) => {
      tags += tag.AD_TAG_NAME + ":\n" + tag.AD_TAGS + "\n\n";
    });
    form.setFieldValue("AD_TAGS", tags);
  };
  useEffect(() => {
    form.setFieldValue("TAGS", props.selectedTags);
    handleSetTag(props.selectedTags);
  }, [props.open]);

  const handleduplicateTag = (value) => {
    let tagData = props.adSourceData.filter((tag) =>
      value.includes(tag.AD_TAG_NAME)
    );
    let tags = "";
    tagData.forEach((tag) => {
      tags += tag.AD_TAG_NAME + ":\n" + tag.AD_TAGS + "\n\n";
    });
    setAdTagdata(tags);
    form.setFieldValue("DUPLICATE_COUNT", "");
  };

  const DuplicateTag = () => {
    let duplicateCount = form.getFieldValue("DUPLICATE_COUNT");
    setTagscout(duplicateCount);
    if (adtagdata) {
      for (let i = 0; i < duplicateCount; i++) {
        form.setFieldsValue({
          AD_TAGS: form.getFieldValue("AD_TAGS") + adtagdata,
        });
      }
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={props.editXml ? "Edit XML" : "Add XML"}
        open={props.open}
        style={{ maxWidth: "100%" }}
        width={800}
        onOk={form.submit}
        onCancel={handleCancel}
        destroyOnClose
        okButtonProps={{
          loading: loading,
        }}
      >
        <Form
          form={form}
          layout="horizontal"
          onFinish={handleSubmit}
          onFieldsChange={handleFieldChange}
          preserve={false}
        >
          {props.editXml && (
            <Form.Item label="Select XML">
              <Select
                onChange={(value) => {
                  setEditIndex(value);
                }}
                placeholder="Select XML to view or edit"
              >
                {props.xmlData.map((obj, i) => {
                  return <Select.Option value={i}>{obj.NAME}</Select.Option>;
                })}
              </Select>
            </Form.Item>
          )}
          {(!props.editXml || data) && (
            <>
              <div className="flex flex-row gap-5">
                <Form.Item
                  label="Enable XML"
                  name="IS_ACTIVE"
                  valuePropName="checked"
                  initialValue={true}
                >
                  <Switch />
                </Form.Item>
                {props.editXml && (
                  <Button
                    onClick={() => {
                      window.open(
                        `https://playerstorage.b-cdn.net/vast/${
                          props?.currWebsite?.config[0]?.name
                        }/${props.xmlData[editIndex].NAME}.xml?ts=${Date.now()}`
                      );
                    }}
                  >
                    View Xml
                  </Button>
                )}
              </div>
              <Form.Item
                name="NAME"
                label="Name"
                style={{ display: "inline-block", width: "30%" }}
                rules={[
                  { required: true, message: "This field is required!" },
                  {
                    validator(_, value) {
                      if (
                        props.editXml ||
                        props.xmlData.every((obj) => obj.NAME !== value)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("XML with the same name exists already!")
                      );
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Enter XML name"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toLowerCase().trim())
                  }
                  disabled={props.editXml}
                />
              </Form.Item>
              <Form.Item
                name="CPM"
                label="CPM"
                style={{
                  display: "inline-block",
                }}
                className="w-[20%] sm:ml-[20px] ml-[14px]"
                initialValue={0}
              >
                <InputNumber
                  placeholder="Enter cpm"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                name="ADVERTISER"
                label="Advertiser"
                style={{
                  display: "inline-block",
                }}
                className="sm:ml-[20px] ml-[14px]"
              >
                <Input />
              </Form.Item>
              <Form.Item name="WEBSITE" label="Website Url" className="w-[50%]">
                <Input placeholder="Enter website url" />
              </Form.Item>
              <Form.Item
                name="TAGS"
                label="Select Tags"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select
                  mode="multiple"
                  onChange={handleSetTag}
                  placeholder="Select tags by name"
                >
                  {props.adSourceData
                    .filter((tag) => tag.VAST_TAG_TYPE !== "XML")
                    .map((tag) => {
                      return (
                        <Select.Option value={tag?.AD_TAG_NAME}>
                          {tag?.AD_TAG_NAME}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Enable Duplicate Tag"
                name="IS_DUPLICATE"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch
                  checked={isDuplicate}
                  onChange={handleDuplicateChange}
                />
              </Form.Item>

              {isDuplicate && (
                <>
                  <div className="w-full flex gap-2">
                    <Form.Item
                      name="DUPLICATE_TAGS_NAME"
                      label="Duplicate Tags"
                      style={{
                        width: "50%",
                      }}
                    >
                      <Select
                        placeholder="Select tags by name"
                        onChange={handleduplicateTag}
                      >
                        {duplicateTag &&
                          duplicateTag.map((tag) => {
                            return (
                              <Select.Option value={tag?.AD_TAG_NAME}>
                                {tag?.AD_TAG_NAME}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Duplicate Tag"
                      name="DUPLICATE_COUNT"
                      style={{
                        width: "25%",
                      }}
                    >
                      <Input></Input>
                    </Form.Item>
                    <Form.Item
                      style={{
                        width: "20%",
                      }}
                    >
                      <Button onClick={DuplicateTag}>Duplicate</Button>
                    </Form.Item>
                  </div>
                </>
              )}

              <Form.Item label="Selected Ad Tags" className="m-0"></Form.Item>
              <Form.Item name="AD_TAGS">
                <TextArea
                  placeholder="Selected tags will show here"
                  rows={9}
                  disabled={true}
                  allowClear={false}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddXml;
