import axios from "../axiosInstance";

const getUsers = async () => {
  try {
    const res = await axios({
      url: "user/",
      method: "GET",
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};


// Fetch user list (admin-only endpoint).
const getUserList =   async () => {
  try {
    const res = await axios({
      url: "user/getuserslist",
      method: "GET",
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};


const postUser = async (userData) => {
  try {
    const res = await axios({
      url: "user/",
      method: "POST",
      data: {
        name: userData.name,
        username: userData.username,
        password: userData.password,
        email: userData.email,
        role: userData.role,
        access: userData.access,
        websites: userData.websites,
        publishers: userData.publishers,
        userrole: userData.userrole,
      },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const patchUser = async (userData) => {
  try {
    const res = await axios({
      url: "user/",
      method: "PATCH",
      data: {
        name: userData.name,
        username: userData.username,
        password: userData?.password?.length > 0 ? userData.password : null,
        email: userData.email,
        role: userData.role,
        access: userData.access,
        websites: userData.websites,
        publishers: userData.publishers,
        userrole: userData.userrole,
      },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const deleteUser = async (userData) => {
  try {
    const res = await axios({
      url: "user/",
      method: "DELETE",
      data: {
        name: userData.name,
        userrole: userData.userrole,
      },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const verifyUser = async (userData) => {
  // console.log(publisherData);
  try {
    const res = await axios({
      url: "user/login",
      method: "POST",
      data: {
        username: userData.username,
        password: userData.password,
        userinfo: userData.userinfo

      },
    });
    return { err: false, msg: res.data, token: res.token };
  } catch (err) {
    return { err: true, msg: err };
  }
};

// const setnewPassword = async (userdata) => {
//   try {
//     const res = await axios({
//       url: "user/set-new-password",
//       method: "POST",
//       data: userdata,
//     });
//     return { err: false, msg: res.data };
//   } catch (err) {
//     return { err: true, msg: err };
//   }
// };

const authUser = async (key) => {
  try {
    const res = await axios({
      url: "user/auth",
      method: "POST",
      data: {
        token: key,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const userlogOut = async () => {
  try {
    const res = await axios({
      url: "user/logout",
      method: "POST",
    });
    return { err: false, msg: res.data };
  } catch (err) { 
    return { err: true, msg: err };
  }
}

export {
  postUser,
  patchUser,
  getUsers,
  verifyUser,
  deleteUser,
  authUser,
  getUserList,
  userlogOut
};
