import { Button, Space, Table, message, Popconfirm, Input } from "antd";
import { useEffect, useState } from "react";
import SuccessPage from "../../Utility/SuccessPage";
import { Icon } from "@iconify/react";
import LoaderComp from "../../Utility/LoaderComp";
import {
  SettingTwoTone,
  PlusCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import CreateUser from "./CreateUser";
import { deleteUser, getUsers, getUserList } from "../../../API/User";

const ManageUsers = (props) => {
  const { Search } = Input;
  const userRole = useSelector((state) => state.user.role);
  const userName = useSelector((state) => state.user.name);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [popOpen, setPopOpen] = useState({});
  const [edit, setEdit] = useState(false);
  const [userData, setUserData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [filteredUsers, setFilteredUser] = useState([]);
  const [userList, setUserList] = useState(null);
  const [userChange, setUserChange] = useState(false);
    const fail = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };
  const successful = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };

    const handleConfirm = (key) => {
    setConfirmLoading(true);
    let popconf = {};
    popconf[key] = false;
    setTimeout(() => {
      setPopOpen(popconf);
      setConfirmLoading(false);
      let archiveUser = { name: key };
      if (userRole === "admin") {
        archiveUser["userrole"] = "admin";
      }
      const result = deleteUserData(archiveUser);
      setUserChange((prev) => !prev);
      messageApi.open({
        type: result ? "success" : "error",
        content: result ? "User deleted!" : "Could not delete user!",
        className: "custom-class",
        style: {
          marginTop: "80vh",
        },
      });
    }, 2000);
  };
  const handlePopConfirm = (key) => {
    let popconf = {};
    popconf[key] = true;
    setPopOpen(popconf);
  };
  const handleCancel = (key) => {
    let popconf = {};
    popconf[key] = false;
    setPopOpen(popconf);
  };
  const handleEdit = (data) => {
    setUserData(data);
    setEdit(true);
    setOpen(true);
    setModalOpen(true);
  };

  const deleteUserData = async (key) => {
    const result = await deleteUser(key);
    return !result.err;
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      style: { fontSize: 20 },
      render: (text) => <span>{text}</span>,
      // width: 150,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      filters: [
        {
          text: "admin",
          value: "admin",
        },
        {
          text: "tech",
          value: "tech",
        },
        {
          text: "sales",
          value: "sales",
        },
        {
          text: "adops",
          value: "adops",
        },
        {
          text: "operations",
          value: "operations",
        },
      ],
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="text"
            onClick={() => {
              handleEdit(record);
            }}
            icon={<SettingTwoTone twoToneColor="#6425FE" />}
          />
          <Popconfirm
            placement="bottomRight"
            title="Confirm"
            description="Are you sure you want to delete this item?"
            open={popOpen[record.name] ?? false}
            onConfirm={() => {
              handleConfirm(record.name);
            }}
            onCancel={() => {
              handleCancel(record.name);
            }}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            style={{ width: "100px" }}
            okButtonProps={{ loading: confirmLoading }}
          >
            {contextHolder}
            <Button
              type="text"
              onClick={() => {
                handlePopConfirm(record.name);
              }}
              className="flex items-center justify-center"
              danger
              icon={
                <Icon
                  icon="material-symbols:archive-rounded"
                  width="18"
                  height="18"
                />
              }
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [spin, setSpin] = useState(true);
  const [searchPub, setSearchPub] = useState("");
  const [archiveModal, setArchiveModal] = useState(false);
  const [archOpen, setArchOpen] = useState(false);
  const handleArchived = () => {
    setArchiveModal(true);
    setArchOpen(true);
  };

  useEffect(() => {
    const getData = async () => {
      const res = await getUserList();
      if (!res.err) {
        setUserList(res?.msg ?? []);
      } else {
        setUserList([]);
      }
    };
    getData();
  }, [userChange]);

  useEffect(() => {
    if (userList) {
      setSpin(false);
    }
    setFilteredUser(userList);
  }, [userList]);

  const afterModify = async () => {
    try {
      const response = {};
      setFilteredUser(response.data.msg);
    } catch (error) {
      fail("refresh page");
    }
  };

  const handleCreatePub = () => {
    setEdit(false);
    setOpen(true);
    setModalOpen(true);
  };
  const handleSearch = (text) => {
    setSearchPub(text);
    let searchedPubs = userList.filter((pub) =>
      pub.name.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredUser(searchedPubs);
  };
  return (
    <>
      {spin ? (
        <LoaderComp />
      ) : (
        <div className="pl-5 pr-5 w-full">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="font-medium text-[20px]">Manage Users</h1>
            </div>
            <div className="flex gap-4">
              {/* <input
                className="p-2 w-[300px] border-0 outline-none text-sm text-gray-600 placeholder-gray-400"
                placeholder="Search for Publishers"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <SearchOutlined className="px-2 text-[#A49F9F] cursor-pointer" /> */}

              <Search
                placeholder="Search for User"
                allowClear
                // style={{
                //   width: 320,
                // }}

                className="sm:w-[320px] w-[100%]"
                value={searchPub}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>

          <Button
            size="large"
            shape="round"
            onClick={handleCreatePub}
            type="primary"
            style={{
              width: "200px",
              height: "60px",
              fontSize: "18px",
              fontWeight: "500",
              position: "fixed",
              right: "30px",
              bottom: "50px",
              zIndex: 10,
            }}
          >
            <PlusCircleFilled style={{ fontSize: "18px" }} /> Add User
          </Button>
          <Table
            columns={columns}
            dataSource={filteredUsers}
            pagination={false}
            // pagination={{ pageSize: 6 }}
            scroll={{ y: 450 }}
          />
        </div>
      )}
      {modalOpen && (
        <CreateUser
          edit={edit}
          open={open}
          setOpen={setOpen}
          setModalOpen={setModalOpen}
          data={userData}
          successful={successful}
          setSuccess={setSuccess}
          fail={fail}
          handleSearch={handleSearch}
          afterModify={afterModify}
          setUserChange={setUserChange}
        />
      )}
    </>
  );
};

export default ManageUsers;
