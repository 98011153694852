import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  InputNumber,
  Tooltip,
  message,
  Button,
  Radio,
} from "antd";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import Macros from "./Macros";
const AdTag = (props) => {
  const { TextArea } = Input;
  const [tagProvider, setTagProvider] = useState("GOOGLE");
  const [openMacro, setOpenMacro] = useState(false);
  const [macroData, setMacroData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [initLength, setInitLength] = useState(null);
  const [delValue, setDelValue] = useState(null);
  const [xmlType, setXmlType] = useState("select");
  const userName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const userRole = useSelector((state) => state.user.role);

  const [selectAll, setSelectAll] = useState({
    allowed: false,
    disallowed: false,
  });

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Ad Tag Name Already Exits.",
    });
  };
  const handleSubmit = (values) => {
    let fields = form.getFieldsValue();
    if (Object.keys(macroData).length > 0) {
      fields["macro"] = macroData;
    }
    let checkDuplicate = false;

    if (!props.edit) {
      props.adSourceData.map((item) => {
        if (
          item.AD_TAG_NAME.toLowerCase() === fields.AD_TAG_NAME.toLowerCase()
        ) {
          error();
          checkDuplicate = true;
        }
      });
    }
    if (props.edit === true && props.adSourceData.length > 1) {
      if (!props.multiEditTag) {
        const newArr = props.adSourceData.filter(
          (item) =>
            item.AD_TAG_NAME.toLowerCase() !==
            props?.adSourceData[props.editTagIndex]?.AD_TAG_NAME.toLowerCase()
        );
        // console.log(newArr);
        newArr.map((item) => {
          if (
            item.AD_TAG_NAME.toLowerCase() === fields.AD_TAG_NAME.toLowerCase()
          ) {
            error();
            checkDuplicate = true;
          }
        });
      }
    }

    if (!checkDuplicate) {
      if (props.edit && props.multiEditTag) {
        let adsData = [...props.adSourceData];
        let newData = adsData.map((item) => {
          let newItem = { ...item };
          if (props.multiEditTag.includes(item.AD_TAG_NAME)) {
            newItem = { ...item, ...fields };
          }
          return newItem;
        });
        props.setAdSourceData(newData);
      } else if (props.edit) {
        let adsData = [...props.adSourceData];
        adsData[props.editTagIndex] = fields;
        props.setAdSourceData(adsData);
      } else {
        props.setAdSourceData((prev) => [...prev, fields]);
      }
      props.setSaveTags(true);
      if (props.setOpen) {
        props.setOpen(false);
      }
    }
  };
  const [form] = Form.useForm();
  const handleCancel = () => {
    if (props.setOpen) {
      props.setOpen(false);
      // props.edit(false);
    }
  };

  useEffect(() => {
    if (props.edit && props.open) {
      let fields = props.adSourceData[props.editTagIndex];
      setXmlType(fields?.XML_TYPE ?? "select");
      form.setFieldsValue(fields);
      if (!fields.ADVERTISER_NAME) {
        form.setFieldValue("ADVERTISER_NAME", "");
      }
      setTagProvider(fields.VAST_TAG_TYPE);
    } else {
      setTagProvider("GOOGLE");
    }
  }, [props.edit, props.editTagIndex, props.tabKey, props.open]);

  const handleFieldChange = () => {
    let fields = form.getFieldsValue();
    let adTags = `https://pubads.g.doubleclick.net/gampad/ads?iu=/${fields.GAM_ID},${fields.NETWORK_CODE}/${fields.AD_TAG_NAME}&description_url=[placeholder]&tfcd=0&npa=0&ad_type=audio_video&sz=1x1%7C320x180%7C320x240%7C400x225%7C640x360&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=`;
    if (fields.NETWORK_CODE && fields.GAM_ID && fields.AD_TAG_NAME) {
      form.setFieldValue("AD_TAGS", adTags);
    }
    if (fields.VAST_TAG_TYPE) {
      setTagProvider(fields.VAST_TAG_TYPE);
    }
  };

  // const handleDeleteBidder = (i) => {
  //   let bidders = [...Bidders];
  //   bidders.splice(i, 1);
  //   setBidders(bidders);
  // };
  // const handleAddMacro = () => {
  //   setOpenMacro(true);
  // };
  const handleClear = (value) => {
    setDelValue(value);
  };

  const [unibotsTag, setUnibotsTag] = useState(true);
  const handleSwitchChange = (val) => {
    setUnibotsTag(val);
  };
  useEffect(() => {
    if (
      props.advertisers.length > 0 &&
      typeof props.advertisers[0] === "string"
    ) {
      let advts = props.advertisers.map((value) => {
        return {
          value: value,
          label: (
            <div className="flex justify-between items-center">
              {value} {/* <Button icon={} /> */}
              {value.length > 0 && (
                <div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClear(value);
                    }}
                    icon={<CloseOutlined style={{ fontSize: "10px" }} />}
                  />
                </div>
              )}
            </div>
          ),
        };
      });
      props.setAdvertisers(advts);
      if (!initLength) {
        setInitLength(advts.length);
      }
    }
  }, [props.advertisers]);
  useEffect(() => {
    let newAdvts = props.advertisers.filter((item) => item.value !== delValue);
    props.setAdvertisers(newAdvts);
    setInitLength(newAdvts.length);
    if (form.getFieldValue("ADVERTISER_NAME") === delValue) {
      form.setFieldValue("ADVERTISER_NAME", "");
    }
  }, [delValue]);

  return (
    <>
      {contextHolder}
      <Modal
        title={
          props.edit && props.multiEditTag
            ? "Edit CPM"
            : props.edit
            ? "Edit Tag"
            : "Add Tag"
        }
        open={props.open}
        style={{ maxWidth: "100%" }}
        width={800}
        onOk={form.submit}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Form
          form={form}
          layout="horizontal"
          // style={{ minWidth: 900, marginLeft: "15px" }}
          onFinish={handleSubmit}
          onFieldsChange={handleFieldChange}
          preserve={false}
        >
          {!props.multiEditTag && (
            <>
              <div className="flex justify-between">
                <Form.Item
                  label="Enable Ad Tag"
                  name="IS_ACTIVE"
                  valuePropName="checked"
                  initialValue={true}
                >
                  <Switch />
                </Form.Item>
                {(userName === "unibots" ||
                  userType === "user" ||
                  userName === "test90") && (
                  <Form.Item label="Unibots Ad Tag" valuePropName="checked">
                    <Switch
                      checked={unibotsTag}
                      onChange={handleSwitchChange}
                    />
                  </Form.Item>
                )}
              </div>
              <Form.Item
                name="AD_TAG_NAME"
                label="Ad Tag Name"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input
                  placeholder="Enter ad tag name"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toLowerCase().trim())
                  }
                />
              </Form.Item>
              {tagProvider === "GOOGLE" &&
                unibotsTag === true &&
                (userName === "unibots" ||
                  userType === "user" ||
                  userName === "test90") && (
                  <div className="flex justify-between">
                    <Form.Item
                      name="GAM_ID"
                      label="GAM Account"
                      style={{
                        display: "inline-block",
                        width: "40%",
                      }}
                      rules={[
                        { required: true, message: "This field is required!" },
                      ]}
                    >
                      <Select placeholder="GAM Account">
                        <Select.Option value="21928950349">
                          21928950349
                        </Select.Option>
                        <Select.Option value="22082859479">
                          22082859479
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="NETWORK_CODE"
                      label="NETWORK CODE"
                      style={{ width: "40%" }}
                      hasFeedback
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (!value) {
                              throw new Error("This field is required!");
                            }
                            if (value.includes(" ")) {
                              throw new Error("No spaces allowed");
                            }
                            if (isNaN(value)) {
                              throw new Error("Value should be a number");
                            }
                          },
                        },
                      ]}
                    >
                      <Input placeholder="Enter Network Code" />
                    </Form.Item>
                  </div>
                )}
              <Form.Item
                name="VAST_TAG_TYPE"
                label="Vast Tag Provider"
                style={{
                  display: "inline-block",
                  width: "35%",
                }}
                rules={[{ required: true, message: "This field is required!" }]}
                initialValue={tagProvider}
              >
                <Select>
                  {tagProvider !== "XML" && (
                    <>
                      <Select.Option value="GOOGLE">Google</Select.Option>
                      <Select.Option value="DIRECT">Direct</Select.Option>
                    </>
                  )}
                  {(!props.edit || tagProvider === "XML") &&
                    (userName === "unibots" ||
                      userType === "user" ||
                      userName === "test90") && (
                      <Select.Option value="XML">XML</Select.Option>
                    )}
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item
            name="CPM"
            label="CPM"
            style={{
              display: "inline-block",
            }}
            className="w-[25%] sm:ml-[20px] ml-[14px]"
            initialValue={0}
          >
            <InputNumber placeholder="Enter cpm" />
          </Form.Item>
          {tagProvider === "DIRECT" && !props.multiEditTag && (
            <Form.Item
              name="DIRECT_CAMPAIGN"
              label={
                <div className="flex gap-2">
                  Direct Campaign
                  <Tooltip
                    placement="top"
                    title={"To prioritize direct ad campaign"}
                  >
                    {" "}
                    <InfoCircleOutlined />{" "}
                  </Tooltip>
                </div>
              }
              valuePropName="checked"
              initialValue={false}
            >
              <Switch />
            </Form.Item>
          )}
          <Form.Item
            name="TAG_RESPONSE"
            label="Select Tag Response"
            initialValue={"vast"}
            style={{ display: "inline-block" }}
          >
            <Select>
              <Select.Option value="vast">vast</Select.Option>
              <Select.Option value="vmap">vmap</Select.Option>
            </Select>
          </Form.Item>
          {!props.multiEditTag && (
            <>
              {userType === "user" && userRole === "admin" && (
                <div>
                  <Form.Item name="ALLOWED" label="Allowed Countries">
                    <Select
                      style={{ maxHeight: "200px", overflow: "auto" }}
                      mode="multiple"
                      allowClear
                      placeholder="Select allowed countries"
                      showSearch
                      onChange={(value) => {
                        if (value.includes("all")) {
                          form.setFieldValue(
                            "ALLOWED",
                            props.countryCodes.map((code) => code.value)
                          );
                          setSelectAll((prev) => {
                            return { ...prev, allowed: true };
                          });
                        } else if (value.includes("remove")) {
                          form.setFieldValue("ALLOWED", []);
                          setSelectAll((prev) => {
                            return { ...prev, allowed: false };
                          });
                        } else {
                          setSelectAll((prev) => {
                            return { ...prev, allowed: false };
                          });
                        }
                      }}
                      filterOption={(inputValue, option) => {
                        return (
                          option.value
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                          option.children
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        );
                      }}
                    >
                      <Select.Option
                        value={selectAll.allowed ? "remove" : "all"}
                      >
                        {selectAll.allowed ? "Remove All" : "Select All"}
                      </Select.Option>
                      {props.countryCodes.map((code) => {
                        return (
                          <Select.Option value={code.value}>
                            {code.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="DISALLOWED" label="Disallowed Countries">
                    <Select
                      mode="multiple"
                      style={{ maxHeight: "200px", overflow: "auto" }}
                      placeholder="Select disallowed countries"
                      showSearch
                      onChange={(value) => {
                        if (value.includes("all")) {
                          form.setFieldValue(
                            "DISALLOWED",
                            props.countryCodes.map((code) => code.value)
                          );
                          setSelectAll((prev) => {
                            return { ...prev, disallowed: true };
                          });
                        } else if (value.includes("remove")) {
                          form.setFieldValue("DISALLOWED", []);
                          setSelectAll((prev) => {
                            return { ...prev, disallowed: false };
                          });
                        } else {
                          setSelectAll((prev) => {
                            return { ...prev, allowed: false };
                          });
                        }
                      }}
                      filterOption={(inputValue, option) => {
                        return (
                          option.value
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                          option.children
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        );
                      }}
                    >
                      <Select.Option
                        value={selectAll.disallowed ? "remove" : "all"}
                      >
                        {selectAll.disallowed ? "Remove All" : "Select All"}
                      </Select.Option>
                      {props.countryCodes.map((code) => {
                        return (
                          <Select.Option value={code.value}>
                            {code.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              )}
              <Form.Item name="ADVERTISER_NAME" label="Advertiser">
                <AutoComplete
                  options={props.advertisers}
                  style={{
                    width: 200,
                  }}
                  onSelect={(data) => {
                    if (data.length > 0) {
                      const found = props.advertisers.some((advt, i) => {
                        return advt.value === data && initLength !== i;
                      });
                      if (!found) {
                        setInitLength((prev) => prev + 1);
                      }
                    }
                  }}
                  onBlur={(e) => {
                    let data = e.target.value;
                    if (data.length > 0) {
                      const found = props.advertisers.some((advt, i) => {
                        return advt.value === data && initLength !== i;
                      });
                      if (!found) {
                        setInitLength((prev) => prev + 1);
                      }
                    }
                  }}
                  onSearch={(text) => {
                    if (text.length > 0) {
                      let advts = [...props.advertisers];
                      advts[initLength] = {
                        value: text,
                        label: (
                          <div className="flex justify-between items-center">
                            {text} {/* <Button icon={} /> */}
                            {text.length > 0 && (
                              <div>
                                <Button
                                  onClick={() => {
                                    handleClear(text);
                                  }}
                                  icon={
                                    <CloseOutlined
                                      style={{ fontSize: "10px" }}
                                    />
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ),
                      };
                      props.setAdvertisers(advts);
                    }
                  }}
                  placeholder="input here"
                  allowClear
                />
              </Form.Item>
              {tagProvider === "XML" ? (
                <>
                  <Form.Item name="XML_TYPE" initialValue={"select"}>
                    <Radio.Group
                      onChange={(e) => {
                        setXmlType(e.target.value);
                        form.setFieldValue(
                          "AD_TAGS",
                          e.target.value ===
                            props.adSourceData[props.editTagIndex].XML_TYPE
                            ? props.adSourceData[props.editTagIndex].AD_TAGS
                            : null
                        );
                      }}
                    >
                      <Radio value="select">Select XML</Radio>
                      <Radio value="url">Enter URL</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="AD_TAGS"
                    label={
                      xmlType === "select" ? "Select XML" : "Enter XML url"
                    }
                    initialValue={""}
                  >
                    {xmlType === "select" ? (
                      <Select placeholder="Select from existing XML">
                        {props.xmlData.map((data) => {
                          return (
                            <Select.Option
                              value={`https://cdn.unibotscdn.com/${process.env.REACT_APP_CDN_DIR}/vast/${props?.currWebsite?.config[0]?.name}/${data.NAME}.xml`}
                            >
                              {data.NAME}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <Input placeholder="Enter url here" />
                    )}
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    label="Ad Tags"
                    className="m-0"
                    required={true}
                  ></Form.Item>
                  <Form.Item
                    name="AD_TAGS"
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                  >
                    <TextArea placeholder="Enter ad tags" rows={4} />
                  </Form.Item>
                </>
              )}
              {/* <Form.Item>
                <Button onClick={handleAddMacro} disabled={true}>
                  Add Macro
                </Button>
              </Form.Item> */}
            </>
          )}
        </Form>
        <Macros
          open={openMacro}
          setOpen={setOpenMacro}
          setMacroData={setMacroData}
        />
      </Modal>
    </>
  );
};

export default AdTag;
