import React, { useState, useEffect } from "react";
import { Form, Checkbox, Divider } from "antd";
const UbLibrary = (props) => {
  const [checkAll, setCheckAll] = useState(null);

  useEffect(() => {
    handleChange();
  }, []);
  const onCheckAllChange = () => {
    if (checkAll === true) {
      props.deselect();
    } else {
      props.selectAll();
    }
    setCheckAll((prev) => !prev);
  };
  const handleChange = () => {
    let fields = props.form.getFieldsValue();
    setCheckAll(fields?.Categories?.length >= props.categories.length);
  };
  const handleSelectAll = (e) => {
    setCheckAll(e.target.checked);
    if (e.target.checked) {
      props.form.setFieldValue("Categories", props.categories);
    } else {
      props.form.setFieldValue("Categories", []);
    }
  };
  return (
    <>
      <Form.Item name="Categories" label="Select Categories" className="">
        <Checkbox.Group
          className="flex flex-col pt-2"
          options={props.categories}
          onChange={handleChange}
        />
      </Form.Item>
      <div className="border-2 w-[50%] h-[2px] bg-gray-100 flex m-auto mt-0 mb-2"></div>
      <div className="flex mt-0">
        <Checkbox
          onChange={handleSelectAll}
          checked={checkAll}
          className="ml-0 sm:ml-[32%]"
        >
          Select All
        </Checkbox>
      </div>
      {/* <div className="flex gap-4 justify-center items-center">
        <Button onClick={() => props.selectAll()}>Select All</Button>
        <Button onClick={() => props.deselect()} danger>
          Unselect All
        </Button>
      </div> */}
    </>
  );
};

export default UbLibrary;
