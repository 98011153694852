import React, { useState, useEffect } from "react";
import { Button, InputNumber, Modal, Spin, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getClient, patchClient } from "../../API/Clients";
import { useNavigate } from "react-router-dom";
function AddPayment(props) {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const clientList = useSelector((state) => state.client);
  const [amount, setAmount] = useState();
  const [balance, setBalance] = useState(0.0);
  const [loading, setLoading] = useState(true);
  const handleAmount = () => {
    if (amount) {
      navigate("/payment", { state: { amount: amount } });
    } else {
      api.warning({
        message: `Required`,
        description: "Please fill the Amount to add",
        placement: "top",
      });
    }
  };
  useEffect(() => {
    const getInfo = async () => {
      const res = await getClient(clientList.name);
      console.log("response in client", res);
      if (res.error === null) {
        if (res.data?.msg?.balance) {
          setLoading(false);
          setBalance(res.data.msg.balance);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    getInfo();
  }, []);
  const handleNumberChange = (value) => {
    // Handle the number change event here
    setAmount(value);
    console.log(value);
  };
  const handleCancel = () => {
    props.closePaymentModal();
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={props.isModalOpen}
        closable={false}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div>
          <div className="flex sm:flex-row flex-col gap-6">
            <div className="flex flex-col">
              <p>Recharge Account</p>
              <InputNumber
                min={0} // Set the minimum value if needed
                // Set the maximum value if needed
                step={1} // Set the step value if needed
                addonAfter="$"
                onChange={handleNumberChange}
                style={{ width: "100%" }} // Set the desired width of the input
              />
              <Button type="primary" onClick={handleAmount} className="mt-4">
                Add Value
              </Button>
            </div>
            <div className="flex gap-4 items-center border-2">
              <p>Acount Balance</p>

              {loading ? (
                <Spin type="large" />
              ) : (
                <p className="border-[4px] border-sky-500">${balance ?? 0}</p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddPayment;
