import SiderLayout from "./Components/Layout/SiderLayout";
import { ConfigProvider , message} from "antd";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./Components/Auth/Login";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { authClient  , clientlogOut} from "./API/Clients";
import { authPublisher , publogOut} from "./API/Publishers";
import { authUser, userlogOut } from "./API/User";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "./redux/reducers/userSlice";
import PublisherDash from "./Components/ChildDash/PublisherDash";
import PublisherLogin from "./Components/ChildDash/PublisherLogin";
import ResetPass from "./Components/Auth/ResetPass";
import PublisherReset from "./Components/ChildDash/PublisherReset";
import { updateClient } from "./redux/reducers/clientSlice";
// import Payments from "./Components/Layout/Payments";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [cookies, setCookie] = useCookies(["access_token"]);
  const [cookieType, setCookieType] = useState(null);
  const userType = useSelector((state) => state.user.type);
  const clientDetail = useSelector((state) => state.client);
  const [messageApi, contextHolder] = message.useMessage();

  function setCookieStatus(newValue, type) {
    setCookie("access_token", newValue + "USER" + type);
  }
  const routes = ["/admin", "/publisher", "/user"];
  useEffect(() => {
    authenticateToken(cookies["access_token"]);
  }, [cookies]);
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.href = `https://cdn.unibotscdn.com/managed_vp_imgs/favicons/${
      process.env.REACT_APP_MODE === "dev"
        ? "unibots"
        : window.location.hostname.split(".")[1]
    }.ico`;
  }, []);
  async function authenticateToken(cookie) {
    let token = "";
    if (cookie !== undefined) token = cookie?.split("USER")[0].split(" ")[1];
    let type = cookie?.split("USER")[1];
    if (type) {
      setCookieType(type);
    }
    let res;
    if (type === "client") {
      res = await authClient(token);
    } else if (type === "publisher") {
      res = await authPublisher(token);
    } else if (type === "user") {
      res = await authUser(token);
    }
    if (!res || res.err || (!res.err && !res.msg.loggedin)) {
      messageApi.open({
        type: "error",
        content: "Your session has expired. Please log in again.",
      });

      let logoutResult;
      let redirectPath = "";
      const typeToLogoutFn = {
        "client": clientlogOut,
        "publisher": publogOut,
        "user": userlogOut
      };

      // Perform the logout action based on the type
      if (typeToLogoutFn[type]) {
        logoutResult = await typeToLogoutFn[type]();
      }
      // Check if logout was successful and handle navigation
      if (logoutResult && !logoutResult.err) {
        switch (type) {
          case "client":
            redirectPath = "/admin";
            break;
          case "publisher":
            redirectPath = "/publisher";
            break;
          case "user":
            redirectPath = "/user";
            break;
          default:
            redirectPath = "/";
            break;
        }
        navigate(redirectPath);
        setCookie("access_token", "");
      }      
      if (cookieType && cookieType !== "client") {
        navigate(`/${cookieType}`);
      } else if (
        !routes.includes(location.pathname) &&
        !location.pathname.includes("/reset")
      ) {
        navigate("/admin");
      }
    } else {
      dispatch(
        updateUser({
          name: res.msg.name,
          type: type,
          role: res.msg?.role ?? "",
          access: res.msg?.access ?? {},
          websites: res.msg?.websites ?? [],
          publishers: res.msg?.publishers ?? [],
        })
      );
      dispatch(updateClient({ ...clientDetail, clientChange: true }));
    }
  }

  if (cookies.access_token) {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#6425FE",
            fontFamily: "Poppins",
          },
        }}
      >
       {contextHolder}
        <div className="App">
          <Routes>
            {cookieType !== "publisher" ? (
              <Route path="/" element={<SiderLayout setCookie={setCookie}  cookies={cookies} />} />
            ) : (
              <Route
                path="/"
                element={<PublisherDash setCookie={setCookie} />}
              />
            )}
            <Route
              path="/admin"
              element={<Login setCookieStatus={setCookieStatus} />}
            />
            <Route
              path="/publisher"
              element={<PublisherLogin setCookieStatus={setCookieStatus} />}
            />
            <Route
              path="/user"
              element={
                <PublisherLogin setCookieStatus={setCookieStatus} type="user" />
              }
            />
            <Route
              path="/reset"
              element={<ResetPass setCookieStatus={setCookieStatus} />}
            />
            {/* <Route path="/resetpub" element={<PublisherReset />} /> */}
            {/* <Route path="/payment" element={<Payments />} /> */}
          </Routes>
        </div>
      </ConfigProvider>
    );
  } else {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#6425FE",
            fontFamily: "Poppins",
          },
        }}
      >
        <div className="App">
          <Routes>
            <Route
              path="/admin"
              element={<Login setCookieStatus={setCookieStatus} />}
            />
            <Route
              path="/publisher"
              element={<PublisherLogin setCookieStatus={setCookieStatus} />}
            />
            <Route
              path="/user"
              element={
                <PublisherLogin setCookieStatus={setCookieStatus} type="user" />
              }
            />
            <Route path="/reset" element={<ResetPass />} />
            {/* <Route path="/resetpub" element={<PublisherReset />} /> */}
          </Routes>
        </div>
      </ConfigProvider>
    );
  }
}

export default App;
