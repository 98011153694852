import { configureStore } from "@reduxjs/toolkit";
import pubReducer from "./reducers/PubSlice";
import webReducer from "./reducers/WebSlice";
import userReducer from "./reducers/userSlice";
import clientSlice from "./reducers/clientSlice";
import dataSlice from "./reducers/dataSlice";

export const store = configureStore({
  reducer: {
    publisher: pubReducer,
    website: webReducer,
    user: userReducer,
    client: clientSlice,
    data: dataSlice,
  },
});
