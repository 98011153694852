import StatsGraph from "../Graphs/StatsGraph";
import AreaGraph from "../Graphs/AreaGraph";
import GraphOptions from "./GraphOptions";
import { useEffect, useState } from "react";
import { Select, Spin, Typography, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import GraphTable from "./GraphTable";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { Alert, Space } from "antd";
import { getSspData } from "../../API/Websites";

const flattenData = (data) => {
  const result = [];
  const flatten = (record, parentKey) => {
    const { key, children, ...rest } = record;
    const currentKey = parentKey ? `${parentKey}.${key}` : key;
    let site = "";
    if (record.site) site = JSON.parse(JSON.stringify(record.site));

    const formattedData = Object.entries(rest).reduce(
      (acc, [property, value]) => {
        // Check if the value is a number and round it to 2 decimal places
        if (
          isNaN(value) &&
          property !== "date" &&
          property !== "name" &&
          property !== "website" &&
          property !== "type" &&
          property !== "site"
        ) {
          acc[property] = 0;
        } else if (typeof value === "number") {
          if (property.includes("fill_rate")) acc[property] = value.toFixed(4);
          else acc[property] = value.toFixed(2);
        } else {
          acc[property] = value;
        }
        return acc;
      },
      {}
    );

    const orderedKeys = [
      "date",
      "name",
      "website",
      "pv",
      "total_imp",
      "avg_ecpm",
      "adreq",
      "api_adreq",
      "fill_rate",
      "total_rev",
      "pub_rev",
      "client_rev",
      "rpm",
      "eff_fill_rate",
      "player_load",
      "site",
    ];

    const orderedData = {};
    orderedKeys.forEach((key) => {
      orderedData[key] = formattedData[key];
    });
    if (formattedData.type === "parent") {
      result.push({
        ...orderedData,
        key: currentKey,
        name: "",
        website: "",
      });
    } else {
      result.push({
        ...orderedData,
        key: currentKey,
        website: site === "" ? record.website : site,
      });
    }

    if (children) {
      children.forEach((child) => flatten(child, currentKey));
    }
  };
  data.forEach((record) => flatten(record));
  return result;
};

function LandingPage() {
  const clientName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const [totalImp, setTotalImp] = useState({ old: 0, new: 0 });
  const [totalRev, setTotalRev] = useState({ old: 0, new: 0 });
  const [totalAd, setTotalAd] = useState({ old: 0, new: 0 });
  const [AverageEcpm, setAverageEcpm] = useState({ old: 0, new: 0 });
  const [netFillRate, setNetFillRate] = useState({ old: 0, new: 0 });
  const [totalPv, setTotalPv] = useState({ old: 0, new: 0 });
  const [eFillRate, seteFillRate] = useState({ old: 0, new: 0 });
  const [total_rpm, setTotalRpm] = useState({ old: 0, new: 0 });
  const [impGraph, setImpGraph] = useState([]);
  const [revGraph, setRevGraph] = useState([]);
  const [ecpmGraph, setEcpmGraph] = useState([]);
  const [fillGraph, setFillGraph] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [childTableData, setChildTableData] = useState([]);
  const [adGraph, setAdGraph] = useState([]);
  const [pvGraph, setPvGraph] = useState([]);
  const [rpmGraph, setRpmGraph] = useState([]);
  const [eFillGraph, seteFillGraph] = useState([]);
  const [spin, setSpin] = useState(true);
  const [filterBy, setFilterBy] = useState("website");
  const [filterPub, setFilterPub] = useState(null);
  const [currweb, setCurrWeb] = useState(null);
  const [pubChange, setpubChange] = useState(false);
  const [webRevMap, setWebRevMap] = useState({});
  const [sspAPI, setSspAPI] = useState(null);
  const [biddersTableData, setBiddersTableData] = useState(null);
  const [graphDate, setGraphDate] = useState(null);
  useEffect(() => {
    if (filterPub !== null && (clientName === "unibots" || userType === "user")) {
      const data = async () => {
        let res = await getSspData(graphDate);
        setSspAPI(res.msg);
      };
      data();
    }
  }, [filterPub]);

  const [graph1, setGraph1] = useState({
    name: "",
    value: "",
  });
  const [graph2, setGraph2] = useState({
    name: "",
    value: "",
  });
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const options = [
    "Impression",
    "Revenue",
    "eCPM",
    "Fill Rate",
    "Effective Fill",
    "Ad Requests",
    "Page Views",
    "RPM",
  ];

  const dropdown = {
    Impression: impGraph,
    Revenue: revGraph,
    eCPM: ecpmGraph,
    "Fill Rate": fillGraph,
    "Effective Fill": eFillGraph,
    "Ad Requests": adGraph,
    "Page Views": pvGraph,
    RPM: rpmGraph,
  };
  const resetGraphValues = () => {
    setTotalImp({ old: 0, new: 0 });
    setTotalRev({ old: 0, new: 0 });
    setTotalAd({ old: 0, new: 0 });
    setAverageEcpm({ old: 0, new: 0 });
    setNetFillRate({ old: 0, new: 0 });
    setTotalPv({ old: 0, new: 0 });
    seteFillRate({ old: 0, new: 0 });
    setTotalRpm({ old: 0, new: 0 });
  };
  const handleDrop1 = (value) => {
    setGraph1({ name: value, value: dropdown[value] });
  };

  const handleDrop2 = (value) => {
    setGraph2({ name: value, value: dropdown[value] });
  };

  useEffect(() => {
    if (graph1.value?.length <= 0) {
      handleDrop1(options[1]);
    }
  }, [revGraph]);

  useEffect(() => {
    if (graph2.value?.length <= 0) {
      handleDrop2(options[7]);
    }
  }, [impGraph]);

  useEffect(() => {
    if (graph1.name) {
      setGraph1({ name: graph1.name, value: dropdown[graph1.name] });
    }
    if (graph2.name) {
      setGraph2({ name: graph2.name, value: dropdown[graph2.name] });
    }
  }, [impGraph, revGraph, ecpmGraph, fillGraph]);

  const flattenedData = flattenData(tableData).map((record) => {
    delete record.key;
    delete record.cpm;
    delete record.type;
    delete record.site;
    if (filterPub === null) delete record.api_adreq;
    return record;
  });

  // console.log(tableData);

  const alertMessage =
    clientName === "unibots"
      ? "Please note that the data in charts is not from GAM. Check table for that purpose"
      : "Please note that data requests of more than 7 days might take some time to fetch";

  return (
    <>
      <div className="px-5 mt-5 w-full">
        {clientName === "unibots" && (
          <Space direction="vertical" style={{ width: "100%", marginTop: -2 }}>
            <Alert message={alertMessage} banner closable />
          </Space>
        )}
        <GraphOptions
          sspAPI={sspAPI}
          setTotalImp={setTotalImp}
          setTotalRev={setTotalRev}
          setTotalAd={setTotalAd}
          setAverageEcpm={setAverageEcpm}
          setNetFillRate={setNetFillRate}
          impGraph={{ impGraph }}
          setImpGraph={setImpGraph}
          setRevGraph={setRevGraph}
          fillGraph={fillGraph}
          setEcpmGraph={setEcpmGraph}
          setFillGraph={setFillGraph}
          setAdGraph={setAdGraph}
          setGraph1={setGraph1}
          graph1={graph1}
          graph2={graph2}
          setGraph2={setGraph2}
          setTableData={setTableData}
          tableData={tableData}
          setChildTableData={setChildTableData}
          setPvGraph={setPvGraph}
          seteFillGraph={seteFillGraph}
          setTotalPv={setTotalPv}
          seteFillRate={seteFillRate}
          setTotalRpm={setTotalRpm}
          setRpmGraph={setRpmGraph}
          resetGraphValues={resetGraphValues}
          setSpin={setSpin}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          setFilterPub={setFilterPub}
          setCurrWeb={setCurrWeb}
          currweb={currweb}
          pubChange={pubChange}
          setpubChange={setpubChange}
          webRevMap={webRevMap}
          setWebRevMap={setWebRevMap}
          setBiddersTableData={setBiddersTableData}
          setGraphDate={setGraphDate}
        />
        {!spin && (
          <div className="inline-grid grid-cols-2 md:grid-cols-8 bg-white p-2 mb-5 rounded-md w-full ">
            <StatsGraph
              bgColor="#A6F7E2"
              graphColor="#6C8C3C"
              title="Page Views"
              textColor="#2C2C2C"
              value={totalPv}
              data={pvGraph}
              dataKey={"Page Views"}
            />
            <StatsGraph
              bgColor="#B79BFF"
              graphColor="#FFFFFF"
              title="Impressions"
              textColor="#FFFFFF"
              value={totalImp}
              data={impGraph}
              dataKey={"Impression"}
            />
            <StatsGraph
              bgColor="#FFE5A5"
              graphColor="#6C8C3C"
              title="eCPM"
              textColor="#2C2C2C"
              value={AverageEcpm}
              data={ecpmGraph}
              decimal={true}
              dataKey={"eCPM"}
              dollar={true}
            />
            <StatsGraph
              bgColor="#C7FFA5"
              graphColor="#6C8C3C"
              title="Revenue"
              textColor="#2C2C2C"
              value={totalRev}
              data={revGraph}
              decimal={true}
              dataKey={"Revenue"}
              dollar={true}
            />
            <StatsGraph
              bgColor="#A6F7E2"
              graphColor="#6C8C3C"
              title="Fill Rate"
              textColor="#2C2C2C"
              value={netFillRate}
              data={fillGraph}
              decimal={true}
              dataKey={"Fill Rate"}
              percent={true}
            />
            <StatsGraph
              bgColor="#B79BFF"
              graphColor="#FFFFFF"
              title="Ad Requests"
              textColor="#FFFFFF"
              value={totalAd}
              data={adGraph}
              dataKey={"Ad Requests"}
            />
            <StatsGraph
              bgColor="#FFE5A5"
              graphColor="#6C8C3C"
              title="Effective Fill"
              textColor=""
              value={eFillRate}
              data={eFillGraph}
              decimal={true}
              dataKey={"Effective Fill"}
              percent={true}
            />
            <StatsGraph
              bgColor="#C7FFA5"
              graphColor="#6C8C3C"
              title="RPM"
              textColor="#2C2C2C"
              value={total_rpm}
              data={rpmGraph}
              decimal={true}
              dataKey={"RPM"}
              dollar={true}
            />
          </div>
        )}

        {!spin && (
          <div className="flex space-x-4 w-full h-80 pr-4 hidden md:flex">
            <div className="w-2/3 bg-white pr-2 rounded-md justify-center">
              <Select
                size="small"
                onChange={handleDrop1}
                placeholder="Select Field"
                className="w-40 mb-4 ml-4 mt-4"
                defaultValue={options[1]}
              >
                {options.map((opt) => {
                  return (
                    <Select.Option disabled={opt === graph2.name} value={opt}>
                      {opt}
                    </Select.Option>
                  );
                })}
              </Select>
              {graph1.value?.length > 0 ? (
                <AreaGraph
                  gradientColor="#1890ff"
                  graphColor="#1890ff"
                  graphData={graph1.value}
                  height={true}
                  width={true}
                  dataKey={graph1.name}
                />
              ) : dropdown[graph1.name]?.length <= 0 ? (
                <div
                  style={{
                    height: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>No Data</Typography>
                </div>
              ) : (
                <div
                  style={{
                    height: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin indicator={antIcon} />
                </div>
              )}
            </div>
            <div className="w-1/3 bg-white pr-2 rounded-md justify-center ">
              <Select
                size="small"
                onChange={handleDrop2}
                placeholder="Select Field"
                className="w-36 mb-4 ml-4 mt-4"
                defaultValue={options[7]}
              >
                {options.map((opt) => {
                  return (
                    <Select.Option disabled={opt === graph1.name} value={opt}>
                      {opt}
                    </Select.Option>
                  );
                })}
              </Select>
              {graph2.value?.length > 0 ? (
                <AreaGraph
                  gradientColor="#1890ff"
                  graphColor="#1890ff"
                  graphData={graph2.value}
                  height={true}
                  dataKey={graph2.name}
                />
              ) : dropdown[graph2.name]?.length <= 0 ? (
                <div
                  style={{
                    height: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>No Data</Typography>
                </div>
              ) : (
                <div
                  style={{
                    height: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin indicator={antIcon} />
                </div>
              )}
            </div>
          </div>
        )}

        {!spin && (
          <div className="mt-3 flex justify-between ">
            <Select
              size="small"
              style={{ width: 180 }}
              placeholder="Filter By"
              value={filterBy}
              // disabled={filterPub === null || clientName === "unibots"}
              disabled={
                filterPub === null ||
                (filterPub !== null &&
                  !(clientName === "unibots" || userType === "user"))
              }
              onChange={(val) => setFilterBy(val)}
            >
              <Select.Option value="website">Filter by Websites</Select.Option>
              <Select.Option value="bidders">Filter by Bidders</Select.Option>
            </Select>
            <Button type="primary" size="small">
              <CSVLink
                filename={"TableData.csv"}
                data={flattenedData}
                className="btn btn-primary"
              >
                Export to CSV
              </CSVLink>
            </Button>
          </div>
        )}

        {!spin && (
          <div className="mt-3">
            <GraphTable
              sspAPI={sspAPI}
              tableData={tableData}
              setTableData={setTableData}
              childData={childTableData}
              setChildData={setChildTableData}
              filterBy={filterBy}
              webRevMap={webRevMap}
              filterPub={filterPub}
              currweb={currweb}
              pubChange={pubChange}
              biddersTableData={biddersTableData}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default LandingPage;
