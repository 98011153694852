import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Switch, Select } from "antd";
import CountryKv from "../CountryKv";
const Google = (props) => {
  const userName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const userRole = useSelector((state) => state.user.role);

  const [selectAll, setSelectAll] = useState({
    allowed: false,
    disallowed: false,
  });
  return (
    <>
      <Form.Item
        label="Enable Google Ad"
        name="GOOGLE"
        valuePropName="checked"
        style={{ display: "inline-block" }}
        className="sm:w-[30%]	w-[50%]"
      >
        <Switch />
      </Form.Item>
      {(userName === "unibots" ||
        (userType === "user" && userRole !== "operations") ||
        userName === "test90") && (
        <>
          <Form.Item
            label="Use Custom Params"
            name={["USE_CUSTOM_PARAMS", "IS_ENABLE"]}
            valuePropName="checked"
            // style={{ display: "inline-block" }}
            initialValue={true}
            className="sm:w-[30%]	w-[50%] inline-block"
          >
            <Switch />
          </Form.Item>
          {props.form.getFieldValue(["USE_CUSTOM_PARAMS", "IS_ENABLE"]) && (
            <>
              <Form.Item
                label="Enable Max Ad Duration"
                name={["USE_CUSTOM_PARAMS", "ENABLE_MAX_AD_DURATION"]}
                valuePropName="checked"
                initialValue={true}
                style={{ display: "inline-block" }}
                className="sm:w-[30%]	w-[50%]"
              >
                <Switch disabled />
              </Form.Item>

              <Form.Item
                label="Enable KV"
                name={["USE_CUSTOM_PARAMS", "ENABLE_KV"]}
                valuePropName="checked"
                style={{ display: "inline-block", width: "30%" }}
              >
                <Switch disabled />
              </Form.Item>
              {props.form.getFieldValue(["USE_CUSTOM_PARAMS", "ENABLE_KV"]) && (
                <>
                  <Form.Item
                    name={["USE_CUSTOM_PARAMS", "CUSTOM_KV", "IS_ENABLED"]}
                    label="Enable Custom KV"
                    valuePropName="checked"
                    initialValue={false}
                    style={{ display: "inline-block", width: "25%" }}
                  >
                    <Switch />
                  </Form.Item>
                  {props.form.getFieldValue([
                    "USE_CUSTOM_PARAMS",
                    "CUSTOM_KV",
                    "IS_ENABLED",
                  ]) && (
                    <div style={{ display: "inline-block", width: "60%" }}>
                      <Form.Item
                        name={["USE_CUSTOM_PARAMS", "CUSTOM_KV", "MAIN"]}
                        label="Main"
                        initialValue={[]}
                      >
                        <Select mode="tags" />
                      </Form.Item>
                      <Form.Item
                        name={["USE_CUSTOM_PARAMS", "CUSTOM_KV", "NEW_MAIN"]}
                        label="New Main"
                        initialValue={[]}
                      >
                        <Select mode="tags" />
                      </Form.Item>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {(userName === "unibots" ||
        (userType === "user" && userRole !== "operations") ||
        userName === "test90") && (
        <>
          {(userName === "test90" ||
            (userType === "user" && userRole === "admin")) && (
            <div className="flex gap-5">
              <Form.Item
                name={["ALLOWED", "GOOGLE"]}
                label="Allowed Countries"
                style={{ width: "50%" }}
              >
                <Select
                  style={{ maxHeight: "200px", overflow: "auto" }}
                  mode="multiple"
                  allowClear
                  placeholder="Select allowed countries"
                  showSearch
                  onChange={(value) => {
                    if (value.includes("all")) {
                      props.form.setFieldValue(
                        ["ALLOWED", "GOOGLE"],
                        props.countryCodes.map((code) => code.value)
                      );
                      setSelectAll((prev) => {
                        return { ...prev, allowed: true };
                      });
                    } else if (value.includes("remove")) {
                      props.form.setFieldValue(["ALLOWED", "GOOGLE"], []);
                      setSelectAll((prev) => {
                        return { ...prev, allowed: false };
                      });
                    } else {
                      setSelectAll((prev) => {
                        return { ...prev, allowed: false };
                      });
                    }
                  }}
                  filterOption={(inputValue, option) => {
                    return (
                      option.value
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      option.children
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    );
                  }}
                >
                  <Select.Option value={selectAll.allowed ? "remove" : "all"}>
                    {selectAll.allowed ? "Remove All" : "Select All"}
                  </Select.Option>
                  {props.countryCodes.map((code) => {
                    return (
                      <Select.Option value={code.value}>
                        {code.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name={["DISALLOWED", "GOOGLE"]}
                label="Disallowed Countries"
                style={{ width: "50%" }}
              >
                <Select
                  mode="multiple"
                  style={{ maxHeight: "200px", overflow: "auto" }}
                  placeholder="Select disallowed countries"
                  showSearch
                  onChange={(value) => {
                    if (value.includes("all")) {
                      props.form.setFieldValue(
                        ["DISALLOWED", "GOOGLE"],
                        props.countryCodes.map((code) => code.value)
                      );
                      setSelectAll((prev) => {
                        return { ...prev, disallowed: true };
                      });
                    } else if (value.includes("remove")) {
                      props.form.setFieldValue(["DISALLOWED", "GOOGLE"], []);
                      setSelectAll((prev) => {
                        return { ...prev, disallowed: false };
                      });
                    } else {
                      setSelectAll((prev) => {
                        return { ...prev, allowed: false };
                      });
                    }
                  }}
                  filterOption={(inputValue, option) => {
                    return (
                      option.value
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      option.children
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    );
                  }}
                >
                  <Select.Option
                    value={selectAll.disallowed ? "remove" : "all"}
                  >
                    {selectAll.disallowed ? "Remove All" : "Select All"}
                  </Select.Option>
                  {props.countryCodes.map((code) => {
                    return (
                      <Select.Option value={code.value}>
                        {code.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          )}
          <Form.Item
            label="DISABLE BASIC UPR"
            name="DISABLE_BASIC_UPR"
            valuePropName="checked"
            // initialValue={false}
            style={{ display: "inline-block" }}
            className="sm:w-[30%]	w-[50%]"
          >
            <Switch />
          </Form.Item>
          <CountryKv
            countryCodes={props.countryCodes}
            countryList={props.countryList}
            setCountryList={props.setCountryList}
          />
        </>
      )}
    </>
  );
};

export default Google;
