import { Spin } from "antd";

function LoaderComp(props) {
  return (
    <>
      {props.pubdash ? (
        <div className="w-screen h-screen flex justify-center items-center ">
          <Spin tip="Loading" size="large" />
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center ">
          <Spin tip="Loading" size="large" />
        </div>
      )}
    </>
  );
}

export default LoaderComp;
