import React, { useEffect, useState } from "react";
import { theme, Form, Input, Select, Modal } from "antd";
import { useSelector } from "react-redux";
import EditAccess from "./EditAccess";
import { patchUser, postUser } from "../../../API/User";
import { getClient } from "../../../API/Clients";

const CreateUser = (props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const websiteList = useSelector((state) => state.website.websiteList);
  const publisherList = useSelector((state) => state.publisher.publisherList);
  const user = useSelector((state) => state.user.name);
  const userRole = useSelector((state) => state.user.role);
  const [pubs, setPubs] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientName, setClientName] = useState("");
  const formItems = [
    [
      {
        label: "User Name",
        name: "name",
        placeholder: "Enter user name",
        rules: [
          {
            required: true,
            message: "User name is required!",
          },
        ],
      },
      {
        label: "Email",
        name: "email",
        placeholder: "Enter email",
        rules: [
          {
            type: "email",
            required: true,
            message: "The input is not valid E-mail!",
          },
        ],
      },
    ],
    [
      {
        label: "Account Login",
        name: "username",
        placeholder: "Enter username",
        rules: [
          {
            required: true,
            message: "Username Required",
          },
        ],
      },
      {
        label: "Account Password",
        name: "password",
        placeholder: "Enter password",
        rules: [
          {
            required: !props.edit,
            message: "Please input your password!",
          },
          {
            min: 12,
            message: "Password must be at least 12 characters long",
          },
          {
            pattern:
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/,
            message:
              "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (#?!@$%^&*-)",
          },
        ],
      },
      {
        label: "Confirm Password",
        placeholder: "Confirm password",
        name: "confpass",
        dependencies: ["password"],
        rules: [
          {
            required: !props.edit,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ],
      },
    ],
  ];

  useEffect(() => {
    if (props.edit) {
      setUserType(props.data.role);
      form.setFieldsValue(props.data);
      // let publishers = props.data.websites.map((site) => site.parent);
      let publishers = props.data.publishers;
      publishers = [...new Set(publishers)];
      form.setFieldValue("publishers", publishers);
      setPubs(publishers);
      let websites = props.data.websites.map((site) => JSON.stringify(site));
      form.setFieldValue("websites", websites);
      form.setFieldValue("client", props.data?.access?.client ?? "");
      setClientName(props.data?.access?.client ?? "");
      form.setFieldValue("password", "");
    }
  }, [props.edit, props.data]);

  useEffect(() => {
    if (userRole === "admin") {
      const getClientList = async () => {
        const response = await getClient();
        setClients(response.data.msg);
      };
      getClientList();
    }
  }, [userRole]);

  const [websites, setWebsites] = useState([""]);

  const handleSubmit = () => {
    // setConfirmLoading(true);
    let data = form.getFieldsValue();
    let sites = data?.websites?.map((site) => {
      return JSON.parse(site);
    });
    if (sites) {
      data["websites"] = sites;
    }

    if (!data?.access) {
      data["access"] = props?.data?.access ?? {
        menuItems: ["home"],
        player: { tabs: [], config: [] },
      };
    }
    if (userRole === "admin") {
      data["userrole"] = "admin";
    }
    if (data.role !== "admin") {
      data["access"]["client"] = userRole === "admin" ? data.client : user;
    }

    handleOk(data);
  };
  const [form] = Form.useForm();
  const [userType, setUserType] = useState("admin");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleCancel = () => {
    if (props.setOpen) {
      props.setOpen((prev) => false);
    }
  };
  const handleClose = () => {
    props.setModalOpen(false);
  };

  const handleOk = async (data) => {
    if (!props.edit) {
      const res = await postUser(data);
      if (!res.err) {
        props.successful("User added successfully!");
      } else {
        props.fail("User not added!");
      }
    } else {
      const res = await patchUser(data);
      if (!res.err) {
        props.successful("User modified successfully!");
      } else {
        props.fail("User not modified!");
      }
    }
    handleCancel();
    props.setUserChange((prev) => !prev);
  };
  const handlePubChange = (value) => {
    setPubs(value);
  };
  const handleRoleChange = (value) => {
    setUserType(value);
  };
  const handleClientChange = (value) => {
    setClientName(value);
  };
  return (
    <Modal
      title={props.edit ? "Edit User" : "Add User"}
      open={props.open}
      confirmLoading={confirmLoading}
      style={{ maxWidth: "100%" }}
      width={800}
      // style={{ minWidth: 800, left: 100 }}
      bodyStyle={{
        height: 510,
        overflowY: "auto",
        paddingTop: "20px",
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit();
          })
          .catch((error) => {
            console.log(error);
          });
      }}
      onCancel={handleCancel}
      afterClose={handleClose}
      centered={true}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        form={form}
      >
        {formItems[0].map((item) => {
          return (
            <Form.Item name={item.name} label={item.label} rules={item.rules}>
              <Input
                placeholder={item.placeholder}
                disabled={props.edit && item.name === "name"}
              />
            </Form.Item>
          );
        })}
        {formItems[1].map((item) => {
          return (
            <Form.Item
              name={item.name}
              label={item.label}
              rules={item?.rules}
              dependencies={item?.dependencies}
            >
              {item.name === "password" ? (
                <Input.Password />
              ) : (
                <Input placeholder={item.placeholder} />
              )}
            </Form.Item>
          );
        })}
        <Form.Item name="role" label="Select Role" initialValue={"admin"}>
          <Select onChange={handleRoleChange}>
            <Select.Option value="admin">Admin</Select.Option>
            <Select.Option value="tech">Tech</Select.Option>
            <Select.Option value="sales">Sales</Select.Option>
            <Select.Option value="adops">Ad-Ops</Select.Option>
            <Select.Option value="operations">Operations</Select.Option>
          </Select>
        </Form.Item>
        {userRole === "admin" && (
          <Form.Item
            name="client"
            label="Select Client"
            rules={[
              {
                required: userType === "admin" ? false : !props.edit,
                message: "Please select a client!",
              },
            ]}
          >
            {userType === "adops" ? (
              <Select mode="multiple" showSearch onChange={handleClientChange}>
                {clients.map((client) => {
                  return (
                    <Select.Option value={client.name}>
                      {client.name}
                    </Select.Option>
                  );
                })}
              </Select>
            ) : (
              <Select showSearch onChange={handleClientChange}>
                {clients.map((client) => {
                  return (
                    <Select.Option value={client.name}>
                      {client.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        )}
        {userType !== "admin" && userType !== "adops" && (
          <Form.Item name="publishers" label="Select Publisher">
            <Select
              onChange={handlePubChange}
              mode="multiple"
              style={{ maxHeight: "150px", overflow: "auto" }}
            >
              {publisherList
                .filter(
                  (pub) =>
                    pub.parent === (userRole === "admin" ? clientName : user) &&
                    pub.isArchived === "false"
                )
                .map((pub) => {
                  return (
                    <Select.Option value={pub.name}>{pub.name}</Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        )}
        {userType !== "admin" && pubs && userType !== "adops" && (
          <Form.Item name="websites" label="Select websites">
            <Select
              showSearch
              mode="multiple"
              style={{ maxHeight: "250px", overflow: "auto" }}
            >
              {websiteList
                .filter(
                  (site) =>
                    pubs.includes(site.parent) && site.isArchived === "false"
                )
                .map((site) => {
                  return (
                    <Select.Option
                      value={JSON.stringify({
                        url: site.url,
                        parent: site.parent,
                      })}
                    >
                      {site.url} ({site.parent})
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        )}
        {userType !== "admin" && (
          <EditAccess form={form} edit={props.edit} data={props.data} />
        )}
      </Form>
    </Modal>
  );
};

export default CreateUser;
