import {
  Table,
  Popconfirm,
  Button,
  Input,
  Switch,
  message,
  Dropdown,
} from "antd";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  RightOutlined,
  SearchOutlined,
  DeleteTwoTone,
  CloseOutlined,
  DashOutlined,
} from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { deletePublishers } from "../../API/Publishers";
import { patchWebsites } from "../../API/Websites";
import "./my-table.css";
import LoaderComp from "../Utility/LoaderComp";
import { updatePub, deletePub } from "../../redux/reducers/PubSlice";
import { updateWebsite } from "../../redux/reducers/WebSlice";
import { getDateTime } from "../Utility/utils";
import { patchClient } from "../../API/Clients";
import { getWebsitesByPlayerConfig } from "../../API/Websites";

const ModifyConnection = () => {
  const { Search } = Input;
  const dispatch = useDispatch();
  const publisherList = useSelector((state) => state.publisher.publisherList);
  const websiteList = useSelector((state) => state.website.websiteList);
  const userName = useSelector((state) => state.user.name);
  const clientDetail = useSelector((state) => state.client);
  const playerPcActive = async (record) => {
    const { date, time } = getDateTime();
    const updatedConfig = {
      ...record.config[0],
      playerConfig: {
        ...record.config[0].playerConfig,
        ENABLED_FOR_PC: !playerStatusPC,
        ENABLED_FOR_MB: playerStatusMB,
      },
      changes: [
        ...(record.config[0]?.changes ?? []),
        {
          name: "ENABLED_FOR_PC",
          user: userName,
          date,
          time,
          lhs: playerStatusPC,
          rhs: !playerStatusPC,
          kind: "edited",
        },
      ],
      // isActive: !playerStatusPC,
    };

    const updatedObject = {
      ...record,
      config: [updatedConfig],
      client: userName,
    };

    setLoadingSwitchPC((prev) => !prev);
    const res = await patchWebsites(updatedObject);
    // console.log(res);

    if (res.err) {
      setLoadingSwitchPC((prev) => !prev);
      fail("change failed");
    } else {
      dispatch(
        updateWebsite({ websiteList: [...websiteList], websiteChange: true })
      );
      setLoadingSwitchPC((prev) => !prev);
      setPlayerStatusPC((prev) => !prev);
      success("Player Status Changed");
    }
  };
  const playerMbActive = async (record) => {
    const { date, time } = getDateTime();
    const updatedConfig = {
      ...record.config[0],
      playerConfig: {
        ...record.config[0].playerConfig,
        ENABLED_FOR_MB: !playerStatusMB,
        ENABLED_FOR_PC: playerStatusPC,
      },
      changes: [
        ...(record.config[0]?.changes ?? []),
        {
          name: "ENABLED_FOR_MB",
          user: userName,
          date,
          time,
          lhs: playerStatusMB,
          rhs: !playerStatusMB,
          kind: "edited",
        },
      ],
      // isActive: !playerStatusPC,
    };

    const updatedObject = {
      ...record,
      config: [updatedConfig],
      client: userName,
    };

    setLoadingSwitchMB((prev) => !prev);
    const res = await patchWebsites(updatedObject);
    // console.log(res);

    if (res.err) {
      setLoadingSwitchMB((prev) => !prev);
      fail("change failed");
    } else {
      dispatch(
        updateWebsite({ websiteList: [...websiteList], websiteChange: true })
      );
      setLoadingSwitchMB((prev) => !prev);
      setPlayerStatusMB((prev) => !prev);
      success("Player Status Changed");
    }
  };
  function deleteClick(e, text) {
    e.stopPropagation();
    // console.log(text);
    setClicked(text);
    setOpen(true);
  }
  const [messageApi, contextHolder] = message.useMessage();

  const success = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };
  const fail = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const [loadingSwitchPC, setLoadingSwitchPC] = useState(false);
  const [loadingSwitchMB, setLoadingSwitchMB] = useState(false);
  const [playerStatusPC, setPlayerStatusPC] = useState();
  const [playerStatusMB, setPlayerStatusMB] = useState();
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleCancel = (e) => {
    e.stopPropagation();
    // console.log("Clicked cancel button");
    setOpen(false);
  };
  const columns = [
    {
      title: "Publisher",
      dataIndex: "name",
      key: "name",
      // width: 200,

      render: (text, record) => (
        <>
          <div className="flex justify-between items-center cursor-pointer">
            <span className="">{text}</span>{" "}
            <div className="flex gap-4">
              <Popconfirm
                title="Are you sure you want to Archive this Publisher?"
                onConfirm={(e) => handleDelete(e, record)}
                open={clicked === text ? open : false}
                okText="Yes"
                cancelText="No"
                okButtonProps={{
                  loading: confirmLoading,
                }}
                onCancel={(e) => handleCancel(e)}
              >
                <DeleteTwoTone
                  twoToneColor="#DC2626"
                  onClick={(e) => deleteClick(e, text)}
                  style={{ fontSize: "18px" }}
                />
              </Popconfirm>
              <RightOutlined
                style={{ fontSize: "16px", outline: "none", height: "32px" }}
              />{" "}
            </div>
          </div>
        </>
      ),
    },
  ];

  const column2 = [
    {
      title: "Websites",
      dataIndex: "url",
      key: "url",
      render: (text) => (
        <div className="flex justify-between relative items-center cursor-pointer">
          <span className="">{text}</span>{" "}
          {/* <div className="hidden sm:block">
            <RightOutlined
              style={{ fontSize: "16px", outline: "none", height: "32px" }}
            />{" "}
          </div> */}
          {/* <div className="block sm:hidden">
            {console.log("inside drop open")}
            <Dropdown
              trigger={["click"]}
              className="absolute inset-0 border-2"
              overlay={
                <Table
                  columns={column3}
                  dataSource={filteredVideos}
                  pagination={false}
                />
              }
            >
              <DashOutlined rotate={90} className="pl-[7rem]" />
            </Dropdown>
          </div> */}
        </div>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  const column3 = [
    {
      title: "Player Id",
      dataIndex: "config",
      key: "config",
      responsive: ["sm"],
      render: (text) => (
        <div className="flex h-[32px] items-center">
          {text.map((item) => (
            <p>{item?.name?.split("_").pop()}</p>
          ))}
        </div>
      ),
    },

    {
      title: "Player PC",
      dataIndex: "",
      key: "x",
      render: (record) => (
        <>
          <Switch
            checked={playerStatusPC}
            loading={loadingSwitchPC}
            onChange={() => playerPcActive(record)}
          />
        </>
      ),
    },
    {
      title: "Player Mobile",
      dataIndex: "",
      key: "x",
      render: (record) => (
        <>
          <Switch
            checked={playerStatusMB}
            loading={loadingSwitchMB}
            onChange={() => playerMbActive(record)}
          />
        </>
      ),
    },
  ];
  const inputRef = useRef(null);
  const [showWeb, setShowWeb] = useState(false);
  const [filteredVideos, setFilteredVideos] = useState(null);
  const [showVideo, setShowVideo] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedWebKeys, setSelectedWebKeys] = useState([]);
  const [spin, setSpin] = useState(true);
  const [pub, setPub] = useState(publisherList);
  const [pubWeb, setPubWeb] = useState([]);
  const [searchPub, setSearchPub] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  inputRef.current = publisherList.filter(
    (publisher) => publisher.isArchived !== "true"
  );
  useEffect(() => {
    if (publisherList?.length > 0) {
      setSpin(false);
      const filterPub = publisherList.filter(
        (publisher) => publisher.isArchived !== "true"
      );
      // console.log(filterPub);
      setPub(filterPub);
    }
  }, [publisherList]);

  useEffect(() => {
    setPubWeb(websiteList);
  }, [websiteList]);

  const handleDelete = async (e, record) => {
    e.stopPropagation();
    setConfirmLoading(true);
    // console.log(record.name);

    const archivePub = await deletePublishers(record.name);

    if (archivePub.err === false) {
      const { date, time } = getDateTime();
      const res = await patchClient({
        ...clientDetail,
        changes: [
          ...(clientDetail?.changes ?? []),
          {
            name: record.name,
            user: userName,
            date,
            time,
            kind: "deleted",
            lhs: record,
          },
        ],
      });
      setTimeout(() => {
        dispatch(
          updatePub({ publisherList: publisherList, publisherChange: true })
        );
        setConfirmLoading(false);
        setOpen(false);
        setShowWeb(false);
        success("Publisher Deleted Successfully");
        handleSearch("");
      }, 2000);
    } else {
    }

    // console.log("deletePub", archivePub);
  };

  const [filteredWebsites, setfilteredWebsites] = useState(null);

  const filterWebsites = (publisher) => {
    let filteredWebsites = websiteList?.filter(
      (website) => website.parent.toLowerCase() === publisher.toLowerCase()
    );
    setfilteredWebsites(filteredWebsites);
  };
  const handleRowClick = (record) => {
    setSelectedRowKeys([record.name]);
  };

  const handleWebClick = (record) => {
    setSelectedWebKeys([record.url]);
  };

  const handleFilteredVideos = async (record) => {
    // console.log("url in filter", vidname);
    // let filteredVids = websiteList?.filter(
    //   (website) =>
    //     website.url === record.url && website.parent === record.parent
    // );

    const res = await getWebsitesByPlayerConfig(record.parent, record.url);
    let filteredVids = res?.data?.msg;
    // console.log(filteredVids);
    setFilteredVideos(filteredVids);
    setPlayerStatusPC(filteredVids[0].config[0].playerConfig?.ENABLED_FOR_PC);
    setPlayerStatusMB(filteredVids[0].config[0].playerConfig?.ENABLED_FOR_MB);
  };

  const handleSearch = (text) => {
    setSearchPub(text);
    setShowWeb(false);
    setShowVideo(false);

    // console.log(text.length);
    if (text.length === 0) {
      setPub(inputRef.current);
    } else {
      // console.log(text);
      const filterPub = inputRef.current.filter((item) => {
        // filter the data based on the search text
        return item.name.toLowerCase().includes(text.toLowerCase());
      });
      // console.log(filterPub);
      if (filterPub.length !== 0) {
        setPub(filterPub);
      } else {
        handleWebsiteSearch(text);
      }
    }
  };
  const handleWebsiteSearch = (name) => {
    if (name.length === 0) {
      setPub(publisherList);
      setShowWeb(false);
      setShowVideo(false);
    } else {
      let filteredWebsites = pubWeb?.filter((website) =>
        website.url.toLowerCase().includes(name.toLowerCase())
      );
      const newPub = filteredWebsites.map((item) =>
        inputRef.current.filter((parent) => {
          return parent.name.toLowerCase() === item.parent.toLowerCase();
        })
      );
      const pubfilter = newPub.map((subArr) => subArr[0]);
      const newArr = pubfilter.filter(
        (obj, index, self) =>
          index ===
          self.findIndex((o) => o._id === obj._id && o.name === obj.name)
      );
      // console.log(newArr);
      // console.log(pubfilter);
      // console.log(newPub);
      // console.log(filteredWebsites);
      if (newArr.length === 0) {
        setShowWeb(false);
      } else {
        setShowWeb(true);
      }
      setPub(newArr);
      setfilteredWebsites(filteredWebsites);
      if (filteredWebsites.length === 1) {
        handleFilteredVideos(filteredWebsites[0]);
        setShowVideo(true);
      }
    }
  };
  const tableHeight = `${(70 / 100) * window.innerHeight}px`;
  return (
    <>
      {spin ? (
        <LoaderComp />
      ) : (
        <div className="pl-5 pr-5 w-full">
          <div className="flex justify-between items-center">
            <h1 className="font-medium text-[20px]">Manage Connections</h1>
            {contextHolder}
            <div>
              {/* <input
                className="p-2 w-[300px] border-0 outline-none text-sm text-gray-600 placeholder-gray-400"
                placeholder="Search for publishers or websites..."
                onChange={(e) => handleSearch(e.target.value)}
                value={searchPub}
              />
              <CloseOutlined
                onClick={() => handleClear()}
                style={{
                  display: searchText ? "inline-block" : "none",
                }}
              />
              <SearchOutlined className="px-2 text-[#A49F9F] cursor-pointer" /> */}
              <Search
                placeholder="Search for publishers or websites..."
                allowClear
                // style={{
                //   width: 320,
                // }}

                className="sm:w-[320px] w-[100%]"
                value={searchPub}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-3">
            <Table
              columns={columns}
              style={{ width: "50%" }}
              className="my-table"
              dataSource={pub}
              scroll={{ y: tableHeight }}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    // console.log(`Clicked row: ${rowIndex}`, record.name);
                    setShowWeb(true);
                    setShowVideo(false);
                    filterWebsites(record.name);
                    handleRowClick(record);
                    setOpen(false);
                    // do something with the clicked row data
                  },
                  style: {
                    backgroundColor: selectedRowKeys.includes(record?.name)
                      ? "#f7f0ff"
                      : "",
                  },
                };
              }}
            />

            {showWeb && (
              <Table
                columns={column2}
                style={{ width: "50%" }}
                className="my-table"
                scroll={{ y: tableHeight }}
                dataSource={filteredWebsites}
                pagination={false}
                showVideo
                expandable={{
                  expandedRowRender: (record) => (
                    <div
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      className="border-2 border-red-500"
                    >
                      <Table
                        columns={column3}
                        dataSource={filteredVideos}
                        pagination={false}
                        style={{ width: "100%" }}
                      />
                    </div>
                  ),

                  expandRowByClick: true,
                  onExpand: (expanded, record) => {
                    if (expanded) {
                      // do something when expanded
                      setShowVideo(true);
                      // setFilteredVideos(record.url);
                      handleFilteredVideos(record);
                      handleWebClick(record);
                      setExpandedRowKeys([record._id]);
                    } else {
                      setExpandedRowKeys([]);
                    }
                  },
                }}
                expandedRowKeys={expandedRowKeys}
                rowKey={(record, index) => record._id}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      // console.log(`Clicked row: ${rowIndex}`, record.url);
                      setShowVideo(true);
                      // setFilteredVideos(record.url);
                      handleFilteredVideos(record);
                      handleWebClick(record);
                      setOpen(false);
                      // do something with the clicked row data
                    },
                    style: {
                      backgroundColor: selectedWebKeys.includes(record.url)
                        ? "#f7f0ff"
                        : "",
                    },
                  };
                }}
              />
            )}
            {showVideo && (
              <></>
              // <Table
              //   style={{ width: "20%" }}
              //   columns={column3}
              //   dataSource={filteredVideos}
              //   pagination={false}
              //   // className="hidden sm:block"
              // />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ModifyConnection;
