const fields = [
  {
    name: "max_ad_duration",
    label: "Max Ad Duration",
    type: "Input",
    default: 0,
    selectOptions: null,
    isBool: false,
    isNum: true,
    display: true,
    category: "general",
  },
  {
    name: "min_ad_duration",
    label: "Min Ad Duration",
    type: "Input",
    default: 0,
    selectOptions: null,
    isBool: false,
    isNum: true,
    display: true,
    category: "general",
  },
];

export default fields;
