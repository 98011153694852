import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Upload,
  Form,
  Modal,
  message,
  Input,
  Switch,
  Select,
  Collapse,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { UploadOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { cdnLogoUpload } from "../../../API/Upload";
import { getClient } from "../../../API/Clients";

const UploadLogo = (props) => {
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const clientList = useSelector((state) => state.client);
  const [form] = Form.useForm(props.form);
  const [messageApi, contextHolder] = message.useMessage();
  const [vidElem, setVidElem] = useState(null);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(false);
  const [link, setLink] = useState("");
  const [redirectLink, setRedirectLink] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [showUpload, setShowUpload] = useState(false);
  const [clientInfo, setClientInfo] = useState(clientList);
  const [clientDefaultLogo, setClientDefaultLogo] = useState(false);
  const userName = useSelector((state) => state.user.name);
  const [uploadedImages, setuploadedImages] = useState(null);
  const [uploadClicked, setUploadClicked] = useState(false);
  if (uploadedImages === null) {
    if (props.initFields.LOGO_URL) {
      setuploadedImages([{ name: props.currWebsite.url }]);
    }
  }

  // const getData = async () => {
  //   let res = await getClient(userName);
  //   console.log("client data", res);
  //   setClientInfo(res.data.msg);
  // };
  // useEffect(() => {
  //   getData();
  // }, []);

  useEffect(() => {
    setClientInfo(clientList);
  }, [clientList]);

  // console.log("client info", clientInfo);

  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg ?? "Maximum upload limit reached!",
    });
  };
  let urlLink;
  const handlePreview = (file) => {
    if (urlLink) {
      setVidElem(() => {
        return <img src={urlLink} alt="preview" />;
      });
    } else {
      setVidElem(() => {
        return <img src={props.initFields.LOGO_URL} alt="preview" />;
      });
    }
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleUpload = (file) => {
    setFile(file);
    setUploadCount((prev) => prev + 1);
    setuploadedImages([{ name: file.name }]);
  };
  const handleRemove = () => {
    setUploadCount((prev) => prev - 1);
    setuploadedImages();
  };
  useEffect(() => {
    if (uploadCount > 10) {
      error();
    }
  }, [uploadCount]);

  useEffect(() => {
    // let fields = props.initFields;
    // // console.log("useEffect fields", fields);
    // if (
    //   (clientInfo?.clientLogo === null ||
    //     clientInfo?.clientLogo === undefined) &&
    //   !fields.LOGO_ENABLE
    // ) {
    //   setShowUpload(false);
    //   setClientDefaultLogo(false);
    // } else {
    //   if (
    //     clientInfo?.clientLogo === true &&
    //     !fields.LOGO_ENABLE &&
    //     fields.DEFAULT_LOGO_ENABLE
    //   ) {
    //     setShowUpload(false);
    //     setClientDefaultLogo(true);
    //     props.setPlayerConfig((prev) => {
    //       return {
    //         ...prev,
    //         // LOGO_REDIRECT_LINK: clientInfo.redirectLink,
    //         DEFAULT_LOGO_REDIRECT_LINK: clientInfo.redirectLink,
    //         DEFAULT_LOGO_URL: clientInfo.logoUrl,
    //         DEFAULT_LOGO_ENABLE: true,
    //         LOGO_ENABLE: false,
    //       };
    //     });
    //   } else if (clientInfo?.clientLogo === true && fields.LOGO_ENABLE) {
    //     setShowUpload(true);
    //     setClientDefaultLogo(false);
    //   } else {
    //     setShowUpload(fields.LOGO_ENABLE ?? false);
    //   }
    // }
  }, [props.initFields, clientInfo]);

  useEffect(() => {
    // if (showUpload === false && clientDefaultLogo === false) {
    //   props.setPlayerConfig((prev) => {
    //     return {
    //       ...prev,
    //       LOGO_REDIRECT_LINK: "",
    //       DEFAULT_LOGO_REDIRECT_LINK: "",
    //       LOGO_URL: "",
    //       LOGO_ENABLE: false,
    //       DEFAULT_LOGO_ENABLE: false,
    //     };
    //   });
    // }
  }, [showUpload, clientDefaultLogo]);

  // async function handleDefaultChange() {
  //   let fields = form.getFieldValue();

  //   // console.log("default feilds", fields);
  //   // console.log("default logo", clientDefaultLogo);
  //   // console.log("showipload", showUpload);
  //   if (
  //     clientInfo["clientLogo"] === false ||
  //     clientInfo.clientLogo === null ||
  //     clientInfo["clientLogo"] === undefined
  //   ) {
  //     error("Add default Client Logo in you Profile");
  //   } else if (showUpload === true && clientDefaultLogo === false) {
  //     setShowUpload(false);
  //     setClientDefaultLogo(true);
  //     props.setPlayerConfig((prev) => {
  //       return {
  //         ...prev,
  //         DEFAULT_LOGO_REDIRECT_LINK: clientInfo.redirectLink,
  //         DEFAULT_LOGO_URL: clientInfo.logoUrl,
  //         DEFAULT_LOGO_ENABLE: true,
  //         LOGO_ENABLE: false,
  //       };
  //     });
  //   } else if (showUpload === false && clientDefaultLogo === false) {
  //     setClientDefaultLogo(true);
  //     props.setPlayerConfig((prev) => {
  //       return {
  //         ...prev,
  //         DEFAULT_LOGO_REDIRECT_LINK: clientInfo.redirectLink,
  //         DEFAULT_LOGO_URL: clientInfo.logoUrl,
  //         DEFAULT_LOGO_ENABLE: true,
  //         LOGO_ENABLE: false,
  //       };
  //     });
  //   } else if (showUpload === false && clientDefaultLogo === true) {
  //     setClientDefaultLogo(false);
  //   }
  // }

  function handleChange(flag) {
    let fields = form.getFieldsValue();
    if (flag === "default") {
      form.setFieldValue("LOGO_ENABLE", !fields.DEFAULT_LOGO_ENABLE);
    }
    if (flag === "custom") {
      form.setFieldValue("DEFAULT_LOGO_ENABLE", !fields.LOGO_ENABLE);
    }
    props.handleChange();
  }

  const [activeKey, setActiveKey] = useState(["1"]);

  const handleCollapseChange = (keys) => {
    setActiveKey(keys);
  };
  useEffect(() => {
    form.setFieldsValue({
      ENABLE_LOGO: {
        PC_INREAD_LOGO: true,
        PC_FLOATING_LOGO: true,
        MOB_INREAD_LOGO: true,
        MOB_FLOATING_LOGO: true,
      },
    });
  }, []);

  const [position, setPosition] = useState(form.getFieldValue("LOGO_POSITION"));
  const [closePosition, setClosePosition] = useState("topleft");
  const [isModalOpen, setIsModalOpen] = useState(false);

  let fields = props.initFields;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const positionOptions = [
    { value: "topright", label: "Top Right" },
    { value: "topleft", label: "Top Left" },
    { value: "bottomright", label: "Bottom Right" },
  ];

  const positionStyles = {
    topright: {
      display: "flex",
      justifyContent: "flex-end",
    },
    topleft: {
      display: "flex",
      justifyContent: "flex-start",
    },
    bottomright: {
      position: "absolute",
      right: "28px",
    },
  };

  const positionChange = (value) => {
    const positionToAutoMap = {
      topright: "topleft",
      topleft: "topright",
      bottomright: "topleft",
    };
    const autoValue = positionToAutoMap[value] || "";
    setPosition(value);
    setClosePosition(autoValue);
    props.handleChange();
  };

  return (
    <>
      <Collapse
        activeKey={activeKey}
        onChange={handleCollapseChange}
        className="mb-8"
      >
        <Panel header="LOGO SETTINGS" key="1">
          <div>
            <div className="flex justify-between mr-10">
              <div>
                {/* DEFAULT LOGO ENABLE */}
                <Form.Item
                  label={<div className="text-xs">Default Player Logo</div>}
                  style={{
                    width: "100%",
                    marginLeft: "40px",
                  }}
                ></Form.Item>
                <Form.Item
                  name="DEFAULT_LOGO_ENABLE"
                  // valuePropName="checked"
                  style={{
                    marginBottom: "10px",
                    marginLeft: "40px",
                    marginTop: "-25px",
                  }}
                  valuePropName="checked"
                >
                  <Switch
                    onChange={() => {
                      handleChange("default");
                    }}
                  />
                </Form.Item>
              </div>
              <div>
                {form.getFieldValue("LOGO_ENABLE") === true &&
                uploadedImages === null ? (
                  ""
                ) : (
                  <div className="flex gap-8">
                    <Form.Item
                      name="LOGO_POSITION"
                      label={<div className="text-xs">Logo Position</div>}
                      style={{
                        display: "inline-block",
                        width: "fit-content",
                        marginBottom: "30px",
                        marginLeft: "100px",
                      }}
                      initialValue={"topright"}
                    >
                      <Select
                        onChange={positionChange}
                        options={positionOptions}
                        dropdownMatchSelectWidth={true}
                      />
                    </Form.Item>
                    <Button
                      type="primary"
                      onClick={showModal}
                      style={{
                        display: "inline-block",
                        marginLeft: "70px",
                        marginBottom: "30px",
                      }}
                    >
                      Logo Preview
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className="flex mb-[-30px]">
              {/* Custom LOGO ENABLE */}
              <div>
                <Form.Item
                  label={
                    <div className="text-xs">Enable Custom Player Logo</div>
                  }
                  style={{
                    marginLeft: "40px",
                    width: "100%",
                  }}
                ></Form.Item>
                <Form.Item
                  name="LOGO_ENABLE"
                  // valuePropName="checked"
                  style={{
                    width: "40%",
                    marginLeft: "40px",
                    marginTop: "-25px",
                  }}
                  valuePropName="checked"
                >
                  <Switch
                    onChange={() => {
                      handleChange("custom");
                    }}
                  />
                </Form.Item>
              </div>
              <div className="flex gap-[100px]">
                <div>
                  {form.getFieldValue("LOGO_ENABLE") && (
                    <>
                      <Form.Item
                        label={
                          <div className="text-xs">Logo Redirect Link</div>
                        }
                        style={{
                          width: "100%",
                          marginLeft: "150px",
                        }}
                      ></Form.Item>
                      <Form.Item
                        name="LOGO_REDIRECT_LINK"
                        style={{
                          display: "inline-block",
                          width: "100%",
                          marginLeft: "150px",
                          marginTop: "-25px",
                          marginBottom: "30px",
                        }}
                        initialValue={""}
                      >
                        <Input
                          placeholder="Enter url"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </>
                  )}
                </div>
                <div>
                  {form.getFieldValue("LOGO_ENABLE") && (
                    <>
                      <Form.Item
                        label={<div className="text-xs">Upload Logo</div>}
                        style={{
                          width: "100%",

                          marginLeft: "100px",
                        }}
                      ></Form.Item>
                      <Form.Item
                        name="upload"
                        style={{
                          marginBottom: "30px",
                          marginLeft: "100px",
                          width: "100%",
                          marginTop: "-25px",
                        }}
                        className="block sm:inline-block ml-[24px] sm:ml-[10px]"
                        initialValue={""}
                      >
                        <Upload
                          accept="image/png, image/svg+xml"
                          fileList={uploadedImages}
                          customRequest={(file) => {
                            let fileSize = file.file.size / 1000;
                            if (fileSize <= 100) {
                              const reader = new FileReader();
                              reader.onload = async (event) => {
                                const response = await cdnLogoUpload(
                                  event.target.result,
                                  props.currWebsite.url +
                                    "." +
                                    file.file.name.split(".").pop()
                                );
                                if (response.err) {
                                  file.onError("error");
                                } else {
                                  setUploadClicked(true);
                                  setLink(response.link);
                                  setRedirectLink(true);
                                  urlLink = response.link;
                                  props.setPlayerConfig((prev) => {
                                    return {
                                      ...prev,
                                      LOGO_URL: response.link,
                                      LOGO_ENABLE: true,
                                      DEFAULT_LOGO_ENABLE: false,
                                    };
                                  });
                                  file.onSuccess("ok");
                                }
                              };
                              reader.readAsArrayBuffer(file.file);
                            } else {
                              error("Image should be under 100 Kb");
                              file.onError("error");
                            }
                          }}
                          onPreview={() => handlePreview(file)}
                          maxCount={1}
                          onRemove={handleRemove}
                          beforeUpload={handleUpload}
                        >
                          <Button icon={<UploadOutlined />}>Upload Logo</Button>
                        </Upload>
                      </Form.Item>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/*  LOGO Status */}
            {(form.getFieldValue("LOGO_ENABLE") ||
              form.getFieldValue("DEFAULT_LOGO_ENABLE")) && (
              <div className="flex justify-between mt-3 ml-10 mr-10">
                <div>
                  <Form.Item
                    label={<div className="text-xs">PC Inread</div>}
                    style={{
                      width: "100%",
                    }}
                  ></Form.Item>
                  <Form.Item
                    name={["ENABLE_LOGO", "PC_INREAD_LOGO"]}
                    style={{
                      width: "30%",
                      marginBottom: "30px",
                      marginTop: "-25px",
                    }}
                    valuePropName="checked"
                  >
                    <Switch onChange={props.handleChange} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label={<div className="text-xs">PC Floating</div>}
                    style={{
                      width: "100%",
                    }}
                  ></Form.Item>
                  <Form.Item
                    name={["ENABLE_LOGO", "PC_FLOATING_LOGO"]}
                    style={{
                      width: "30%",
                      marginTop: "-25px",
                      marginBottom: "30px",
                    }}
                    valuePropName="checked"
                  >
                    <Switch onChange={props.handleChange} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label={<div className="text-xs">Mobile Inread</div>}
                    style={{
                      width: "100%",
                    }}
                  ></Form.Item>
                  <Form.Item
                    name={["ENABLE_LOGO", "MOB_INREAD_LOGO"]}
                    style={{
                      width: "30%",
                      marginBottom: "30px",
                      marginTop: "-25px",
                    }}
                    valuePropName="checked"
                  >
                    <Switch onChange={props.handleChange} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label={<div className="text-xs">Mobile Floating</div>}
                    style={{
                      width: "100%",
                    }}
                  ></Form.Item>
                  <Form.Item
                    name={["ENABLE_LOGO", "MOB_FLOATING_LOGO"]}
                    style={{
                      width: "30%",
                      marginBottom: "30px",
                      marginTop: "-25px",
                    }}
                    valuePropName="checked"
                  >
                    <Switch onChange={props.handleChange} />
                  </Form.Item>
                </div>
              </div>
            )}
          </div>
        </Panel>
      </Collapse>
      {contextHolder}
      <Modal
        title="Preview"
        width={370}
        open={open}
        footer={null}
        onCancel={handleCancel}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            background: "#FEF8DD",
          }}
        >
          {vidElem}
        </div>

        {/* <SketchPicker color={color} onChange={handleColorChange} /> */}
        {/* <Form>
          <Form.Item label="Redirect Link">
            <Input placeholder="Enter link" />
          </Form.Item>
        </Form> */}
      </Modal>
      <Modal
        title="Player Logo Position"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexFlow: position === "topleft" ? "row-reverse" : "",
          }}
        >
          <div style={positionStyles[closePosition]}>
            <img
              src="https://unibots.b-cdn.net/ubplayer/logo/new/logo_2.svg"
              style={{
                marginTop: "15px",
                borderRadius: "0px",
                transform: closePosition === "topright" ? "scaleX(-1)" : "",
              }}
              alt="closebutton"
            ></img>
          </div>

          <div
            style={positionStyles[position]}
            className={
              position === "bottomright" ? "sm:bottom-[95px]	bottom-[85px]" : ""
            }
          >
            <img
              style={{
                width: "120px",
                height: "30px",
                marginTop: "5px",
                objectFit: "scale-down",
              }}
              src={
                fields.LOGO_ENABLE === true
                  ? fields.LOGO_URL
                  : fields.DEFAULT_LOGO_URL
              }
              alt="logo"
            />
          </div>
        </div>
        <img
          style={{ width: "100%" }}
          src={"https://cdn.unibotscdn.com/managed_vp_imgs/sample_player.svg"}
          alt="player"
        ></img>
      </Modal>
    </>
  );
};

export default UploadLogo;
