import React, { useEffect, useRef, useState } from "react";
import { Form, Collapse, Typography, Button } from "antd";
import ManageBidder from "./ManageBidder";
import { DeleteTwoTone } from "@ant-design/icons";
const Bidders = (props) => {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [activeKeys, setActiveKeys] = useState([]);
  const [initCount, setInitCount] = useState([]);

  const BiddersList = [
    { name: "appnexus", params: ["placement_id"], optparams: [] },
    { name: "pubmatic", params: ["publisherId|159939"], optparams: ["adSlot"] },
    { name: "33across", params: ["siteId", "productId"], optparams: [] },
    {
      name: "adf",
      label: "AdForm",
      params: ["mid"],
      optparams: ["adxDomain|adx.adform.net"],
    },
    { name: "adsolut", params: ["host", "zoneId"], optparams: [] },
    { name: "adtelligent", params: ["aid"], optparams: [] },
    { name: "adyoulike", params: ["placement"], optparams: [] },
    { name: "beyondmedia", params: ["placementId"], optparams: [] },
    {
      name: "aniview",
      params: ["AV_PUBLISHERID", "AV_CHANNELID"],
      optparams: [],
    },
    { name: "automatad", params: ["siteId"], optparams: ["placementId"] },
    {
      name: "avantisvideo",
      params: ["AV_PUBLISHERID", "AV_CHANNELID"],
      optparams: [],
    },
    { name: "adprime", params: ["placementId"], optparams: [] },
    {
      name: "bluebillywig",
      params: ["publicationName", "accountId", "rendererCode", "connections"],
      optparams: [],
    },
    { name: "criteo", params: ["zoneId", "networkId"], optparams: [] },
    { name: "freewheelssp", params: ["zoneId"], optparams: [] },
    { name: "emx_digital", params: ["tagid"], optparams: [] },
    { name: "ix", params: ["siteId"], optparams: [] },
    { name: "kueezrtb", params: ["cId", "pId"], optparams: ["bidFloor"] },
    {
      name: "nextMillennium",
      params: ["placement_id"],
      optparams: ["group_id"],
    },
    {
      name: "openx",
      params: ["delDomain|unibots-d.openx.net", "unit"],
      optparams: [],
    },
    { name: "oftmedia", params: ["placementId"], optparams: [] },
    { name: "onetag", params: ["pubId|60c32c42465aac2"], optparams: [] },
    {
      name: "pgammedia",
      params: ["AV_PUBLISHERID", "AV_CHANNELID"],
      optparams: [],
    },
    { name: "rhythmone", params: ["placementId"], optparams: ["zone", "path"] },
    {
      name: "rubicon",
      params: ["accountId|23976", "siteId|400078", "zoneId"],
      optparams: [],
    },
    { name: "sharethrough", params: ["pkey"], optparams: [] },
    {
      name: "smaato",
      params: ["publisherId", "adspaceId", "adbreakId"],
      optparams: [],
    },
    {
      name: "smartadserver",
      params: ["networkId", "siteId", "pageId", "formatId"],
      optparams: [],
    },
    { name: "ttd", params: ["supplySourceId", "publisherId"], optparams: [] },
    { name: "smartyads", params: ["placementId"], optparams: [] },
    {
      name: "triplelift",
      params: ["inventoryCode", "adUnit.mediaTypes.video.placement"],
      optparams: [],
    },
    { name: "spotx", params: ["channel_id", "ad_unit"], optparams: [] },
    { name: "sonobi", params: ["placement_id", "ad_unit"], optparams: [] },
    { name: "sovrn", params: ["tagid"], optparams: ["bidfloor", "adunitcode"] },
    { name: "ucfunnel", params: ["adid"], optparams: [] },
    { name: "unruly", params: ["siteId"], optparams: ["featureOverrides"] },
  ];
  const handleChange = () => {
    let fields = form.getFieldsValue();
    if (fields.Bidders) {
      let bidders = fields["Bidders"].map((bidder) => {
        if (bidder?.BIDDER) {
          let bidderName = bidder["BIDDER"];
          let bidderData = BiddersList.filter((bidder) => {
            return bidder.name === bidderName;
          });
          bidderData = bidderData[0];
          for (let key in bidder["PARAMS"]) {
            let params = bidderData.params.map((param) => param.split("|")[0]);

            let optparams = bidderData.optparams.map(
              (param) => param.split("|")[0]
            );
            let paramInclude = params.includes(key) || optparams.includes(key);
            if (bidder["PARAMS"][key] === undefined || !paramInclude) {
              delete bidder["PARAMS"][key];
            }
          }
          if (bidder["supply_chain_config"]) {
            bidder["supply_chain_config"]["hp"] = 1;
          }
          for (let key in bidder["supply_chain_config"]) {
            if (bidder["supply_chain_config"][key] === undefined) {
              delete bidder["supply_chain_config"][key];
            }
          }
          return bidder ?? {};
        } else {
          return {};
        }
      });
      bidders = bidders.filter((bidder) => {
        return Object.keys(bidder).length > 0;
      });
      props.setBiddersData(bidders);
    }
  };

  useEffect(() => {
    let fields = { Bidders: props.initBidderData };
    form.setFieldsValue(fields);
    setInitCount(props.initBidderData.length);
  }, []);

  const ref = useRef();

  const handleCollapse = (value) => {
    setActiveKeys(value);
  };

  return (
    <Form form={form} onFieldsChange={handleChange}>
      <Form.List name="Bidders">
        {(Bidders, { add, remove }) => (
          <>
            {
              <div>
                <div className="my-5">
                  <Button
                    type="primary"
                    onClick={() => {
                      add();
                      setActiveKeys((prev) => [...prev, "panel" + 0]);
                      // const elem = document.getElementById(
                      //   "panel" + (Bidders.length - props.initBidderData.length)
                      // );
                      // elem.scrollIntoView({
                      //   behavior: "smooth",
                      //   block: "center",
                      //   inline: "center",
                      // });
                    }}
                  >
                    Add Bidder
                  </Button>
                </div>
                <div ref={ref}>
                  <Collapse activeKey={activeKeys} onChange={handleCollapse}>
                    {Bidders.slice(0)
                      .reverse()
                      .map((field, i) => {
                        let bidderData =
                          field.key < initCount
                            ? BiddersList.filter(
                                (bidder) =>
                                  bidder.name ===
                                  props?.initBidderData[field.key]?.BIDDER
                              )
                            : null;
                        let bidderLabel = "New Bidder " + (field.key + 1);
                        if (bidderData && bidderData.length > 0) {
                          bidderLabel =
                            bidderData[0].label ?? bidderData[0].name;
                        }
                        return (
                          <Panel
                            key={"panel" + i}
                            id={"panel" + i}
                            header={
                              <div className="flex justify-between items-center">
                                <Typography>{bidderLabel}</Typography>
                                <Button
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                  icon={
                                    <DeleteTwoTone twoToneColor="#DC2626" />
                                  }
                                />
                              </div>
                            }
                          >
                            <ManageBidder
                              field={field}
                              form={form}
                              biddersList={BiddersList}
                              type={
                                i < props.initBidderData.length
                                  ? props.initBidderData[i]?.type
                                  : "net"
                              }
                            />
                          </Panel>
                        );
                      })}
                  </Collapse>
                </div>
              </div>
            }
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default Bidders;
