import React from "react";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";

const UploadTags = (props) => {
  const XLSX = require("xlsx");
  const handleUpload = (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const workbook = XLSX.read(e.target.result);
      var sheet_name_list = workbook.SheetNames;
      props.setAdSourceData((prev) => [
        ...prev,
        ...XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]),
      ]);
    };
    reader.readAsArrayBuffer(file);
  };
  const handleFormat = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      {
        AD_TAG_NAME: "Tag name here",
        VAST_TAG_TYPE: "GOOGLE",
        ADVERTISER_NAME: "Advertiser name here",
        CPM: 0,
        IS_ACTIVE: true,
        AD_TAGS: "Enter Tag here",
      },
    ]);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const buffer = XLSX.write(wb, { type: "buffer", bookType: "xlsx" });

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "format.xlsx";

    downloadLink.click();
  };
  return (
    <div className="flex gap-3">
      <Upload
        beforeUpload={handleUpload}
        showUploadList={false}
        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
      <Button icon={<DownloadOutlined />} onClick={handleFormat}>
        Format
      </Button>
    </div>
  );
};

export default UploadTags;
