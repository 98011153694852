import React, { useState, useEffect } from "react";
import { Button, Typography } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

const SuccessPage = (props) => {
  // console.log("sucesspage props", props);
  function handleaddweb(name) {
    // console.log("funciton triggred");

    props.getpubname(name);
    goWebsite();
  }
  function goWebsite() {
    props.setCurrOption("website");
  }

  const [texts, setTexts] = useState({
    pub: [
      {
        heading: "Publisher",
        name: props.pubName,
        text: "Go Back",
        handleClick: () => {
          props.setSuccess(false);
        },
      },
      {
        heading: "Website",
        text: "Add Website",
        handleClick: () => {
          handleaddweb(props.pubName);
        },
      },
    ],
    web: [
      {
        heading: props.multi ? "Multiple" : "Website",
        name: props.multi ? "Websites" : props.webName,
        text: "Add More Website",
        handleClick: () => {
          props.setSuccess(false);
        },
      },
      {
        heading: "Player",
        text: props.multi ? "Configure Players" : "Add Player",
        handleClick: () => {
          props.handleAddPlayer(
            null,
            props.pubName,
            props.multi ? null : props.webName
          );
        },
      },
    ],
  });
  const { Title } = Typography;
  return (
    <div className="h-full w-[100%] flex flex-col justify-center items-center gap-5">
      <CheckCircleFilled style={{ fontSize: "80px", color: "#52C41A" }} />
      <Title level={3}>
        {texts[props.type][0]["heading"] + " " + texts[props.type][0]["name"]}{" "}
        Successfully Added!
      </Title>
      <div className="flex justify-center items-center gap-3">
        <Button onClick={texts[props.type][1].handleClick} type="primary">
          {texts[props.type][1]["text"]}
        </Button>
        <Button onClick={texts[props.type][0].handleClick}>
          {texts[props.type][0]["text"]}
        </Button>
      </div>
    </div>
  );
};

export default SuccessPage;
