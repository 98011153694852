import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  type: "",
  role: "",
  notice: [],
  access: null,
  websites: null,
  publishers: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.name = action.payload.name;
      state.type = action.payload.type;
      state.role = action.payload.role;
      state.notice = action.payload.notice;
      state.access = action.payload.access;
      state.websites = action.payload.websites;
      state.publishers = action.payload.publishers;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
