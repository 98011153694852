import { useEffect, useState, useRef } from "react";
import { Modal, Button, Table, message, Space, Popconfirm, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { updatePub } from "../../redux/reducers/PubSlice";
import { patchWebsites } from "../../API/Websites";
import { updateWebsite } from "../../redux/reducers/WebSlice";
function ArchivedWebsites(props) {
  const { Search } = Input;
  const [messageApi, contextHolder] = message.useMessage();
  const websiteList = useSelector((state) => state.website.websiteList);

  const webList = useRef(null);
  webList.current = props.archivedWebsites;

  const fail = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };
  const successful = (msg) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };
  useEffect(() => {
    setSearchPub("");
  }, [props.archOpen]);
  const [websites, setWebsites] = useState(null);
  const [popOpen, setPopOpen] = useState({});
  const [searchPub, setSearchPub] = useState("");

  useEffect(() => {
    setWebsites(props.archivedWebsites);
  }, [props]);
  const handleSearch = (text) => {
    setSearchPub(text);

    let searchedPubs = webList?.current?.filter((web) =>
      web.url.toLowerCase().includes(text.toLowerCase())
    );

    setWebsites(searchedPubs);
  };

  const handleCancel = () => {
    if (props.setArchOpen) {
      props.setArchOpen((prev) => false);
    }
  };

  const handleCancelPop = (key) => {
    let popconf = {};
    popconf[key] = false;
    setPopOpen(popconf);
  };

  const dispatch = useDispatch();
  const handleUnarchive = async (record) => {
    let websiteData = { ...record, isArchived: "false" };

    let res = await patchWebsites(websiteData);
    if (res.err) {
      fail("error occured");
    } else {
      props.setLogs(record, "edited", "archived", "unarchived");
      dispatch(
        updateWebsite({
          websiteList: [...websiteList],
          websiteChange: true,
        })
      );
      props.handleRefresh();
      setWebsites(props.archivedWebsites);
      successful("Website Unarchived");
    }
  };

  const columns = [
    {
      title: "Websites",
      dataIndex: "url",
      key: "url",
      style: { fontSize: 20 },
      render: (text) => <span>{text}</span>,
      // width: 150,
    },
    {
      title: "Publisher",
      dataIndex: "parent",
      key: "parent",
      style: { fontSize: 20 },
      render: (text) => <span>{text}</span>,
      // width: 150,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure you want to UnArchive this Publisher?"
            onConfirm={() => handleUnarchive(record)}
            // open={true}
            okText="Yes"
            cancelText="No"
            // okButtonProps={{
            //   loading: confirmLoading,
            // }}
            onCancel={(e) => handleCancelPop(e)}
          >
            <Button>unarchive</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      open={props.archOpen}
      style={{ maxWidth: "100%" }}
      width={800}
      onOk={handleCancel}
      onCancel={handleCancel}
      closable={false}
    >
      {contextHolder}
      <div className="flex justify-between items-center">
        <h2 className="text-[12px] sm:text-[1.5rem]">Archived Website List</h2>
        <Search
          placeholder="Search for Publishers"
          allowClear
          // style={{
          //   width: 320,
          // }}

          className="sm:w-[320px] w-[100%]"
          value={searchPub}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <Table dataSource={websites} columns={columns} scroll={{ y: 400 }} />
    </Modal>
  );
}

export default ArchivedWebsites;
