import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Select } from "antd";

const CountryOrigin = (props) => {

  const [form] = Form.useForm(props.form);
  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    const getCountryCodes = async () => {
      let url = `${process.env.REACT_APP_CDN_URL}/${process.env.REACT_APP_CDN_DIR}/mvp/countrycodes.json`;
      const res = await axios.get(url);
      let data = [];
      if (res.status === 200) {
        data = res.data.countries;
        setCountryCodes(data);
      }
    };
    getCountryCodes();
  }, []);

  useEffect(() => {
    form.setFieldValue("COUNTRY_ORIGIN", "");
    form.setFieldValue("TIER", "");
  }, []);


  return (
    <div className="sm:flex	block">
      <Form.Item
        name="TIER"
        label={<div className="text-xs">TIER</div>}
        style={{
          marginLeft: "10%",
          display: "inline-block",
          width: "40%",
        }}
        initialValue={""}
      >
        <Select
          dropdownMatchSelectWidth={true}
          onChange={props.handleChange}
          placeholder="Select Tier"
          options={[
            { value: "mg", label: "MG" },
            { value: "tier1", label: "TIER 1" },
            { value: "tier2", label: "TIER 2" },
            { value: "tier3", label: "TIER 3" },
          ]}
        ></Select>
      </Form.Item>
      <Form.Item
        name="COUNTRY_ORIGIN"
        initialValue={""}
        label={<div className="text-xs">COUNTRY OF ORIGIN</div>}
        style={{
          marginLeft: "10%",
          width: "100%",
          paddingRight: "20px",
        }}
      >
        <Select
          dropdownMatchSelectWidth={true}
          showSearch
          onChange={props.handleChange}
          placeholder="Select Country Origin"
          filterOption={(inputValue, option) => {
            return (
              option.value.toLowerCase().includes(inputValue.toLowerCase()) ||
              option.children.toLowerCase().includes(inputValue.toLowerCase())
            );
          }}

        >
          {countryCodes.map((code) => (
            <Select.Option key={code.value} value={code.value}>
              {code.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default CountryOrigin;