import {
  UserOutlined,
  LogoutOutlined,
  HomeOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Button, message } from "antd";
import { useState, useEffect } from "react";
import { Typography } from "antd";
import LandingPage from "../Graphs/LandingPage";
import GetData from "../../API/GetData";
import { useSelector } from "react-redux";
import BotAvatar from "../Layout/BotAvatar";
import ManageUsers from "./Users/ManageUsers";
import ManageClient from "./Clients/ManageClient";

const { Header, Content, Footer, Sider } = Layout;

const items = [
  { label: "Users", key: "users", icon: <UserOutlined /> },
  { label: "Clients", key: "clients", icon: <TeamOutlined /> },
];
const AdminPanel = (props) => {
  const [adminOption, setAdminOption] = useState(
    window.localStorage.getItem("adminOption") ?? "users"
  );
  const [currPage, setCurrPage] = useState(null);
  const userName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const userRole = useSelector((state) => state.user.role);

  const [pubname, setPubName] = useState(null);
  const [currPub, setCurrPub] = useState(null);
  const [currWeb, setCurrWeb] = useState(null);
  const [currUrl, setCurrUrl] = useState(null);
  const [fromwebsite, setFromWebsite] = useState(false);
  const [saved, setSaved] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const warning = (msg) => {
    messageApi.open({
      type: "warning",
      content: msg ?? "Please save changes before exiting!",
    });
  };
  useEffect(() => {
    window.localStorage.setItem("adminOption", adminOption);

    switch (adminOption) {
      case "users":
        setCurrPage(() => {
          return <ManageUsers />;
        });
        break;
      case "clients":
        setCurrPage(() => {
          return <ManageClient />;
        });
        break;
      default:
        setCurrPage(() => {
          return <ManageUsers />;
        });
        break;
    }
  }, [adminOption, currPub, currWeb, fromwebsite]);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleItemClick = (e) => {
    if (saved) {
      setAdminOption(e.key);
    } else {
      warning();
    }
  };

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    console.log("screen width", window.innerWidth);
    if (window.innerWidth < 1000) {
      // let elements = document.getElementsByClassName("ant-tooltip-content");
      // for (let i = 0; i < elements.length; i++) {
      //   elements[i].style.display = "none";
      // }
      setCollapsed(!collapsed);
    }
  };
  const openPanel = () => {};
  return (
    <>
      {contextHolder}
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          theme="light"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            setCollapsed(broken);
          }}
          onClick={toggleCollapsed}
          collapsed={collapsed}
          className="sidebar"
          style={{
            position: "fixed",
            height: "100%",
            left: 0,
            zIndex: 10,
          }}
        >
          <div className="flex flex-col justify-between h-screen">
            <div className="flex flex-col gap-3 items-start">
              <div className="flex justify-start pl-5 w-full h-16">
                <img
                  className="w-4/5 cursor-pointer"
                  src={`https://unibots.b-cdn.net/managed_vp_imgs/${
                    process.env.REACT_APP_MODE === "dev"
                      ? "unibots"
                      : window.location.hostname.split(".")[1]
                  }.svg`}
                  alt="logo-icon"
                  onClick={props.closePanel}
                />
              </div>

              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  marginLeft: "20px",
                  letterSpacing: "0em",
                  textSlign: "left",
                  color: "#6b7280",
                }}
              >
                Admin Panel
              </Typography>

              <Menu
                theme="light"
                defaultSelectedKeys={["users"]}
                selectedKeys={[adminOption]}
                mode="inline"
                items={items}
                onClick={handleItemClick}
              />
            </div>
          </div>
        </Sider>
        <Layout className="site-layout">
          <Header
            style={{
              padding: "0 20px",
              background: colorBgContainer,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              zIndex: 1,
            }}
            className="sticky lg:relative w-[100%] top-0"
          >
            <Typography className="ml-[2rem] lg:ml-0">
              Hello {userName}
            </Typography>
            <div className="flex items-center gap-4">
              {(userName === "test90" ||
                userName === "unibots" ||
                userRole === "admin") && (
                <Button
                  icon={<HomeOutlined />}
                  onClick={props.closePanel}
                  type="text"
                  shape="circle"
                />
              )}
              <div>
                {userName?.length > 0 && userType?.length > 0 && (
                  <BotAvatar
                    userName={userName}
                    userType={userType}
                    // handleLogout={handleLogout}
                  />
                )}
              </div>
              <div>
                <Button
                  danger
                  type="text"
                  icon={<LogoutOutlined />}
                  size="small"
                  onClick={props.handleLogout}
                >
                  Logout
                </Button>
              </div>
              {/* <Popconfirm
                  placement="bottomRight"
                  description={<NoticeBox />}
                  icon={<></>}
                  okButtonProps={{ style: { display: "none" } }}
                  showCancel={false}
                  style={{ padding: 0, lineHeight: 0 }}
                >
                  <Button icon={<BellOutlined />} shape="circle" />
                </Popconfirm> */}
            </div>
          </Header>

          <Content
            style={{
              margin: "0",
              backgroundColor: "#F7F6F9",

              display: "flex",
            }}
          >
            {/* <div
              style={{
                padding: 20,
              }}
            >
              <Typography>Dashboard</Typography>
            </div> */}
            {adminOption === "home" ? <LandingPage /> : currPage}
          </Content>
          <Footer
            style={{
              textAlign: "center",
              padding: "4px 50px",
            }}
          >
            Unibots ©2023
          </Footer>
        </Layout>
      </Layout>
      <GetData />
    </>
  );
};
export default AdminPanel;
