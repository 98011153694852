import React, { useState } from "react";
import { Table, Space, Typography, Button } from "antd";
import ContIntForm from "./ContIntForm";
const ContIntegeration = (props) => {
  const [mediaData, setMediaData] = useState([]);
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Media URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <a>Edit</a>
          <a className="text-red-500">Delete</a>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <div className="flex items-center gap-2">
        {/* <Title style={{ marginBottom: "28px" }} level={5}>
          Ad Sources Name
        </Title> */}
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <ContIntForm
          currWebsite={props.currWebsite}
          setContent={props.setContent}
          content={props.content}
          videoUrlUpdate={props.videoUrlUpdate}
          setContentDisable={props.setContentDisable}
          contentDisable={props.contentDisable}
        />
      </div>
    </div>
  );
};

export default ContIntegeration;
