import React, { useState, useEffect } from "react";
import { Form, Card, Switch, Button, Modal, message } from "antd";
import Youtube from "../Implementations/Youtube";
import UbLibrary from "../Implementations/UbLibrary";
import UploadVid from "../Implementations/UploadVid";
import { categoryApi, contentApi, patchClient } from "../../../API/Clients";
import { updateAll } from "../../../API/Websites";
import { useSelector } from "react-redux";
import { getVideos, getYtInfo } from "../../../API/Upload";
import DefaultContent from "../Implementations/DefaultContent";
const ContIntForm = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const clientDetail = useSelector((state) => state.client);
  const [form] = Form.useForm();
  const [impl, setImpl] = useState(null);
  const content = props.content;
  const [dropValue, setDropValue] = useState("");
  const [catVal, setCatVal] = useState(null);
  const [uploadLinks, setUploadLinks] = useState(content.links ?? [""]);
  const [videoList, setVideoList] = useState([]);
  const [enableLink, setEnableLink] = useState([]);
  const [cdnEnabled, setCdnEnabled] = useState(null);
  const [clientUrls, setClientUrls] = useState(content?.defaultUrls);
  const [open, setOpen] = useState(false);
  const [initList, setInitList] = useState([]);
  const [okLoading, setOkLoading] = useState(false);
  const userRole = useSelector((state) => state.user.role);
  const [selectedSwitch, setSelectedSwitch] = useState({
    [content?.type]: true,
  });
  const [loading, setLoading] = useState(true);
  const [saved, setSaved] = useState(false);
  const defaultClientUrls = (fields) => {
    setClientUrls(fields);
  };

  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  useEffect(() => {
    if (props.currWebsite) {
      setCdnEnabled(
        props.currWebsite.config[0].playerConfig.CDN_ENABLED ?? true
      );
    }
  }, [props.currWebsite]);

  const handleSwitch = (value, type) => {
    const data = {
      vidlib: false,
      defaultUrls: false,
      upload: false,
      youtube: false,
    };

    setSelectedSwitch({
      ...data,
      [value]: true,
    });
    props.setContent((prev) => {
      return { ...prev, type: value };
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      Categories: content?.categories?.length > 0 ? content?.categories : [],
      yturl: content?.youtube?.url ?? "",
      yttype: content?.youtube?.yttype ?? "url",
      id: content?.youtube?.id ?? "",
      frequency: content?.youtube?.frequency ?? "weekly",
      urltype: content?.youtube?.type ?? "channel",
    });
  }, [content]);

  useEffect(() => {
    handleOk();
  }, [selectedSwitch, enableLink, uploadLinks]);

  useEffect(() => {
    if (cdnEnabled) {
      const getVideoList = async () => {
        setLoading(true);
        const res = await getVideos(props.currWebsite.url);
        let defaultVideos = [];
        if (!res.err) {
          defaultVideos = res.videos;
        }
        let links = defaultVideos.map((vid) => {
          return `https://mvpstream.unibotscdn.com/${vid.uid}/playlist.m3u8`;
        });
        setUploadLinks(links);
        let enableLink = links.map((link) => {
          let flag = content.links.every((val) => {
            return typeof val === "object"
              ? link === val.link
                ? val.enabled
                : true
              : true;
          });
          return flag;
        });
        setEnableLink(enableLink);
        setVideoList(defaultVideos);
        setLoading(false);
      };
      getVideoList();
    } else {
      let customLinks = content.links.map((link, i) => {
        form.setFieldValue(
          "url" + i,
          typeof link === "object" ? link?.link : link
        );
        return typeof link === "object" ? link?.link : link;
      });
      setUploadLinks(customLinks);
      let enableLink = content.links.map((link) => {
        return link?.enabled ?? true;
      });
      setEnableLink(enableLink);
    }
  }, [cdnEnabled]);

  const handleOk = async (value) => {
    if (selectedSwitch.vidlib && content.type !== "defaultUrls") {
      let fields;
      fields = form.getFieldsValue();
      if (!fields.Categories) {
        fields = {
          Categories: content.categories,
        };
      }
      let res;
      let categories = fields?.Categories ?? content.categories;
      // console.log("cate", categories);
      if (categories?.length > 0) {
        res = await contentApi(categories);
      }
      props.setContent((prev) => {
        return {
          ...prev,
          video: res?.data?.msg ?? null,
          categories: categories ?? [],
        };
      });
      if (value) {
        setOpen(false);
      }
    } else if (selectedSwitch.upload) {
      let hls = form.getFieldValue("hls");
      let vidLinks = [];
      let links = [];
      links = enableLink.map((value, i) => {
        if (value) {
          vidLinks.push(uploadLinks[i]);
        }
        return { link: uploadLinks[i], enabled: value };
      });

      props.setContent((prev) => {
        return {
          ...prev,
          video: vidLinks,
          categories: props?.initCat ?? content.categories,
          type: "upload",
          links: links,
          hls: hls ?? prev.hls,
        };
      });
      if (value) {
        setOpen(false);
      }
    } else if (selectedSwitch.youtube) {
      let fields = form.getFieldsValue();
      console.log(fields);
      if (fields.yttype === "url" && fields?.yturl && fields.yturl.length > 0) {
        setOkLoading(true);
        const result =
          content.youtube.url !== fields.yturl
            ? await getYtInfo(fields.yturl)
            : { err: false, msg: { id: content.youtube.id } };

        if (!result.err) {
          props.setContent((prev) => {
            return {
              ...prev,
              youtube: {
                ...prev.youtube,
                yttype: fields.yttype,
                url: fields.yturl,
                id: result.msg.id,
                frequency: fields.frequency,
                type: fields.urltype,
              },
            };
          });
          if (value) {
            setOpen(false);
          }
        } else {
          error(result.msg);
        }

        setOkLoading(false);
      } else if (fields.yttype === "id" && fields?.id && fields.id.length > 0) {
        props.setContent((prev) => {
          return {
            ...prev,
            youtube: {
              ...prev.youtube,
              yttype: fields.yttype,
              url: fields.yturl,
              id: fields.id,
              frequency: fields.frequency,
              type: fields.urltype,
            },
          };
        });
        console.log("hey");
        if (value) {
          setOpen(false);
        }
      }
    } else {
      console.log("triggered");
      if (!clientUrls || clientUrls.length === 0) {
        console.log("client urls empty");
      } else {
        const { balance, ...clientListData } = clientDetail;
        let updatedData = {
          ...clientListData,
          defaultUrls: clientUrls,
          userrole: "client",
        };
        const res = await patchClient(updatedData);
        props.setContent((prev) => {
          return {
            ...prev,
            type: "defaultUrls",
            video: clientUrls,
            defaultUrls: clientUrls,
          };
        });
        let data;
        if (clientUrls.length === 1 && clientUrls[0] === "") {
          console.log("no url defined");
          data = {
            client: clientDetail.name,
            videoSrcDefault: [
              "https://stream.unibotscdn.com/25acc3a8-9b3c-41a6-92f3-216becb358f6/playlist.m3u8",
            ],
            website: props.currWebsite.url,
          };
        } else {
          data = {
            client: clientDetail.name,
            videoSrcDefault: clientUrls,
            defaultUrls: clientUrls,
            website: props.currWebsite.url,
          };
        }
        const updated = await updateAll(data);
        props.videoUrlUpdate();
        if (value) {
          setOpen(false);
        }
      }
    }
  };

  const handleCancel = async () => {
    setOpen(false);
  };
  const handleClickVid = (value) => {
    setDropValue(value);
    setOpen(true);
    if (content?.hls !== undefined) {
      form.setFieldValue("hls", content.hls);
    }
  };

  function deselect() {
    form.setFieldValue("Categories", []);
  }

  function selectAll() {
    if (catVal === null || catVal === undefined) {
      const getCategories = async () => {
        let res = await categoryApi();
        form.setFieldValue("Categories", res.data.msg);
      };
      getCategories();
    } else {
      form.setFieldValue("Categories", catVal);
    }
  }

  useEffect(() => {
    switch (dropValue) {
      case "youtube":
        setImpl(() => {
          return <Youtube currWebsite={props.currWebsite} form={form} />;
        });
        break;
      case "defaultUrls":
        setImpl(() => {
          return (
            <DefaultContent
              currWebsite={props.currWebsite}
              form={form}
              content={content}
              clientInfo={clientDetail}
              defaultClientUrls={defaultClientUrls}
            />
          );
        });
        break;
      case "ub-lib":
        const getCategories = async () => {
          let res = await categoryApi();

          setCatVal(res.data.msg);
          setImpl(() => {
            return (
              <UbLibrary
                categories={res.data.msg}
                form={form}
                deselect={deselect}
                selectAll={selectAll}
                formvalues={content.categories ?? form.getFieldsValue()}
              />
            );
          });
        };
        getCategories();
        break;
      case "upload":
        setImpl(() => {
          return (
            <UploadVid
              currWebsite={props.currWebsite}
              setUploadLinks={setUploadLinks}
              initList={initList}
              uploadLinks={uploadLinks}
              videoList={videoList}
              setVideoList={setVideoList}
              loading={loading}
              enableLink={enableLink}
              setEnableLink={setEnableLink}
              setContent={props.setContent}
              content={content}
              form={form}
              cdnEnabled={cdnEnabled}
            />
          );
        });
        break;
      default:
        setImpl(() => {
          return <></>;
        });
    }
  }, [dropValue, content, initList, uploadLinks, enableLink]);


// Disabled Contents
const [confirmDisable, setConfirmDisable] = useState(false);
const [disabledContent, setDisabledContent] = useState(
  props.currWebsite?.config[0]?.playerConfig?.DISABLE_CONTENT  ?? false 
);


const handleSwitchChange = (val) => {
  if (val === true) {
    setConfirmDisable(true);
    props.setContentDisable(true);
  } else {
    setDisabledContent(false);
    props.setContentDisable(false);
  }
};

useEffect(() => {
  props.setContentDisable(disabledContent);
  }, [])


const handleDisableConfirm = () => {
  setDisabledContent(true);
  setConfirmDisable(false);
};

const handleDisableCancel = () => {
  setDisabledContent(false);
  setConfirmDisable(false);
};
  return (
  <div>
      {
        userRole === "admin" && ( <div className="mb-2 flex gap-5 ml-5">
          <p className="text-[16px]">Content Disabled</p>
          <Switch
            className="mt-5"
            checked={disabledContent}
            onChange={handleSwitchChange}
          />
        </div>)
      }

      <div className="flex gap-6 flex-wrap">
      {contextHolder}

      {
        props.currWebsite.config[0].playerConfig.DISABLE_CONTENT === true ? (
          <>
         <div className="text-[14px]">The Video Player Team is currently updating the content. Please reach out to them for assistance.</div>
          </>
        ): ( <div className="flex w-[650px]">
          <div className="flex flex-col sm:flex-row justify-evenly gap-4 flex-wrap">
            {cdnEnabled ? (
              <Card
                bordered={true}
                style={{ width: "300px" }}
                className="border-blue-500 border-2"
                bodyStyle={{ padding: "12px", height: "150px" }}
              >
                <div className="flex justify-between items-center p-0 mb-6">
                  <p className="m-0 w-[50%]">Unibots Video Library</p>
                  <Switch
                    checked={selectedSwitch.vidlib}
                    onChange={() => handleSwitch("vidlib")}
                  />
                </div>
                <div className="flex justify-between items-center p-0">
                  <p className="text-xs text-gray-400 font-extralight">
                    Select from Unibots Video Library
                  </p>
                  <Button
                    onClick={() => handleClickVid("ub-lib")}
                    disabled={!selectedSwitch.vidlib}
                  >
                    Categories
                  </Button>
                </div>
              </Card>
            ) : (
              <Card
                bordered={true}
                style={{ width: "300px" }}
                className="border-blue-500 border-2"
                bodyStyle={{ padding: "12px", height: "150px" }}
              >
                <div className="flex justify-between items-center p-0 mb-6">
                  <p className="m-0 w-[50%]">Add Default Urls</p>
                  <Switch
                    checked={selectedSwitch.defaultUrls}
                    onChange={() => handleSwitch("defaultUrls")}
  
                  />
                </div>
                <div className="flex justify-between items-center p-0">
                  <p className="text-xs text-gray-400 w-[50%] font-extralight">
                    Add your own Default Content for all websites
                  </p>
                  <Button
                    onClick={() => handleClickVid("defaultUrls")}
                    disabled={!selectedSwitch.defaultUrls}
                  >
                    Add Urls
                  </Button>
                </div>
              </Card>
            )}
            <Card
              bordered={true}
              style={{ width: "300px" }}
              bodyStyle={{ padding: "12px", height: "150px" }}
              className="border-blue-500 border-2"
            >
              <div className="flex justify-between items-center p-0 mb-6">
                <p className="m-0 w-[50%]">Own Content</p>
                <Switch
                  checked={selectedSwitch.upload}
                  onChange={() => handleSwitch("upload")}
  
                />
              </div>
              <div className="flex justify-between items-center p-0">
                <p className="text-xs text-gray-400 w-[50%] font-extralight">
                  {cdnEnabled
                    ? "Upload your own video content to play on video player"
                    : "Add custom media links"}
                </p>
                <Button
                  onClick={() => handleClickVid("upload")}
                  disabled={!selectedSwitch.upload}
                >
                  {cdnEnabled ? "Upload" : "Add URLs"}
                </Button>
              </div>
            </Card>
            <Card
              bordered={true}
              style={{ width: "300px" }}
              bodyStyle={{ padding: "12px", height: "150px" }}
              className="border-blue-500 border-2 p-0"
            >
              <div className="flex justify-between items-center p-0 mb-6">
                <p className="m-0 w-[50%]">Youtube Channel Automapping</p>
                <Switch
                  checked={selectedSwitch.youtube}
                  onChange={() => handleSwitch("youtube")}
  
                />
              </div>
              <div className="flex justify-between items-center p-0">
                <p className="text-xs text-gray-400 w-[50%] font-extralight">
                  Play latest youtube videos automatically.
                </p>
                <Button
                  disabled={!selectedSwitch.youtube}
                  onClick={() => {
                    handleClickVid("youtube");
                  }}
                >
                  Config
                </Button>
              </div>
            </Card>
            <Card
              bordered={true}
              style={{ width: "300px" }}
              bodyStyle={{ padding: "12px", height: "150px" }}
              className="border-blue-500 border-2"
            >
              <div className="flex justify-between items-center p-0 mb-6">
                <p className="m-0 w-[50%]">Ai Driven Content Integration</p>
                <Switch disabled checked={false} />
              </div>
              <div className="flex justify-between items-center p-0">
                <p className="text-xs text-gray-400 w-[50%] font-extralight">
                  Coming Soon
                </p>
                <Button disabled>Config</Button>
              </div>
            </Card>
          </div>
          {/* <div className="flex flex-col sm:flex-row gap-4 justify-between flex-wrap"></div> */}
          {/* </div> */}
          <Modal
            open={open}
            okButtonProps={{ loading: okLoading }}
            onOk={() => {
              form.submit();
            }}
            onCancel={handleCancel}
            bodyStyle={{ minHeight: 400 }}
            destroyOnClose
            closable={false}
          >
            <Form layout="horizontal" form={form} onFinish={handleOk}>
              {impl}
            </Form>
          </Modal>
        </div>)
      }

     
    </div>
  
      <Modal
        title="Confirmation"
        open={confirmDisable}
        onOk={handleDisableConfirm}
        onCancel={handleDisableCancel}
      >
        <p>Are you sure you want to disable Content?</p>
      </Modal>
  </div>
  );
};

export default ContIntForm;
