import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Select,
  Form,
  Typography,
  message,
  Divider,
  Switch,
  Tooltip,
} from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import DeviceSpecificKV from "./DeviceSpecificKV";

const CountryKv = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [deviceKv, setDeviceKv] = useState(
    props?.countryList?.map((country) => {
      return country?.DEVICE_SPECIFIC_KV ?? false;
    }) ?? []
  );
  const [volume, setVolume] = useState(
    props?.countryList?.map((country) => {
      return country?.VOLUME_SPECIFIC_KV ?? false;
    }) ?? []
  );
  const [deviceVolume, setDeviceVolume] = useState(
    props?.countryList?.map((country) => {
      return (
        (country?.DEVICE_SPECIFIC_KV && country?.VOLUME_SPECIFIC_KV) ?? false
      );
    }) ?? []
  );

  // const handleDeviceKvChange = (value, i) => {
  //   setDeviceKv((prevState) => {
  //     const newState = [...prevState];
  //     newState[i] = { ...newState[i], status: value };
  //     return newState;
  //   });
  // };

  const handleDeviceKvChange = (value, i) => {
    setDeviceKv((prevState) => {
      const newState = [...prevState];
      newState[i] = { status: value };
      return newState;
    });
  };

  const handleVolumeKvChange = (value, i) => {
    setVolume((prevState) => {
      const newState = [...prevState];
      newState[i] = { status: value };
      return newState;
    });
  };

  const handleDeviceVolumeChange = (value, i) => {
    setDeviceVolume((prevState) => {
      const newState = [...prevState];
      newState[i] = { status: value };
      return newState;
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Please Select a Country",
    });
  };
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };
  const handleOk = () => {
    if (
      (props.countryList.length === 1 &&
        Object.keys(props.countryList[0]).length === 0) ||
      props.countryList.every((item) => item.COUNTRY && item.COUNTRY.length > 0)
    ) {
      handleCancel();
    } else {
      error();
    }
  };
  const handleAddMore = () => {
    props.setCountryList((prev) => [...prev, {}]);
  };

  const handleChange = (value, i) => {
    let newList = JSON.parse(JSON.stringify(props.countryList));
    newList[i]["COUNTRY"] = value;
    props.setCountryList(newList);
  };
  // const handleDelete = (i) => {
  //   let newList = JSON.parse(JSON.stringify(props.countryList));
  //   console.log(deviceKv);
  //   newList.splice(i, 1);
  //   props.setCountryList(newList);
  //   const lastelm = newList.slice(-1);
  //   if (lastelm.length === 0) {
  //     handleAddMore();
  //   }
  // };

  // const handleDelete = (i) => {
  //   let newList = JSON.parse(JSON.stringify(props.countryList));
  //   const newvolumeKv = [...volume];
  //   newvolumeKv.splice(i, 1);
  //   setVolume(newvolumeKv);

  //   const newDeviceKv = [...deviceKv];
  //   newDeviceKv.splice(i, 1);
  //   setDeviceKv(newDeviceKv);

  //   const newDeviceVolume = [...deviceVolume];
  //   newDeviceVolume.splice(i, 1);
  //   setDeviceVolume(newDeviceVolume);

  //   newList.splice(i, 1);
  //   props.setCountryList(newList);
  //   const lastelm = newList.slice(-1);
  //   if (lastelm.length === 0) {
  //     handleAddMore();
  //   }
  // };

  const handleDelete = (i) => {
    const newList = props.countryList.filter((_, index) => index !== i);
    const newVolume = volume.filter((_, index) => index !== i);
    const newDeviceKv = deviceKv.filter((_, index) => index !== i);
    const newDeviceVolume = deviceVolume.filter((_, index) => index !== i);
    setVolume(newVolume);
    setDeviceKv(newDeviceKv);
    setDeviceVolume(newDeviceVolume);
    props.setCountryList(newList);
    if (newList.length === 0) {
      handleAddMore();
    }
  };

  let deviceParameters = [
    "MAIN_PC",
    "MAIN_MOB",

    "NEW_MAIN_PC",
    "NEW_MAIN_MOB",

    "CR_ADS_PC",
    "CR_ADS_MOB",

    "LOWER_LIMIT_CR_ADS_PC",
    "LOWER_LIMIT_CR_ADS_MOB",

    "LOWER_LIMIT_MAIN_PC",
    "LOWER_LIMIT_MAIN_MOB",
    "LOWER_LIMIT_NEW_MAIN_PC",
    "LOWER_LIMIT_NEW_MAIN_MOB",
  ];

  let objName = [
    "MAIN",
    "NEW_MAIN",
    "CR_ADS",
    "LOWER_LIMIT_MAIN",
    "LOWER_LIMIT_NEW_MAIN",
    "LOWER_LIMIT_CR_ADS",
  ];

  let volumeparameters = [
    "MAIN_MUTED",
    "MAIN_UNMUTED",

    "NEW_MAIN_MUTED",
    "NEW_MAIN_UNMUTED",

    "CR_ADS_MUTED",
    "CR_ADS_UNMUTED",

    "LOWER_LIMIT_MAIN_MUTED",
    "LOWER_LIMIT_MAIN_UNMUTED",
    "LOWER_LIMIT_NEW_MAIN_MUTED",
    "LOWER_LIMIT_NEW_MAIN_UNMUTED",

    "LOWER_LIMIT_CR_ADS_MUTED",
    "LOWER_LIMIT_CR_ADS_UNMUTED",
  ];

  let deviceVolumeParameters = [
    "MAIN_PC_MUTED",
    "MAIN_PC_UNMUTED",

    "MAIN_MB_MUTED",
    "MAIN_MB_UNMUTED",

    "LOWER_LIMIT_MAIN_PC_MUTED",
    "LOWER_LIMIT_MAIN_PC_UNMUTED",

    "LOWER_LIMIT_MAIN_MB_MUTED",
    "LOWER_LIMIT_MAIN_MB_UNMUTED",

    "NEW_MAIN_PC_MUTED",
    "NEW_MAIN_PC_UNMUTED",

    "NEW_MAIN_MB_MUTED",
    "NEW_MAIN_MB_UNMUTED",

    "LOWER_LIMIT_NEW_MAIN_PC_MUTED",
    "LOWER_LIMIT_NEW_MAIN_PC_UNMUTED",

    "LOWER_LIMIT_NEW_MAIN_MB_MUTED",
    "LOWER_LIMIT_NEW_MAIN_MB_UNMUTED",

    "CR_ADS_PC_MUTED",
    "CR_ADS_PC_UNMUTED",

    "CR_ADS_MB_MUTED",
    "CR_ADS_MB_UNMUTED",

    "LOWER_LIMIT_CR_ADS_PC_MUTED",
    "LOWER_LIMIT_CR_ADS_PC_UNMUTED",

    "LOWER_LIMIT_CR_ADS_MB_MUTED",
    "LOWER_LIMIT_CR_ADS_MB_UNMUTED",
  ];

  const handleKvChange = (name, value, i) => {
    let newList = JSON.parse(JSON.stringify(props.countryList));

    if (deviceKv[i]) {
      if (deviceVolume[i]) {
        if (deviceVolumeParameters.includes(name)) {
          const newitem = {
            ...newList[i],
            DEVICE_SPECIFIC_KV: true,
            VOLUME_SPECIFIC_KV: true,
          };
          objName.forEach((nameKey) => {
            newitem[nameKey] = {
              ...(newList[i][nameKey] || {}),
              PC: {
                MUTED:
                  name === `${nameKey}_PC_MUTED`
                    ? value
                    : newList[i]?.[nameKey]?.PC?.MUTED,
                UNMUTED:
                  name === `${nameKey}_PC_UNMUTED`
                    ? value
                    : newList[i]?.[nameKey]?.PC?.UNMUTED,
              },
              MB: {
                MUTED:
                  name === `${nameKey}_MB_MUTED`
                    ? value
                    : newList[i]?.[nameKey]?.MB?.MUTED,
                UNMUTED:
                  name === `${nameKey}_MB_UNMUTED`
                    ? value
                    : newList[i]?.[nameKey]?.MB?.UNMUTED,
              },
            };
          });
          newList[i] = newitem;
        }
      } else {
        if (deviceParameters.includes(name)) {
          const updatedItem = {
            ...newList[i],
            DEVICE_SPECIFIC_KV: true,
          };
          objName.forEach((nameKey) => {
            updatedItem[nameKey] = {
              ...(newList[i][nameKey] || {}),
              PC: name === `${nameKey}_PC` ? value : newList[i][nameKey]?.PC,
              MB: name === `${nameKey}_MOB` ? value : newList[i][nameKey]?.MB,
            };
          });
          newList[i] = updatedItem;
        }
      }
    } else if (volume[i]) {
      if (volumeparameters.includes(name)) {
        const updatedItem = {
          ...newList[i],
          VOLUME_SPECIFIC_KV: true,
        };
        objName.forEach((nameKey) => {
          updatedItem[nameKey] = {
            ...(newList[i][nameKey] || {}),
            MUTED:
              name === `${nameKey}_MUTED`
                ? value
                : newList[i]?.[nameKey]?.MUTED,
            UNMUTED:
              name === `${nameKey}_UNMUTED`
                ? value
                : newList[i]?.[nameKey]?.UNMUTED,
          };
        });
        newList[i] = updatedItem;
      }
    } else {
      newList[i][name] = value;
    }

    props.setCountryList(newList);
  };
  const [kvdata, setKvData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://cdn.unibotscdn.com/${process.env.REACT_APP_CDN_DIR}/mvp/kvdata.json`;
        const res = await axios.get(url);
        if (res.status === 200) {
          setKvData(res.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {contextHolder}

      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add KV
      </Button>
      <Modal
        onCancel={handleCancel}
        onOk={handleOk}
        open={open}
        closable={false}
        centered
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <div style={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}>
          {props.countryList.map((item, i) => {
            let codes = props.countryCodes.filter(
              (code) =>
                !props.countryList.some(
                  (count, j) => count.COUNTRY === code.value && i !== j
                )
            );
            return (
              <div>
                <div className="flex justify-end mr-6 mt-[-5px]">
                  <Tooltip
                    placement="bottom"
                    title={
                      deviceKv[i] || volume[i]
                        ? "Once enabled, it cannot be disabled. Only you can edit or delete the entire field"
                        : ""
                    }
                  >
                    <Form.Item label={"Device Specific KV"}>
                      <Switch
                        checked={deviceKv[i]}
                        onChange={(value) => handleDeviceKvChange(value, i)}
                        disabled={
                          (item.COUNTRY && deviceKv[i] === false) || volume[i]
                            ? true
                            : false
                        }
                      />
                    </Form.Item>
                  </Tooltip>
                </div>
                {deviceKv[i] ? (
                  <>
                    <DeviceSpecificKV
                      kvdata={kvdata}
                      handleKvChange={handleKvChange}
                      i={i}
                      handleChange={handleChange}
                      item={item}
                      codes={codes}
                      handleDelete={handleDelete}
                      deviceKv={deviceKv}
                      handleDeviceVolumeChange={handleDeviceVolumeChange}
                      deviceVolume={deviceVolume}
                    />
                  </>
                ) : (
                  <div className=" mb-[-1.25rem] mt-[-20px]">
                    <div className="w-full mt-[-20px]">
                      <div className="flex justify-between">
                        <div className="flex">
                          <div className="flex gap-10 w-[500px]">
                            <div>
                              <Typography className="font-bold">
                                Country:
                              </Typography>
                              <Form.Item>
                                <Select
                                  dropdownMatchSelectWidth={true}
                                  value={item.COUNTRY}
                                  showSearch
                                  onChange={(value) => {
                                    handleChange(value, i);
                                  }}
                                  placeholder="Select Country"
                                  filterOption={(inputValue, option) => {
                                    return (
                                      option.value
                                        .toLowerCase()
                                        .includes(inputValue.toLowerCase()) ||
                                      option.children
                                        .toLowerCase()
                                        .includes(inputValue.toLowerCase())
                                    );
                                  }}
                                >
                                  {codes.map((code) => (
                                    <Select.Option key={code.value}>
                                      {code.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="mt-[20px]">
                              <Tooltip
                                placement="bottom"
                                title={
                                  volume[i]
                                    ? "Once enabled, it cannot be disabled. Only you can edit or delete the entire field"
                                    : ""
                                }
                              >
                                <Form.Item label={"Volume Specific KV"}>
                                  <Switch
                                    checked={volume[i]}
                                    onChange={(value) =>
                                      handleVolumeKvChange(value, i)
                                    }
                                  />
                                </Form.Item>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="mr-4 mt-4">
                          <DeleteTwoTone
                            twoToneColor="#DC2626"
                            onClick={(e) => handleDelete(i)}
                            style={{ fontSize: "20px" }}
                          />
                        </div>
                      </div>
                    </div>

                    {volume[i] ? (
                      <div className="w-full mb-[20px] bg-slate-50 mt-[-15px]">
                        <div className="flex w-[90%] flex-col mr-4 ml-4 mb-[20px]">
                          <div className="w-full flex gap-5 mt-3">
                            <div className="w-[50%]">
                              <Typography>Main Muted KV:</Typography>
                              <Form.Item>
                                <Select
                                  value={item.MAIN ? item.MAIN.MUTED : []}
                                  onChange={(value) => {
                                    handleKvChange("MAIN_MUTED", value, i);
                                  }}
                                  mode="multiple"
                                  placeholder="Select Main KV"
                                >
                                  {kvdata &&
                                    kvdata.mainkv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="w-[50%]">
                              <Typography>Main Unmuted KV:</Typography>
                              <Form.Item>
                                <Select
                                  value={item.MAIN ? item.MAIN.UNMUTED : []}
                                  onChange={(value) => {
                                    handleKvChange("MAIN_UNMUTED", value, i);
                                  }}
                                  mode="multiple"
                                  placeholder="Select Main KV"
                                >
                                  {kvdata &&
                                    kvdata.mainkv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className="w-full flex gap-5 mt-[-20px]">
                            <div className="w-[50%]">
                              <Typography>
                                Lower Limit Main Muted KV:
                              </Typography>
                              <Form.Item>
                                <Select
                                  value={
                                    item.LOWER_LIMIT_MAIN
                                      ? item.LOWER_LIMIT_MAIN.MUTED
                                      : []
                                  }
                                  onChange={(value) => {
                                    handleKvChange(
                                      "LOWER_LIMIT_MAIN_MUTED",
                                      value,
                                      i
                                    );
                                  }}
                                  mode="multiple"
                                  placeholder="Select Main KV"
                                >
                                  {kvdata &&
                                    kvdata.mainkv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="w-[50%]">
                              <Typography>
                                Lower Limit Main Unmuted KV:
                              </Typography>
                              <Form.Item>
                                <Select
                                  value={
                                    item.LOWER_LIMIT_MAIN
                                      ? item.LOWER_LIMIT_MAIN.UNMUTED
                                      : []
                                  }
                                  onChange={(value) => {
                                    handleKvChange(
                                      "LOWER_LIMIT_MAIN_UNMUTED",
                                      value,
                                      i
                                    );
                                  }}
                                  mode="multiple"
                                  placeholder="Select Main KV"
                                >
                                  {kvdata &&
                                    kvdata.mainkv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-[90%] flex-col mr-4 ml-4 mb-[-20px] mt-[-10px]">
                          <div className="w-full flex gap-5 mt-[-20px]">
                            <div className="w-[50%]">
                              <Typography>New Main Muted KV:</Typography>
                              <Form.Item>
                                <Select
                                  value={
                                    item.NEW_MAIN ? item.NEW_MAIN.MUTED : []
                                  }
                                  onChange={(value) => {
                                    handleKvChange("NEW_MAIN_MUTED", value, i);
                                  }}
                                  mode="multiple"
                                  placeholder="Select New Main KV"
                                >
                                  {kvdata &&
                                    kvdata.newmainkv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="w-[50%]">
                              <Typography>New Main Unmuted KV:</Typography>
                              <Form.Item>
                                <Select
                                  value={
                                    item.NEW_MAIN ? item.NEW_MAIN.UNMUTED : []
                                  }
                                  onChange={(value) => {
                                    handleKvChange(
                                      "NEW_MAIN_UNMUTED",
                                      value,
                                      i
                                    );
                                  }}
                                  mode="multiple"
                                  placeholder="Select New Main KV"
                                >
                                  {kvdata &&
                                    kvdata.newmainkv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className="w-full flex gap-5 mt-[-20px]">
                            <div className="w-[50%]">
                              <Typography>
                                Lower Limit New Main Muted KV:
                              </Typography>
                              <Form.Item>
                                <Select
                                  value={
                                    item.LOWER_LIMIT_NEW_MAIN
                                      ? item.LOWER_LIMIT_NEW_MAIN.MUTED
                                      : []
                                  }
                                  onChange={(value) => {
                                    handleKvChange(
                                      "LOWER_LIMIT_NEW_MAIN_MUTED",
                                      value,
                                      i
                                    );
                                  }}
                                  mode="multiple"
                                  placeholder="Select New Main KV"
                                >
                                  {kvdata &&
                                    kvdata.newmainkv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="w-[50%]">
                              <Typography>
                                Lower Limit New Main Unmuted KV:
                              </Typography>
                              <Form.Item>
                                <Select
                                  value={
                                    item.LOWER_LIMIT_NEW_MAIN
                                      ? item.LOWER_LIMIT_NEW_MAIN.UNMUTED
                                      : []
                                  }
                                  onChange={(value) => {
                                    handleKvChange(
                                      "LOWER_LIMIT_NEW_MAIN_UNMUTED",
                                      value,
                                      i
                                    );
                                  }}
                                  mode="multiple"
                                  placeholder="Select New Main KV"
                                >
                                  {kvdata &&
                                    kvdata.newmainkv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-[90%] flex-col mr-4 ml-4 mt-[30px]">
                          <div className="w-full flex gap-5 mt-[-20px]">
                            <div className="w-[50%]">
                              <Typography>CR ADS Muted KV:</Typography>
                              <Form.Item>
                                <Select
                                  value={item.CR_ADS ? item.CR_ADS.MUTED : []}
                                  onChange={(value) => {
                                    handleKvChange("CR_ADS_MUTED", value, i);
                                  }}
                                  mode="multiple"
                                  placeholder="Select CR ADS Muted KV"
                                >
                                  {kvdata &&
                                    kvdata.cradskv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="w-[50%]">
                              <Typography>CR ADS Unmuted KV:</Typography>
                              <Form.Item>
                                <Select
                                  value={item.CR_ADS ? item.CR_ADS.UNMUTED : []}
                                  onChange={(value) => {
                                    handleKvChange("CR_ADS_UNMUTED", value, i);
                                  }}
                                  mode="multiple"
                                  placeholder="Select CR ADS Unmuted KV"
                                >
                                  {kvdata &&
                                    kvdata.cradskv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className="w-full flex gap-5 mt-[-20px]">
                            <div className="w-[50%]">
                              <Typography>
                                Lower Limit CR ADS Muted KV:
                              </Typography>
                              <Form.Item>
                                <Select
                                  value={
                                    item.LOWER_LIMIT_CR_ADS
                                      ? item.LOWER_LIMIT_CR_ADS.MUTED
                                      : []
                                  }
                                  onChange={(value) => {
                                    handleKvChange(
                                      "LOWER_LIMIT_CR_ADS_MUTED",
                                      value,
                                      i
                                    );
                                  }}
                                  mode="multiple"
                                  placeholder="Select Lower Limit CR ADS Muted KV"
                                >
                                  {kvdata &&
                                    kvdata.cradskv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>{" "}
                            <div className="w-[50%]">
                              <Typography>
                                Lower Limit CR ADS Unmuted KV:
                              </Typography>
                              <Form.Item>
                                <Select
                                  value={
                                    item.LOWER_LIMIT_CR_ADS
                                      ? item.LOWER_LIMIT_CR_ADS.UNMUTED
                                      : []
                                  }
                                  onChange={(value) => {
                                    handleKvChange(
                                      "LOWER_LIMIT_CR_ADS_UNMUTED",
                                      value,
                                      i
                                    );
                                  }}
                                  mode="multiple"
                                  placeholder="Select Lower Limit CR ADS Unmuted KV"
                                >
                                  {kvdata &&
                                    kvdata.cradskv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="w-full flex gap-2 mb[-20px]">
                        <div className="w-[70%] mr-4 mt-[-20px] mb-[20px] bg-slate-100 rounded-2xl">
                          <div className="w-full">
                            <div className="flex w-[90%] flex-col mr-4 ml-4 mb-[-20px]">
                              <Typography>Main KV:</Typography>
                              <Form.Item>
                                <Select
                                  value={item.MAIN}
                                  onChange={(value) => {
                                    handleKvChange("MAIN", value, i);
                                  }}
                                  mode="multiple"
                                  placeholder="Select Main KV"
                                >
                                  {kvdata &&
                                    kvdata.mainkv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="flex flex-col mr-4 ml-4 w-[90%] mb-[-12px]">
                              <Typography>New Main KV:</Typography>
                              <Form.Item>
                                <Select
                                  value={item.NEW_MAIN}
                                  onChange={(value) => {
                                    handleKvChange("NEW_MAIN", value, i);
                                  }}
                                  mode="multiple"
                                  placeholder="Select New Main KV"
                                >
                                  {kvdata &&
                                    kvdata.newmainkv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="flex flex-col mr-4 ml-4 w-[90%] mb-[-12px]">
                              <Typography>CR ADS:</Typography>
                              <Form.Item>
                                <Select
                                  value={item.CR_ADS}
                                  onChange={(value) => {
                                    handleKvChange("CR_ADS", value, i);
                                  }}
                                  mode="multiple"
                                  placeholder="Select New Main KV"
                                >
                                  {kvdata &&
                                    kvdata.cradskv.map((data) => {
                                      return (
                                        <Select.Option value={data}>
                                          {data}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className=" w-[30%] mt-[-18px] mr-4">
                          <div className="flex flex-col w-full">
                            <Typography>Lower Limit Main:</Typography>
                            <Form.Item>
                              <Select
                                value={item.LOWER_LIMIT_MAIN}
                                onChange={(value) => {
                                  handleKvChange("LOWER_LIMIT_MAIN", value, i);
                                }}
                                mode="multiple"
                                placeholder="Select Lower Limit Main"
                                showSearch
                                dropdownMatchSelectWidth={true}
                              >
                                {kvdata &&
                                  kvdata.mainkv.map((data) => {
                                    return (
                                      <Select.Option value={data}>
                                        {data}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="flex flex-col w-full mt-[-15px]">
                            <Typography>Lower Limit New Main:</Typography>
                            <Form.Item>
                              <Select
                                value={item.LOWER_LIMIT_NEW_MAIN}
                                onChange={(value) => {
                                  handleKvChange(
                                    "LOWER_LIMIT_NEW_MAIN",
                                    value,
                                    i
                                  );
                                }}
                                mode="multiple"
                                placeholder="Select Lower Limit New Main"
                                showSearch
                                dropdownMatchSelectWidth={true}
                              >
                                {kvdata &&
                                  kvdata.newmainkv.map((data) => {
                                    return (
                                      <Select.Option value={data}>
                                        {data}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="flex flex-col w-full mt-[-15px]">
                            <Typography>Lower Limit CR ADS:</Typography>
                            <Form.Item>
                              <Select
                                value={item.LOWER_LIMIT_CR_ADS}
                                onChange={(value) => {
                                  handleKvChange(
                                    "LOWER_LIMIT_CR_ADS",
                                    value,
                                    i
                                  );
                                }}
                                mode="multiple"
                                placeholder="Select Lower Limit CR ADS"
                                showSearch
                                dropdownMatchSelectWidth={true}
                              >
                                {kvdata &&
                                  kvdata.cradskv.map((data) => {
                                    return (
                                      <Select.Option value={data}>
                                        {data}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    )}

                    <Divider className="mt-[-10px]" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <Button className="mt-4" onClick={handleAddMore} type="primary">
          Add more
        </Button>
      </Modal>
    </>
  );
};

export default CountryKv;
