import React, { useEffect, useState, useRef } from "react";
import { Table, Tooltip } from "antd";
import "../../index.css";
import { useSelector } from "react-redux";
import { getSspData } from "../../API/Websites";
function commaSeparated(num) {
  if (num !== undefined) {
    // Convert number to string
    let str = num.toString();

    // Split the string into an array of three-digit groups
    let groups = [];
    while (str.length > 0) {
      groups.unshift(str.slice(-3));
      str = str.slice(0, -3);
    }

    // Join the array with commas and return the result
    return groups.join(",");
  } else return num;
}

function formatDateISO(dateStr) {
  const date = new Date(dateStr);
  const options = { year: "2-digit", month: "short", day: "2-digit" };
  return date.toLocaleDateString("en-GB", options).toUpperCase();
}

const GraphTable = (props) => {
  const clientName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const userRole = useSelector((state) => state.user.role);
  const [data, setData] = useState(props.tableData);
  const [bidderContri, setBidderContri] = useState({});
  const sspBidders = useRef([]);

  let bidderconditions =
    props.filterBy === "bidders" &&
    props.filterPub &&
    props.filterPub.length > 0;

  const getChildren = (data, param) => {
    return data.filter((item) => item === param);
  };

  useEffect(() => {
    let childData = props.childData;
    // console.log("Table data: ", props.tableData);
    // console.log(props.filterPub);

    childData.map((obj) => {
      if (obj.name && obj.name === "undefined") {
        if (obj.total_imp) obj.name = "unnamed";
        else obj.name = "removed";
      }
      // obj = obj.children;
    });

    let filterData = childData.filter((crow) => {
      return !(crow.name === "removed");
    });

    // let newTableData = props.tableData;
    let totalChildData = childData;
    let grandfather = props.tableData;

    if (
      (clientName === "unibots" || userType === "user") &&
      userRole !== "admin"
    ) {
      totalChildData.forEach((p) => {
        // Calculate the sum of 'total_imp', 'total_rev', 'fill_rate', etc. from children
        if (p.children) {
          // if ("Publisher" in p.children) {
          //   p.total_imp = p.children.Impressions;
          //   p.total_rev = p.children.Revenue;
          //   p.adreq = p.children.Ad_Requests;
          //   p.site = p.children.Domain;
          //   p.name = p.children.Publisher;
          //   p.avg_ecpm = p.children.eCPM;
          //   p.fill_rate = p.children.Impressions / p.children.Ad_Requests;
          //   p.eff_fill_rate =
          //     (p.children.Impressions / p.children.Ad_Requests) * 100;
          //   p.pub_rev = (props.webRevMap[p.website] * p.total_rev) / 100;
          //   p.client_rev =
          //     ((100 - props.webRevMap[p.website]) * p.total_rev) / 100;
          //   p.type = "pub";
          // }
          p.rpm = 0;
          p.eff_fill_rate = 0;
          p.fill_rate = 0;

          const sumTotalImp = p.children.reduce(
            (sum, c) => sum + (c.api_total_imp || c.total_imp || 0),
            0
          );
          const sumTotalRev = p.children.reduce(
            (sum, c) => sum + (c.api_total_rev || c.total_rev || 0),
            0
          );
          const sumAdreq = p.children.reduce(
            (sum, c) => sum + (c.adreq || 0),
            0
          );
          const sumpv = p.children.reduce((sum, c) => sum + (c.pv || 0), 0);
          const sumCPM = p.children.reduce(
            (sum, c) => sum + (c.avg_ecpm * c.total_imp || 0),
            0
          );

          // Update the parent object with the sum values
          // console.log(sumTotalImp);
          p.total_imp = sumTotalImp;
          p.total_rev = sumTotalRev;
          p.adreq = sumAdreq;
          if (sumAdreq) p.fill_rate = sumTotalImp / sumAdreq;
          if (sumpv) p.eff_fill_rate = sumTotalImp / sumpv;
          if (sumpv) p.rpm = (sumTotalRev * 1000) / sumpv;
          p.pv = sumpv;
          if (sumTotalImp) p.avg_ecpm = sumCPM / sumTotalImp;
          p.pub_rev = (props.webRevMap[p.website] * p.total_rev) / 100;
          p.client_rev =
            ((100 - props.webRevMap[p.website]) * p.total_rev) / 100;
          p.type = "pub";
        }
      });

      props.setChildData(totalChildData);
      // console.log(totalChildData);

      grandfather.forEach((gf) => {
        gf.total_imp = 0;
        gf.total_rev = 0;
        gf.adreq = 0;
        gf.fill_rate = 0;
        gf.eff_fill_rate = 0;
        gf.rpm = 0;
        gf.pv = 0;
        gf.pub_rev = 0;
        gf.client_rev = 0;
        // Find the corresponding parent object based on the 'date' and 'name' fields
        const correspondingParent = totalChildData.filter(
          (p) => p.date === gf.date
        );
        // console.log(correspondingParent)
        // If a corresponding parent object is found, update the values in the grandfather object
        for (const i in correspondingParent) {
          // Sum the corresponding values from the parent object and update the grandfather object
          gf.total_imp += correspondingParent[i].total_imp || 0;
          gf.total_rev += correspondingParent[i].total_rev || 0;
          gf.pub_rev += correspondingParent[i].pub_rev || 0;
          gf.client_rev += correspondingParent[i].client_rev || 0;
          gf.adreq += correspondingParent[i].adreq || 0;
          gf.pv += correspondingParent[i].pv || 0;
          if (
            correspondingParent[i].avg_ecpm &&
            correspondingParent[i].total_imp
          )
            gf.avg_ecpm +=
              correspondingParent[i].avg_ecpm *
              correspondingParent[i].total_imp;
        }
        if (gf.total_imp) gf.avg_ecpm /= gf.total_imp;
        if (gf.adreq) gf.fill_rate = gf.total_imp / gf.adreq;
        if (gf.pv) gf.eff_fill_rate = gf.total_imp / gf.pv;
        if (gf.pv) gf.rpm = (gf.total_rev * 1000) / gf.pv;
        // gf.pub_rev = (props.webRevMap[gf.website] * gf.total_rev) / 100;
        // gf.client_rev =
        //   ((100 - props.webRevMap[gf.website]) * gf.total_rev) / 100;
        gf.type = "parent";
      });
    }

    // if (1) {
    //   newTableData = [];
    //   // Iterate over each object in the original array
    //   childData.forEach((obj) => {
    //     // Iterate over each key in the current object
    //     Object.keys(obj).forEach((key) => {
    //       // Check if the key exists in the new array
    //       if (newTableData.hasOwnProperty(key)) {
    //         if (typeof obj[key] === "string") {
    //           newTableData[key] = obj[key];
    //         } else {
    //           // Add the value to the existing key
    //           if (obj[key]) newTableData[key] += obj[key];
    //         }
    //         // Add the value to the existing key
    //       } else {
    //         // Create a new key and set its value
    //         if (obj[key]) newTableData[key] = obj[key];
    //       }
    //     });
    //   });
    //   newTableData["key"] += 100000;
    //   // props.setTableData(newTableData);
    // }

    let newData = grandfather.map((row) => {
      let date = row.date;
      let subData = filterData.filter((crow) => crow.date === date);
      if (props.filterPub !== null && props.pubChange) {
        row["children"] = subData;
      } else if (props.currweb !== null) {
        const matchingData = subData.find(
          (crow) => crow.website === props.currweb
        );
        if (matchingData) {
          [
            "children",
            "pv",
            "total_imp",
            "avg_ecpm",
            "adreq",
            "fill_rate",
            "total_rev",
            "pub_rev",
            "rpm",
            "eff_fill_rate",
          ].forEach((key) => {
            if (matchingData[key] !== undefined) {
              row[key] = matchingData[key];
            }
          });
          row.client_rev = row.total_rev - row.pub_rev;
        } else {
          row["children"] = subData.map((crow) => crow.children || crow);
        }
      } else if (props.filterPub !== null) {
        row["children"] = subData;
      }

      return row;
    });

    if (clientName !== "unibots") {
      newData.forEach((obj) => {
        obj.type = "parent";
        if (obj.hasOwnProperty("children"))
          obj.children.forEach((childObj) => {
            childObj.type = "pub";
          });
      });
      // props.setChildData(totalChildData);
    }

    let contriMap = {};
    if (newData && Array.isArray(newData)) {
      newData.forEach((obj) => {
        if (obj && obj.children && Array.isArray(obj.children)) {
          obj.children.forEach((child) => {
            const temp = child.date + child.site; // change to website after site is added
            contriMap[temp] = {
              imp: child.total_imp,
              rev: child.total_rev,
              adreq: child.adreq,
            };
          });
        }
      });
      setBidderContri(contriMap);
    }

    // if (clientName !== "unibots") {
    //   newData.forEach((obj) => {
    //     obj.type = "parent";
    //     if (obj.hasOwnProperty("children"))
    //       obj.children.forEach((childObj) => {
    //         childObj.type = "pub";
    //       });
    //   });
    //   // props.setChildData(totalChildData);
    // }

    // let contriMap = {};
    // newData.forEach((obj) => {
    //   // console.log(obj);
    //   obj.children.forEach((child) => {
    //     const temp = child.date + child.website;
    //     // console.log(child);
    //     contriMap[temp] = {
    //       imp: child.total_imp,
    //       rev: child.total_rev,
    //       adreq: child.adreq,
    //     };
    //   });
    //   // console.log(obj);
    //   // contriMap[obj.date] = {
    //   //   imp: obj.total_imp,
    //   //   rev: obj.total_rev,
    //   //   adreq: obj.adreq,
    //   // };
    // });
    // setBidderContri(contriMap);
    // console.log(contriMap);

    if (
      (clientName === "unibots" || userType === "user") &&
      userRole !== "admin" &&
      props.filterPub !== null
    ) {
      if (props.currweb !== null) {
        newData.forEach((obj) => {
          if (obj && obj.children && Array.isArray(obj.children)) {
            obj.children = obj.children.filter((childObj) => {
              return (
                childObj.hasOwnProperty("total_imp") ||
                childObj.adreq >= 0.001 * contriMap[childObj.date]?.adreq
              );
            });
          }
        });
      } else {
        newData.forEach((obj) => {
          obj.children.forEach((childObj) => {
            if (childObj.hasOwnProperty("children")) {
              childObj.children = childObj.children.filter(
                (bidder) =>
                  bidder.hasOwnProperty("total_imp") ||
                  bidder.adreq >= 0.001 * contriMap[bidder.date]?.adreq
              );
            }
          });
        });
      }
    }

    // newData.forEach((obj) => {
    //   if (obj && obj.children && Array.isArray(obj.children)) {
    //     obj.children.forEach((childObj) => {
    //       // console.log(childObj.children);
    //       // childObj.children.forEach((bidder) =>
    //       //   console.log(bidder, !bidder.hasOwnProperty("total_imp"))
    //       // );
    //       if (childObj.hasOwnProperty("children")) {
    //         childObj.children = childObj.children.filter(
    //           (bidder) =>
    //             bidder.hasOwnProperty("total_imp") ||
    //             bidder.adreq >= 0.001 * contriMap[bidder.date]?.adreq
    //         );

    //         // let sspApi = props.sspAPI
    //         //   .map((child) => {
    //         //     let matchingChild = childObj.children.filter((obj) => {

    //         //       return (
    //         //         obj.site?.split(".")[0] === child.Domain?.split(".")[0] &&
    //         //         obj.date === child.Date &&
    //         //         obj.name === child.Publisher
    //         //       );
    //         //     });

    //         //     if (matchingChild.length > 0) {
    //         //       return child;
    //         //     }
    //         //   })
    //         //   .filter((item) => item !== undefined);

    //         // sspApi.forEach((ssp) => {
    //         //   ssp["date"] = ssp["Date"];
    //         //   ssp["adreq"] = ssp["Ad_Requests"];
    //         //   ssp["total_rev"] = ssp["Revenue"];
    //         //   ssp["total_imp"] = ssp["Impressions"];
    //         //   ssp["site"] = ssp["Domain"];
    //         //   ssp["name"] = ssp["Publisher"] + "API";
    //         //   ssp["avg_ecpm"] = ssp["eCPM"];
    //         //   ssp["fill_rate"] = ssp["Impressions"] / ssp["Ad_Requests"];
    //         //   ssp["eff_fill_rate"] =
    //         //     (ssp["Impressions"] / ssp["Ad_Requests"]) * 100;
    //         //   const keysToDelete = [
    //         //     "Revenue",
    //         //     "Ad_Requests",
    //         //     "Impressions",
    //         //     "Domain",
    //         //     "Publisher",
    //         //     "eCPM",
    //         //     "Date",
    //         //   ];
    //         //   keysToDelete.forEach((key) => {
    //         //     delete ssp[key];
    //         //   });
    //         //   // childObj.children.push(ssp);

    //         //   sspBidders.current.push(ssp);
    //         // });
    //       }
    //     });
    //   }
    // });
  }, [props.tableData, props.filterBy, props.filterPub, props.currweb]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "DATE",
      // width: "20%",
      width: window.innerWidth >= 768 ? 255 : "14%",
      fixed: true,
      defaultSortOrder: "descend",
      render: (_, record, index) => {
        let cond =
          (clientName === "unibots" || userType === "user") &&
          userRole !== "admin"
            ? !(record.type === "parent")
            : !("children" in record) || record.name === "google";
        // let cond = !(record.type === "parent");

        if (bidderconditions) {
          if (!record.date && !record.site) {
            return <div>{record.name}</div>;
          } else if (!record.date) {
            return <div>{record.site}</div>;
          } else {
            // This is a child row without website property
            return <div>{formatDateISO(record.date)}</div>;
          }
        } else {
          if (record.type === "pub" || !record.type)
            // if (cond)
            return (
              <Tooltip title={record.name}>
                <span>
                  {props.filterBy === "website" ? (
                    // record.type === "pub" ? (
                    //   formatDateISO(record.date)
                    // ) :
                    record.website ? (
                      record.website.length > 25 ? (
                        <>...{record.website.slice(-25)}</>
                      ) : (
                        record.website
                      )
                    ) : record.name ? (
                      record.name.length > 25 ? (
                        <>...{record.name.slice(-25)}</>
                      ) : (
                        record.name
                      )
                    ) : (
                      <div className="italic text-gray-300">Unnamed</div>
                    )
                  ) : record.name ? (
                    record.name.length > 25 ? (
                      <>...{record.name.slice(-25)}</>
                    ) : (
                      record.name
                    )
                  ) : (
                    <div className="italic text-gray-300">Unnamed</div>
                  )}
                </span>
              </Tooltip>
            );
          else return formatDateISO(record.date);
        }
      },
      sorter: (a, b) => a.date > b.date,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "name",
    //   width: "16%",
    //   render: (text, record) => (
    // <Tooltip title={record.name}>
    //   <span>
    //     {record.name ? (
    //       record.name?.length > 25 ? (
    //         `${record.name.slice(0, 25)}...`
    //       ) : (
    //         record.name
    //       )
    //     ) : (
    //       <div className="italic text-gray-300">Expand to see more</div>
    //     )}
    //   </span>
    // </Tooltip>
    //   ),
    // },
    {
      title: "Page Views",
      dataIndex: "pv",
      key: "pv",
      sorter: (a, b) => a.pv - b.pv,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        if (record.pv === 0) return 0;
        else return commaSeparated(record.pv) ?? null;
      },
      width: 100,
    },
    {
      title: "UB Page Views",
      dataIndex: "player_load",
      key: "player_load",
      sorter: (a, b) => a.player_load - b.player_load,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.player_load)
          ? // ||
            //   record.type === "parent" ||
            //   record.hasOwnProperty("children")
            ""
          : commaSeparated(record.player_load);
      },
      width: 100,
    },
    {
      title: "Impressions",
      dataIndex: "total_imp",
      key: "imp",
      render: (_, record) => commaSeparated(record.total_imp) ?? 0,
      sorter: (a, b) => a.total_imp - b.total_imp,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 100,
    },
    {
      title: "API Impressions",
      dataIndex: "api_total_imp",
      key: "api_total_imp",
      render: (_, record) => commaSeparated(record.api_total_imp) ?? " ",
      width: 100,
    },

    // sorter: (a, b) => a.total_imp - b.total_imp,
    // sortDirections: ["ascend", "descend", "ascend"],
    // width: 100,

    {
      title: "Imp Contri",
      dataIndex: "imp_contri",
      key: "imp_contri",
      sorter: (a, b) => a.imp_contri - b.imp_contri,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.total_imp) ||
          record.type === "parent" ||
          record.hasOwnProperty("children")
          ? ""
          : record.api_total_imp
          ? (
              (Number(record.api_total_imp) * 100) /
              bidderContri[record.date + record.site]?.imp
            ).toFixed(2) + "%"
          : (
              (Number(record.total_imp) * 100) /
              bidderContri[record.date + record.site]?.imp
            ).toFixed(2) + "%";
      },
      width: 100,
    },
    {
      title: "Pub Rev",
      dataIndex: "pub_rev",
      key: "pub_rev",
      sorter: (a, b) => a.pub_rev - b.pub_rev,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.pub_rev)
          ? // ? isNaN(record.total_rev)
            (clientName === "unibots" || userType === "user") &&
            userRole !== "admin"
            ? ""
            : ""
          : (clientName === "unibots" || userType === "user") &&
            userRole !== "admin" &&
            record.type !== "pub" &&
            record.type !== "parent"
          ? " "
          : // : "$" +
            //   (
            //     (Number(props.webRevMap[record.url]) * record.total_rev) /
            //     100
            //   ).toFixed(2)
            "$" + Number(record.pub_rev).toFixed(2);
      },
      width: 100,
    },

    {
      title: "My Rev",
      dataIndex: "client_rev",
      key: "client_rev",
      sorter: (a, b) => a.client_rev - b.client_rev,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.client_rev)
          ? // ? isNaN(record.total_rev)
            (clientName === "unibots" || userType === "user") &&
            userRole !== "admin"
            ? ""
            : ""
          : (clientName === "unibots" || userType === "user") &&
            userRole !== "admin" &&
            record.type !== "pub" &&
            record.type !== "parent"
          ? " "
          : record.client_rev < 0
          ? "-" + "$" + Math.abs(Number(record.client_rev)).toFixed(2)
          : // : "$" +
            //   (
            //     ((100 - Number(props.webRevMap[record.url])) *
            //       record.total_rev) /
            //     100
            //   ).toFixed(2)
            "$" + Number(record.client_rev).toFixed(2);
      },
      width: 100,
    },
    {
      title: "Total Rev",
      dataIndex: "total_rev",
      key: "total_rev",
      sorter: (a, b) => a.total_rev - b.total_rev,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.total_rev)
          ? 0
          : "$" + Number(record.total_rev).toFixed(2);
      },
      width: 100,
    },
    {
      title: "API Total Rev",
      dataIndex: "api_total_rev",
      key: "api_total_rev",
      sorter: (a, b) => a.total_rev - b.total_rev,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.api_total_rev)
          ? " "
          : "$" + Number(record.api_total_rev).toFixed(2);
      },
      width: 100,
    },
    {
      title: "Rev Contri",
      dataIndex: "rev_contri",
      key: "rev_contri",
      sorter: (a, b) => a.rev_contri - b.rev_contri,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        // console.log(
        //   "Date  Bidder Rev: Total rev: ",
        //   record.date,
        //   record.total_rev,
        //   bidderContri[record.date]?.rev
        // );
        return isNaN(record.total_rev) ||
          record.type === "parent" ||
          record.hasOwnProperty("children")
          ? ""
          : record.api_total_rev
          ? (
              (Number(record.api_total_rev) * 100) /
              bidderContri[record.date + record.site]?.rev
            ).toFixed(2) + "%"
          : (
              (Number(record.total_rev) * 100) /
              bidderContri[record.date + record.site]?.rev
            ).toFixed(2) + "%";
      },
      width: 100,
    },
    {
      title: "Average ECPM  ",
      dataIndex: "avg_ecpm",
      key: "ecpm",
      sorter: (a, b) => a.avg_ecpm - b.avg_ecpm,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.avg_ecpm)
          ? 0
          : "$" + Number(record.avg_ecpm).toFixed(2);
      },
      width: 100,
    },
    {
      title: "API Average ECPM  ",
      dataIndex: "api_avg_ecpm",
      key: "api_avg_ecpm",
      sorter: (a, b) => a.api_avg_ecpm - b.api_avg_ecpm,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.api_avg_ecpm)
          ? " "
          : "$" + Number(record.api_avg_ecpm).toFixed(2);
      },
      width: 100,
    },
    {
      title: "Fill Rate",
      dataIndex: "fill_rate",
      key: "fill_rate",
      sorter: (a, b) => a.fill_rate - b.fill_rate,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.fill_rate)
          ? 0
          : Number(record.fill_rate * 100).toFixed(2) + "%";
      },
      width: 100,
    },
    {
      title: "API Fill Rate",
      dataIndex: "api_fill_rate",
      key: "api_fill_rate",
      sorter: (a, b) => a.api_fill_rate - b.api_fill_rate,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.api_fill_rate)
          ? " "
          : Number(record.api_fill_rate * 100).toFixed(2) + "%";
      },
      width: 100,
    },
    {
      title: "Effective Fill",
      dataIndex: "eff_fill_rate",
      key: "eff_fill_rate",
      sorter: (a, b) => a.eff_fill_rate - b.eff_fill_rate,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.eff_fill_rate)
          ? 0
          : Number(record.eff_fill_rate * 100).toFixed(2) + "%";
      },
      width: 100,
    },
    {
      title: "Ad Requests",
      dataIndex: "adreq",
      key: "adreq",
      sorter: (a, b) => a.adreq - b.adreq,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.adreq) ? 0 : commaSeparated(record.adreq);
      },
      width: 100,
    },
    {
      title: "API Ad Requests",
      dataIndex: "api_adreq",
      key: "api_adreq",
      sorter: (a, b) => a.api_adreq - b.api_adreq,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.api_adreq) ? " " : commaSeparated(record.api_adreq);
      },
      width: 100,
    },
    {
      title: "RPM",
      dataIndex: "rpm",
      key: "rpm",
      sorter: (a, b) => a.rpm - b.rpm,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.rpm) ? 0 : "$" + Number(record.rpm).toFixed(4);
      },
      width: 100,
    },
    {
      title: "UB RPM",
      dataIndex: "UB RPM",
      key: "ub_rpm",
      sorter: (a, b) => a.ub_rpm - b.ub_rpm,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.player_load) || isNaN(record.total_rev)
          ? // ||
            //   record.type === "parent" ||
            //   record.hasOwnProperty("children")
            ""
          : "$" +
              Number((record.total_rev * 1000) / record.player_load).toFixed(4);
      },
      width: 100,
    },
  ];

  const excludedColumns = [
    "imp_contri",
    "rev_contri",
    "player_load",
    "ub_rpm",
    "api_total_imp",
    "api_total_rev",
    "api_adreq",
    "api_avg_ecpm",
    "api_fill_rate",
  ];

  const updatedColumns =
    (clientName === "unibots" || userType === "user") &&
    userRole !== "admin" &&
    props.filterPub !== null
      ? columns
      : columns.filter((column) => !excludedColumns.includes(column.key));

  return (
    <div className="overflow-x-auto w-full">
      <Table
        dataSource={bidderconditions ? props.biddersTableData : [...data]}
        // dataSource={props.tableData}
        columns={updatedColumns}
        // columns={columns}
        pagination={false}
        size="small"
        scroll={{ x: 1300, y: 400 }}
      />
    </div>
  );
};

export default GraphTable;
