import React, { useEffect, useState } from "react";
import { Table, Select } from "antd";
import dayjs from "dayjs";
import { DatePicker, Typography, Radio, Button, Tag } from "antd";
import AreaGraph from "../Graphs/AreaGraph";
import { useSelector } from "react-redux";
import { getWebsiteByPub, getWebsites } from "../../API/Websites";
import { LogoutOutlined } from "@ant-design/icons";
import LoaderComp from "../Utility/LoaderComp";
import dayjsPluginUTC from "dayjs-plugin-utc";
import io from "socket.io-client";
import { getPlacementData } from "../../API/Publishers";
import moment from "moment-timezone";
import TimezoneDropdown from "../Graphs/TimezoneDropdown";
import { getPublishersByName, publogOut } from "../../API/Publishers";
import axios from "axios";

// let socket = "";
dayjs.extend(dayjsPluginUTC);

const { RangePicker } = DatePicker;

const createDate = (today) => {
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  return [dd, mm, yyyy];
};

function convertTimezone(dateString, timezone) {
  // Create a Date object from the input date string in UTC timezone
  const date = new Date(dateString);

  // Get the timezone offset in minutes from UTC for the input timezone
  const timezoneOffset = moment.tz(date, timezone).utcOffset();

  // Convert the offset to milliseconds and subtract it from the input date to get the new date in the input timezone
  const newDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);

  // Format the new date string in ISO format with UTC timezone
  const newDateString = newDate.toISOString().replace("Z", "+00:00");

  return newDateString;
}

function addTimezone(dateString, timezone) {
  // Create a Date object from the input date string in UTC timezone
  const date = new Date(dateString);

  // Get the timezone offset in minutes from UTC for the input timezone
  const timezoneOffset = moment.tz(date, timezone).utcOffset();

  // Convert the offset to milliseconds and subtract it from the input date to get the new date in the input timezone
  const newDate = new Date(date.getTime() + timezoneOffset * 60 * 1000);

  // Format the new date string in ISO format with UTC timezone
  const newDateString = newDate.toISOString().replace("Z", "+00:00");

  return newDateString;
}

const selectOptions = [
  { label: "All Devices", value: "All Devices" },
  { label: "Desktop", value: "Desktop" },
  { label: "Tablet", value: "Tablet" },
  { label: "Smartphone", value: "Smartphone" },
  { label: "Connected TV", value: "Connected TV" },
];

function PublisherDash(props) {
  const publisherName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.type);
  const [columns, setColumns] = useState(null);
  const [websites, setWebsites] = useState("");
  const [spin, setSpin] = useState(true);
  const [filterWeb, setFilterWeb] = useState();
  const [filterWebInfo, setFilterWebInfo] = useState();
  const [tableData, setTableData] = useState([]);
  const [searchDate, setSearchDate] = useState(0);
  const [selectedTimezone, setSelectedTimezone] = useState("Asia/Kolkata");

  const [totalImp, setTotalImp] = useState({ old: 0, new: 0 });
  const [totalRev, setTotalRev] = useState({ old: 0, new: 0 });
  const [AverageEcpm, setAverageEcpm] = useState({ old: 0, new: 0 });
  const [impGraph, setImpGraph] = useState([]);
  const [revGraph, setRevGraph] = useState([]);
  const [ecpmGraph, setEcpmGraph] = useState([]);
  const [webRevMap, setWebRevMap] = useState({});
  const [radioValue, setRadioValue] = useState(-1);
  const [graphFilter, setGraphFilter] = useState({
    startDate: "",
    endDate: "",
    publisher: "",
  });
  const [showGamData, setShowGamData] = useState(false);
  const [selectCountryOptions, setSelectCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("All Countries");
  const [selectedDevice, setSelectedDevice] = useState("All Devices");

  const options = [
    { label: "1D", value: 0 },
    { label: "7D", value: 6 },
    { label: "1M", value: 29 },
  ];

  const initColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "DATE",
      // width: 150,
      fixed: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date > b.date,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Page Views",
      dataIndex: "pv",
      key: "pv",
      sorter: (a, b) => a.pv - b.pv,
      sortDirections: ["ascend", "descend", "ascend"],
      // width: 100
    },
    {
      title: "Impressions",
      dataIndex: "total_imp",
      key: "imp",
      render: (_, record) => record.total_imp ?? 0,
      sorter: (a, b) => a.total_imp - b.total_imp,
      sortDirections: ["ascend", "descend", "ascend"],
      // width: 150
    },
    {
      title: "Net Revenue",
      dataIndex: "total_rev",
      key: "rev",
      sorter: (a, b) => a.total_rev - b.total_rev,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.total_rev)
          ? 0
          : "$" + Number(record.total_rev).toFixed(2);
      },
    },
    {
      title: "Gross Revenue",
      dataIndex: "gross_rev",
      key: "grev",
      sorter: (a, b) => a.gross_rev - b.gross_rev,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.gross_rev)
          ? 0
          : "$" + Number(record.gross_rev).toFixed(2);
      },
    },
    {
      title: "Average ECPM",
      dataIndex: "avg_ecpm",
      key: "ecpm",
      sorter: (a, b) => a.avg_ecpm - b.avg_ecpm,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.avg_ecpm)
          ? 0
          : "$" + Number(record.avg_ecpm).toFixed(2);
      },
    },
    {
      title: "Gross ECPM  ",
      dataIndex: "gross_ecpm",
      key: "gecpm",
      sorter: (a, b) => a.gross_ecpm - b.gross_ecpm,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.gross_ecpm)
          ? 0
          : "$" + Number(record.gross_ecpm).toFixed(2);
      },
    },
    {
      title: "Fill Rate",
      dataIndex: "fill_rate",
      key: "fill_rate",
      sorter: (a, b) => a.fill_rate - b.fill_rate,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.fill_rate)
          ? 0
          : Number(record.fill_rate * 100).toFixed(2) + "%";
      },
    },
    {
      title: "Eff Fill Rate",
      dataIndex: "eff_fill_rate",
      key: "eff_fill_rate",
      sorter: (a, b) => a.eff_fill_rate - b.eff_fill_rate,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.eff_fill_rate)
          ? 0
          : Number(record.eff_fill_rate * 100).toFixed(2) + "%";
      },
    },
    {
      title: "Ad Requests",
      dataIndex: "adreq",
      key: "adreq",
      sorter: (a, b) => a.adreq - b.adreq,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.adreq) ? 0 : record.adreq;
      },
    },
    {
      title: "RPM",
      dataIndex: "rpm",
      key: "rpm",
      sorter: (a, b) => a.rpm - b.rpm,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.rpm) ? 0 : "$" + Number(record.rpm).toFixed(2);
      },
    },
  ];
  const gamColumn = [
    {
      title: "Date",
      dataIndex: "date",
      key: "DATE",
      // width: 150,
      fixed: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date > b.date,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Page Views",
      dataIndex: "pv",
      key: "pv",
      sorter: (a, b) => a.pv - b.pv,
      sortDirections: ["ascend", "descend", "ascend"],
      // width: 100
    },
    {
      title: "Impressions",
      dataIndex: "total_imp",
      key: "imp",
      render: (_, record) => record.total_imp ?? 0,
      sorter: (a, b) => a.total_imp - b.total_imp,
      sortDirections: ["ascend", "descend", "ascend"],
      // width: 150
    },
    {
      title: "Net Revenue",
      dataIndex: "total_rev",
      key: "rev",
      sorter: (a, b) => a.total_rev - b.total_rev,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.total_rev)
          ? 0
          : "$" +
              (
                Number(record.total_rev) *
                (filterWebInfo[filterWeb]["revShare"] ?? 1)
              ).toFixed(2);
      },
    },
    // {
    //   title: "Gross Revenue",
    //   dataIndex: "total_rev",
    //   key: "rev",
    //   sorter: (a, b) => a.total_rev - b.total_rev,
    //   sortDirections: ["ascend", "descend", "ascend"],
    //   render: (_, record) => {
    //     return isNaN(record.total_rev)
    //       ? 0
    //       : "$" + Number(record.total_rev).toFixed(2);
    //   },
    // },
    {
      title: "Average ECPM",
      dataIndex: "avg_ecpm",
      key: "ecpm",
      sorter: (a, b) => a.avg_ecpm - b.avg_ecpm,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.avg_ecpm)
          ? 0
          : "$" + Number(record.avg_ecpm).toFixed(2);
      },
    },
    // {
    //   title: "Eff Fill Rate",
    //   dataIndex: "eff_fill_rate",
    //   key: "eff_fill_rate",
    //   sorter: (a, b) => a.eff_fill_rate - b.eff_fill_rate,
    //   sortDirections: ["ascend", "descend", "ascend"],
    //   render: (_, record) => {
    //     return isNaN(record.pv) || record.pv === 0
    //       ? 0
    //       : ((Number(record.total_imp) * 100) / Number(record.pv)).toFixed(2) +
    //           "%";
    //   },
    // },
    {
      title: "RPM",
      dataIndex: "rpm",
      key: "rpm",
      sorter: (a, b) => a.rpm - b.rpm,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.rpm) ? 0 : "$" + Number(record.rpm).toFixed(2);
      },
    },
  ];

  const getLastDate = (days) => {
    let date = new Date();
    let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
    last.setUTCHours(0, 0, 0);
    setGraphFilter((prev) => {
      return {
        ...prev,
        startDate: last.toISOString(),
        endDate: date.toISOString(),
      };
    });
    setSearchDate(searchDate + 1);
  };

  const handleDateChange = (value) => {
    setRadioValue(-1);
    // console.log(value)
    setGraphFilter((prev) => {
      return {
        ...prev,
        startDate: value[0]["$d"].toISOString(),
        endDate: value[1]["$d"].toISOString(),
      };
    });
  };

  const handleChange = (e) => {
    setRadioValue(e.target.value);
    getLastDate(e.target.value);
  };

  const round = (value, decimals) => {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  };

  const handleLogout = async () => {
    let res = await publogOut();
    if (!res.err) {
      props.setCookie("access_token", "");
    }
  };

  async function getSelectCountryOptions() {
    const res = await axios.get(
      `${process.env.REACT_APP_GAMDASH_CDN}/selectCountryOptions.json`
    );
    setSelectCountryOptions(res.data);
  }

  useEffect(() => {
    getSelectCountryOptions();
  }, []);

  useEffect(() => {
    if (publisherName?.length > 0) {
      const getData = async () => {
        const res = await getPublishersByName(publisherName);
        let tableFields = res.data.msg?.config?.columns ?? [
          "pv",
          "imp",
          "rev",
          "ecpm",
          "fill_rate",
          "eff_fill_rate",
          "adreq",
          "rpm",
        ];
        let columnData = initColumns.filter(
          (col) => tableFields.includes(col.key) || col.key === "DATE"
        );
        setColumns(columnData);
      };
      getData();
      checkToShowGamData();
    }
  }, [publisherName]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}-${month}`;
  }

  const handleWebsiteChange = (value) => {
    setFilterWeb(value);
  };

  const calcData = (msg) => {
    // console.log(msg);
    const today = new Date();
    if (
      graphFilter["endDate"].split("T")[0] === today.toISOString().split("T")[0]
    )
      graphFilter["endDate"] = today.toISOString();
    const res = { msg: msg };
    // setSitemap(res.msg.siteMap);
    // console.log(res.msg)
    res.msg.sort(compareDates);

    res.msg.map((obj) => {
      obj.createdAt = addTimezone(obj.createdAt, selectedTimezone);
    });
    // console.log(res);
    // setRawData(res.msg.data);
    let arr = [];
    let cnt = 0;
    res.msg.forEach((obj) => {
      if (filterWeb) {
        if (obj.website !== filterWeb) return;
      }
      const dt = obj.createdAt.split("T")[0];
      const found = arr.find((o) => o.date === dt);
      if (!found) {
        const temp = {};
        temp["key"] = cnt++;
        temp["date"] = dt;
        temp["pv"] = Number(obj.player_load);

        let imp = obj.imp_tags;
        let count = 0,
          cpm = 0,
          gCpm = 0;
        for (const key in imp) {
          count += imp[key].count;
          // cpm += Number(imp[key].cpm) * Number(imp[key].count);

          cpm += Number(imp[key].cpm) * Number(imp[key].count);
          // * (Number(webRevMap[obj.website]) / 100);
          gCpm += Number(imp[key].cpm) * Number(imp[key].count);
        }
        // console.log(obj.website)
        temp["total_imp"] = count;
        temp["avg_ecpm"] = round(cpm / count, 2);
        temp["gross_ecpm"] = round(gCpm / count, 2);
        // temp["avg_ecpm"] *= Number(webRevMap[obj.website]) / 100;
        let adreq = obj.ad_request_tags;
        let adreqCnt = 0;
        for (const key in adreq) {
          adreqCnt += Number(adreq[key]);
        }
        temp["adreq"] = Number(adreqCnt);
        temp["fill_rate"] = round(temp["total_imp"] / temp["adreq"], 2);
        temp["total_rev"] = round(
          (temp["avg_ecpm"] * temp["total_imp"]) / 1000,
          2
        );
        temp["gross_rev"] = round(
          (temp["gross_ecpm"] * temp["total_imp"]) / 1000,
          2
        );
        temp["rpm"] = round((temp["total_rev"] * 1000) / temp["pv"], 2);
        temp["eff_fill_rate"] = round(temp["total_imp"] / temp["pv"], 2);
        temp["website"] = obj.website;
        // console.log(temp)
        arr.push(temp);
      } else {
        found["pv"] += Number(obj.player_load);
        let imp = obj.imp_tags;
        let count = 0,
          cpm = 0;
        for (const key in imp) {
          count += imp[key].count;
          // cpm += Number(imp[key].cpm) * Number(imp[key].count);

          cpm +=
            Number(imp[key].cpm) *
            Number(imp[key].count) *
            (Number(webRevMap[obj.website]) / 100);
        }
        const oldCnt = found["total_imp"];
        const oldEcpm = found["avg_ecpm"] * oldCnt;
        found["total_imp"] += count;
        found["avg_ecpm"] = round((oldEcpm + cpm) / (oldCnt + count), 2);
        // found["avg_ecpm"] *= Number(webRevMap[obj.website]) / 100;
        let adreq = obj.ad_request_tags;
        let adreqCnt = 0;
        for (const key in adreq) {
          adreqCnt += Number(adreq[key]);
        }
        found["adreq"] += adreqCnt;
        found["fill_rate"] = round(found["total_imp"] / found["adreq"], 2);
        found["total_rev"] = round(
          (found["avg_ecpm"] * found["total_imp"]) / 1000,
          2
        );
        found["gross_rev"] = round(
          (found["gross_ecpm"] * found["total_imp"]) / 1000,
          2
        );
        found["rpm"] = round((found["total_rev"] * 1000) / found["pv"], 2);
        found["eff_fill_rate"] = round(found["total_imp"] / found["pv"], 2);
      }
    });
    // console.log(graphFilter);
    // console.log(arr);
    let impG = [],
      revG = [],
      ecpmG = [],
      fillG = [],
      adG = [],
      pvG = [],
      eFillG = [],
      rpmG = [];
    let total_imp = 0,
      total_rev = 0,
      avg_ecpm = 0,
      total_adreq = 0,
      fill_rate = 0,
      total_pv = 0,
      eff_fill_rate = 0,
      total_rpm = 0;
    arr.forEach((obj) => {
      // obj.avg_ecpm *= Number(webRevMap[obj.website]) / 100;
      // obj.total_rev *= Number(webRevMap[obj.website]) / 100;
      // obj.rpm *= Number(webRevMap[obj.website]) / 100;
      // console.log(Number(webRevMap[obj.website]));

      total_imp += obj.total_imp;
      if (obj.total_rev) total_rev += obj.total_rev;
      if (obj.avg_ecpm) avg_ecpm += obj.avg_ecpm * obj.total_imp;
      if (obj.adreq) total_adreq += obj.adreq;
      if (obj.pv) total_pv += obj.pv;
      if (obj.total_imp)
        impG.push({ date: formatDate(obj.date), Impression: obj.total_imp });
      if (obj.total_rev)
        revG.push({
          date: formatDate(obj.date),
          Revenue: obj.total_rev,
        });
      if (obj.avg_ecpm)
        ecpmG.push({
          date: formatDate(obj.date),
          eCPM: obj.avg_ecpm,
        });
      // fillG.push({ date: formatDate(obj.date), Fill_Rate: round(Number(obj.fill_rate*100),2) });
      // eFillG.push({ date: formatDate(obj.date), Eff_Fill_Rate: round(Number(obj.eff_fill_rate*100),2) });
      // adG.push({ date: formatDate(obj.date), Ad_Requests: obj.adreq });
      // pvG.push({ date: formatDate(obj.date), Page_Views: obj.pv });
      // rpmG.push({date: formatDate(obj.date), RPM: obj.rpm });
    });
    // console.log(arr);
    setTableData(arr);
    // console.log(arr);
    setImpGraph(impG);
    setRevGraph(revG);
    setEcpmGraph(ecpmG);
    // setFillGraph(fillG);
    // seteFillGraph(eFillG)
    // setAdGraph(adG)
    // setRpmGraph(rpmG)
    // setPvGraph(pvG)

    total_rev = round(total_rev, 2);
    total_rev.toFixed(2);
    total_imp = round(total_imp, 2);
    total_imp.toFixed(2);

    if (avg_ecpm !== 0 && total_imp !== 0) {
      avg_ecpm = round(avg_ecpm / total_imp, 2);
      avg_ecpm.toFixed(2);
    }
    // if (total_adreq !== 0) {
    //   fill_rate = total_imp / total_adreq;
    //   fill_rate = round(fill_rate, 4);
    // }
    // if (total_pv !== 0) {
    //   eff_fill_rate = total_imp / total_pv;
    //   eff_fill_rate = round(eff_fill_rate, 4);
    // }
    // if (total_pv !== 0) total_rpm = round((total_rev * 1000) / total_pv, 2);

    setTotalImp((prev) => {
      let obj = { ...prev, new: total_imp };
      if (prev.new !== total_imp) {
        obj["old"] = prev.new;
      }
      return obj;
    });
    setTotalRev((prev) => {
      let obj = { ...prev, new: total_rev };
      if (prev.new !== total_rev) {
        obj["old"] = prev.new;
      }
      return obj;
    });
    setAverageEcpm((prev) => {
      let obj = { ...prev, new: avg_ecpm };
      if (prev.new !== avg_ecpm) {
        obj["old"] = prev.new;
      }
      return obj;
    });

    const uniqueWeb = res.msg.map((site) => {
      return site.website;
    });

    let uniqueChars = [...new Set(uniqueWeb)];
    setWebsites(uniqueChars);
    setSpin(false);
  };

  function compareDates(a, b) {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  const getWeb = async () => {
    const res = await getWebsites();
    const temp = {};
    res.data.msg.forEach((obj) => {
      temp[obj["url"]] = obj["revShare"];
    });
    setWebRevMap(temp);
  };

  const getWebsiteListByPub = async () => {
    const res = await getWebsiteByPub({ parent: publisherName });
    if (!res.err) {
      const websiteList = res.msg.data.map((item) => item.url);
      setWebsites(websiteList);
      setFilterWeb(websiteList[0]);
    }
  };

  const getFilterWebInfo = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CDN_URL}/mvp/websiteInfo.json`
    );
    setFilterWebInfo(res.data);
  };

  const checkToShowGamData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_CDN_URL}/mvp/gamPublishers.json`
    );
    console.log(res.data.includes(publisherName));
    if (res.data.includes(publisherName)) setShowGamData(true);
  };

  useEffect(() => {
    if (!showGamData) getWeb();
  }, []);

  useEffect(() => {
    if (showGamData) {
      getFilterWebInfo();
      getWebsiteListByPub();
    }
  }, [showGamData]);

  useEffect(() => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1, 5, 30);

    setGraphFilter({
      startDate: firstDay.toISOString(),
      endDate: today.toISOString(),
      publisher: publisherName,
    });
  }, [userType]);

  useEffect(() => {
    if (
      graphFilter["startDate"] !== "" &&
      graphFilter["endDate"] !== "" &&
      Object.keys(webRevMap).length > 0
    ) {
      setSpin(true);
      console.log("graphFilter useEffect ran");

      const getGAMData = async () => {
        const res = await axios.post(
          `${process.env.REACT_APP_CLIENTDASH_URL}/client/device_country_DB`,
          {
            data: {
              dateStart: graphFilter["startDate"].split("T")[0],
              dateEnd: graphFilter["endDate"].split("T")[0],
              deviceType: selectedDevice,
              countryName: selectedCountry,
              adUnitIDMain: filterWebInfo[filterWeb]["adUnitIDMain"],
              adUnitIDNewMain: filterWebInfo[filterWeb]["adUnitIDNewMain"],
              GaPropertyId: filterWebInfo[filterWeb]["GaPropertyId"],
              children: "false",
            },
          },
          {
            headers: {
              // "x-api-key": process.env.REACT_APP_API_KEY,
              Authorization: process.env.REACT_APP_GLOBAL_TOKEN,
            },
          }
        );
        console.log(res.data);

        const revenueGraph = res.data.revGraph.map((item) => ({
          date: item.name,
          Revenue: parseFloat(
            (
              parseFloat(item.Revenue) * filterWebInfo[filterWeb].revShare
            ).toFixed(2)
          ),
        }));

        const impressionGraph = res.data.impGraph.map((item) => ({
          date: item.name,
          Impressions: parseFloat(item.Impressions),
        }));

        const ecpmGraph = res.data.ecpmGraph.map((item) => ({
          date: item.name,
          eCPM: parseFloat(item.eCPM),
        }));

        setRevGraph(revenueGraph);
        setImpGraph(impressionGraph);
        setEcpmGraph(ecpmGraph);
        setTotalImp({ new: res.data.imp });
        setTotalRev({
          new: (res.data.rev * filterWebInfo[filterWeb].revShare).toFixed(2),
        });
        setAverageEcpm({ new: res.data.ecpm });
        setTableData(res.data.data);
        setSpin(false);
      };
      if (showGamData) {
        getGAMData();
      } else {
        let socket = io(process.env.REACT_APP_API_URL, {
          transports: ["websocket"],
        });
        const getData = () => {
          const start = convertTimezone(
            graphFilter["startDate"],
            selectedTimezone
          );
          let end = graphFilter["endDate"];
          if (
            !(
              graphFilter["endDate"].split("T")[0] ==
              new Date().toISOString().split("T")[0]
            )
          ) {
            end = convertTimezone(graphFilter["endDate"], selectedTimezone);
          }
          socket.emit("data", {
            name: publisherName,
            startDate: start,
            endDate: end,
            type: userType,
          });
        };
        getData();
        const interval = setInterval(() => {
          // setSpin(false)
          getData();
        }, 10000);

        // console.log("Set interval: ", interval);

        socket.on("data_received", (msg) => {
          calcData(msg);
        });

        return () => {
          socket.off("data_received");
          // console.log("Clear interval: ", interval);
          clearInterval(interval);
        };
      }
    }
  }, [
    graphFilter,
    filterWeb,
    publisherName,
    selectedTimezone,
    webRevMap,
    selectedCountry,
    selectedDevice,
  ]);

  return (
    <>
      {spin ? (
        <LoaderComp pubdash={true} />
      ) : (
        <div>
          <div className="px-12 ">
            <div className="flex justify-between items-center mx-1">
              <img
                width="150px"
                src={`https://unibots.b-cdn.net/managed_vp_imgs/${
                  process.env.REACT_APP_MODE === "dev"
                    ? "unibots"
                    : window.location.hostname.split(".")[1]
                }.svg`}
                alt="client logo"
              />
              <div className="flex gap-3">
                <Select
                  placeholder="Websites"
                  onChange={handleWebsiteChange}
                  style={{
                    width: 200,
                  }}
                  defaultValue={filterWeb}
                >
                  {websites &&
                    websites?.map((site) => (
                      <Select.Option value={site}>{site}</Select.Option>
                    ))}
                </Select>
                <Select
                  // mode="multiple"
                  showSearch
                  style={{ width: 160 }}
                  placeholder="Select Country"
                  options={selectCountryOptions}
                  value={selectedCountry}
                  onChange={(val) => {
                    setSelectedCountry(val);
                  }}
                ></Select>
                <Select
                  style={{ width: 160 }}
                  placeholder="Select Device"
                  options={selectOptions}
                  value={selectedDevice}
                  onChange={(val) => setSelectedDevice(val)}
                ></Select>
              </div>

              <div className="flex justify-between items-center mb-3 mt-3 space-x-3 ">
                {/* <div className="flex justify-between"> */}
                {/* <TimezoneDropdown
                  // onChange={handleTimezoneChange}
                  selectedTimezone={selectedTimezone}
                  setSelectedTimezone={setSelectedTimezone}
                /> */}
                <Radio.Group
                  options={options}
                  value={radioValue}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={handleChange}
                />
                <RangePicker
                  // className="w-2/5"
                  onChange={handleDateChange}
                  value={[
                    dayjs.utc(graphFilter["startDate"].split("T")[0]),
                    dayjs.utc(graphFilter["endDate"].split("T")[0]),
                  ]}
                />

                {/* <Button
                  className="mx-2"
                  type="primary"
                  onClick={() => setSearchDate(searchDate + 1)}
                >
                  Search
                </Button> */}
              </div>

              <Button
                style={{ marginLeft: "20px" }}
                danger
                type="text"
                icon={<LogoutOutlined />}
                size="small"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
            <div>
              <div>
                <div className="flex justify-between items-center my-4 w-full">
                  <div className="flex space-x-4 h-72 w-full pr-4">
                    <div className="w-1/3 bg-white p-4 rounded-md flex justify-center">
                      <AreaGraph
                        gradientColor="#2e7d32"
                        graphColor="#2e7d32"
                        graphData={impGraph}
                        dataKey={"Impressions"}
                        index={"1"}
                      />
                    </div>
                    <div className="w-1/3 bg-white p-4 rounded-md flex justify-center">
                      <AreaGraph
                        gradientColor="#1976d2"
                        graphColor="#1976d2"
                        graphData={revGraph}
                        dataKey={"Revenue"}
                        index={"2"}
                      />
                    </div>
                    <div className="w-1/3 bg-white p-4 rounded-md flex justify-center">
                      <AreaGraph
                        gradientColor="#e83462"
                        graphColor="#e83462"
                        graphData={ecpmGraph}
                        dataKey={"eCPM"}
                        index={"3"}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mb-6 mx-40">
                  <Tag color="green">Total Imp: {totalImp.new}</Tag>
                  <Tag color="blue">Total Rev: {totalRev.new}</Tag>
                  <Tag color="volcano">Average eCPM: {AverageEcpm.new}</Tag>
                </div>
                <div>
                  <Table
                    bordered
                    columns={
                      // publisherName === "footballwebb" ? gamColumn : columns
                      showGamData ? gamColumn : columns
                    }
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100"],
                    }}
                    dataSource={tableData}
                    scroll={{
                      x: 1000,
                      y: 800,
                    }}
                  />
                </div>
                <div className="flex justify-between my-2">
                  <Typography variant="caption">
                    *NA entries will be updated within 36 hours
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PublisherDash;
